import React from "react";
import Popover from "react-bootstrap/Popover";
import { connect } from "react-redux";
import InventorySegmentLabel from "./inventory_segment_label";
import { updateSegmentLocally, saveSegment } from "./redux/actions";

class InventorySegmentLabelPopover extends React.Component {
  constructor(props) {
    super(props);
    this.handleLabelChange = this.handleLabelChange.bind(this);
  }

  handleLabelChange(segment, label) {
    const { updateSegmentLocally, saveSegment } = this.props;
    segment.label = label;
    updateSegmentLocally(segment);
    saveSegment(segment);
  }

  render() {
    const that = this;
    const { segment, labels } = this.props;
    const propsWithoutFunctions = _.cloneDeep(this.props);
    delete propsWithoutFunctions.updateSegmentLocally;
    delete propsWithoutFunctions.saveSegment;
    return (
      <Popover {...propsWithoutFunctions}>
        <Popover.Content>
          <div className="text-bold">Choose a label</div>
          <div className="d-flex flex-wrap" style={{ width: "150px" }}>
            {_.map(labels, (label, i) => (
              <InventorySegmentLabel
                key={i}
                className="clickable mr-1 mb-1"
                label={label}
                onClick={() => {
                  that.handleLabelChange(segment, label);
                }}
              />
            ))}
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}
export default connect(mapStateToProps, { updateSegmentLocally, saveSegment })(
  InventorySegmentLabelPopover
);
