import React from "react";
import Toggle from "react-toggle";
import {
  triggerQuestionClusters,
  capitalizeFirstLetterAndAddQuestionMark,
  getShuffledClusterColors,
} from "./helpers";
import Loader from "./loader";

class EditorSidebarQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupBySemanticSimilarityLoading: false,
      groupBySemanticSimilarity: false,
    };

    this.enableGroupBySemanticSimilarity =
      this.enableGroupBySemanticSimilarity.bind(this);
    this.handleSemanticSimiliarityToggleClick =
      this.handleSemanticSimiliarityToggleClick.bind(this);
    this.getQuestionArray = this.getQuestionArray.bind(this);
  }

  enableGroupBySemanticSimilarity() {
    // calls the API to get the topic relationships for this content brief
    const that = this;
    const reportId = this.props.report.id;
    const { groupBySemanticSimilarityLoading } = this.state;
    if (!groupBySemanticSimilarityLoading) {
      this.setState({ groupBySemanticSimilarityLoading: true });
      triggerQuestionClusters(
        reportId,
        () => {
          that.props.fetchReport(reportId);
          that.setState({
            groupBySemanticSimilarityLoading: false,
            groupBySemanticSimilarity: true,
          });
        },
        () => {
          that.props.showToast({
            message: `Something went wrong clustering the questions.`,
            options: { appearance: "error", autoDismiss: true },
          });
          that.setState({
            groupBySemanticSimilarityLoading: false,
            groupBySemanticSimilarity: false,
          });
        }
      );
    }
  }

  handleSemanticSimiliarityToggleClick() {
    let { quora_questions, autocomplete_questions, questions } = this.props;
    quora_questions = this.getQuestionArray(quora_questions);
    autocomplete_questions = this.getQuestionArray(autocomplete_questions);
    questions = this.getQuestionArray(questions);

    const all_questions = quora_questions
      .concat(autocomplete_questions)
      .concat(questions);
    const enableSortingToggle =
      !_.isEmpty(all_questions) && _.isNumber(all_questions[0].cluster);
    if (enableSortingToggle) {
      this.setState({
        groupBySemanticSimilarity: !this.state.groupBySemanticSimilarity,
      });
    } else {
      this.enableGroupBySemanticSimilarity();
    }
  }

  getQuestionArray(q) {
    if (!_.isEmpty(q) && _.isArray(q.questions) && !_.isEmpty(q.questions)) {
      return q.questions;
    }
    return [];
  }

  editorSidebarQuestionsBySource() {
    let { questions, quora_questions, autocomplete_questions } = this.props;
    questions = this.getQuestionArray(questions);
    quora_questions = this.getQuestionArray(quora_questions);
    autocomplete_questions = this.getQuestionArray(autocomplete_questions);
    const hasNoQuestions =
      questions.length +
        quora_questions.length +
        autocomplete_questions.length <
      1;

    function renderQuestionSourceGroup(title, questions) {
      if (questions.length > 0) {
        return (
          <div>
            <div className="text-bold mt-2">{title}</div>
            <table className="table-minimal table-striped mt-0">
              <tbody>
                {_.map(questions, (q, i) => (
                  <tr key={i}>
                    <td>{q.keyword}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }
      return <div />;
    }

    return (
      <div>
        {hasNoQuestions && <>No questions found</>}
        {!hasNoQuestions && (
          <>
            {renderQuestionSourceGroup("Google People Also Ask", questions)}
            {renderQuestionSourceGroup(
              "Google Autosuggest",
              autocomplete_questions
            )}
            {renderQuestionSourceGroup("Quora", quora_questions)}
          </>
        )}
      </div>
    );
  }

  editorSidebarQuestionsByCluster() {
    const { clusteredQuestions, report } = this.props;
    const colorSeed = report.name.length; // this ensures colors stay consistent within a brief
    const clusterColors = getShuffledClusterColors(colorSeed);

    return (
      <div className="mb-3">
        {_.map(clusteredQuestions, (cluster, i) => {
          cluster = _.sortBy(
            cluster,
            (q) => -1 * (_.isNumber(q.vol) ? q.vol : 0)
          );
          return (
            <div key={i} className="d-flex mb-3">
              <div
                className={`bg-${clusterColors[i % clusterColors.length]}-5`}
                style={{ width: "3px" }}
              />
              <table
                className="table-minimal table-striped mt-0 mb-0 flex-grow-1"
                style={{
                  border: "3px solid rgba(94, 130, 152, 0.05)",
                  borderLeft: "none",
                }}
              >
                <tbody>
                  {_.map(cluster, (question, ii) => (
                    <tr key={ii}>
                      <td className={`pl-2 ${ii === 0 ? "text-bold" : ""}`}>
                        {question.keyword}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { groupBySemanticSimilarity, groupBySemanticSimilarityLoading } =
      this.state;
    return (
      <div className="p-3 w-100">
        <div className="text-bold text-l">Questions to Cover</div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="mb-2">
              <div className="d-flex toggle-small mt-2">
                <Toggle
                  id="group-by-semantic"
                  checked={groupBySemanticSimilarity}
                  icons={false}
                  onChange={this.handleSemanticSimiliarityToggleClick}
                />
                <label
                  style={{ marginLeft: "5px" }}
                  className="text-s clickable"
                  htmlFor="group-by-semantic"
                >
                  Group By Theme
                </label>
                {groupBySemanticSimilarityLoading && (
                  <div className="ml-3" style={{ marginTop: "-3px" }}>
                    <Loader type="spinner" className="dark" />{" "}
                    <div
                      className="text-muted"
                      style={{ marginTop: "-30px", marginLeft: "35px" }}
                    >
                      <span className="text-s">
                        analyzing Questions... (this may take a minute)
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {groupBySemanticSimilarity && this.editorSidebarQuestionsByCluster()}
          {!groupBySemanticSimilarity && this.editorSidebarQuestionsBySource()}
        </div>
      </div>
    );
  }
}

export default EditorSidebarQuestions;
