import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import {
  fetchReportsIfNeeded,
  invalidateReports,
  saveReport,
  addTags,
} from "./redux/actions";
import { getActiveReport } from "./redux/selectors";
import { validateTags, loadTags } from "./helpers";

class ReportTagModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: null,
      reportError: "",
      reportId: null,
    };
    this.formRef = React.createRef();
    this.modalRef = React.createRef();
    this.saveTags = this.saveTags.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    const that = this;
    $(this.modalRef.current).on("hidden.bs.modal", () => {
      that.setState({ reportError: "" });
    });
  }

  // this lifecycle method is used to copy redux experiment data to this component's local state
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.report && nextProps.report.id !== state.reportId) {
      // we cache the reportId in the component's state and check against what is in redux
      // then, we only update name if reportId has changed
      const derivedState = {
        tags: _.map(nextProps.report.tags, (tag) => ({
          value: tag,
          label: tag,
        })),
        reportId: nextProps.report.id,
      };
      return derivedState;
    }
    return null;
  }

  saveTags() {
    const tagArray = _.map(this.state.tags, (tag) => tag.value.trim());

    // update the report
    const reportCopy = { ...this.props.report };
    reportCopy.tags = tagArray;
    this.props.saveReport(reportCopy);

    // update redux tag object so that navigation updates with any new tags
    // note: we do not remove tags from redux because doing so requires knowledge of the global
    // state of tags, which is not possible with the current architecture of the system
    this.props.addTags(tagArray);

    $(".report-tag-modal").modal("hide");
  }

  validate(successFunction, event) {
    if (this.formRef.current.reportValidity()) {
      const { tags } = this.state;
      const isValid = validateTags(tags);
      if (!isValid) {
        this.setState({
          reportError:
            "Only letters, numbers, underscores, and spaces are allowed.",
        });
      } else {
        successFunction(event);
      }
    }
  }

  render() {
    return (
      <div
        ref={this.modalRef}
        aria-hidden="true"
        aria-labelledby="reportTagModal"
        className="modal fade report-tag-modal onboarding-modal"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div
              className="onboarding-content with-gradient"
              style={{ textAlign: "center" }}
            >
              <h4 className="onboarding-title">Edit Tags</h4>
              <div className="onboarding-text">
                Use tags to organize your content briefs.
              </div>
              <form ref={this.formRef}>
                <div className="row">
                  <div className="col-sm-12">
                    <label htmlFor="location">
                      <span>Tags</span>
                    </label>
                    <AsyncCreatableSelect
                      name="location"
                      cacheOptions
                      isMulti
                      defaultOptions={_.map(this.props.tags, (tag) => ({
                        value: tag,
                        label: tag,
                      }))}
                      loadOptions={loadTags}
                      onChange={(selected) => {
                        this.setState({ tags: selected });
                      }}
                      value={this.state.tags}
                      placeholder="Add a tag to your report"
                    />
                  </div>
                </div>
                {this.state.reportError && (
                  <div className="row">
                    <div className="col-sm-12 text-danger">
                      {this.state.reportError}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-buttons-w">
                      <button
                        onClick={this.validate.bind(null, this.saveTags)}
                        className="btn btn-primary"
                        type="button"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    report: getActiveReport(state),
    tags: state.tags,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchReportsIfNeeded,
    invalidateReports,
    saveReport,
    addTags,
  })(ReportTagModal)
);
