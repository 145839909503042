import KeenTracking from 'keen-tracking';

const KEEN_MAX_LENGTH = 10000;
const truncateNote = '... (truncated)';

// Initialize a instance of the KeenTracking client
const client = new KeenTracking({
  projectId: AP.keen_project_id,
  writeKey: AP.keen_write_key
});

// Helper function to track events (we can add more keen functions to disabling import warning here)
// eslint-disable-next-line import/prefer-default-export
export function trackEvent(name, data) {
  if (!AP.keen_project_id || !AP.keen_write_key) {
    return;
  }

  // Keen tracking has a limit of 10,000 characters per event
  // If any of the values are longer than that, we truncate them so there's no error
  const parsedData = Object.entries(data).reduce((acc, [key, value]) => {
    if(value.toString().length > KEEN_MAX_LENGTH) {
      acc[key] = `${value.toString().substring(0, KEEN_MAX_LENGTH-truncateNote.length)}${truncateNote}`;
    }
    else {
      acc[key] = value;
    }
    return acc;
  });

  client.recordEvent(name, parsedData);
}