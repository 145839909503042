import React from "react";

class ReportAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.animate = this.animate.bind(this);
    this.steps = [
      `Searching Google for \"${this.props.keyword}\"`,
      "Extracting content from top 30 results",
      "Analyzing competitor content",
      "Using Topic AI to discover key topics",
    ];
    this.stepTime = 3000;
    this.state = {
      steps: [],
      currentStep: 0,
    };
    this.widthCounter = 0;
    this.node = React.createRef();
  }

  componentDidMount() {
    const that = this;
    window.setTimeout(() => {
      that.animate();
    }, 350);
  }

  animate() {
    const that = this;

    this.animationInterval = window.setInterval(() => {
      if (that.widthCounter < 100) {
        that.widthCounter++;
      }

      // each time this interval runs, increment the current step's progress bar
      $(that.node.current)
        .find(`[data-step="${that.state.currentStep}"] .progress-bar`)
        .css("width", `${that.widthCounter}%`);

      // look at the status being passed in from the modal to check whether it is time to finish and step forward
      const { status } = that.props;
      if (
        (status === "" || status === "Extracting Topics") &&
        that.widthCounter === 1
      ) {
        that.setState({
          currentStep: 0,
          steps: that.state.steps.concat([that.steps[0]]),
        });
      } else if (
        status === "Filtering Relevant Topics" &&
        that.state.currentStep !== 1
      ) {
        that.widthCounter = 0;
        $(that.node.current)
          .find(`[data-step="${that.state.currentStep}"] .progress-bar`)
          .addClass("bg-success")
          .css("width", "100%");
        that.setState({
          currentStep: 1,
          steps: that.state.steps.concat([that.steps[1]]),
        });
      } else if (
        status === "Analyzing Competitors Content" &&
        that.state.currentStep !== 2
      ) {
        that.widthCounter = 0;
        $(that.node.current)
          .find(`[data-step="${that.state.currentStep}"] .progress-bar`)
          .addClass("bg-success")
          .css("width", "100%");
        that.setState({
          currentStep: 2,
          steps: that.state.steps.concat([that.steps[2]]),
        });
      } else if (
        status === "Generating Summary" &&
        that.state.currentStep !== 3
      ) {
        that.widthCounter = 0;
        $(that.node.current)
          .find(`[data-step="${that.state.currentStep}"] .progress-bar`)
          .addClass("bg-success")
          .css("width", "100%");
        that.setState({
          currentStep: 3,
          steps: that.state.steps.concat([that.steps[3]]),
        });
      } else if (status === "Success") {
        window.clearInterval(that.animationInterval);
      }
    }, 1000);
  }

  render() {
    return (
      <div ref={this.node} className="text-center report-animation">
        <h3>Creating Content Brief</h3>
        {this.state.steps.map((step, index) => (
          <div key={index} data-step={index} className="mb-3 mt-3 fadeInDown">
            <div className="progress">
              <div
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow="0"
                className="progress-bar"
                role="progressbar"
              />
            </div>
            <div className="text-muted mt-1">{step}</div>
          </div>
        ))}
      </div>
    );
  }
}

export default ReportAnimation;
