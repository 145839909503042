import React from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getActiveSegment } from "./redux/selectors";
import {
  fetchPages,
  updatePageSortOrder,
  setSelectedPages,
  setSelectedSegment,
} from "./redux/actions";
import { timeAgo } from "./helpers";
import InventoryPageModal from "./inventory_page_modal";

class InventoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlColumnExpanded: false,
      activePage: null, // used by the inventory modal
    };
    this.handleSortClick = this.handleSortClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.fetchPages = this.fetchPages.bind(this);
    this.getGradeBadge = this.getGradeBadge.bind(this);
    this.getMetricDeltaLabel = this.getMetricDeltaLabel.bind(this);
    this.handleClearSelection = this.handleClearSelection.bind(this);
    this.handleSelectSegment = this.handleSelectSegment.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleChangeLimit = this.handleChangeLimit.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSegment, inventory, sort, order, extraFilters, limit } =
      this.props;
    const segmentChanged = prevProps.activeSegment.id !== activeSegment.id;
    const sortChanged = prevProps.sort !== sort;
    const orderChanged = prevProps.order !== order;
    const limitChanged = prevProps.limit !== limit;
    const extraFilterChanged = !_.isEqual(prevProps.extraFilters, extraFilters);
    const activeSegmentFiltersChanged = !_.isEqual(
      prevProps.activeSegment.filters,
      activeSegment.filters
    );
    const activeSegmentFiltersHaveValues =
      _.filter(activeSegment.filters, (filter) => _.isEmpty(filter.value))
        .length === 0;
    if (
      inventory &&
      (segmentChanged ||
        sortChanged ||
        orderChanged ||
        limitChanged ||
        extraFilterChanged ||
        (activeSegmentFiltersChanged && activeSegmentFiltersHaveValues))
    ) {
      this.fetchPages();
    }
  }

  handleSortClick(newSort) {
    const { sort, order, updatePageSortOrder, limit } = this.props;
    if (newSort === sort) {
      updatePageSortOrder({
        offset: 0,
        sort: newSort,
        order: order === "desc" ? "asc" : "desc",
        limit,
      });
    } else {
      // name gets default asc sort
      updatePageSortOrder({
        offset: 0,
        sort: newSort,
        order: newSort === "title" ? "asc" : "desc",
        limit,
      });
    }
  }

  handlePageClick(data) {
    const { selected } = data;
    const { limit, sort, order, updatePageSortOrder } = this.props;
    const offset = Math.ceil(selected * limit);

    updatePageSortOrder({ offset, sort, order, limit });
    window.setTimeout(this.fetchPages, 1);
    // this.fetchPages();
  }

  fetchPages() {
    const {
      limit,
      inventory,
      activeSegment,
      sort,
      order,
      fetchPages,
      extraFilters,
      offset,
    } = this.props;
    const activeSegmentIsUnsaved =
      activeSegment && activeSegment.saveState === "unsaved";
    fetchPages(inventory.id, activeSegment.id, {
      sort,
      order,
      offset,
      limit,
      extraFilters,
      segment_filter: activeSegmentIsUnsaved ? activeSegment.filters : null,
    });
  }

  getGradeBadge() {
    const random = _.random(5);
    const randomGrade = ["A", "A-", "B", "C", "D", "F"][random];
    const randomColor = [
      "green",
      "yellow-green",
      "yellow",
      "orange-yellow",
      "orange",
      "red",
    ][random];
    return (
      <div
        className={`badge badge-pill bg-${randomColor}-2 text-${randomColor}-10`}
      >
        {randomGrade}
      </div>
    );
  }

  getMetricDeltaLabel(before, after, globalMinMax) {
    const globalMin = globalMinMax[0];
    const globalMax = globalMinMax[1];
    const delta = after - before;
    let percentage = null;
    if (before !== 0 && delta !== 0) {
      percentage = Math.round((delta / before) * 100);
    }
    let color;

    const minThreshhold = globalMin < 0 ? globalMin : -100;
    const maxThreshhold = globalMax > 0 ? globalMax : 100;

    if (delta < minThreshhold * 0.75) {
      color = "red";
    } else if (delta < minThreshhold * 0.5) {
      color = "orange";
    } else if (delta < minThreshhold * 0.25) {
      color = "orange-yellow";
    } else if (delta < 0) {
      color = "yellow";
    } else if (delta < maxThreshhold * 0.3) {
      color = "primary";
    } else if (delta < maxThreshhold * 0.6) {
      color = "yellow-green";
    } else {
      color = "green";
    }

    return (
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className={`badge badge-pill bg-${color}-2 text-${color}-10`}>
            {delta > 0 ? "+" : ""}
            {delta}
          </div>
        </div>
        <div className="text-primary-4 text-s mt-2 d-flex">
          <div>
            {before} <i className="fal fa-arrow-right" /> {after}
          </div>
          {percentage && (
            <div className="ml-2">
              {delta > 0 ? "+" : ""}
              {percentage}%
            </div>
          )}
        </div>
      </div>
    );
  }

  handleSelectAll() {
    const { selectedPageIds, pages, setSelectedPages } = this.props;
    if (!_.isEmpty(selectedPageIds)) {
      this.handleClearSelection();
    } else {
      setSelectedPages(_.map(pages, "id"));
    }
  }

  handleSelectPage(id) {
    const { selectedPageIds, setSelectedPages } = this.props;
    if (_.includes(selectedPageIds, id)) {
      setSelectedPages(_.filter(selectedPageIds, (pid) => pid !== id));
    } else {
      setSelectedPages(_.concat(selectedPageIds, id));
    }
  }

  handleClearSelection() {
    const { setSelectedSegment, setSelectedPages } = this.props;
    setSelectedPages(null);
    setSelectedSegment(null);
  }

  handleSelectSegment() {
    const { setSelectedSegment, setSelectedPages, activeSegment } = this.props;
    setSelectedPages(null);
    setSelectedSegment(activeSegment.id);
  }

  handleChangeLimit(selected) {
    const { offset, sort, order, updatePageSortOrder } = this.props;
    const limit = selected.value;
    updatePageSortOrder({ offset, sort, order, limit });
  }

  handleRowClick(page) {
    this.setState({
      activePage: page,
    });
    $(".inventory-page-modal").modal("show");
  }

  render() {
    const that = this;

    const {
      inventory,
      pages,
      total,
      limit,
      isFetching,
      sort,
      order,
      selectedPageIds,
      selectedSegment,
      segments,
      activeSegment,
      clicks_delta_min_max,
      impressions_delta_min_max,
    } = this.props;
    const { urlColumnExpanded, activePage } = this.state;

    const tableColumns = [
      // label, key, isSortable
      ["URL", "url", false],
      ["Top Keyword", "top_keyword", false],
      ["Clicks", "clicks", true],
      ["Impressions", "impressions", true],
      ["Position", "position", false],
      ["CTR", "ctr", false],
      // ['Grade', 'grade', false],
      ["Title", "title", false],
    ];
    const showEmptyState = _.isEmpty(pages);
    const pageCount = Math.ceil(total / limit);

    let selectedSegmentObj;
    const hasSelectedSegment = _.isNumber(selectedSegment);
    if (hasSelectedSegment) {
      selectedSegmentObj = _.find(segments, { id: selectedSegment });
    }
    const hasSelectedAllPagesButNotEntireSegment =
      pages &&
      selectedPageIds &&
      !_.isEmpty(selectedPageIds) &&
      _.isEqual(_.sortBy(_.map(pages, "id")), _.sortBy(selectedPageIds)) &&
      !hasSelectedSegment;
    const activeSegmentCanBeSelected = _.isNumber(activeSegment.id);
    const activeSegmentHasMoreThanOnePage =
      activeSegment.page_count !== pages.length;
    const anythingIsSelected =
      !_.isEmpty(selectedPageIds) || _.isNumber(selectedSegment);
    const everythingIsSelected =
      hasSelectedAllPagesButNotEntireSegment || hasSelectedSegment;
    const activeSegmentIsIgnoredPages = activeSegment.id === "ignored";

    return (
      <div ref={this.ref} className="inventory-table flex-grow-1">
        {selectedSegmentObj && (
          <div className="inventory-table-select-bar text-center">
            All {selectedSegmentObj.page_count} pages in "
            {selectedSegmentObj.name}" segment are selected.{" "}
            <a href="#" onClick={this.handleClearSelection}>
              Clear selection
            </a>
          </div>
        )}
        {hasSelectedAllPagesButNotEntireSegment && (
          <div className="inventory-table-select-bar text-center">
            {selectedPageIds.length}{" "}
            {selectedPageIds.length > 1 ? "pages are" : "page is"} selected.
            {activeSegmentCanBeSelected && activeSegmentHasMoreThanOnePage && (
              <a href="#" onClick={this.handleSelectSegment}>
                {" "}
                Select all {activeSegment.page_count} pages in "
                {activeSegment.name}" segment
              </a>
            )}
          </div>
        )}
        {showEmptyState && (
          <div className="p-5 text-center">
            {!activeSegmentIsIgnoredPages && <div>No pages found.</div>}
            {activeSegmentIsIgnoredPages && (
              <div>
                You haven't ignored any pages yet. Select one or more pages and
                click "Ignore" in order to keep them from showing up in your
                table. Useful for getting rid of spurrious URLs generated by
                your CMS.
              </div>
            )}
          </div>
        )}
        {!showEmptyState && (
          <div className="inventory-table-div">
            <table
              className={`table-striped w-100 ${
                urlColumnExpanded ? "inventory-table-url-expanded" : ""
              }`}
              style={{ opacity: `${isFetching ? "0.7" : "1"}` }}
            >
              <thead className="text-bold">
                <tr>
                  {tableColumns.map((item, i) => {
                    const label = item[0];
                    const key = item[1];
                    const isSortable = item[2];
                    let caret = (
                      <i
                        className="show-on-parent-hover fas fa-sort-down"
                        style={{ opacity: "0.5" }}
                      />
                    );
                    let thClass = "";
                    // logic to handle "select all" behavior
                    const isUrl = i === 0;

                    // sortable
                    if (sort === key) {
                      caret =
                        order === "desc" ? (
                          <i className="fas fa-sort-down" />
                        ) : (
                          <i className="fas fa-sort-up" />
                        );
                      thClass = "text-bluegray-6";
                    }
                    return (
                      <th
                        className={`${
                          isSortable ? "clickable" : ""
                        } ${thClass} ${
                          i === 0 ? "inventory-table-first-col" : ""
                        }`}
                        key={i}
                        onClick={() => {
                          if (isSortable) {
                            that.handleSortClick(key);
                          }
                        }}
                      >
                        <div className="d-flex">
                          {isUrl && (
                            <div className="pr-4">
                              <label
                                className={`checkbox ${
                                  anythingIsSelected && !everythingIsSelected
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  checked={everythingIsSelected}
                                  onChange={that.handleSelectAll}
                                />
                              </label>
                            </div>
                          )}
                          <div className={`${isUrl ? "flex-grow-1" : ""}`}>
                            {label} {isSortable && caret}
                          </div>
                          {isUrl && !urlColumnExpanded && (
                            <div
                              className="clickable text-primary-5"
                              onClick={() => {
                                that.setState({
                                  urlColumnExpanded:
                                    !that.state.urlColumnExpanded,
                                });
                              }}
                            >
                              <OverlayTrigger
                                overlay={<Tooltip>Expand URL</Tooltip>}
                              >
                                <i className="fad fa-arrow-alt-to-right" />
                              </OverlayTrigger>
                            </div>
                          )}
                          {isUrl && urlColumnExpanded && (
                            <div
                              className="clickable text-primary-5"
                              onClick={() => {
                                that.setState({
                                  urlColumnExpanded:
                                    !that.state.urlColumnExpanded,
                                });
                              }}
                            >
                              <OverlayTrigger
                                overlay={<Tooltip>Shrink URL</Tooltip>}
                              >
                                <i className="fad fa-arrow-alt-to-left" />
                              </OverlayTrigger>
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {_.map(pages, (page, i) => {
                  const url = new URL(page.url);
                  let {
                    clicks,
                    impressions,
                    previous_clicks,
                    previous_impressions,
                    id,
                  } = page;
                  const isSelected =
                    _.includes(selectedPageIds, id) || hasSelectedSegment;
                  if (
                    _.isNumber(previous_clicks) &&
                    _.isNumber(previous_impressions)
                  ) {
                    clicks = that.getMetricDeltaLabel(
                      previous_clicks,
                      clicks,
                      clicks_delta_min_max
                    );
                    impressions = that.getMetricDeltaLabel(
                      previous_impressions,
                      impressions,
                      impressions_delta_min_max
                    );
                  }
                  return (
                    <tr
                      key={i}
                      className={`clickable ${i % 2 === 0 ? "" : "odd"}`}
                      onClick={() => {
                        this.handleRowClick(page);
                      }}
                    >
                      <td className="inventory-table-first-col">
                        <div className="d-flex">
                          <div className="pr-4">
                            <label
                              className="checkbox"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => {
                                  that.handleSelectPage(id);
                                }}
                              />
                            </label>
                          </div>
                          <div className="inventory-table-url">
                            <span title={`${url.pathname}${url.hash}`}>
                              <a
                                href={page.url}
                                target="_blank"
                                className="text-blue-5"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                rel="noreferrer"
                              >{`${url.pathname}${url.hash}`}</a>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{page.top_keyword}</td>
                      <td className="text-right">{clicks}</td>
                      <td className="text-right">{impressions}</td>
                      <td className="text-right">
                        {page.top_keyword_position
                          ? Math.round(page.top_keyword_position)
                          : ""}
                      </td>
                      <td className="text-right">{`${(
                        (page.clicks / page.impressions) *
                        100
                      ).toFixed(1)}%`}</td>
                      <td>{page.metadata.title}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {!showEmptyState && (
          <div className="text-center position-relative">
            <div
              className="text-left pt-2 text-primary-4"
              style={{
                position: "absolute",
                left: 0,
                top: "7px",
                width: "350px",
              }}
            >
              Updated: {timeAgo(inventory.last_updated)}
            </div>
            <ReactPaginate
              previousLabel="previous"
              nextLabel="next"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
            />
            <div
              className="text-right d-flex"
              style={{
                position: "absolute",
                right: 0,
                top: "7px",
                width: "350px",
              }}
            >
              <div className="flex-grow-1" />
              <div className="mt-2">Showing </div>
              <div style={{ width: "80px" }}>
                <Select
                  style={{ width: "80px" }}
                  options={[
                    {
                      value: 20,
                      label: "20",
                    },
                    {
                      value: 50,
                      label: "50",
                    },
                    {
                      value: 100,
                      label: "100",
                    },
                  ]}
                  defaultValue={{
                    value: 20,
                    label: "20",
                  }}
                  value={{
                    value: limit,
                    label: limit.toString(),
                  }}
                  isClearable={false}
                  isSearchable={false}
                  className="inventory-table-limit-select"
                  onChange={this.handleChangeLimit}
                  menuPlacement="top"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",
                    }),
                  }}
                />
              </div>
              <div className="mt-2 mr-2"> of {total} found</div>
            </div>
          </div>
        )}
        <InventoryPageModal page={activePage} inventory={inventory} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { segments, pages, selectedPages, selectedSegment } = state;
  const activeSegment = getActiveSegment(state) || segments.defaultSegment;
  return {
    activeSegment,
    segments: segments.items,
    pages: pages.items,
    total: pages.total,
    clicks_delta_min_max: pages.clicks_delta_min_max,
    impressions_delta_min_max: pages.impressions_delta_min_max,
    isFetching: segments.isFetching,
    sort: pages.sort,
    order: pages.order,
    offset: pages.offset,
    limit: pages.limit,
    extraFilters: pages.extraFilters,
    selectedPageIds: selectedPages,
    selectedSegment,
  };
}

export default connect(mapStateToProps, {
  fetchPages,
  updatePageSortOrder,
  setSelectedSegment,
  setSelectedPages,
})(InventoryTable);
