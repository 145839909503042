import React from "react";
import Toggle from "react-toggle";
import _ from "lodash";
import Loader from "./loader";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ignoredSites: null,
      error: null,
      siteInput: "",
      defaultMode: "",
      changeInProgress: false,
    };

    this.ignoreSite = this.ignoreSite.bind(this);
    this.restoreSite = this.restoreSite.bind(this);
    this.updateSites = this.updateSites.bind(this);
    this.validate = this.validate.bind(this);
    this.formRef = React.createRef();
    this.changeDefaultMode = this.changeDefaultMode.bind(this);
  }

  componentDidMount() {
    const that = this;
    fetch(`/account/ignored_sites`)
      .then((response) => response.json())
      .then((ignoredSites) => {
        if (ignoredSites.error) {
          that.setState({ error: ignoredSites.error });
        } else if (_.isArray(ignoredSites)) {
          that.setState({ ignoredSites });
        }
      });
    if (AP.currentUser.default_mode !== this.state.defaultMode) {
      this.setState({ defaultMode: AP.currentUser.default_mode });
    }
  }

  ignoreSite() {
    let oldIgnoredSites = this.state.ignoredSites;
    if (!_.isArray(oldIgnoredSites)) {
      oldIgnoredSites = [];
    }
    const newIgnoredSites = this.state.ignoredSites.concat([
      this.state.siteInput,
    ]);
    this.setState({ ignoredSites: newIgnoredSites, siteInput: "" });
    this.updateSites(newIgnoredSites);
  }

  restoreSite(site) {
    const newIgnoredSites = _.filter(
      this.state.ignoredSites,
      (asite) => asite !== site
    );
    this.setState({ ignoredSites: newIgnoredSites });
    this.updateSites(newIgnoredSites);
  }

  updateSites(ignoredSites) {
    const that = this;
    fetch("/account/ignored_sites", {
      method: "put",
      body: JSON.stringify({ ignored_sites: ignoredSites }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          that.setState({ error: null });
        } else {
          that.setState({
            error: "something went wrong updating the list of ignored sites",
          });
        }
      });
  }

  validate(successFunction, event) {
    event.preventDefault();
    if (this.formRef.current.reportValidity()) {
      // check to see if valid URL
      // accepts URL's without protocol
      const { siteInput } = this.state;

      /* eslint-disable no-useless-escape */
      const urlRegex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi;
      if (urlRegex.test(siteInput)) {
        this.setState({ error: null });
        successFunction(event);
      } else {
        this.setState({ error: "please enter a valid url" });
      }
    }
  }

  changeDefaultMode() {
    this.setState({ changeInProgress: true }, () => {
      const currentMode = this.state.defaultMode;
      const newMode = currentMode === "PRIVATE" ? "PUBLIC" : "PRIVATE";
      fetch(`/account/default_mode`, {
        method: "put",
        body: JSON.stringify({ mode: newMode }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.setState({ error: response.error });
          } else {
            this.setState({ defaultMode: newMode, changeInProgress: false });
          }
        });
    });
  }

  render() {
    const isLoading = this.state.ignoredSites === null;
    const { ignoredSites, defaultMode, changeInProgress } = this.state;
    return (
      <div className="element-wrapper">
        <div className="element-box">
          <h5 className="element-box-header">Sites to Ignore</h5>
          <p>
            Have a specific site that you want to ignore when creating a content
            brief? Add it here to exclude it from analysis in future content
            briefs.
          </p>
          <p className="text-s">
            Note: This affects all briefs generated by your team.
          </p>
          <div>
            {isLoading && <Loader type="spinner" className="dark p-2" />}
            {!isLoading &&
              _.isArray(ignoredSites) &&
              !_.isEmpty(ignoredSites) && <h6>Ignored Sites</h6>}
            {!isLoading &&
              _.isArray(ignoredSites) &&
              ignoredSites.map((site, index) => (
                <div className="d-flex pb-2" key={index}>
                  <div>{site}</div>
                  <div className="ml-3">
                    <i
                      className="fa fa-times clickable"
                      onClick={() => {
                        this.restoreSite(site);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
          <form
            ref={this.formRef}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="input-group mb-3 mt-2">
              <input
                className="form-control"
                placeholder="wikipedia.org"
                aria-label="url"
                type="text"
                value={this.state.siteInput}
                onChange={(e) => {
                  this.setState({ siteInput: e.target.value });
                }}
                required
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={this.validate.bind(null, this.ignoreSite)}
                >
                  Add
                </button>
              </div>
            </div>
          </form>
          {this.state.error && (
            <div className="text-danger">{this.state.error}</div>
          )}
        </div>

        <div className="element-box" style={{ marginTop: 0 }}>
          <h5 className="element-box-header">Brief Credits</h5>
          <p>
            You currently have{" "}
            <h6 style={{ display: "inline", textDecoration: "underline" }}>
              {AP.currentUser.briefs_remaining}
            </h6>{" "}
            brief credits available to use this month
          </p>
          <p>Out of {AP.currentUser.briefs_remaining} credits:</p>
          <p>
            <h6 style={{ display: "inline", textDecoration: "underline" }}>
              {AP.currentUser.roll_over_briefs_count}
            </h6>{" "}
            credits are rollover credits from last month and will expire on{" "}
            {new Date(
              AP.currentUser.currentPeriod.replace(/-/g, "/")
            ).toLocaleString("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            .
          </p>
          <p>
            <h6 style={{ display: "inline", textDecoration: "underline" }}>
              {AP.currentUser.current_month_briefs_remaining}
            </h6>{" "}
            credits are from this month and will roll over to the next month.
          </p>
          <a
            href="https://usetopic.zendesk.com/hc/en-us/articles/1500004925042"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-m" style={{ color: "rgb(57, 93, 154)" }}>
              How do rollover credits work ?
            </p>
          </a>
        </div>

        <div
          className="element-box default-brief-mode"
          style={{ marginTop: 0 }}
        >
          <h5 className="element-box-header">Make Briefs Public by Default</h5>
          <p>
            Typically, when you create a content brief, it is private by
            default. That means that it can only be accessed by logging into
            Topic with your account. Sharing a brief with a writer requires you
            to manually turn on a sharable link for that specific brief.
          </p>
          <p>
            However, this can become tedious if you want all of your content
            briefs to be accessible by your writers.
          </p>
          <p>
            This setting allows you to make all new content briefs <b>public</b>{" "}
            by default. When this is turned on, new content briefs will
            automatically be sharable via link.
          </p>
          <h6 style={{ display: "inline" }}>
            <label htmlFor="briefs-public-by-default">
              Make Briefs Public by Default
            </label>
          </h6>
          <Toggle
            id="briefs-public-by-default"
            checked={defaultMode === "PUBLIC"}
            icons={false}
            onChange={() => {
              this.changeDefaultMode();
            }}
            disabled={changeInProgress}
          />
        </div>
      </div>
    );
  }
}

export default Settings;
