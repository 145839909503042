/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery'
import 'lodash'
window.$ = window.jQuery = require('jquery');
window._ = require('lodash');

import 'bootstrap'
import '../images/index.js'
import '../stylesheets/application.scss'
import '../stylesheets/topic.scss'
import "react-toggle/style.css"
// import 'babel-polyfill'

// initialize timeago
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
JavascriptTimeAgo.locale(en)


import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux'
import Settings from '../src/topic/settings'
import Pregrading from '../src/topic/pregrading'
import TopicApp from '../src/topic/topic_app'
import AnonymousReportInitializerWithPath from '../src/topic/anonymous_report_initializer_with_path'
import NavTop from '../src/topic/nav_top'
import store from '../src/topic/redux/store' // topic redux
import { ToastProvider } from 'react-toast-notifications'
import queryString from 'query-string'

import {
  BrowserRouter as Router
} from 'react-router-dom'

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://ebc1f076692b42e1964bfcd61ea74f89@sentry.io/4186035",
  environment: AP.env,
  release: `topic-react@${AP.revision}`,
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
  })],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (event.exception) {
      // disable showing the report dialog, which is appearing too frequently
      // Sentry.showReportDialog({ eventId: event.event_id });
    }

    try{
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        event.extra = {
          LogRocket: logRocketSession
        }
        return event;
      } else {
        return event;
      }
    } catch(e){
      return event;
    }
  }
});

if(_.isObject(AP.currentUser)){
  Sentry.setUser({"email": AP.currentUser.email, "id": AP.currentUser.id});
}

document.addEventListener('DOMContentLoaded', () => {
  
  if(!_.isUndefined(analytics) && AP.currentUser.email){
    analytics.identify(AP.currentUser.id, {
      email: AP.currentUser.email
    });
  }

  // define a global error reporting function to send 
  AP.reportError = function (e){
    Sentry.captureException(e);
  }

  window.setTimeout(() => {
    // log the current user to store in logrocket for debugging
    if(_.isObject(AP.currentUser)){
      console.log('Current User: ');
      console.log(AP.currentUser);
    }
  }, 1500);

  var application;
  const shouldLoadTopic = true;
  if(window.location.pathname === '/anon'){
    // application = <AnonymousReportInitializerWithPath />
    window.location.href = "https://www.usetopic.com";
    return;
  } else if(shouldLoadTopic){
    application = <TopicApp />;
  }

  // Main Application
  if(document.getElementById('apparatus-app')){
    ReactDOM.render(
      <ToastProvider>
        <Provider store={store}>
          <Router>
            { application }
          </Router>
        </Provider>
      </ToastProvider>,
      document.getElementById('apparatus-app'),
    )
  }

  // /profile/edit settings page - kept separate just to retain profile editing functionality
  if(document.getElementById('app-settings')){
    ReactDOM.render(
      <Provider store={store}>
          <Settings />
      </Provider>,
      document.getElementById('app-settings'),
    )
  }

  if(document.getElementById('nav-top')){
    ReactDOM.render(
      <Provider store={store}><Router history={history}><NavTop forceReload={true} /></Router></Provider>,
      document.getElementById('nav-top'),
    )
  }
  // end settings page

  if(document.getElementById('pregrading')){
    ReactDOM.render(
      <Pregrading />,
      document.getElementById('pregrading'),
    )
  }
})

// Does not process requires when imported like this
import '../../assets/javascripts/application'
import '../src/global'
import '../src/topic/subscriptions.jsx'

// import Rails from 'rails-ujs';
// import Turbolinks from 'turbolinks';
// Rails.start();
// Turbolinks.start();

// import * as ActiveStorage from "activestorage"
// ActiveStorage.start()

// import '../packs/src/subscriptions'
// import '../packs/src/modals'

