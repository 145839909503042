import React from "react";

class EditorImportInfoModal extends React.Component {
  render() {
    const { report } = this.props;
    const importUrl = report && report.import_url;

    return (
      <div
        aria-hidden="true"
        aria-labelledby="editorImportInfoModal"
        className="modal fade editorImportInfoModal onboarding-modal"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div className="onboarding-content with-gradient">
              <p className="text-bold mb-1">What should I do now?</p>
              <p>
                You should manually update the imported content so that it
                matches the content of your post. If extra content was imported,
                simply delete it from the grader. If you are missing content,
                then{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href={importUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  click here to open your post
                </a>
                , then manually copy and paste the missing content from your
                post into the grader. Then, click “yes” to confirm and our
                system will grade your content.
              </p>
              <p className="text-bold mb-1">How can I avoid import issues?</p>
              <p>
                One alternative is to use the Topic Wordpress Plugin which
                allows you to grade content directly from the Wordpress editor.{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://help.adthrive.com/hc/en-us/articles/4406596212763-How-to-use-Topic-in-WordPress-and-Google-Docs"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to learn more.
                </a>
              </p>
              <p className="text-bold mb-1">
                Why did my content not import correctly?
              </p>
              <p>
                The structure of every webpage is unique, which makes it hard
                for our system to separate the content from things like the
                navigation, footer, ads, or comments. We are constantly working
                on ways to improve this experience. If this is happening
                consistently, let us know at{" "}
                <a
                  target="_blank"
                  href="mailto:support@cafemedia.com"
                  style={{ textDecoration: "underline" }}
                  rel="noreferrer"
                >
                  support@cafemedia.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorImportInfoModal;
