import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  updateSegmentLocally,
  saveSegment,
  setActiveSegment,
  addSegment,
  removeSegment,
  fetchSegments,
} from "./redux/actions";
import { getActiveSegment } from "./redux/selectors";
import InventorySegmentPanel from "./inventory_segment_panel";
import Loader from "./loader";
import InventorySegmentLabel from "./inventory_segment_label";
import InventorySegmentLabelPopover from "./inventory_segment_label_popover";
import { getSegmentLabel } from "./helpers";

class InventorySegmentCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      segmentHasChanged: false,
      originalSegment: null,
      name: "",
    };
    this.filterOptions = [
      {
        value: "url",
        label: "Url",
        datatype: "string",
      },
      {
        value: "title",
        label: "Title",
        datatype: "string",
      },
      {
        value: "top_keyword",
        label: "Top Keyword",
        datatype: "string",
      },
      {
        value: "position",
        label: "Position",
        datatype: "integer",
      },
      {
        value: "backlinks",
        label: "Backlinks",
        datatype: "integer",
      },
    ];

    this.filterDatatypeOptions = {
      string: [
        {
          value: "equals",
          label: "Equals",
        },
        {
          value: "does_not_equal",
          label: "Does not equal",
        },
        {
          value: "contains",
          label: "Contains",
        },
        {
          value: "does_not_contain",
          label: "Does not contain",
        },
        {
          value: "any",
          label: "Any of these",
        },
        {
          value: "none",
          label: "None of these",
        },
      ],
      integer: [
        {
          value: "equals",
          label: "Equals",
        },
        {
          value: "does_not_equal",
          label: "Does not equal",
        },
        {
          value: "less_than",
          label: "Is less than",
        },
        {
          value: "greater_than",
          label: "Is greater than",
        },
      ],
    };
    this.handleAddFilter = this.handleAddFilter.bind(this);
    this.handleSaveSegment = this.handleSaveSegment.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.updateFilterType = this.updateFilterType.bind(this);
    this.updateFilterValue = this.updateFilterValue.bind(this);
    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
    this.validate = this.validate.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { activeSegment } = this.props;
    this.setState({
      segmentHasChanged: false,
      name: activeSegment.name,
      originalSegment: _.cloneDeep(activeSegment),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeSegment } = this.props;
    const { originalSegment, segmentHasChanged, name } = this.state;
    const segmentChanged = !_.isEqual(originalSegment, activeSegment);
    const nameChangedLocally = name !== originalSegment.name;
    if ((segmentChanged || nameChangedLocally) && !segmentHasChanged) {
      this.setState({
        segmentHasChanged: true,
      });
    }
  }

  handleAddFilter(filter) {
    const {
      activeSegment,
      updateSegmentLocally,
      segments,
      addSegment,
      defaultSegment,
      setActiveSegment,
      inventory,
    } = this.props;
    const that = this;

    window.setTimeout(() => {
      activeSegment.filters.push({
        property: filter.value,
        filter_type: null,
        value: "",
      });
      updateSegmentLocally(activeSegment);
    }, 1);
  }

  handleSaveSegment() {
    if (this.validate()) {
      const {
        activeSegment,
        removeSegment,
        setActiveSegment,
        inventory,
        saveSegment,
        fetchSegments,
        segmentLabels,
        handleStopEditing,
      } = this.props;
      const { name } = this.state;
      if (activeSegment.id === "newid") {
        delete activeSegment.id;
      }
      if (_.isEmpty(activeSegment.label)) {
        // assign random label
        activeSegment.label = segmentLabels[_.random(segmentLabels.length)];
      }
      activeSegment.name = name;
      saveSegment(activeSegment, (segment) => {
        // remove unsaved segment, update active segment
        removeSegment("newid");
        setActiveSegment(segment.id);
        fetchSegments(inventory.id);
        handleStopEditing();
      });
    }
  }

  handleReset() {
    const {
      removeSegment,
      setActiveSegment,
      activeSegment,
      handleStopEditing,
      updateSegmentLocally,
    } = this.props;
    const { originalSegment } = this.state;
    if (activeSegment.id === "newid") {
      removeSegment("newid");
      setActiveSegment("all");
    } else {
      updateSegmentLocally(originalSegment);
    }
    handleStopEditing();
  }

  handleRemoveFilter(index) {
    const {
      activeSegment,
      updateSegmentLocally,
      removeSegment,
      setActiveSegment,
    } = this.props;
    activeSegment.filters.splice(index, 1);
    if (_.isEmpty(activeSegment.filters)) {
      removeSegment("newid");
      setActiveSegment("all");
    } else {
      updateSegmentLocally(activeSegment);
    }
  }

  validate() {
    const { activeSegment } = this.props;

    let isValid = true;

    _.each(activeSegment.filters, (filter) => {
      if (_.isEmpty(filter.filter_type) || filter.value === "") {
        isValid = false;
      }
    });

    if (!isValid) {
      this.setState({
        error: "Please complete all filters before saving segment.",
      });
    } else {
      this.setState({
        error: "",
      });
    }

    return isValid;
  }

  // equals, does not equal, contains, etc
  updateFilterType(filterType, index) {
    const { activeSegment, updateSegmentLocally } = this.props;
    activeSegment.filters[index].filter_type = filterType;
    activeSegment.saveState = "unsaved";
    updateSegmentLocally(activeSegment);
  }

  // free-form text or integer field
  updateFilterValue(filterValue, index) {
    const { activeSegment, updateSegmentLocally } = this.props;
    activeSegment.filters[index].value = filterValue;
    activeSegment.saveState = "unsaved";
    updateSegmentLocally(activeSegment);
  }

  render() {
    const that = this;
    const {
      activeSegment,
      segments,
      inventory,
      segmentLabels,
      handleStopEditing,
    } = this.props;
    const { error, name, segmentHasChanged } = this.state;
    const isLoading = !_.isObject(activeSegment);
    const noFiltersAdded = activeSegment.filters.length === 0;

    const showSaveButton =
      segmentHasChanged &&
      _.isArray(activeSegment.filters) &&
      activeSegment.filters.length > 0;
    const isNewSegment = activeSegment.id === "newid";

    const labelPopover = (
      <InventorySegmentLabelPopover
        segment={activeSegment}
        labels={segmentLabels}
      />
    );
    const label = getSegmentLabel(segmentLabels, activeSegment);
    const labelDiv = <InventorySegmentLabel label={label} />;

    return (
      <div ref={this.ref} className="inventory-segment-creator w-100">
        {isLoading && <Loader type="spinner" className="dark" />}
        {!isLoading && (
          <div>
            <div className="flex-grow-1 d-flex mb-3">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={labelPopover}
              >
                {labelDiv}
              </OverlayTrigger>
              <div style={{ width: "10px" }} />
              <div className="flex-grow-1">
                <input
                  className="w-100"
                  value={name}
                  onChange={(e) => {
                    that.setState({ name: e.target.value });
                  }}
                />
              </div>
            </div>

            {_.map(activeSegment.filters, (filter, index) => {
              const filterDatatype = _.find(that.filterOptions, {
                value: filter.property,
              }).datatype;
              const filterTypeOptionsForProperty =
                that.filterDatatypeOptions[filterDatatype];
              let filterValue = "";
              if (_.isString(filter.value) || _.isNumber(filter.value)) {
                filterValue = filter.value;
              }

              const filterIsActive = filter.filter_type && filterValue !== "";

              return (
                <div
                  key={index}
                  className={`inventory-segment-filter ${
                    filterIsActive ? "inventory-segment-filter-active" : ""
                  }`}
                >
                  <a
                    href="#"
                    style={{ position: "absolute", top: "4px", right: "9px" }}
                    onClick={() => {
                      that.handleRemoveFilter(index);
                    }}
                  >
                    <i className="fa fa-times text-bluegray-4" />
                  </a>
                  <input
                    value={filter.property}
                    className="mb-2 w-100 bg-primary-2"
                    disabled
                  />
                  <Select
                    className="inventory-segment-creator-select mb-2"
                    options={filterTypeOptionsForProperty}
                    isClearable={false}
                    value={_.find(filterTypeOptionsForProperty, {
                      value: filter.filter_type,
                    })}
                    onChange={(filterType) => {
                      that.updateFilterType(filterType.value, index);
                    }}
                  />
                  <input
                    value={filterValue}
                    type={filterDatatype === "string" ? "text" : "number"}
                    onChange={(e) => {
                      that.updateFilterValue(e.target.value, index);
                    }}
                  />
                </div>
              );
            })}

            <Select
              options={this.filterOptions}
              isClearable={false}
              className="inventory-segment-creator-select mb-3"
              placeholder="Add Filter"
              value={null}
              onChange={this.handleAddFilter}
            />

            {error && <div className="p-2 text-danger">{error}</div>}
            {showSaveButton && (
              <div className="d-flex">
                <div
                  className="text-primary-7 flex-grow-1 clickable text-center pt-1"
                  onClick={this.handleReset}
                >
                  Cancel
                </div>
                <div style={{ width: "10px" }} />
                <button
                  className="btn btn-primary flex-grow-1"
                  onClick={this.handleSaveSegment}
                >
                  {isNewSegment ? "Save" : "Update"}
                </button>
              </div>
            )}
            {!showSaveButton && (
              <div
                className="clickable text-center text-bluegray-4"
                onClick={this.handleReset}
              >
                Cancel
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { segments } = state;
  const activeSegment = _.cloneDeep(getActiveSegment(state));
  return {
    segments: segments.items,
    activeSegment,
    isFetching: segments.isFetching,
    defaultSegment: segments.defaultSegment,
    segmentLabels: segments.segmentLabels,
  };
}

export default connect(mapStateToProps, {
  saveSegment,
  updateSegmentLocally,
  setActiveSegment,
  addSegment,
  removeSegment,
  fetchSegments,
})(InventorySegmentCreator);
