import React from "react";
import _ from "lodash";
import { capitalizeFirstLetterAndAddQuestionMark } from "./helpers";

class ReportOverviewQuestionsBySource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortPAABy: "relevance", // interest or relevance
    };
    this.questionView = this.questionView.bind(this);
    this.sortQuestionsForSourceView =
      this.sortQuestionsForSourceView.bind(this);
    this.getQuestionArray = this.getQuestionArray.bind(this);
  }

  getQuestionArray(q) {
    if (!_.isEmpty(q) && _.isArray(q.questions) && !_.isEmpty(q.questions)) {
      return q.questions;
    }
    return [];
  }

  sortQuestionsForSourceView() {
    let { quora_questions, autocomplete_questions, questions, report } =
      this.props;
    quora_questions = this.getQuestionArray(quora_questions);
    autocomplete_questions = this.getQuestionArray(autocomplete_questions);
    questions = this.getQuestionArray(questions);

    // sort
    quora_questions = _.sortBy(quora_questions, (q) => -1 * q.follow_count);
    autocomplete_questions = _.sortBy(
      autocomplete_questions,
      (q) => -1 * q.vol
    );
    questions = _.sortBy(questions, (q) => -1 * q.vol);

    // only take the top 65 questions
    quora_questions = _.take(quora_questions, 65);
    autocomplete_questions = _.take(autocomplete_questions, 65);
    questions = _.take(questions, 65);

    // create normalized dot score
    const maxDots = 5;
    let maxScore;
    let metric;
    [questions, autocomplete_questions, quora_questions].forEach((qs) => {
      if (_.isEmpty(qs)) {
        return;
      }
      if (_.isNumber(qs[0].vol)) {
        metric = "vol";
      } else {
        metric = "follow_count";
      }
      maxScore = qs[0][metric];
      qs = _.map(qs, (q) => {
        q.score = Math.ceil((q[metric] || 0) / (maxScore / maxDots)) || 1;
        if (q.score === 1 && q[metric] > 0) {
          q.score = 2;
        }
        return q;
      });
    });
    return {
      questions,
      autocomplete_questions,
      quora_questions,
    };
  }

  questionView(qs, index) {
    const that = this;
    const { sortPAABy } = this.state;
    const { cards, handleQuestionClick } = this.props;
    const isQuora = index === 2;
    const isPAA = index === 0;
    if (_.isEmpty(qs)) {
      return null;
    }

    const isPAAandSortedByRelevance = isPAA && sortPAABy === "relevance";
    if (isPAAandSortedByRelevance) {
      qs = _.sortBy(qs, (q) => q.depth);
    }

    return (
      <table className="table-minimal table-striped" key={index}>
        <thead>
          <tr>
            <th>Question</th>
            <th>{isPAAandSortedByRelevance ? "Relevance" : "Interest"}</th>
          </tr>
        </thead>
        <tbody>
          {qs.map((q, index) => {
            const score = isPAAandSortedByRelevance ? 4 - q.depth : q.score;
            const scoreDots = _.map(_.range(score), (i) => (
              <i key={i} className="fas fa-circle text-bluegray-5" />
            ));
            const cardIdentifier = {
              type: "question",
              originalText: q.keyword,
            };
            const isHighlighted = _.isObject(_.find(cards, cardIdentifier));
            return (
              <tr
                key={index}
                className={`clickable ${
                  isHighlighted
                    ? "highlight-child-span"
                    : "highlight-child-span-on-hover"
                }`}
                onClick={() => {
                  handleQuestionClick(q.keyword);
                }}
              >
                <td className="pr-3">
                  <span>
                    {capitalizeFirstLetterAndAddQuestionMark(q.keyword)}
                  </span>
                  {isQuora && (
                    <a
                      href={q.link}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noreferrer"
                    >
                      <i className="fas fa-external-link-alt pl-2 text-bluegray-4" />
                    </a>
                  )}
                </td>
                <td>{scoreDots}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { showAll } = this.props;
    const { sortPAABy } = this.state;
    const { questions, autocomplete_questions, quora_questions } =
      this.sortQuestionsForSourceView();
    const questionViews = [
      questions,
      autocomplete_questions,
      quora_questions,
    ].map(this.questionView);
    const columns = _.filter(
      [quora_questions, autocomplete_questions, questions],
      (q) => !_.isEmpty(q)
    ).length;

    return (
      <div
        className={`overview-questions-new overview-questions-cols-${columns} ${
          !showAll ? "overview-questions-minimized" : ""
        }`}
        style={{ position: "relative" }}
      >
        <div className="d-flex overview-questions-holder">
          {questionViews[0] && (
            <div className="overview-questions-group">
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <div className="text-bold pt-2">Google People Also Ask</div>
                <div
                  className="text-s"
                  style={{ position: "absolute", top: "31px" }}
                >
                  Sort By:{" "}
                  <a
                    href="#"
                    className={sortPAABy === "relevance" ? "text-bold" : ""}
                    onClick={() => {
                      this.setState({ sortPAABy: "relevance" });
                    }}
                  >
                    Relevance
                  </a>{" "}
                  or{" "}
                  <a
                    href="#"
                    className={sortPAABy === "interest" ? "text-bold" : ""}
                    onClick={() => {
                      this.setState({ sortPAABy: "interest" });
                    }}
                  >
                    Interest
                  </a>
                </div>
              </div>
              {questionViews[0]}
            </div>
          )}
          {questionViews[1] && (
            <div className="overview-questions-group">
              <div className="text-bold pt-2">Google Suggest</div>
              {questionViews[1]}
            </div>
          )}
          {questionViews[2] && (
            <div className="overview-questions-group">
              <div className="text-bold pt-2">Quora</div>
              {questionViews[2]}
            </div>
          )}
        </div>
        <div className="overview-questions-gradient w-100" />
      </div>
    );
  }
}

export default ReportOverviewQuestionsBySource;
