import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InventorySegmentPanel from "./inventory_segment_panel";
import InventoryFilterbar from "./inventory_filterbar";
import InventorySegmentbar from "./inventory_segmentbar";
import InventoryTable from "./inventory_table";
import { getInventoryById } from "./redux/selectors";
import {
  fetchSegments,
  fetchPages,
  setActiveSegment,
  fetchInventory,
} from "./redux/actions";

class InventoryOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const {
      fetchSegments,
      fetchPages,
      inventory,
      setActiveSegment,
      fetchInventory,
      pagesSort,
      pagesOrder,
      pagesLimit,
    } = this.props;
    const inventoryId = this.props.match.params.id;

    if (!_.isObject(inventory)) {
      fetchInventory(inventoryId);
    }

    fetchSegments(inventoryId, () => {
      setActiveSegment("all"); // on initial load, set active segment as "all pages"
    });
    fetchPages(inventoryId, "all", {
      sort: pagesSort,
      order: pagesOrder,
      limit: pagesLimit,
    });
  }

  componentDidUpdate() {}

  render() {
    const that = this;

    const { inventory } = this.props;
    const { sidebarOpen } = this.state;

    const collapseTooltip = <Tooltip>Hide Segments</Tooltip>;

    return (
      <div
        ref={this.ref}
        className="inventory-overview d-flex flex-grow-1 flex-column"
      >
        {inventory && (
          <div className="d-flex" style={{ width: "100vw" }}>
            <div
              className={`inventory-overview-sidebar fadeRight ${
                sidebarOpen ? "" : "collapsed"
              }`}
            >
              <div className="inventory-overview-sidebar-inner p-4">
                <OverlayTrigger overlay={collapseTooltip}>
                  <i
                    className="fas fa-chevron-double-left text-primary-3 clickable p-1"
                    style={{
                      position: "absolute",
                      right: "21px",
                      top: "28px",
                      fontSize: "17px",
                    }}
                    onClick={() => {
                      this.setState({ sidebarOpen: !sidebarOpen });
                    }}
                  />
                </OverlayTrigger>
                <h5 className="mb-4">Content Opportunities</h5>
                <InventorySegmentPanel inventory={inventory} />
              </div>
            </div>
            {!sidebarOpen && (
              <div
                className="inventory-overview-sidebar-expand clickable bg-primary-1"
                onClick={() => {
                  this.setState({ sidebarOpen: !sidebarOpen });
                }}
              >
                <i className="fas fa-chevron-right mb-2" aria-hidden="true" />
                Manage Segments
              </div>
            )}
            <div className="flex-grow-1">
              <div
                className={`inventory-overview-pages-container d-flex flex-column ${
                  sidebarOpen ? "" : "expanded"
                }`}
              >
                <InventoryFilterbar inventory={inventory} />
                <div
                  className="w-100"
                  style={{ height: "20px", minHeight: "20px" }}
                />
                <InventoryTable inventory={inventory} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    inventory: getInventoryById(state, ownProps.match.params.id),
    pagesSort: state.pages.sort,
    pagesOrder: state.pages.order,
    pagesLimit: state.pages.limit,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchSegments,
    fetchPages,
    setActiveSegment,
    fetchInventory,
  })(InventoryOverview)
);
