import React from "react";
import OutlineTitleBookmarkButton from "./bookmark_button";

/**
 * Renders a list item for the title bookmarks
 * @param {object} props
 * @returns
 */
function OutlineTitleBookmarkListItem({
  report,
  title,
  i,
  selectedTitle,
  handleSelect,
}) {
  const onClick = () => {
    if (handleSelect) {
      handleSelect(title);
    }
  };

  const titleIsChosen = selectedTitle === title;
  return (
    <div
      onClick={onClick}
      key={i}
      className={`clickable p-2 pr-4 mt-2 mb-2 builder-popover-card animated fadeUp delay-${i} ${
        titleIsChosen ? "text-bold active" : ""
      }`}
    >
      {title}
      {titleIsChosen && <i className="fas fa-check text-success ml-2" />}
      <OutlineTitleBookmarkButton report={report} title={title} />
    </div>
  );
}

export default OutlineTitleBookmarkListItem;
