import React from "react";
import { useDispatch } from "react-redux";
import { useReport } from "../../../hooks/useReport";
import { bypassInitialBrainstorm } from "../../../redux/actions";

function InitialBrainstormStartFromScratch() {
  const dispatch = useDispatch();
  const report = useReport();

  if (!report) {
    return null;
  }

  const onStartFromScratch = () => {
    dispatch(bypassInitialBrainstorm(report));
  };
  return (
    <div className="pt-1 px-3 pb-3 position-absolute w-100 initial-brainstorm-bypass-cta">
      <div className="text-l text-gray text-center">or</div>
      <button
        onClick={onStartFromScratch}
        className="btn bg-primary-2 hover-bg-primary-3 w-100 mt-2"
      >
        Start From Scratch
      </button>
    </div>
  );
}

export default InitialBrainstormStartFromScratch;
