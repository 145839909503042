import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select from "react-select";
import KeywordsDashboard from "./keywords_dashboard";
import { fetchReportsIfNeeded, invalidateReports } from "./redux/actions";
import ReportAnimation from "./report_animation";
import { loadTags, validateTags, isURL } from "./helpers";

// Props
// type - [keyword, report] - determines whether this modal shows keyword research UI or report creation choices
// if the type is keyword, then the state starts at step 0 and skips steps 1 and 2
// if the report type is report, then the state starts at step 1 and never goes to step 0
class ReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: "",
      keywords: [],
      url: "",
      urls: [],
      reportType: "", // new or existing
      step: this.props.type === "keyword" ? 0 : 1, // [0, keyword research], [1, i want to...], [2, report form], [3, report loading animation]
      pollStatus: "", // used to keep animation synced up with status of the report creation
      reportError: "", // shows a text error if API responds with error
      isCreatingBrief: false, // used to prevent multiple briefs from being accidentally created
      location: null,
      language: {
        value: "en",
        label: "English",
      },
      tags: null,
      isBulkBriefSelected: false,
      existingContentSubStep: 0,
      keywordURLMap: [],
    };
    this.reportCreated = false; // used to trigger report invalidation when modal closes
    this.baseState = this.state;
    this.formRef = React.createRef();
    this.modalRef = React.createRef();
    this.resetState = this.resetState.bind(this);
    this.createReport = this.createReport.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChooseReportType = this.handleChooseReportType.bind(this);
    this.validate = this.validate.bind(this);
    this.redirectToReport = this.redirectToReport.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleCreateReportFromKeywordResearch =
      this.handleCreateReportFromKeywordResearch.bind(this);
    this.loadLocations = this.loadLocations.bind(this);
    this.animateCheckmark = this.animateCheckmark.bind(this);
    this.setBulkBriefSelection = this.setBulkBriefSelection.bind(this);
    this.setKeywordsOrURLSForBulkBriefs =
      this.setKeywordsOrURLSForBulkBriefs.bind(this);
    this.getListObject = this.getListObject.bind(this);
    this.existingContentNextStep = this.existingContentNextStep.bind(this);
    this.clearExistingContent = this.clearExistingContent.bind(this);
    this.areURLsInvalid = this.areURLsInvalid.bind(this);
  }

  resetState() {
    this.setState(this.baseState);
  }

  componentDidMount() {
    const that = this;
    $(this.modalRef.current).find('[data-toggle="tooltip"]').tooltip();
    this.animateCheckmark();

    $(this.modalRef.current).on("hidden.bs.modal", () => {
      // fetch reports when modal is hidden
      // on new report creation, this triggers polling in reports_dashboard
      if (that.reportCreated) {
        that.reportCreated = false;
        that.props.invalidateReports();
        that.props.fetchReportsIfNeeded();
      }

      that.resetState();
    });
  }

  componentDidUpdate() {
    $(this.modalRef.current).find('[data-toggle="tooltip"]').tooltip();
    this.animateCheckmark();
  }

  handleChooseReportType(type) {
    this.setState({
      reportType: type,
      step: 2,
    });
  }

  handleBackClick() {
    this.setState(
      {
        isBulkBriefSelected: false,
        existingContentSubStep: 0,
        keywordURLMap: [],
      },
      () => {
        this.setState({ step: 1 });
      }
    );
  }

  handleInputChange(event) {
    const { target } = event;
    this.setState({
      [target.name]: target.value,
    });
    if (this.state.isBulkBriefSelected) {
      if (target.name == "keyword" || target.name == "url") {
        this.setKeywordsOrURLSForBulkBriefs(target.value, target.name);
      }
    }
  }

  setBulkBriefSelection() {
    this.setState(
      {
        isBulkBriefSelected: !this.state.isBulkBriefSelected,
      },
      () => {
        this.setState({
          keyword: "",
          keywords: [],
          url: "",
          urls: [],
        });
      }
    );
  }

  setKeywordsOrURLSForBulkBriefs(inputString, inputName) {
    let inputsArray = inputString.split(/\n|,/);
    inputsArray = inputsArray.map((s) => s.trim());
    inputsArray = inputsArray.filter(Boolean);
    const stateName = `${inputName}s`;
    if (inputsArray.length > 0) {
      this.setState({
        [stateName]: inputsArray,
      });
    }
  }

  existingContentNextStep() {
    const listData = this.getListObject();
    this.setState(
      {
        keywordURLMap: listData,
      },
      () => {
        this.setState({ existingContentSubStep: 1, reportError: "" });
      }
    );
  }

  clearExistingContent() {
    this.setState({
      keyword: "",
      keywords: [],
      url: "",
      urls: [],
      existingContentSubStep: 0,
      reportError: "",
    });
  }

  areURLsInvalid() {
    let areInvalid = false;
    this.state.urls.map((singleURL) => {
      if (!isURL(singleURL)) {
        areInvalid = true;
      }
    });
    return areInvalid;
  }

  validate(successFunction, event) {
    if (this.formRef.current.reportValidity()) {
      // don't allow commas in focus keyword
      if (!this.state.isBulkBriefSelected && /,/.test(this.state.keyword)) {
        this.setState({
          reportError:
            "Content briefs are tied to a single focus keyword. Create separate briefs for each keyword you are targetting.",
        });
      }
      // check for the number of remaining briefs vs requested ones.
      else if (
        this.state.isBulkBriefSelected &&
        this.state.keywords.length > AP.currentUser.briefs_remaining
      ) {
        this.setState({
          reportError: `You are requesting ${this.state.keywords.length} briefs but you have only ${AP.currentUser.briefs_remaining} Briefs left on your plan.`,
        });
      }
      // validation for bulk brief exisitng flow
      else if (
        this.state.isBulkBriefSelected &&
        this.state.urls.length > 0 &&
        this.state.keywords.length != this.state.urls.length
      ) {
        // check for the number of rows in the improve existing flow
        this.setState({
          reportError: `The number of Urls (${this.state.urls.length}) and Focus Keywords (${this.state.keywords.length}) don't match.`,
        });
      } else if (this.areURLsInvalid()) {
        // url validation for bulk briefs for improve existing flow
        this.setState({
          reportError:
            'One or more Urls in the existing content field is invalid. (make sure to add the "https://")',
        });
      } else {
        successFunction(event);
      }
    }
  }

  createReport(event, keyword) {
    const that = this;
    if (this.state.isCreatingBrief) {
      // prevent multiple briefs from being created
      return;
    }
    this.reportCreated = true;
    const tagArray = _.map(this.state.tags, (tag) => tag.value.trim());
    let reportData = {};
    if (this.state.isBulkBriefSelected) {
      const listData = this.getListObject();
      reportData = {
        list: listData,
        language: this.state.language.value,
        tags: tagArray,
      };
    } else {
      reportData = {
        name: keyword || this.state.keyword,
        import_url: this.state.url,
        language: this.state.language.value,
        tags: tagArray,
      };
    }
    if (this.state.location && this.state.location.value) {
      reportData.location = this.state.location.value;
    }
    this.setState({
      isCreatingBrief: true,
    });
    fetch("/api/keyword_reports", {
      method: "post",
      body: JSON.stringify(reportData),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((responseObj) => {
        if (responseObj.success && responseObj.id) {
          tanalytics.track("Report Created", {
            keyword: that.state.keyword,
            url: that.state.url,
          });
          that.setState({ step: 3, isCreatingBrief: false });
        } else if (responseObj.error) {
          that.setState({
            reportError: responseObj.error,
            isCreatingBrief: false,
          });
        }
      });
    if (event) {
      event.preventDefault();
    }
  }

  handleCreateReportFromKeywordResearch(keyword, location) {
    this.setState({ keyword, url: "" });
    this.loadLocations(location).then((locationresults) => {
      if (locationresults.length > 0) {
        this.setState({ location: locationresults[0] }, () => {
          this.createReport(null, keyword);
        });
      }
    });
    console.log(keyword, location);
  }

  redirectToReport(pendingReportId) {
    $(this.modalRef.current).modal("hide");
    this.resetState();
    this.props.invalidateReports();
    this.props.history.push(`/briefs/${pendingReportId}/overview`);
  }

  getListObject() {
    const ListArray = [];
    this.state.keywords.map((singleKeywords, index) => {
      if (this.state.urls.length > 0) {
        ListArray.push({
          name: singleKeywords,
          import_url: this.state.urls[index],
        });
      } else {
        ListArray.push({
          name: singleKeywords,
        });
      }
    });
    return ListArray;
  }

  loadLocations(query) {
    return new Promise((resolve) => {
      fetch(`/api/keyword_reports/locations/${encodeURIComponent(query)}`, {
        credentials: "same-origin",
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          resolve(
            result.map((location_obj) => ({
              value: location_obj,
              label: `${location_obj.canonical_name} (${location_obj.domain})`,
            }))
          );
        });
    });
  }

  // animates the success checkmark when report is created
  animateCheckmark() {
    const that = this;
    const animationDelay = 700;
    if (this.state.step === 3) {
      const checkmark = $(that.modalRef.current).find(".label__check");
      checkmark.removeClass("animated");
      if (that.checkmarkTimeout) {
        window.clearTimeout(that.checkmarkTimeout);
      }

      that.checkmarkTimeout = window.setTimeout(() => {
        checkmark.addClass("animated");
      }, animationDelay);
    }
  }

  render() {
    let modalTitle = "";
    if (this.state.step === 1) {
      modalTitle = "I want to...";
    } else if (this.state.step === 3) {
      modalTitle = "";
    } else if (this.state.reportType === "new") {
      modalTitle = "Create New Content";
    } else if (this.state.reportType === "existing") {
      modalTitle = "Improve Existing Content";
    } else if (this.state.step === 0) {
      modalTitle = "Find Focus Keyword";
    }

    const languageSelectOptions = [
      {
        value: "en",
        label: "English",
      },
      {
        value: "fr",
        label: "French",
      },
      {
        value: "de",
        label: "German",
      },
      {
        value: "pt",
        label: "Portugese",
      },
      {
        value: "it",
        label: "Italian",
      },
    ];

    return (
      <div
        ref={this.modalRef}
        aria-hidden="true"
        aria-labelledby="reportModal"
        className={`modal fade ${
          this.props.type === "keyword" ? "keywordModal" : "reportReportModal"
        } reportModal onboarding-modal`}
        role="dialog"
        tabIndex="-1"
      >
        <div
          className={`modal-dialog ${
            this.props.type === "keyword" && this.state.step !== 3
              ? "modal-lg"
              : ""
          }`}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div
              className="onboarding-content with-gradient"
              style={{ textAlign: "center" }}
            >
              <a
                href="#"
                onClick={this.handleBackClick}
                style={{
                  display: this.state.step === 2 ? "block" : "none",
                  position: "absolute",
                  left: "50px",
                  top: "15px",
                  color: "#888",
                }}
              >
                <i className="fa fa-chevron-left" /> Back
              </a>
              <h4 className="onboarding-title">{modalTitle}</h4>

              {this.state.step === 0 && (
                <div>
                  <KeywordsDashboard
                    handleCreateBrief={
                      this.handleCreateReportFromKeywordResearch
                    }
                    modalRef={this.modalRef}
                  />
                </div>
              )}

              <div
                style={{ display: this.state.step === 1 ? "block" : "none" }}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      className="report-modal-type clickable"
                      onClick={this.handleChooseReportType.bind(null, "new")}
                      data-test="experiment-modal-ba"
                    >
                      <h3 style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        Create New Content
                      </h3>
                      <p>
                        Find the right topics to cover when creating a new blog
                        post or article.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="report-modal-type clickable"
                      onClick={this.handleChooseReportType.bind(
                        null,
                        "existing"
                      )}
                      data-test="experiment-modal-ab"
                    >
                      <h3>Improve Existing Content</h3>
                      <p>
                        Enhance your existing content and improve its search
                        visibility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <form
                ref={this.formRef}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{ display: this.state.step === 2 ? "block" : "none" }}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="keyword">
                        <span>Focus Keyword</span>{" "}
                        <a
                          style={{
                            color: "rgb(57 93 154)",
                            marginLeft: 5,
                            cursor: "pointer",
                          }}
                          onClick={this.setBulkBriefSelection}
                        >
                          {this.state.isBulkBriefSelected
                            ? "(or... create just one)"
                            : "(or... create in bulk)"}
                        </a>
                      </label>
                      {this.state.isBulkBriefSelected ? (
                        <div>
                          <textarea
                            name="keyword"
                            className="form-control"
                            placeholder="Add keywords to optimize for"
                            rows="4"
                            value={this.state.keyword}
                            onChange={this.handleInputChange}
                            required
                            disabled={this.state.existingContentSubStep > 0}
                          />
                          <p style={{ color: "rgb(57 93 154)" }}>
                            seperate keywords with commas or in new lines
                          </p>
                        </div>
                      ) : (
                        <input
                          name="keyword"
                          className="form-control"
                          placeholder="Add keyword to optimize for"
                          type="text"
                          value={this.state.keyword}
                          onChange={this.handleInputChange}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>
                {this.state.reportType === "existing" && (
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="url">
                          <span>Existing Content</span>
                        </label>
                        {this.state.isBulkBriefSelected ? (
                          <div>
                            <textarea
                              name="url"
                              className="form-control"
                              placeholder="https://www.example.com/blog/post,    https://www.example.com/blog/next-post"
                              rows="4"
                              value={this.state.url}
                              onChange={this.handleInputChange}
                              required
                              disabled={this.state.existingContentSubStep > 0}
                            />
                            <p style={{ color: "rgb(57 93 154)" }}>
                              seperate urls with commas or in new lines
                            </p>
                          </div>
                        ) : (
                          <input
                            name="url"
                            className="form-control"
                            placeholder="https://www.example.com/blog/post"
                            type="url"
                            value={this.state.url}
                            onChange={this.handleInputChange}
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="mt-1">
                      <label htmlFor="location">
                        <span>Location</span>{" "}
                        <a
                          data-original-title="This is where your content's audience is searching from. Defaults to United States."
                          data-toggle="tooltip"
                          href="#"
                          title=""
                        >
                          <i className="fa fa-info-circle" />
                        </a>
                      </label>
                      <AsyncSelect
                        name="location"
                        cacheOptions
                        loadOptions={this.loadLocations}
                        onChange={(selected) => {
                          this.setState({ location: selected });
                        }}
                        value={this.state.location}
                        placeholder="Start typing to see locations (default: USA)"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mt-3">
                      <label htmlFor="language">
                        <span>Language</span>{" "}
                        <a
                          data-original-title="This is the language your content will be in."
                          data-toggle="tooltip"
                          href="#"
                          title=""
                        >
                          <i className="fa fa-info-circle" />
                        </a>
                      </label>
                      <Select
                        name="language"
                        options={languageSelectOptions}
                        onChange={(selected) => {
                          this.setState({ language: selected });
                        }}
                        value={this.state.language}
                        placeholder="default: English"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mt-3">
                      <label htmlFor="tags">
                        <span>Tags</span>{" "}
                        <a
                          data-original-title="Add tags to organize your briefs."
                          data-toggle="tooltip"
                          href="#"
                          title=""
                        >
                          <i className="fa fa-info-circle" />
                        </a>
                      </label>
                      <AsyncCreatableSelect
                        name="tags"
                        cacheOptions
                        isMulti
                        loadOptions={loadTags}
                        onChange={(selected) => {
                          this.setState({ tags: selected });
                        }}
                        value={this.state.tags}
                        placeholder="Add a tag to your report"
                      />
                    </div>
                  </div>
                </div>
                {this.state.reportType === "existing" &&
                this.state.isBulkBriefSelected &&
                this.state.existingContentSubStep > 0 ? (
                  <div className="mt-3">
                    Just to double check, does this table look correct?
                    <div
                      className="mt-1"
                      style={{
                        maxHeight: "11rem",
                        overflow: "auto",
                        border: "solid #8c8c8c42",
                      }}
                    >
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">focus keyword</th>
                            <th scope="col">url</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.keywordURLMap.map((keyURL) => (
                            <tr>
                              <td>{keyURL.name}</td>
                              <td>{keyURL.import_url}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
                {this.state.reportError && (
                  <div className="row">
                    <div className="col-sm-12 text-danger mt-2">
                      {this.state.reportError}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-buttons-w">
                      {this.state.reportType === "existing" &&
                      this.state.isBulkBriefSelected ? (
                        this.state.existingContentSubStep === 0 ? (
                          <button
                            onClick={this.validate.bind(
                              null,
                              this.existingContentNextStep
                            )}
                            className={`btn btn-primary ${
                              this.state.isCreatingBrief ? "disabled" : ""
                            }`}
                            type="button"
                          >
                            Next
                          </button>
                        ) : (
                          <div>
                            <button
                              onClick={this.validate.bind(
                                null,
                                this.createReport
                              )}
                              className={`btn btn-primary ${
                                this.state.isCreatingBrief ? "disabled" : ""
                              }`}
                              type="button"
                            >{`Yes, Create ${this.state.keywords.length} Briefs`}</button>
                            <button
                              onClick={this.clearExistingContent}
                              className={`btn btn-light ${
                                this.state.isCreatingBrief ? "disabled" : ""
                              }`}
                              type="button"
                            >
                              No, Clear Form
                            </button>
                          </div>
                        )
                      ) : (
                        <button
                          onClick={this.validate.bind(null, this.createReport)}
                          className={`btn btn-primary ${
                            this.state.isCreatingBrief ? "disabled" : ""
                          }`}
                          type="button"
                        >
                          {this.state.isBulkBriefSelected
                            ? `Create ${this.state.keywords.length} Briefs`
                            : "Create Brief"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>

              {this.state.step === 3 && (
                <div
                  className="pl-5 pr-5 text-center"
                  style={{ marginTop: "-70px" }}
                >
                  <div
                    className="d-flex mb-4"
                    style={{ justifyContent: "center" }}
                  >
                    <span className="label__check">
                      <i className="fa fa-check icon" />
                    </span>
                  </div>

                  <p className="mb-3">
                    We've started creating your new content brief(s). It will
                    take a minute or two for it to be ready.
                  </p>

                  <button
                    className="btn btn-primary mb-2"
                    onClick={() => {
                      this.resetState();
                    }}
                  >
                    Create another brief
                  </button>

                  <p>
                    <a
                      href="#"
                      aria-label="Close"
                      data-dismiss="modal"
                      className="text-bluegray-4"
                      onClick={() => {
                        this.resetState();
                      }}
                    >
                      or return to Dashboard
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, { fetchReportsIfNeeded, invalidateReports })(
    ReportModal
  )
);
