import React from "react";
import Toggle from "react-toggle";
import {
  triggerQuestionClusters,
  downloadCSV,
  capitalizeFirstLetterAndAddQuestionMark,
} from "./helpers";
import Loader from "./loader";
import ReportOverviewQuestionsBySource from "./report_overview_questions_by_source";
import ReportOverviewQuestionsByCluster from "./report_overview_questions_by_cluster";

class ReportOverviewQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      groupBySemanticSimilarityLoading: false,
      groupBySemanticSimilarity: false,
    };
    this.handleQuestionClick = this.handleQuestionClick.bind(this);
    this.downloadQuestionsCSV = this.downloadQuestionsCSV.bind(this);
    this.enableGroupBySemanticSimilarity =
      this.enableGroupBySemanticSimilarity.bind(this);
    this.handleSemanticSimiliarityToggleClick =
      this.handleSemanticSimiliarityToggleClick.bind(this);
    this.getQuestionArray = this.getQuestionArray.bind(this);
  }

  handleQuestionClick(question) {
    // either add or remove card from the outline cards in redux
    this.props.toggleCard({
      text: question,
      indent: 0,
      originalText: question,
      type: "question",
      selected: false,
      editing: false,
    });
  }

  // download CSV
  downloadQuestionsCSV(questions, autocomplete_questions, quora_questions) {
    const that = this;
    const data = [["question", "type", "interest"]];
    [questions, autocomplete_questions, quora_questions].forEach(
      (questions, i) => {
        if (_.isArray(questions) && !_.isEmpty(questions)) {
          let type;
          if (i === 0) {
            type = "People Also Ask";
          } else if (i === 1) {
            type = "Google Suggest";
          } else {
            type = "Quora";
          }
          questions.forEach((question) => {
            data.push([
              capitalizeFirstLetterAndAddQuestionMark(
                (question.keyword || question.question).replace(/,/g, "")
              ),
              type,
              question.score,
            ]);
          });
        }
      }
    );

    downloadCSV(
      `Questions for ${this.props.report.report.focus_keyword}.csv`,
      data
    );
  }

  enableGroupBySemanticSimilarity() {
    // calls the API to get the topic relationships for this content brief
    const that = this;
    const reportId = this.props.report.id;
    const { groupBySemanticSimilarityLoading } = this.state;
    if (!groupBySemanticSimilarityLoading) {
      this.setState({ groupBySemanticSimilarityLoading: true });
      triggerQuestionClusters(
        reportId,
        () => {
          that.props.fetchReport(reportId, () => {
            that.setState({
              groupBySemanticSimilarityLoading: false,
              groupBySemanticSimilarity: true,
            });
            window.setTimeout(() => {
              $("#group-by-semantic-questions")
                .parents(".react-toggle")
                .trigger("click");
              window.setTimeout(() => {
                $("#group-by-semantic-questions")
                  .parents(".react-toggle")
                  .trigger("click");
              }, 10);
            }, 10);
          });
        },
        () => {
          that.props.showToast({
            message: `Something went wrong clustering the questions.`,
            options: { appearance: "error", autoDismiss: true },
          });
          that.setState({
            groupBySemanticSimilarityLoading: false,
            groupBySemanticSimilarity: false,
          });
        }
      );
    }
  }

  handleSemanticSimiliarityToggleClick() {
    let { quora_questions, autocomplete_questions, questions } = this.props;
    quora_questions = this.getQuestionArray(quora_questions);
    autocomplete_questions = this.getQuestionArray(autocomplete_questions);
    questions = this.getQuestionArray(questions);

    const all_questions = quora_questions
      .concat(autocomplete_questions)
      .concat(questions);
    const enableSortingToggle =
      !_.isEmpty(all_questions) && _.isNumber(all_questions[0].cluster);
    if (enableSortingToggle) {
      this.setState({
        groupBySemanticSimilarity: !this.state.groupBySemanticSimilarity,
      });
    } else {
      this.enableGroupBySemanticSimilarity();
    }
  }

  getQuestionArray(q) {
    if (!_.isEmpty(q) && _.isArray(q.questions) && !_.isEmpty(q.questions)) {
      return q.questions;
    }
    return [];
  }

  render() {
    try {
      const that = this;
      let {
        quora_questions,
        autocomplete_questions,
        questions,
        cards,
        report,
      } = this.props;
      const {
        showAll,
        groupBySemanticSimilarity,
        groupBySemanticSimilarityLoading,
      } = this.state;

      quora_questions = this.getQuestionArray(quora_questions);
      autocomplete_questions = this.getQuestionArray(autocomplete_questions);
      questions = this.getQuestionArray(questions);
      const all_questions = quora_questions
        .concat(autocomplete_questions)
        .concat(questions);
      const enableSortingToggle =
        !_.isEmpty(all_questions) && _.isNumber(all_questions[0].cluster);
      const has_questions = all_questions.length > 0;

      return (
        <div className="position-relative">
          <h2>Questions To Answer</h2>
          <button
            className="btn btn-white"
            style={{
              position: "absolute",
              top: "-10px",
              right: "-12px",
              opacity: "0.5",
            }}
            onClick={() => {
              this.downloadQuestionsCSV(
                questions,
                autocomplete_questions,
                quora_questions
              );
            }}
          >
            <i className="fa fa-download" /> Export CSV
          </button>
          <p>
            Looking at commonly searched questions can help you understand what
            kind of answers your audience is looking for. Find natural ways to
            answer these questions in your content.
          </p>
          {!has_questions && (
            <div className="mt-2 text-left">No questions found</div>
          )}
          {has_questions && (
            <>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="mb-3">
                    <div className="d-flex toggle-medium">
                      <label
                        className="clickable mr-2 text-bold"
                        htmlFor="group-by-semantic-questions"
                      >
                        Group By Theme
                      </label>
                      <Toggle
                        id="group-by-semantic-questions"
                        checked={groupBySemanticSimilarity}
                        icons={false}
                        onChange={this.handleSemanticSimiliarityToggleClick}
                      />
                      <a
                        className="ml-2"
                        data-original-title="This organizes the list into groups of questions that are frequently asked together"
                        data-toggle="tooltip"
                        href="#"
                        title=""
                        style={{ color: "inherit" }}
                      >
                        <i className="fas fa-info-circle text-bluegray-4" />
                      </a>
                      {groupBySemanticSimilarityLoading && (
                        <div className="ml-3" style={{ marginTop: "-3px" }}>
                          <Loader type="spinner" className="dark" />{" "}
                          <div
                            className="text-muted"
                            style={{ marginTop: "-30px", marginLeft: "35px" }}
                          >
                            analyzing Questions...{" "}
                            <span className="text-s">
                              (this may take a minute)
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!groupBySemanticSimilarity && (
                <ReportOverviewQuestionsBySource
                  questions={this.props.questions}
                  quora_questions={this.props.quora_questions}
                  autocomplete_questions={this.props.autocomplete_questions}
                  cards={this.props.cards}
                  handleQuestionClick={this.handleQuestionClick}
                  report={this.props.report}
                  showToast={this.props.showToast}
                  fetchReport={this.props.fetchReport}
                  showAll={showAll}
                />
              )}
              {groupBySemanticSimilarity && (
                <ReportOverviewQuestionsByCluster
                  questions={this.props.questions}
                  quora_questions={this.props.quora_questions}
                  autocomplete_questions={this.props.autocomplete_questions}
                  cards={this.props.cards}
                  handleQuestionClick={this.handleQuestionClick}
                  report={this.props.report}
                  showToast={this.props.showToast}
                  fetchReport={this.props.fetchReport}
                  clusteredQuestions={this.props.clusteredQuestions}
                  showAll={showAll}
                />
              )}
              {!groupBySemanticSimilarity && (
                <div
                  className="clickable bg-bluegray-1 p-3 text-center mt-2"
                  onClick={() => {
                    this.setState({ showAll: !showAll });
                  }}
                >
                  {!showAll ? "Show all questions" : "Minimize questions"}
                </div>
              )}
            </>
          )}
        </div>
      );
    } catch (e) {
      console.log(e);
      console.log("exception rendering questions");
      return (
        <div className="position-relative">
          <h2>Questions To Answer</h2>
          <p>
            Looking at commonly searched questions can help you understand what
            kind of answers your audience is looking for. Find natural ways to
            answer these questions in your content.
          </p>
          <div className="overview-questions-new text-center p-4">
            No questions{" "}
          </div>
        </div>
      );
    }
  }
}

export default ReportOverviewQuestions;
