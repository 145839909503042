import React from "react";
import ReportOverviewQuestionsByClusterItem from "./report_overview_questions_by_cluster_item";

class ReportOverviewQuestionsByCluster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: 3,
    };
    this.node = React.createRef();
    this.resize = this.resize.bind(this);
    this.layoutIsotope = this.layoutIsotope.bind(this);
  }

  componentDidMount() {
    const that = this;
    this.resize();
    this.resizeObserver = new ResizeObserver(this.resize);
    this.resizeObserver.observe(this.node.current);
    this.layoutIsotope();
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    if (this.resizeObserver && this.node) {
      this.resizeObserver.unobserve(this.node.current);
    }
  }

  layoutIsotope() {
    const that = this;

    const windowWidth = $(window).width();
    if (windowWidth < 800) {
      this.setState({
        columns: 2,
      });
    } else {
      this.setState({
        columns: 3,
      });
    }

    $(".report-overview-questions-box").css("opacity", 0);
    const gutter = 10;
    const columnWidth =
      Math.floor($(that.node.current).width() / that.state.columns) - gutter;
    $(".report-overview-questions-box").css("width", `${columnWidth}px`);
    if ($(".report-overview-questions-by-cluster").data("isotope")) {
      $(".report-overview-questions-by-cluster").isotope("destroy");
    }
    $(".report-overview-questions-by-cluster").isotope({
      itemSelector: ".report-overview-questions-box",
      horizontalOrder: true,
      masonry: {
        columnWidth,
        gutter,
      },
    });
    $(".report-overview-questions-box").css("opacity", 1);
  }

  resize() {
    const that = this;
    this.layoutIsotope();
    window.setTimeout(() => {
      that.layoutIsotope();
    }, 351); // wait until brief width resize animation finishes
  }

  render() {
    let { clusteredQuestions, cards, handleQuestionClick, report } = this.props;
    clusteredQuestions = _.filter(clusteredQuestions, (cq) => cq.length > 1);
    return (
      <div className="report-overview-questions-by-cluster" ref={this.node}>
        {_.map(clusteredQuestions, (cluster, i) => (
          <ReportOverviewQuestionsByClusterItem
            questions={cluster}
            key={i}
            index={i}
            cards={cards}
            handleQuestionClick={handleQuestionClick}
            report={report}
          />
        ))}
      </div>
    );
  }
}

export default ReportOverviewQuestionsByCluster;
