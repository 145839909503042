import {
  RECEIVE_OUTLINE,
  SET_OUTLINE_TITLE,
  SET_OUTLINE_DESCRIPTION,
  SET_OUTLINE_WORD_COUNT,
  MOVE_CARD,
  MOVE_SELECTED_CARDS,
  SELECT_CARD,
  UNSELECT_ALL_CARDS,
  DISCARD_EDITS_ALL_CARDS,
  EDIT_CARD,
  EDIT_CARDS,
  SHIFT_CARDS,
  SHIFT_SELECTIONS,
  REMOVE_CARDS,
  REMOVE_LOADING_CARDS,
  ADD_CARD,
  TOGGLE_CARD,
  TOGGLE_HIGHLIGHTED_TOPIC,
  TOGGLE_HIGHLIGHTED_STATISTIC,
  TOGGLE_HIGHLIGHTED_CITATION,
  ADD_HIGHLIGHTED_TOPICS,
  REMOVE_HIGHLIGHTED_TOPICS,
  CLEAR_RECENTLY_TOGGLED,
  ADD_OUTLINE_TITLE_BOOKMARK,
  REMOVE_OUTLINE_TITLE_BOOKMARK,
  ADD_OUTLINE_DESCRIPTION_BOOKMARK,
  REMOVE_OUTLINE_DESCRIPTION_BOOKMARK,
  SHOW_OUTLINE_BOOKMARKS,
  HIDE_OUTLINE_BOOKMARKS,
  BYPASS_INITIAL_BRAINSTORM,
} from "../actions";

const maxIndent = 3;
const defaultCard = {
  text: "Default text",
  indent: 0,
  originalText: "",
  type: "heading",
  selected: false,
  editing: false,
};
const initialState = {
  isFetching: false,
  cards: [
    // {
    //   text: "How do I create a pillar page?",
    //   indent: 0,
    //   originalText: "How do I create a pillar page?",
    //   type: "heading",
    //   selected: false,
    //   editing: false,
    //   id:2
    // }
  ],
  title: "",
  description: "",
  wordCount: 0,
  highlightedTopics: [], // string array of highlighted topics
  lastSelectedIndex: 0, // used to handle shift clicking
  recentlyToggled: "", // used for the "just added" animation
  reportId: null, // used to decide when to reset state vs just apply changes on top
  titleBookmarks: [],
  descriptionBookmarks: [],
  showBookmarks: false,
  bypassBrainstorm: false,
};

export function outline(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_OUTLINE: {
      if (state.reportId !== action.reportId) {
        // reset outline to exactly match action
        return {
          ...initialState,
          ...action.outline,
          isFetching: false,
          reportId: action.reportId,
        };
      }
      // apply changes on top
      return {
        ...state,
        ...action.outline,
        isFetching: false,
        reportId: action.reportId,
      };
    }
    case SET_OUTLINE_TITLE: {
      const { title } = action;
      var stateCopy = { ...state };
      stateCopy.title = title;
      return stateCopy;
    }
    case SET_OUTLINE_DESCRIPTION: {
      const { description } = action;
      var stateCopy = { ...state };
      stateCopy.description = description;
      return stateCopy;
    }
    case SET_OUTLINE_WORD_COUNT: {
      const { wordCount } = action;
      var stateCopy = { ...state };
      stateCopy.wordCount = wordCount;
      return stateCopy;
    }
    case MOVE_CARD: {
      // moves a single card from index lastY to nextY
      const { lastY, nextY } = action;
      var stateCopy = { ...state };

      const dragCard = stateCopy.cards[lastY];
      var cardsClone = stateCopy.cards.slice();
      cardsClone.splice(lastY, 1);
      cardsClone.splice(nextY, 0, dragCard);
      stateCopy.cards = cardsClone;

      return stateCopy;
    }
    case MOVE_SELECTED_CARDS: {
      // moves all selected cards to nextY (nextY is already the card that was dragged and dropped)
      const { nextY } = action;
      var stateCopy = { ...state };

      const dragCard = stateCopy.cards[nextY];
      var cardsClone = stateCopy.cards.slice();

      // look for all selected cards that are not the dragged card and add them to a list
      const otherSelectedCards = [];
      _.each(cardsClone, (card, i) => {
        if (card.selected && i !== nextY) {
          otherSelectedCards.push(card);
          cardsClone[i] = null; // these moved selected cards will be cleared out when array is compacted
        }
      });

      cardsClone.splice(nextY, 1);
      cardsClone.splice(nextY, 0, otherSelectedCards);
      cardsClone.splice(nextY, 0, dragCard);
      cardsClone = _.compact(_.flatten(cardsClone));

      stateCopy.cards = cardsClone;
      return stateCopy;
    }
    case SELECT_CARD: {
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      const { index, ctrl, shift } = action;

      if (shift) {
        // use lastSelectedIndex and set all cards in between to selected
        const range = _.sortBy([state.lastSelectedIndex, index]);
        cardsCopy.forEach((card, i) => {
          if (i >= range[0] && i <= range[1]) {
            card.selected = true;
          }
        });
      } else if (ctrl) {
        // toggle the target card
        cardsCopy[index].selected = !cardsCopy[index].selected;
      } else {
        // unselect all the other cards
        cardsCopy.forEach((card, i) => {
          if (i !== index) {
            card.selected = false;
          }
        });

        // toggle the target card
        cardsCopy[index].selected = !cardsCopy[index].selected;
      }

      stateCopy.cards = cardsCopy;
      stateCopy.lastSelectedIndex = index;

      return stateCopy;
    }
    case UNSELECT_ALL_CARDS: {
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      cardsCopy.forEach((card) => {
        card.selected = false;
      });
      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case DISCARD_EDITS_ALL_CARDS: {
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      cardsCopy.forEach((card) => {
        card.editing = false;
      });
      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case EDIT_CARD: {
      const { index, edits } = action;
      const newCard = { ...state.cards[index], ...edits };
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      cardsCopy[index] = newCard;
      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case EDIT_CARDS: {
      const { edits } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();

      var newCardsCopy = cardsCopy.slice();
      cardsCopy.forEach((card, index) => {
        if (card.selected && card.type != "link") {
          const newCard = { ...card, ...edits };
          newCardsCopy[index] = newCard;
        }
      });
      cardsCopy = newCardsCopy;

      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case SHIFT_CARDS: {
      const { direction } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      switch (direction) {
        case "up": {
          var newCardsCopy = cardsCopy.slice();
          cardsCopy.forEach((card, index) => {
            if (
              index > 0 &&
              card.selected &&
              !newCardsCopy[index - 1].selected
            ) {
              const temp = newCardsCopy[index];
              newCardsCopy[index] = newCardsCopy[index - 1];
              newCardsCopy[index - 1] = temp;
            }
          });
          cardsCopy = newCardsCopy;
          break;
        }
        case "down": {
          var newCardsCopy = cardsCopy.slice();

          // reverse both arrays, do the same algorithm, and then reverse the result
          newCardsCopy.reverse();
          cardsCopy.reverse();

          cardsCopy.forEach((card, index) => {
            if (
              index > 0 &&
              card.selected &&
              !newCardsCopy[index - 1].selected
            ) {
              const temp = newCardsCopy[index];
              newCardsCopy[index] = newCardsCopy[index - 1];
              newCardsCopy[index - 1] = temp;
            }
          });
          cardsCopy = newCardsCopy;
          cardsCopy.reverse();
          break;
        }
        case "left": {
          cardsCopy.forEach((card, index) => {
            if (card.selected && card.indent > 0) {
              card.indent -= 1;
            }
          });
          break;
        }
        case "right": {
          cardsCopy.forEach((card, index) => {
            if (card.selected && card.indent < maxIndent - 1) {
              card.indent += 1;
            }
          });
          break;
        }
      }
      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case SHIFT_SELECTIONS: {
      const { direction } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      const selectedCardIndexes = _.filter(
        _.map(cardsCopy, (card, index) => (card.selected ? index : null)),
        (index) => !_.isNull(index)
      );
      switch (direction) {
        case "up": {
          var newSelectedCardIndexes = _.map(
            selectedCardIndexes,
            (cIndex) => cIndex - 1
          );
          cardsCopy = _.map(cardsCopy, (card, index) => {
            if (_.includes(newSelectedCardIndexes, index)) {
              card.selected = true;
            } else {
              card.selected = false;
            }
            return card;
          });
          break;
        }
        case "down": {
          var newSelectedCardIndexes = _.map(
            selectedCardIndexes,
            (cIndex) => cIndex + 1
          );
          cardsCopy = _.map(cardsCopy, (card, index) => {
            if (_.includes(newSelectedCardIndexes, index)) {
              card.selected = true;
            } else {
              card.selected = false;
            }
            return card;
          });
          break;
        }
      }
      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case REMOVE_CARDS: {
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();

      cardsCopy = cardsCopy.filter((card) => !card.selected);

      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case REMOVE_LOADING_CARDS: {
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();

      cardsCopy = cardsCopy.filter((card) => card.type !== "loading");

      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case ADD_CARD: {
      const { index, card } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();
      const cardCopy = { ...defaultCard, ...card };

      if (!cardCopy.id) {
        cardCopy.id = _.random(100000); // generate a random number id for this card
      }

      if (index >= cardsCopy.length) {
        cardsCopy.push(cardCopy);
      } else if (index >= 0) {
        cardsCopy.splice(index, 0, cardCopy);
      }
      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case TOGGLE_CARD: {
      // depending on if the originalText exists in the current list of cards, it will add/remove the card
      const { card } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();

      const identifyingFields = {
        originalText: card.originalText,
        type: card.type,
      };
      const cardExists = _.isObject(_.find(cardsCopy, identifyingFields));

      if (cardExists) {
        // remove card
        cardsCopy = _.reject(cardsCopy, identifyingFields);
      } else {
        // add card
        if (!card.id) {
          card.id = _.random(100000); // generate a random number id for this card
        }

        // splice after last selected card or end of list
        let lastSelectedCardIndex = -1;
        _.each(cardsCopy, (c, i) => {
          if (c.selected) {
            lastSelectedCardIndex = i;
          }
        });
        if (lastSelectedCardIndex === -1) {
          cardsCopy.push({ ...defaultCard, ...card });
        } else {
          cardsCopy.splice(lastSelectedCardIndex + 1, 0, {
            ...defaultCard,
            ...card,
          });
        }

        stateCopy.recentlyToggled = card.text;
      }

      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case TOGGLE_HIGHLIGHTED_TOPIC: {
      const { topic } = action;
      var stateCopy = { ...state };
      var topicsCopy = stateCopy.highlightedTopics.slice();

      const topicExists = topicsCopy.indexOf(topic) !== -1;
      if (topicExists) {
        // remove topic
        topicsCopy = _.without(topicsCopy, topic);
      } else {
        // add topic
        topicsCopy.push(topic);
        stateCopy.recentlyToggled = topic;
      }

      stateCopy.highlightedTopics = topicsCopy;
      return stateCopy;
    }
    case TOGGLE_HIGHLIGHTED_STATISTIC: {
      const { statistics } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();

      const statisticsExists = (() => {
        let statisticsExists = false;
        let statisticsIndex;
        cardsCopy.map((singleCard, index) => {
          if (
            singleCard.type == "link" &&
            singleCard.identifier == "statistics"
          ) {
            if (singleCard.text === statistics.text) {
              statisticsExists = true;
              statisticsIndex = index;
            }
          }
        });
        return {
          exists: statisticsExists,
          index: statisticsIndex,
        };
      })();

      if (statisticsExists.exists) {
        // remove statistics
        cardsCopy = cardsCopy.filter((v, i) => i != statisticsExists.index);
      } else {
        // Check for selected cards & get index if it exists.
        const index = (() => {
          let latest_index;
          cardsCopy.map((eachCard, index) => {
            if (eachCard.selected) {
              latest_index = index;
            }
          });
          return latest_index >= 0 ? latest_index + 1 : 999999;
        })();
        // Add statistics
        if (index >= cardsCopy.length) {
          cardsCopy.push(statistics);
        } else if (index >= 0) {
          cardsCopy.splice(index, 0, statistics);
        }
        stateCopy.recentlyToggled = statistics;
      }

      stateCopy.cards = cardsCopy;
      return stateCopy;
    }

    case TOGGLE_HIGHLIGHTED_CITATION: {
      const { citation } = action;
      var stateCopy = { ...state };
      var cardsCopy = stateCopy.cards.slice();

      const citationExists = (() => {
        let citationExists = false;
        let citationIndex;
        cardsCopy.map((singleCard, index) => {
          if (
            singleCard.type == "link" &&
            singleCard.identifier == "citations"
          ) {
            if (singleCard.text === citation.text) {
              citationExists = true;
              citationIndex = index;
            }
          }
        });
        return {
          exists: citationExists,
          index: citationIndex,
        };
      })();

      if (citationExists.exists) {
        // remove citation
        cardsCopy = cardsCopy.filter((v, i) => i != citationExists.index);
      } else {
        // Check for selected cards & get index if it exists.
        const index = (() => {
          let latest_index;
          cardsCopy.map((eachCard, index) => {
            if (eachCard.selected) {
              latest_index = index;
            }
          });
          return latest_index >= 0 ? latest_index + 1 : 999999;
        })();
        // Add citations
        if (index >= cardsCopy.length) {
          cardsCopy.push(citation);
        } else if (index >= 0) {
          cardsCopy.splice(index, 0, citation);
        }

        stateCopy.recentlyToggled = citation;
      }

      stateCopy.cards = cardsCopy;
      return stateCopy;
    }
    case ADD_HIGHLIGHTED_TOPICS: {
      var { topics } = action;
      var stateCopy = { ...state };
      var topicsCopy = stateCopy.highlightedTopics.slice();

      _.each(topics, (topic) => {
        if (topicsCopy.indexOf(topic) === -1) {
          topicsCopy.push(topic);
        }
      });

      stateCopy.highlightedTopics = topicsCopy;
      return stateCopy;
    }
    case REMOVE_HIGHLIGHTED_TOPICS: {
      var { topics } = action;
      var stateCopy = { ...state };
      var topicsCopy = stateCopy.highlightedTopics.slice();

      topicsCopy = _.difference(topicsCopy, topics);

      stateCopy.highlightedTopics = topicsCopy;
      return stateCopy;
    }
    case CLEAR_RECENTLY_TOGGLED: {
      var stateCopy = { ...state };
      stateCopy.recentlyToggled = initialState.recentlyToggled;
      return stateCopy;
    }
    case ADD_OUTLINE_TITLE_BOOKMARK: {
      var { title } = action;
      return {
        ...state,
        titleBookmarks: [...state.titleBookmarks, title],
      };
    }
    case REMOVE_OUTLINE_TITLE_BOOKMARK: {
      var { title } = action;
      return {
        ...state,
        titleBookmarks: state.titleBookmarks.filter(
          (bookmark) => bookmark !== title
        ),
      };
    }
    case ADD_OUTLINE_DESCRIPTION_BOOKMARK: {
      var { description } = action;
      return {
        ...state,
        descriptionBookmarks: [...state.descriptionBookmarks, description],
      };
    }
    case REMOVE_OUTLINE_DESCRIPTION_BOOKMARK: {
      var { description } = action;
      return {
        ...state,
        descriptionBookmarks: state.descriptionBookmarks.filter(
          (bookmark) => bookmark !== description
        ),
      };
    }
    case SHOW_OUTLINE_BOOKMARKS: {
      return {
        ...state,
        showBookmarks: true,
      };
    }
    case HIDE_OUTLINE_BOOKMARKS: {
      return {
        ...state,
        showBookmarks: false,
      };
    }
    case BYPASS_INITIAL_BRAINSTORM: {
      return {
        ...state,
        bypassBrainstorm: true,
      };
    }
    default:
      return state;
  }
}

export default outline;
