import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import ReportAnimation from "./report_animation";

// When a new trial is started from the marketing pages, the user is redirected to /anon
// which loads this application, which walks them through the creation of a new brief
class AnonymousReportInitializerWithPath extends Component {
  constructor(props) {
    super(props);
    this.pollForStatus = this.pollForStatus.bind(this);
    this.redirectToReport = this.redirectToReport.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.submitHubspotForm = this.submitHubspotForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validate = this.validate.bind(this);
    this.loadLocations = this.loadLocations.bind(this);
    this.createReport = this.createReport.bind(this);
    this.pollInterval = 2500;
    this.contactFormRef = React.createRef();
    this.briefFormRef = React.createRef();
    this.mainRef = React.createRef();
    this.reportId = "";
    this.state = {
      showLoader: false,
      showReportButton: false,
      pollStatus: "",
      error: false,
      firstname: "",
      lastname: "",
      phone: "",
      url: "",
      email: "",
      keyword: "",
      location: null,
      reportError: "",
      currentState: "choosePath", // choosePath, createNew, optimizeExisting, showForm, generatingBrief
    };
  }

  isUrl(s) {
    const regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }

  componentDidMount() {
    $(this.mainRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $(this.mainRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  pollForStatus() {
    const that = this;
    fetch(`/api/keyword_reports/${this.reportId}`)
      .then((response) => response.json())
      .then((pendingReport) => {
        that.setState({ pollStatus: pendingReport.status });
        if (pendingReport.status === "Success") {
          window.clearInterval(that.pollInterval);
          window.setTimeout(() => {
            that.setState({
              showReportButton: true,
            });
          }, 1000);
        }
      });
  }

  getCookie(name) {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }

  handleInputChange(event) {
    const { target } = event;
    this.setState({
      [target.name]: target.value,
    });
  }

  validate(formRef, successFunction, event) {
    if (formRef.current.reportValidity()) {
      successFunction(event);
    }
  }

  loadLocations(query) {
    return new Promise((resolve) => {
      fetch(`/api/keyword_reports/locations/${encodeURIComponent(query)}`, {
        credentials: "same-origin",
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          resolve(
            result.map((location_obj) => ({
              value: location_obj,
              label: `${location_obj.canonical_name} (${location_obj.domain})`,
            }))
          );
        });
    });
  }

  submitHubspotForm() {
    const that = this;
    const portalId = "6847770";
    const formGuid = "ff1f2d9b-8ae5-4cfb-8ac2-9c94515d441a";
    $.ajax({
      url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      type: "POST",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        fields: [
          {
            name: "email",
            value: this.state.email,
          },
          {
            name: "firstname",
            value: this.state.firstname,
          },
          {
            name: "lastname",
            value: this.state.lastname,
          },
          {
            name: "phone",
            value: this.state.phone,
          },
        ],
        context: {
          hutk: that.getCookie("hubspotutk"),
          pageUri: window.location.href,
          pageName: document.title,
        },
      }),
    })
      .done(() => {
        that.setState({ showThanks: true });
      })
      .fail(() => {
        that.setState({ showThanks: true });
      });
  }

  redirectToReport(id) {
    window.location.href = `/briefs/${id}/editor?guide=true`;
  }

  createReport(event) {
    const that = this;
    const { keyword, url, email, location } = this.state;

    const reportData = {
      name: keyword,
      import_url: url,
      email,
    };
    if (location && location.value) {
      reportData.location = location.value;
    }

    fetch("/api/keyword_reports/create_anonymous_report", {
      method: "post",
      body: JSON.stringify(reportData),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((responseObj) => {
        if (responseObj.success) {
          that.reportId = responseObj.id;
          tanalytics.track("Anonymous Report Created", {
            keyword,
            url,
            email,
          });
          that.pollInterval = window.setInterval(
            that.pollForStatus,
            that.pollInterval
          );
          that.setState({ showLoader: true, currentState: "generatingBrief" });
        } else if (responseObj.error) {
          that.setState({
            error: responseObj.error,
            currentState: "generatingBrief",
          });
        }
      });
    if (event) {
      event.preventDefault();
    }
  }

  render() {
    const pageHasError = $(".alert").length > 0;
    const {
      currentState,
      url,
      keyword,
      location,
      reportError,
      showLoader,
      error,
      pollStatus,
      showReportButton,
    } = this.state;

    if (!pageHasError) {
      return (
        <div
          className="anonymous-report-initializer text-center container"
          ref={this.mainRef}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: `
              .report-animation h3{
                display: none;
              }
              .all-wrapper{
                background-color: transparent !important;
                box-shadow:none !important;
              }
              @-webkit-keyframes rotate-vert-center {
                0% {
                  -webkit-transform: rotateY(0);
                          transform: rotateY(0);
                }
                20%, 100% {
                  -webkit-transform: rotateY(360deg);
                          transform: rotateY(360deg);
                }
              }
              @keyframes rotate-vert-center {
                0% {
                  -webkit-transform: rotateY(0);
                          transform: rotateY(0);
                }
                20%, 100% {
                  -webkit-transform: rotateY(360deg);
                          transform: rotateY(360deg);
                }
              }

            `,
            }}
          />
          <div className="row">
            <div
              className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12"
              style={{ padding: "20px", paddingTop: "110px" }}
            >
              <div>
                <div
                  className="p-3 m-3"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                    boxShadow: "rgba(64, 100, 127, 0.5) 0px 26px 22px -20px",
                    position: "relative",
                  }}
                >
                  {currentState === "choosePath" && (
                    <div>
                      <h3 className="mt-3 mb-0">I want to...</h3>
                      <div className="d-flex">
                        <div
                          className="clickable anonymous-path"
                          onClick={() => {
                            this.setState({ currentState: "createNew" });
                          }}
                        >
                          <h4>Create New Content</h4>
                          <p>
                            Find the right topics to cover when creating a new
                            blog post or article.
                          </p>
                        </div>

                        <div
                          className="clickable anonymous-path"
                          onClick={() => {
                            this.setState({ currentState: "optimizeExisting" });
                          }}
                        >
                          <h4>Improve Existing Content</h4>
                          <p>
                            Enhance your existing content and improve its search
                            visibility.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {(currentState === "createNew" ||
                    currentState === "optimizeExisting") && (
                    <a
                      href="#"
                      style={{
                        display: "block",
                        position: "absolute",
                        left: "20px",
                        top: "15px",
                        color: "rgb(136, 136, 136)",
                      }}
                      onClick={() => {
                        this.setState({
                          currentState: "choosePath",
                          url: "",
                          keyword: "",
                          location: null,
                        });
                      }}
                    >
                      <i className="fa fa-chevron-left" aria-hidden="true" />{" "}
                      Back
                    </a>
                  )}

                  {currentState === "createNew" && (
                    <h3 className="m-4">Create New Content</h3>
                  )}
                  {currentState === "optimizeExisting" && (
                    <h3 className="m-4">Optimize Existing Content</h3>
                  )}

                  {(currentState === "createNew" ||
                    currentState === "optimizeExisting") && (
                    <div>
                      <form
                        className="ml-5 mr-5 mt-3 mb-3"
                        ref={this.briefFormRef}
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                        style={{ textAlign: "left" }}
                      >
                        {currentState === "optimizeExisting" && (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="url">
                                  <span>Existing Content</span>
                                </label>
                                <input
                                  name="url"
                                  className="form-control"
                                  placeholder="https://www.example.com/blog/post"
                                  type="url"
                                  value={url}
                                  onChange={this.handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="keyword">
                                <span>Focus Keyword</span>
                              </label>
                              <input
                                name="keyword"
                                className="form-control"
                                placeholder="Add keyword to optimize for"
                                type="text"
                                value={keyword}
                                onChange={this.handleInputChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="mt-1">
                              <label htmlFor="location">
                                <span>Location</span>{" "}
                                <a
                                  data-original-title="This is where your content's audience is searching from. Defaults to United States."
                                  data-toggle="tooltip"
                                  href="#"
                                  title=""
                                >
                                  <i className="fa fa-info-circle" />
                                </a>
                              </label>
                              <AsyncSelect
                                name="location"
                                cacheOptions
                                loadOptions={this.loadLocations}
                                onChange={(selected) => {
                                  this.setState({ location: selected });
                                }}
                                value={location}
                                placeholder="Start typing to see locations (default: USA)"
                              />
                            </div>
                          </div>
                        </div>
                        {reportError && (
                          <div className="row">
                            <div className="col-sm-12 text-danger">
                              {reportError}
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-buttons-w">
                              <button
                                onClick={this.validate.bind(
                                  this,
                                  this.briefFormRef,
                                  () => {
                                    this.setState({ currentState: "showForm" });
                                  }
                                )}
                                className="btn btn-primary"
                                type="button"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  {currentState === "showForm" && (
                    <div>
                      <img
                        style={{ width: "80px" }}
                        src="https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5e8cd838af93730de38b4af2_quill-transparent.png"
                      />
                      <p>
                        Before we generate your personalized content brief, we
                        have a few more questions.
                      </p>
                      <form ref={this.contactFormRef}>
                        <div className="form-group row">
                          <label
                            className="col-form-label col-sm-4"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              id="email"
                              placeholder="harper@lee.com"
                              value={this.state.email}
                              onChange={this.handleInputChange}
                              name="email"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-form-label col-sm-4"
                            htmlFor="firstname"
                          >
                            First Name
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              id="firstname"
                              placeholder="Harper"
                              value={this.state.firstname}
                              onChange={this.handleInputChange}
                              name="firstname"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-form-label col-sm-4"
                            htmlFor="lastname"
                          >
                            Last Name
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              id="lastname"
                              placeholder="Lee"
                              value={this.state.lastname}
                              onChange={this.handleInputChange}
                              name="lastname"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-form-label col-sm-4"
                            htmlFor="phone"
                          >
                            Phone Number
                          </label>
                          <div className="col-sm-8">
                            <input
                              id="phone"
                              className="form-control"
                              placeholder="415-555-0100"
                              value={this.state.phone}
                              onChange={this.handleInputChange}
                              type="tel"
                              name="phone"
                              required
                            />
                          </div>
                        </div>
                        <button
                          onClick={this.validate.bind(
                            this,
                            this.contactFormRef,
                            () => {
                              this.submitHubspotForm();
                              this.createReport();
                            }
                          )}
                          className="btn btn-primary mb-3 mt-2"
                          type="button"
                        >
                          Generate Content Brief
                        </button>
                      </form>
                    </div>
                  )}
                  <div
                    className={
                      currentState === "generatingBrief" ? "" : "d-none"
                    }
                  >
                    <img
                      src="https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5e8cd838af93730de38b4af2_quill-transparent.png"
                      style={{
                        width: "80px",
                        animation:
                          "rotate-vert-center 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both",
                      }}
                    />
                    <h3>Now, let us work our magic...</h3>
                    <p>
                      It takes about 3-4 minutes to generate the content brief.
                      In the meantime, watch this video to learn how to use our
                      product. We will let you know as soon as your brief is
                      finished.
                    </p>
                    <div
                      className="anonymous-video"
                      dangerouslySetInnerHTML={{
                        __html: `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/cykeiotl7c?videoFoam=true" title="Getting Started with Topic Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`,
                      }}
                    />
                    <div className="mt-5 mb-3">
                      {!showReportButton && showLoader && (
                        <ReportAnimation
                          keyword={keyword}
                          status={pollStatus}
                        />
                      )}
                      {showReportButton && (
                        <div>
                          <p>Your content brief is ready!</p>
                          <a
                            className="btn btn-success text-white"
                            href={
                              _.isEmpty(url)
                                ? `/briefs/${this.reportId}/overview`
                                : `/briefs/${this.reportId}/editor`
                            }
                          >
                            Open Content Brief
                          </a>
                        </div>
                      )}
                      {_.isString(this.state.error) && (
                        <div className="anonymous-report-error text-danger">
                          {error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default AnonymousReportInitializerWithPath;
