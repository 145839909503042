import React, { Component } from "react";

import { Route, Switch, withRouter } from "react-router-dom";
import _ from "lodash";
import { QueryClient, QueryClientProvider } from "react-query";
import NavSide from "./nav_side";
import NavTop from "./nav_top";
import ReportsDashboard from "./reports_dashboard";
import InventoriesDashboard from "./inventories_dashboard";
import InventoryOverview from "./inventory_overview";
import ReportOverview from "./report_overview";
import Editor from "./editor";
import UpgradeModal from "./upgrade_modal";
import IntroSlider from "./intro_slider";
import ReportModal from "./report_modal";
import ToastsView from "./toasts_view";
import VideoModal from "./video_modal";
import Learn from "./learn";
import TrialCustomerDataForm from "./trial_customer_data_form";

const queryClient = new QueryClient();

class TopicApp extends Component {
  render() {
    const isEditor = /\/editor|\/overview/.test(this.props.location.pathname);
    const isInventoryOverview = /\/inventories\//.test(
      this.props.location.pathname
    );

    if (
      /trial\/confirmation/.test(this.props.location.pathname) &&
      tanalytics &&
      _.isFunction(tanalytics.track)
    ) {
      tanalytics.track("trial_started");
    } else if (
      /subscribe_confirmation/.test(this.props.location.pathname) &&
      tanalytics &&
      _.isFunction(tanalytics.track)
    ) {
      tanalytics.track("subscription_started");
    }

    return (
      <QueryClientProvider client={queryClient}>
        <div className="topic d-flex flex-column">
          <Switch>
            <Route path="/briefs/:id/*" render={() => <NavTop />} />
            <Route path="*" render={() => <NavTop />} />
          </Switch>
          <div className="topic-container layout-w d-flex flex-grow-1 flex-column">
            <Route
              path={["/briefs/:id/editor", "/briefs/:id/overview"]}
              render={() => <NavSide />}
            />
            <div className="d-flex">
              <div className="content-i d-flex w-100 topic-app-content-i">
                <div
                  className={`content-box d-flex ${
                    isEditor || isInventoryOverview ? "p-0" : ""
                  }`}
                >
                  <Switch>
                    <Route
                      path="/briefs/:id/editor"
                      render={() => <Editor />}
                    />
                    <Route
                      path="/briefs/:id/overview"
                      render={() => <ReportOverview />}
                    />
                    <Route
                      exact
                      path="/briefs"
                      render={() => <ReportsDashboard perPage={20} />}
                    />
                    <Route
                      exact
                      path="/inventories"
                      render={() => <InventoriesDashboard />}
                    />
                    <Route
                      exact
                      path="/inventories/:id"
                      render={() => <InventoryOverview perPage={20} />}
                    />
                    <Route
                      path="/briefs/tag/:tag"
                      render={() => <ReportsDashboard perPage={20} />}
                    />
                    <Route path="/learn" render={() => <Learn />} />
                    <Route
                      exact
                      path="/subscribe_confirmation"
                      render={() => <ReportsDashboard perPage={20} />}
                    />
                    <Route
                      exact
                      path="/trial/confirmation"
                      render={() => <TrialCustomerDataForm />}
                    />
                  </Switch>
                  <ReportModal type="keyword" onSave={() => {}} />
                  <ReportModal type="report" onSave={() => {}} />
                </div>
              </div>
            </div>
          </div>
          <IntroSlider />
          <ToastsView />
          <VideoModal id="topic-pro-video" vimeoId="524063085" />
          <VideoModal id="getting-started-video" wistiaId="cykeiotl7c" />
          <VideoModal id="outline-builder-video" vimeoId="487099541" />
          <VideoModal id="grader-video" vimeoId="467944989" />
          <VideoModal id="keyword-research-video" vimeoId="485703355" />
          <VideoModal id="statistics-video" vimeoId="567213846" />
          <UpgradeModal />
        </div>
      </QueryClientProvider>
    );
  }
}

export default withRouter(TopicApp);
