/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import SubscriptionUpdate from "./subscription_update";

let formSubmissionInProcess = false;
const stripeTokenHandler = function (token) {
  // Insert the token ID into the form so it gets submitted to the server
  const form = document.getElementById("payment_form");
  const hiddenInput = document.createElement("input");
  hiddenInput.setAttribute("type", "hidden");
  hiddenInput.setAttribute("name", "stripeToken");
  hiddenInput.setAttribute("value", token.id);
  form.appendChild(hiddenInput);
  ["brand", "exp_month", "exp_year", "last4"].forEach((field) =>
    addFieldToPaymentForm(form, token, field)
  );
  // Submit the form
  document.getElementById("purchase_btn").disabled = true;
  formSubmissionInProcess = false;
  return form.submit();
};

var addFieldToPaymentForm = function (form, token, field) {
  const hiddenInput = document.createElement("input");
  hiddenInput.setAttribute("type", "hidden");
  hiddenInput.setAttribute("name", `card_${field}`);
  hiddenInput.setAttribute("value", token.card[field]);
  return form.appendChild(hiddenInput);
};

$(document).ready(() => {
  if (document.querySelector("#card-element") !== null) {
    const public_key = AP.stripe_public_key;
    const stripe = Stripe(public_key);
    const elements = stripe.elements();
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        fontSize: "16px",
        color: "#32325d",
      },
    };
    // Create an instance of the card Element
    const card = elements.create("card", { style });
    // Add an instance of the card Element into the `card-element` <div>
    card.mount("#card-element");
    card.addEventListener("change", (event) => {
      const displayError = document.getElementById("card-errors");
      if (event.error) {
        return (displayError.textContent = event.error.message);
      }
      return (displayError.textContent = "");
    });

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById("payment_form");
    form.addEventListener("submit", (event) => {
      if (!formSubmissionInProcess) {
        formSubmissionInProcess = true;
        document.getElementById("purchase_btn").disabled = true;
        event.preventDefault();
        return stripe.createToken(card).then((result) => {
          if (result.error) {
            // Inform the customer that there was an error
            const errorElement = document.getElementById("card-errors");
            formSubmissionInProcess = false;
            document.getElementById("purchase_btn").disabled = false;
            return (errorElement.textContent = result.error.message);
          }
          // Send the token to your server
          return stripeTokenHandler(result.token);
        });
      }
    });
  }

  if (document.getElementById("subscription-upgrade")) {
    ReactDOM.render(
      <SubscriptionUpdate type="upgrade" />,
      document.getElementById("subscription-upgrade")
    );
  }

  if (document.getElementById("subscription-upgrade-switch")) {
    ReactDOM.render(
      <SubscriptionUpdate type="switch" />,
      document.getElementById("subscription-upgrade-switch")
    );
  }

  // confirmation for cancellation
  $(".btn-cancel-account").click((e) => {
    if (!window.confirm("Are you sure you want to cancel your account?")) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
});
