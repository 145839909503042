import React from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import { saveOutlineDescription } from "./redux/actions";
import { cleanTitle } from "./helpers";
import OutlineDescriptionBookmarkButton from "./components/outline/bookmarks/content/description/bookmark_button";
import OutlineRecentDescriptionBookmarks from "./components/outline/bookmarks/recent/description_bookmarks";

class OutlineBuilderPopoverDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      descriptions: [],
      descriptionChosen: "",
      error: "",
      currentState: "descriptions", // 'descriptions', 'tryAgain', 'noTitle'
    };
    this.ref = React.createRef();
    this.handleSelectDescription = this.handleSelectDescription.bind(this);
    this.generateDescriptions = this.generateDescriptions.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.title)) {
      this.setState({ currentState: "noTitle" });
    } else {
      this.generateDescriptions();
    }
  }

  generateDescriptions() {
    const that = this;
    this.setState({ isLoading: true, error: "" });
    $.getJSON(
      `/api/openai/description_variations?title=${encodeURIComponent(
        this.props.title
      )}&keyword_report_id=${encodeURIComponent(this.props.report.id)}`
    )
      .done((res) => {
        if (!_.isEmpty(res)) {
          if (res.filter === "1" || res.filter === "2") {
            that.setState({
              error:
                "The content filter has detected that the generated text may potentially be sensitive, political, or controversial. Give it an extra check just in case something sounds wrong.",
              descriptions: res.descriptions,
              currentState: "descriptions",
            });
          } else if (!_.isEmpty(res.descriptions)) {
            that.setState({
              descriptions: res.descriptions,
              currentState: "descriptions",
            });
          }
        } else {
          that.setState({
            currentState: "tryAgain",
            error: "Unable to come up with any ideas. Please try again.",
          });
        }
      })
      .fail((res) => {
        that.setState({
          currentState: "tryAgain",
          error:
            "Something went wrong getting the ideas. Please email support@usetopic.com",
        });
      })
      .always(() => {
        that.setState({
          isLoading: false,
        });
      });
  }

  handleSelectDescription(description) {
    const { descriptionChosen } = this.state;
    if (descriptionChosen === description) {
      this.setState({
        descriptionChosen: "",
      });
    } else {
      this.setState({
        descriptionChosen: description,
      });
    }
  }

  // description has been chosen for outline
  saveDescriptionToOutline() {
    this.props.saveOutlineDescription(
      this.props.report,
      this.state.descriptionChosen
    );
    this.props.handleClose();
  }

  render() {
    var that = this;
    var { isLoading, descriptions, descriptionChosen, error, currentState } =
      this.state;
    var { type, handleClose } = this.props;

    const builderBodyClass =
      this.props.outline.descriptionBookmarks.length > 0
        ? "builder-popover-body-compact"
        : "builder-popover-body";

    return (
      <div>
        <div
          className={`builder-popover-rainbow ${isLoading ? "loading" : ""}`}
        />
        <div className="builder-popover-head mb-2">Rewrite Description</div>
        <OutlineRecentDescriptionBookmarks
          report={this.props.report}
          handleSelect={this.handleSelectDescription}
          selectedDescription={descriptionChosen}
        />

        <div className={isLoading ? "builder-popover-isloading" : ""}>
          {currentState === "noTitle" && (
            <div>Please generate a title before generating a description.</div>
          )}
          {currentState === "tryAgain" && (
            <div>
              {error && <div className="text-danger">{error}</div>}
              <button
                className="btn btn-primary"
                onClick={this.generateDescriptions}
              >
                Try Again
              </button>
            </div>
          )}
          {currentState === "descriptions" && (
            <div>
              {isLoading && <div>rewriting description...</div>}

              {!isLoading && (
                <div>
                  <div className="mb-2">
                    <h6 className="d-inline">New Ideas</h6>{" "}
                    <button
                      className="btn btn-white text-bluegray-5 builder-popover-refresh"
                      onClick={this.generateDescriptions}
                    >
                      <i className="fas fa-redo-alt" /> Refresh
                    </button>
                  </div>
                  <div className={builderBodyClass}>
                    {descriptions
                      .filter(
                        (d) =>
                          !this.props.outline.descriptionBookmarks.includes(d)
                      )
                      .map((description, i) => {
                        var descriptionIsChosen =
                          descriptionChosen === description;
                        return (
                          <div
                            key={i}
                            className={`clickable ml-1 mr-1 p-2 pr-4 mt-2 mb-2 builder-popover-card animated fadeUp delay-${i} ${
                              descriptionIsChosen ? "text-bold active" : ""
                            }`}
                            onClick={that.handleSelectDescription.bind(
                              that,
                              description
                            )}
                          >
                            {description}{" "}
                            {descriptionIsChosen && (
                              <i className="fas fa-check text-success ml-2" />
                            )}
                            <OutlineDescriptionBookmarkButton
                              report={this.props.report}
                              description={description}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {error && <div className="text-danger">{error}</div>}
              {!isLoading && (
                <div className="text-right">
                  <div
                    onClick={() => {
                      if (_.isEmpty(descriptionChosen)) {
                        this.setState({
                          error: "Please select a description",
                        });
                      } else {
                        this.saveDescriptionToOutline();
                      }
                    }}
                    className={`clickable text-right btn btn-primary mt-2 ${
                      _.isEmpty(descriptionChosen) ? "disabled" : ""
                    }`}
                  >
                    Choose Description
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var props = {
    title: state.outline.title,
    outline: state.outline,
  };
  return props;
}

export default connect(mapStateToProps, { saveOutlineDescription })(
  OutlineBuilderPopoverDescription
);
