import React from "react";
import { useSelector } from "react-redux";
import OutlineDescriptionBookmarkListItem from "../content/description/list_item";

/**
 * Renders the 3 most recent description bookmarks
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineRecentDescriptionBookmarks({
  report,
  handleSelect,
  selectedDescription,
}) {
  const { descriptionBookmarks } = useSelector((state) => state.outline);

  if (!descriptionBookmarks || descriptionBookmarks.length === 0) {
    return null;
  }

  const list = [];
  const offset =
    descriptionBookmarks.length > 3 ? descriptionBookmarks.length - 3 : 0;
  for (let i = offset; i < descriptionBookmarks.length; i++) {
    const itemIndex = i - offset;
    list.push(
      <OutlineDescriptionBookmarkListItem
        key={`recent-description-${itemIndex}`}
        report={report}
        description={descriptionBookmarks[i]}
        i={itemIndex}
        handleSelect={handleSelect}
        selectedDescription={selectedDescription}
        smallText
      />
    );
  }
  return (
    <div className="pb-2">
      <h6>Recently Bookmarked</h6>
      {list}
    </div>
  );
}

export default OutlineRecentDescriptionBookmarks;
