import React from "react";
import { withRouter } from "react-router-dom";

class TrialCustomerDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.node = React.createRef();
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    document.body.appendChild(script);
    this.node.current.addEventListener(
      "PaperformSubmission",
      this.handleSubmit
    );
  }

  componentWillUnmount() {
    this.node.current.removeEventListener("PaperformSubmission");
  }

  handleSubmit() {
    this.props.history.push(`/briefs`);
  }

  render() {
    return (
      <div
        ref={this.node}
        className="d-flex w-100"
        style={{ justifyContent: "center" }}
      >
        <div
          data-paperform-id="topictrial"
          prefill={`au0g7=${encodeURIComponent(AP.currentUser.email)}`}
          style={{ width: "540px" }}
        />
      </div>
    );
  }
}

export default withRouter(TrialCustomerDataForm);
