import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { InitialBrainstormContext } from "./context";

function InitialBrainstormLoadingSkeleton() {
  const { isFetching } = useContext(InitialBrainstormContext);

  if (!isFetching) {
    return null;
  }
  const list = Array(3)
    .fill()
    .map((_, i) => (
      <div
        key={`initial-brainstorm-loading-${i}`}
        className={`p-3 mt-3 bg-white shadow rounded-md animated fadeUp delay-${i}`}
      >
        <div className="text-bold">
          <Skeleton height={20} />
        </div>
        <div className="text-primary-5 mt-2" style={{ fontSize: "12px" }}>
          <Skeleton count={3} />
          <Skeleton width={60} />
        </div>
        <button className="btn bg-primary-2 w-100 mt-2" disabled>
          Start With This
        </button>
      </div>
    ));

  return <div className="px-3 pb-4 initial-brainstorm-content">{list}</div>;
}

export default InitialBrainstormLoadingSkeleton;
