import React from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import { createReportBulk } from "./redux/actions";
import InventoryPageModalRow from "./inventory_page_modal_row";
import Loader from "./loader";

class InventoryPageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      keywords: null,
      current_clicks_metrics_report: null,
      current_impressions_metrics_report: null,
      previous_clicks_metrics_report: null,
      previous_impressions_metrics_report: null,
      declining_query_list: null,
    };
    this.defaultState = this.state;
    this.resetState = this.resetState.bind(this);
    this.modalRef = React.createRef();
    this.fetchPageData = this.fetchPageData.bind(this);
    this.getGraphData = this.getGraphData.bind(this);
    this.graphOptions = {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
    };
  }

  resetState() {
    this.setState(this.defaultState);
  }

  componentDidMount() {
    const that = this;
    // $(this.modalRef.current).on('hidden.bs.modal', () => {
    //   that.resetState();
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoading, keywords } = this.state;
    if (
      !isLoading &&
      this.props.page &&
      (!prevProps.page || prevProps.page.id !== this.props.page.id)
    ) {
      this.fetchPageData();
    }
  }

  fetchPageData() {
    const that = this;
    const { page, inventory, extraFilters } = this.props;
    this.setState({
      isLoading: true,
    });
    fetch("/api/inventories/page", {
      method: "POST",
      body: JSON.stringify({
        inventory_id: inventory.id,
        page_id: page.id,
        extra_filter: extraFilters[0],
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let newState = {
          isLoading: false,
        };
        if (json.keywords) {
          newState = Object.assign(newState, {
            keywords: json.keywords,
          });
        }

        if (!_.isEmpty(json.current_clicks_metrics_report)) {
          newState = Object.assign(newState, {
            current_clicks_metrics_report: json.current_clicks_metrics_report,
          });
        }
        if (!_.isEmpty(json.current_impressions_metrics_report)) {
          newState = Object.assign(newState, {
            current_impressions_metrics_report:
              json.current_impressions_metrics_report,
          });
        }
        if (!_.isEmpty(json.previous_clicks_metrics_report)) {
          newState = Object.assign(newState, {
            previous_clicks_metrics_report: json.previous_clicks_metrics_report,
          });
        }
        if (!_.isEmpty(json.previous_impressions_metrics_report)) {
          newState = Object.assign(newState, {
            previous_impressions_metrics_report:
              json.previous_impressions_metrics_report,
          });
        }
        if (!_.isEmpty(json.declining_query_list)) {
          newState = Object.assign(newState, {
            declining_query_list: json.declining_query_list,
          });
        }

        that.setState(newState);
      });
  }

  getGraphData() {
    const { current_clicks_metrics_report, previous_clicks_metrics_report } =
      this.state;
    const dates = _.map(
      current_clicks_metrics_report,
      (metric, index) => index
    );
    const clicks = _.map(current_clicks_metrics_report, (metric) => metric[1]);
    const previous_clicks = _.map(
      previous_clicks_metrics_report,
      (metric) => metric[1]
    );
    return {
      labels: dates,
      datasets: [
        {
          label: "Clicks",
          data: clicks,
          backgroundColor: "#e9ecef",
          borderColor: "rgba(35, 123, 230, .5)",
          fill: false,
        },
        {
          label: "Previous Clicks",
          data: previous_clicks,
          backgroundColor: "#e9ecef",
          borderColor: "rgba(35, 123, 230, .5)",
          borderDash: [5, 5],
          fill: false,
        },
      ],
    };
  }

  render() {
    const {
      isLoading,
      keywords,
      current_clicks_metrics_report,
      current_impressions_metrics_report,
      previous_clicks_metrics_report,
      declining_query_list,
    } = this.state;
    const { page, createReportBulk } = this.props;
    const showGraph =
      !_.isEmpty(current_clicks_metrics_report) &&
      !_.isEmpty(current_impressions_metrics_report);
    let graphChangeColor;
    let graphChangeDelta;
    if (showGraph) {
      graphChangeDelta =
        _.sumBy(current_clicks_metrics_report, (item) => item[1]) -
        _.sumBy(previous_clicks_metrics_report, (item) => item[1]);
      graphChangeColor = graphChangeDelta > 0 ? "green" : "orange";
    }

    let globalMinClicksDelta;
    let globalMaxClicksDelta;
    let globalMinImpressionsDelta;
    let globalMaxImpressionsDelta;
    if (!_.isEmpty(declining_query_list)) {
      // calculate the global max and min click delta so that it can be colored correctly in the declining keywords table
      globalMinClicksDelta = _.min(_.map(declining_query_list, "clicks_delta"));
      globalMaxClicksDelta = _.max(_.map(declining_query_list, "clicks_delta"));
      globalMinImpressionsDelta = _.min(
        _.map(declining_query_list, "impressions_delta")
      );
      globalMaxImpressionsDelta = _.max(
        _.map(declining_query_list, "impressions_delta")
      );
    }

    return (
      <div
        ref={this.modalRef}
        aria-hidden="true"
        className="modal fade inventory-page-modal onboarding-modal"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div
            className="modal-content"
            style={{ backgroundColor: "#f5f8fb", overflow: "hidden" }}
          >
            <div className={`modal-rainbow ${isLoading ? "loading" : ""}`} />
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div className="pt-3 onboarding-content">
              {page && (
                <div className="row mb-5">
                  <div className="col-md-4">
                    <img
                      className="w-100 inventory-page-modal-screenshot"
                      src={`https://image.thum.io/get/auth/16969-usetopiclocalhost/width/400/crop/2600/allowJPG/${page.url}`}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="pl-2">
                      <div>
                        <a
                          href={page.url}
                          target="_blank"
                          className="text-blue-4 text-sm"
                          rel="noreferrer"
                        >
                          {page.url}
                        </a>
                      </div>
                      <div className="mt-2 text-2xl">{page.title}</div>
                      {page.metadata && (
                        <div className="mt-2 text-primary-4">
                          {page.metadata.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {isLoading && <Loader type="spinner" className="dark" />}
              {!isLoading && showGraph && (
                <div
                  className="mb-4 d-flex flex-column"
                  style={{ height: "300px", position: "relative" }}
                >
                  <div className="text-xl mb-2">
                    Organic Traffic{" "}
                    <div
                      className={`badge badge-pill text-sm bg-${graphChangeColor}-2 text-${graphChangeColor}-10`}
                    >
                      {graphChangeDelta > 0 ? "+" : ""}
                      {graphChangeDelta}
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <Line
                      data={this.getGraphData}
                      options={this.graphOptions}
                    />
                  </div>
                </div>
              )}
              {!isLoading && !_.isEmpty(declining_query_list) && (
                <div>
                  <div className="mb-4 mt-2 text-xl">Declining Keywords</div>
                  <table className="table-striped w-100">
                    <thead>
                      <tr>
                        <th>keyword</th>
                        <th>impressions</th>
                        <th>position</th>
                        <th>clicks</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(declining_query_list, (keyword, i) => (
                        <InventoryPageModalRow
                          key={i}
                          i={i}
                          page={page}
                          keyword={keyword.keyword}
                          previousClicks={keyword.previous_clicks}
                          clicks={keyword.clicks}
                          previousPosition={Math.round(
                            keyword.previous_position
                          )}
                          impressions={keyword.impressions}
                          previousImpressions={keyword.previous_impressions}
                          position={Math.round(keyword.position)}
                          handleCreateBrief={createReportBulk}
                          globalMinClicksDelta={globalMinClicksDelta}
                          globalMaxClicksDelta={globalMaxClicksDelta}
                          globalMinImpressionsDelta={globalMinImpressionsDelta}
                          globalMaxImpressionsDelta={globalMaxImpressionsDelta}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {!isLoading &&
                !_.isEmpty(keywords) &&
                _.isEmpty(declining_query_list) && (
                  <div>
                    <div className="mb-4 mt-4 text-xl">
                      Opportunity Keywords
                    </div>
                    <table className="table-striped w-100">
                      <thead>
                        <tr>
                          <th>keyword</th>
                          <th>clicks</th>
                          <th>impressions</th>
                          <th>position</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(keywords, (keyword, i) => (
                          <InventoryPageModalRow
                            key={i}
                            i={i}
                            page={page}
                            keyword={keyword.keyword}
                            clicks={keyword.clicks}
                            impressions={keyword.impressions}
                            position={keyword.position}
                            handleCreateBrief={createReportBulk}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { pages } = state;
  return {
    extraFilters: pages.extraFilters,
  };
}

export default connect(mapStateToProps, { createReportBulk })(
  InventoryPageModal
);
