import React from "react";

function Coachmark({ children }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {children}
      <div className="coachmark-circle" />
    </div>
  );
}

export default Coachmark;
