import React from "react";
import * as clipboard from "clipboard-polyfill"; // need to use polyfill to support copying formatted html in Safari
import store from "./redux/store";

export function dateToPrettyString(date) {
  if (!_.isDate(date)) {
    const splitDate = date
      .split("T")[0]
      .split("-")
      .map((x) => parseInt(x, 10));
    date = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (!!date.getDate() && !!date.getFullYear() && date.getFullYear() > 1990) {
    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }
  return "";
}

export function gradeToColor(grade) {
  if (grade === "A++") {
    return "cyan";
  }
  if (grade === "A+") {
    return "green";
  }
  if (grade === "A") {
    return "green";
  }
  if (grade === "A-") {
    // A
    return "yellow-green";
  }
  if (/B/.test(grade)) {
    // B
    return "yellow";
  }
  if (/C/.test(grade)) {
    // C
    return "orange-yellow";
  }
  if (/D/.test(grade)) {
    // D
    return "orange";
  }
  return "red";
}

export function wordCountToColor(wordCount, recommendedWordCount) {
  const comparisonFactor = wordCount / recommendedWordCount;
  if (comparisonFactor > 1.5) {
    return "cyan";
  }
  if (comparisonFactor > 1.3) {
    return "green";
  }
  if (comparisonFactor > 1.1) {
    return "green";
  }
  if (comparisonFactor > 1) {
    return "green";
  }
  if (comparisonFactor > 0.8) {
    return "yellow-green";
  }
  if (comparisonFactor > 0.7) {
    return "yellow";
  }
  if (comparisonFactor > 0.6) {
    return "orange-yellow";
  }
  return "orange";
}

// turns raw HTML content, extracts the headings, and turns it into structured HTML
export function headersToHTML(inputHTML, elementConstructor) {
  if (!inputHTML) {
    return [];
  }
  const headers = inputHTML.match(/<\/?h[1-6]>.*/gi) || [];
  const outputHTML = [];
  let headerObjs = [];
  const domParser = new DOMParser();

  function typeToClassName(type) {
    return `competitor-h${type}`;
  }

  headers.forEach((header, index) => {
    let type = false;
    if (/h1/.test(header)) {
      type = 1;
    } else if (/h2/.test(header)) {
      type = 2;
    } else if (/h3/.test(header)) {
      type = 3;
    } else if (/h4/.test(header)) {
      type = 4;
    } else if (/h5/.test(header)) {
      type = 5;
    } else if (/h6/.test(header)) {
      type = 6;
    }
    if (type) {
      const contents = domParser.parseFromString(header, "text/html").body
        .textContent;
      if (
        _.isString(contents) &&
        contents.trim().length < 50 &&
        contents.trim().length > 0
      ) {
        // save heading object
        headerObjs.push({
          className: typeToClassName(type),
          contents,
          type,
        });
      }
    }
  });

  // shift headings if necessary so that if all headings are more than H2, they are shifted so that minimum heading is H2
  const minHeading = _.min(_.map(headerObjs, "type"));
  if (minHeading && minHeading > 2) {
    const shiftAmount = minHeading - 2;
    headerObjs = _.map(headerObjs, (headerObj) => {
      headerObj.type -= shiftAmount;
      headerObj.className = typeToClassName(headerObj.type);
      return headerObj;
    });
  }

  // turn heading objects into HTML
  _.each(headerObjs, (headerObj, index) => {
    const { className, contents } = headerObj;
    if (_.isFunction(elementConstructor)) {
      outputHTML.push(elementConstructor(index, className, contents));
    } else {
      outputHTML.push(
        <div key={index} className={`competitor-h ${className}`}>
          <span>{contents}</span>
        </div>
      );
    }
  });

  return outputHTML;
}

// returns how long ago something happened in a human readable format
// ex: '2 days ago' or 'Yesterday'
export function timeAgo(time) {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  const time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000;
  let token = "ago";
  let list_choice = 1;

  if (seconds == 0) {
    return "Just now";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "from now";
    list_choice = 2;
  }
  let i = 0;
  let format;
  // eslint-disable-next-line no-cond-assign
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] === "string") return format[list_choice];
      return `${Math.floor(seconds / format[2])} ${format[1]} ${token}`;
    }
  return time;
}

export function parseHTMLString(encodedStr) {
  try {
    if (!encodedStr) {
      return "";
    }
    const parser = new DOMParser();
    const dom = parser.parseFromString(
      `<!doctype html><body>${encodedStr}`,
      "text/html"
    );
    const decodedString = dom.body.textContent;
    return decodedString;
  } catch (e) {
    console.log("Error parsing HTML string");
    return "";
  }
}

export function occurrencesInString(string, subString, allowOverlapping) {
  try {
    string = parseHTMLString(string);
    subString = subString || "";
    string = string.toLowerCase();
    subString = subString.toLowerCase();
    string += "";
    subString += "";
    if (subString.length <= 0) return string.length + 1;

    let n = 0;
    let pos = 0;
    const step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  } catch (e) {
    return 0;
  }
}

// returns whether the keyword was used enough in the editor contents
export function hasUsedTopic(report, topic) {
  return (
    report.content.html &&
    _.has(report.content.covered_topics, topic.name.toLowerCase())
  );
}

export function timesTopicIsUsed(report, topic) {
  try {
    if (
      report &&
      report.content &&
      _.isObject(report.content.covered_topics) &&
      _.isNumber(report.content.covered_topics[topic.name])
    ) {
      return report.content.covered_topics[topic.name];
    }
    return 0;
  } catch (e) {
    console.log(e);
    return 0;
  }
}

// returns a true/false on whether a topic has been used significantly over the usual amount - returns an integer if it is overused, representing how much it is overused by
export function isTopicOverused(report, topic) {
  const max = _.max(topic.count);
  let limit;
  if (max <= 4) {
    limit = max + 2;
  } else {
    limit = max + 3;
  }

  if (timesTopicIsUsed(report, topic) > limit) {
    return timesTopicIsUsed(report, topic) - limit;
  }
  return false;
}

// gets URL parameters ?foo=bar
// returns undefined if not found
export function getUrlParam(key) {
  const vars = {};
  const parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => {
      vars[key] = value;
    }
  );
  return vars[key];
}

export function htmlTextIsDifferent(html1, html2) {
  try {
    // passing in virtual document to prevent images from loading
    const ownerDocument = document.implementation.createHTMLDocument("virtual");
    return $(html1, ownerDocument).text() !== $(html2, ownerDocument).text();
  } catch {
    console.log("HTML parse failure");
    return true;
  }
}

export function htmlMarksAreDifferent(html1, html2) {
  try {
    // passing in virtual document to prevent images from loading
    const ownerDocument = document.implementation.createHTMLDocument("virtual");
    const mark1 = $(html1, ownerDocument).find("mark");
    const mark2 = $(html2, ownerDocument).find("mark");
    const differentMarks = !_.isEqual(
      _.map(mark1, (m) => m.innerText),
      _.map(mark2, (m) => m.innerText)
    );
    return differentMarks;
  } catch {
    console.log("HTML parse failure");
    return true;
  }
}

export function formattedNumber(number, digits) {
  if (!_.isNumber(digits)) {
    digits = 1;
  }
  const num = parseInt(number, 10);
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function getShuffledClusterColors(seed) {
  const colors = [
    "red",
    "orange",
    "green",
    "cyan",
    "blue",
    "indigo",
    "purple",
    "magenta",
    "pink",
  ];
  function shuffle(array, seed) {
    let m = array.length;
    let t;
    let i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(random(seed) * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
      ++seed;
    }

    return array;
  }

  function random(seed) {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  return shuffle(colors, seed);
}

export function findInterestingContent(competitors) {
  try {
    // find the most comprehensive content in top 10
    var competitors = _.map(competitors, _.clone);
    const top10Competitors = _.take(_.sortBy(competitors, ["position"]), 10);
    let mostComprehensiveFirstPageUrl = _.sortBy(
      top10Competitors,
      (i) => -1 * i.coverage
    )[0].url;
    const mostComprehensiveOverallUrl = _.sortBy(
      competitors,
      (i) => -1 * i.coverage
    )[0].url;

    if (mostComprehensiveOverallUrl === mostComprehensiveFirstPageUrl) {
      mostComprehensiveFirstPageUrl = "";
    }

    // find the most interesting content
    // any content that is 17 points below the average Domain Authority of the top 15, maximum of 3
    // excluding all domain_scores of zero in both average and interestingness calculation
    competitors = _.map(competitors, _.clone);
    const top15Competitors = _.take(_.sortBy(competitors, ["position"]), 15);
    const interestingnessCoefficient = 17;
    const maxInterestingContent = 3;
    const averageDS = _.mean(
      _.compact(_.map(top15Competitors, (c) => parseInt(c.domain_score, 10)))
    );
    let interestingContentUrls = [];
    top15Competitors.forEach((competitor) => {
      const ds = competitor.domain_score;
      if (
        ds < averageDS - interestingnessCoefficient &&
        ds > 0 &&
        interestingContentUrls.length < maxInterestingContent
      ) {
        interestingContentUrls.push(competitor.url);
      }
    });

    // if there is no interesting content, find the piece of content that has the biggest diff between it and the 3 reports below it
    if (_.isEmpty(interestingContentUrls)) {
      const competitorsWithDiff = _.map(competitors, (competitor, index) => {
        const next3Competitors = _.slice(competitors, index + 1, index + 4);
        if (next3Competitors.length === 3) {
          // sum of differences with each of the next 3 competitors
          competitor.diff = _.reduce(
            _.map(next3Competitors, "domain_score"),
            (result, next) => result + (next - competitor.domain_score),
            0
          );
        } else {
          competitor.diff = 0;
        }

        return competitor;
      });
      interestingContentUrls = _.map(
        _.takeRight(_.sortBy(competitorsWithDiff, ["diff"]), 3),
        "url"
      );
    }

    // prevent any url from being used twice
    interestingContentUrls = _.compact(
      _.map(interestingContentUrls, (url) => {
        if (
          url === mostComprehensiveOverallUrl ||
          url === mostComprehensiveFirstPageUrl
        ) {
          return null;
        }
        return url;
      })
    );

    return {
      mostComprehensiveOverallUrl,
      mostComprehensiveFirstPageUrl,
      interestingContentUrls,
    };
  } catch (e) {
    console.log("something went wrong finding interesting content", e);
    AP.reportError(e);
    return {
      mostComprehensiveOverallUrl: "",
      mostComprehensiveFirstPageUrl: "",
      interestingContentUrls: [],
    };
  }
}

// used to cluster topics using GPT-3
export function triggerTopicClusters(reportId, success, error) {
  const endpoint = `/api/openai/generate_relationships/${reportId}`;
  fetch(endpoint)
    .then((response) => response.json())
    .then((response) => {
      if (!_.isEmpty(response.success) && _.isFunction(success)) {
        success();
      } else if (_.isFunction(error)) {
        error(response);
      }
    });
}

// used to cluster questions using GPT-3
export function triggerQuestionClusters(reportId, success, error) {
  const endpoint = `/api/openai/generate_relationships_questions/${reportId}`;
  fetch(endpoint)
    .then((response) => response.json())
    .then((response) => {
      if (!_.isEmpty(response.success) && _.isFunction(success)) {
        success();
      } else if (_.isFunction(error)) {
        error(response);
      }
    });
}

// used to kick off async job for getting takeaways for an outline
export function triggerExpandOutline(reportId, position, success, failure) {
  const endpoint = `/api/openai/expand_competitor_outline/${reportId}/${position}`;
  fetch(endpoint)
    .then((response) => response.json())
    .then((response) => {
      if (!_.isEmpty(response.success)) {
        success();
      } else {
        failure();
      }
    });
}

// removes any branding from the end of a title
// returns null if title has ellipsis at end
export function cleanTitle(title) {
  const delimiter = /\||-|–|—/gi;
  const delimiter_threshhold = 20; // looks at this number of characters from the end

  title = title.replace("...", "");

  const title_end = title.slice(title.length - delimiter_threshhold);
  if (delimiter.test(title_end)) {
    // string has the delimiter, so strip it of branding
    title = title.split(delimiter);
    title.pop();
    title = title.join("").trim();
    return title;
  }
  return title;
}

// copy formatted text (html) to clipboard
export function copyFormatted(html) {
  const item = new clipboard.ClipboardItem({
    "text/html": new Blob([html], { type: "text/html" }),
  });

  clipboard.write([item]);
}

// chooses a default label if segment does not already have one
export function getSegmentLabel(labels, segment) {
  if (_.isObject(segment.label)) {
    return segment.label;
  }
  return labels[segment.name.length % labels.length];
}

export function loadTags(query) {
  return new Promise((resolve) => {
    fetch(`/api/keyword_reports/tags/${encodeURIComponent(query)}`, {
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        resolve(
          result.map((tag) => ({
            value: tag,
            label: tag,
          }))
        );
      });
  });
}

export function validateTags(tags) {
  let onlyAlphaNumericUnderscoreSpaces = true;
  if (tags) {
    tags.forEach((tag) => {
      if (!/^([A-Za-z]|[0-9]|_| )+$/.test(tag.value)) {
        onlyAlphaNumericUnderscoreSpaces = false;
      }
    });
    return onlyAlphaNumericUnderscoreSpaces;
  }
  return false;
}

export function setJSONToLocalStorage(key, obj) {
  try {
    const storage = window.localStorage;
    storage.setItem(key, JSON.stringify(obj));
  } catch (e) {
    console.log(e);
    console.log("something went wrong setting JSON from session storage");
    return false;
  }
}

export function getJSONFromLocalStorage(key) {
  try {
    const storage = window.localStorage;
    return JSON.parse(storage.getItem(key));
  } catch (e) {
    console.log(e);
    console.log("something went wrong getting JSON from session storage");
    return null;
  }
}

export function isURL(str) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function downloadCSV(filename, data) {
  if (_.isArray(data) && !_.isEmpty(data)) {
    const prefix = "data:text/csv;charset=utf-8,";
    const csvContent = data.map((e) => e.join(",")).join("\n");

    const encodedUri = prefix + encodeURIComponent(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
}

export function isImportedAndNotConfirmed(report) {
  return (
    report &&
    report.import_url &&
    _.isBoolean(report.content.confirmed) &&
    !report.content.confirmed
  );
}

export function capitalizeFirstLetterAndAddQuestionMark(string) {
  try {
    return (
      string.charAt(0).toUpperCase() +
      string.slice(1) +
      (string.indexOf("?") === -1 ? "?" : "")
    );
  } catch (e) {
    return string;
  }
}

export function checkIfRevisionContentHasChanged(newContent) {
  const { revisions } = store.getState();
  if (newContent && revisions) {
    // No Revisions
    if (revisions.length < 1) {
      return true;
    }

    // Find the Most Recent Revisions Object
    const recentRevision = revisions[0];

    // Check if the content are same
    if (recentRevision.content.html == newContent) {
      return false;
    }

    const difference = Math.abs(
      newContent.replace(/ /g, "").length -
        recentRevision.content.html.replace(/ /g, "").length
    );
    if (difference < 10) {
      return false;
    }

    return true;
  }

  return false;
}

export function updateContentWithRevision(keyword_report_id, revision_id) {
  return new Promise((resolve, reject) => {
    fetch(`/api/keyword_reports/${keyword_report_id}`, {
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify({ revision_id }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          resolve(response.report);
        } else {
          reject(response);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
// returns whether the keyword was used enough in the editor contents

// This version of the function covers the similar_keywords. However, on turning it on, I saw that
// there is an issue where multiple topics have the same similar keywords, so we should fix that issue
// before turning this function on
// export function hasUsedTopic(report, topic){
//   if(report.content.html){
//     var words = [topic.name];
//     var hasUsedTopic = false;
//     if(!_.isEmpty(topic.similar_keywords)){
//       words = _.concat(words, topic.similar_keywords);
//     }
//     _.each(words, (word) => {
//       if((report.content.html.match(new RegExp(word, 'gi')) || []).length > 0){
//         hasUsedTopic = true;
//       }
//     });
//     return hasUsedTopic;
//   } else{
//     return false;
//   }
// }
