import { ADD_TAGS, REMOVE_TAGS, UPDATE_TAGS } from "../actions";

const initialState = AP.currentUser.tags;

export function tags(state = initialState, action) {
  switch (action.type) {
    case ADD_TAGS: {
      return _.sortBy(_.uniq(state.concat(action.tag)));
    }
    case REMOVE_TAGS: {
      if (_.isArray(action.tag)) {
        return _.sortBy(_.difference(state, action.tag));
      }
      return _.sortBy(_.difference(state, [action.tag]));
    }
    case UPDATE_TAGS: {
      return _.sortBy(_.uniq(action.tag));
    }
    default: {
      return state;
    }
  }
}

export default tags;
