import { combineReducers } from "redux";
import { reports, activeReport } from "./reports";
import { tags } from "./tags";
import { revisions } from "./revisions";
import { outline } from "./outline";
import { inventories, activeInventory } from "./inventories";
import { segments, activeSegment, selectedSegment } from "./segments";
import { pages, selectedPages } from "./pages";
import { toast } from "./toast";

export default combineReducers({
  reports,
  activeReport,
  tags,
  outline,
  toast,
  inventories,
  activeInventory,
  segments,
  activeSegment,
  selectedSegment,
  pages,
  selectedPages,
  revisions,
});
