import React from "react";

class Learn extends React.Component {
  constructor(props) {
    super(props);
    this.openSupport = this.openSupport.bind(this);
    this.openVideoModal = this.openVideoModal.bind(this);
  }

  openSupport() {
    if (zE) {
      zE.activate();
    }
  }

  openVideoModal(id) {
    $(id).modal("show");
  }

  render() {
    return (
      <div className="container learn">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 mt-5 text-center mb-5">
            <h2>
              Learn how to create quality SEO content,{" "}
              <span className="highlight">faster</span>.
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 mt-4">
            <div className="w-100 element-box d-flex">
              <div className="p-3" style={{ width: "50%" }}>
                <h3>Become a Topic Pro in 11 Minutes</h3>
                <p>
                  In this video, we'll explain why using Topic improves organic
                  search performance, and do a full walkthrough of our product
                  to produce a new article.
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.openVideoModal("#topic-pro-video");
                  }}
                >
                  <i className="fad fa-play-circle" /> Watch Video (11 mins)
                </button>
              </div>
              <div className="p-3" style={{ width: "50%" }}>
                <img
                  src="/img/demo-thumbnail.gif"
                  className="rounded clickable w-100"
                  onClick={() => {
                    this.openVideoModal("#topic-pro-video");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 mt-5">
            <div className="position-relative">
              <div
                aria-hidden="true"
                className="position-absolute d-flex text-center"
                style={{
                  alignItems: "center",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                <div
                  className="w-100"
                  style={{ borderTop: "1px solid #D9E2EC" }}
                />
              </div>
              <div className="position-relative d-flex justify-center text-s">
                <span className="pl-2 pr-2 text-primary-6 bg-primary-1">
                  {" "}
                  Or take things step by step...
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 mt-5 d-flex learn-cards"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="learn-card element-box d-flex flex-column mt-0 position-relative overflow-hidden p-0"
              style={{ width: "32%" }}
            >
              <img
                className="w-100 clickable"
                src="/img/keyword-research-thumbnail.jpg"
                onClick={() => {
                  this.openVideoModal("#keyword-research-video");
                }}
              />
              <div className="p-4 d-flex flex-column learn-card-text">
                <h6 className="mt-1">1. Pick the right keyword</h6>
                <div className="flex-grow-1 text-m text-primary-6 pb-4">
                  Great content starts with discovering what your audience is
                  searching for. Learn how to use our Keyword Research tool to
                  speed up the process.
                </div>
                <button
                  className="btn bg-primary-1 hover-bg-primary-2"
                  onClick={() => {
                    this.openVideoModal("#keyword-research-video");
                  }}
                >
                  <i className="fad fa-play-circle mr-2" /> Watch Video (3 mins)
                </button>
              </div>
            </div>
            <div
              className="learn-card element-box d-flex flex-column mt-0 position-relative overflow-hidden p-0"
              style={{ width: "32%" }}
            >
              <img
                className="w-100 clickable"
                src="/img/content-research-thumbnail.jpg"
                onClick={() => {
                  this.openVideoModal("#outline-builder-video");
                }}
              />
              <div className="p-4 d-flex flex-column learn-card-text">
                <h6 className="mt-1">2. Quickly build an outline</h6>
                <div className="flex-grow-1 text-m text-primary-6 pb-4">
                  Quickly create high-quality outlines for your writers with
                  Topic's Content Briefs. Spend less time creating better
                  outlines. Better outlines = better drafts.
                </div>
                <button
                  className="btn bg-primary-1 hover-bg-primary-2"
                  onClick={() => {
                    this.openVideoModal("#outline-builder-video");
                  }}
                >
                  <i className="fad fa-play-circle mr-2" /> Watch Video (6 mins)
                </button>
              </div>
            </div>
            <div
              className="learn-card element-box d-flex flex-column mt-0 position-relative overflow-hidden p-0"
              style={{ width: "32%" }}
            >
              <img
                className="w-100 clickable"
                src="/img/content-grader-thumbnail.jpg"
                onClick={() => {
                  this.openVideoModal("#grader-video");
                }}
              />
              <div className="p-4 d-flex flex-column learn-card-text">
                <h6 className="mt-1">3. Grade your draft or existing post</h6>
                <div className="flex-grow-1 text-m text-primary-6 pb-4">
                  Set a consistent bar for the content that you publish. Get an
                  A++ and rank higher in search.
                </div>
                <button
                  className="btn bg-primary-1 hover-bg-primary-2"
                  onClick={() => {
                    this.openVideoModal("#grader-video");
                  }}
                >
                  <i className="fad fa-play-circle mr-2" /> Watch Video (5 mins)
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 mt-4">
            <div className="w-100 element-box d-flex align-items-center">
              <div className="p-3">
                <div
                  className="bg-primary-1 text-center"
                  style={{
                    borderRadius: "100px",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <i className="fad fa-comments text-l mt-3" />
                </div>
              </div>
              <div className="p-3">
                <h4>Want a personal walkthrough?</h4>
                <p className="text-m text-primary-6">
                  Register for a personal walkthrough with one of our founders.
                  People have found these sessions to be helpful in highlighting
                  key features and learning how they can fit the tool into their
                  content workflow. They are typically 20-30 minutes with plenty
                  of time for questions.
                </p>
              </div>
              <div className="p-3 d-flex align-items-center">
                <a
                  className="btn bg-primary-1 hover-bg-primary-2"
                  href="https://calendly.com/usetopic/30"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fad fa-calendar mr-2" /> Book a Time
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 mt-4">
            <h4>How can we help?</h4>
            <div className="d-flex mt-4">
              <div
                className="d-flex align-items-center p-2 hover-bg-white clickable rounded animate-all"
                onClick={this.openSupport}
              >
                <div
                  className="bg-white text-center flex-grow-1 text-blue-6"
                  style={{
                    borderRadius: "100px",
                    width: "120px",
                    height: "50px",
                  }}
                >
                  <i className="fad fa-comments text-l mt-3" />
                </div>
                <div className="pl-3">
                  <div className="text-bold">Contact Support</div>
                  <div className="text-m text-primary-6">
                    Our hours are 10am-5pm PST on most weekdays. We'll reply to
                    you via email by the next business day.
                  </div>
                </div>
              </div>
              <div style={{ width: "10px" }} />
              <a
                href="https://usetopic.zendesk.com/"
                target="_blank"
                className="no-text-decoration-on-hover"
                rel="noreferrer"
              >
                <div className="d-flex align-items-center p-2 hover-bg-white clickable rounded animate-all">
                  <div
                    className="bg-white text-center flex-grow-1 text-blue-6"
                    style={{
                      borderRadius: "100px",
                      width: "120px",
                      height: "50px",
                    }}
                  >
                    <i className="fad fa-question-circle text-l mt-3" />
                  </div>
                  <div className="pl-3">
                    <div className="text-bold">Knowledge Base</div>
                    <div className="text-m text-primary-6">
                      Browse our knowledge base to dive deeper into the product.
                      Get answers to frequently asked questions and learn about
                      our lesser-known features.
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Learn;
