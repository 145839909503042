import React from "react";

class InventorySegmentLabel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, className, onClick } = this.props;
    return (
      <div
        className={`inventory-segment-label ${className || ""}`}
        onClick={() => {
          if (_.isFunction(onClick)) {
            onClick();
          }
        }}
        style={{ backgroundColor: label.backgroundColor, color: label.color }}
      >
        <i className={label.icon} />
      </div>
    );
  }
}

export default InventorySegmentLabel;
