import {
  REQUEST_PAGES,
  RECEIVE_PAGES,
  SET_IS_FETCHING,
  UPDATE_PAGE_SORT_ORDER,
  UPDATE_PAGE_EXTRA_FILTERS,
  SET_SELECTED_PAGES,
} from "../actions";

export function pages(
  state = {
    isFetching: false,
    items: [],
    total: null, // number of total pages
    offset: 0,
    sort: "clicks",
    order: "desc",
    limit: 20,
    extraFilters: [], // declining, compare dates, etc
  },
  action
) {
  switch (action.type) {
    case REQUEST_PAGES:
      return { ...state, isFetching: true };
    case RECEIVE_PAGES:
      return {
        ...state,
        isFetching: false,
        items: action.pages,
        total: action.total,
        clicks_delta_min_max: action.clicks_delta_min_max,
        impressions_delta_min_max: action.impressions_delta_min_max,
      };
    case SET_IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case UPDATE_PAGE_SORT_ORDER:
      return {
        ...state,
        sort: action.sort,
        order: action.order,
        offset: action.offset,
        limit: action.limit,
      };
    case UPDATE_PAGE_EXTRA_FILTERS:
      var res = { ...state, extraFilters: action.extraFilters };
      if (
        action.extraFilters &&
        action.extraFilters[0] &&
        action.extraFilters[0].type === "declining_traffic"
      ) {
        // declining traffic default sort is ascending, not descending
        res.order = "asc";
      } else {
        res.order = "desc";
      }
      return res;
    default:
      return state;
  }
}

export function selectedPages(state = null, action) {
  if (action.type === SET_SELECTED_PAGES) {
    return _.cloneDeep(action.pageIds);
  }
  return state; // default to 'newid' id active segment if no active segment is defined
}
