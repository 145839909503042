export const getReports = (store, siteId) => {
  if (store.reports) {
    return store.reports.items;
  }
  return [];
};

export const getReportById = (store, reportId) => {
  const allReports = store.reports.items;
  return _.find(allReports, (report) => report.id === reportId);
};

export const getActiveReport = (store) =>
  _.find(store.reports.items, { id: store.activeReport });

export const getInventoryById = (store, inventoryId) => {
  const allInventories = store.inventories.items;
  return _.find(allInventories, { id: inventoryId });
};

export const getActiveSegment = (store) =>
  _.find(store.segments.items, { id: store.activeSegment }) ||
  store.segments.defaultSegment;

// this method takes the 3 separate question arrays and produces an array of clustered questions
export const getClusteredQuestions = (store, reportId) => {
  try {
    const allReports = store.reports.items;
    const report = _.find(allReports, (report) => report.id === reportId);
    if (report && report.report) {
      const {
        related_questions,
        related_questions_quora,
        related_questions_autocomplete,
      } = report.report;

      // all the arrays together
      let all_questions = related_questions.questions
        .concat(
          (related_questions_quora && related_questions_quora.questions) || []
        )
        .concat(
          (related_questions_autocomplete &&
            related_questions_autocomplete.questions) ||
            []
        );

      // remove any questions that are the same or very similar
      all_questions = _.uniqBy(all_questions, (q) => q.keyword.toLowerCase());

      // group by cluster
      const question_clusters = _.groupBy(all_questions, "cluster");
      delete question_clusters.undefined;
      return question_clusters;
    }
    return [];
  } catch (e) {
    console.log("Error in getClusteredQuestions", e);
    return [];
  }
};
