import React from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import { saveOutlineTitle } from "./redux/actions";
import { cleanTitle } from "./helpers";
import OutlineTitleBookmarkButton from "./components/outline/bookmarks/content/title/bookmark_button";
import OutlineRecentTitleBookmarks from "./components/outline/bookmarks/recent/title_bookmarks";

class OutlineBuilderPopoverTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: "",
      titles: [], // array of title ideas
      titleChosen: "", // the title idea that was chosen
      idealQualities: [],
    };
    this.ref = React.createRef();
    this.generateTitleIdeas = this.generateTitleIdeas.bind(this);
    this.handleSelectTitle = this.handleSelectTitle.bind(this);
    this.saveTitleToOutline = this.saveTitleToOutline.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.state.titles)) {
      this.generateTitleIdeas();
    }
  }

  generateTitleIdeas() {
    const that = this;
    const { title } = this.props;
    this.setState({ isLoading: "true", error: "" });

    $.getJSON(`/api/openai/title_variations/${this.props.report.id}`, {
      title,
    })
      .done((res) => {
        if (!_.isEmpty(res)) {
          if (res.filter === "1" || res.filter === "2") {
            that.setState({
              isLoading: false,
              titles: res.titles,
              idealQualities: res.ideal_qualities,
              error:
                "The content filter has detected that the generated text may potentially be sensitive, political, or controversial. Give it an extra check just in case something sounds wrong.",
            });
          } else if (res.error) {
            that.setState({
              isLoading: false,
              error: "Unable to come up with any ideas. Please try again.",
            });
          } else if (!_.isEmpty(res.titles)) {
            that.setState({
              titles: res.titles,
              idealQualities: res.ideal_qualities,
              isLoading: false,
            });
          }
        } else {
          that.setState({
            isLoading: false,
            error: "Unable to come up with any ideas. Please try again.",
          });
        }
      })
      .fail((res) => {
        that.setState({
          isLoading: false,
          error:
            "Something went wrong getting the ideas. Please email support@usetopic.com",
        });
      });
  }

  handleSelectTitle(title) {
    const { titleChosen } = this.state;
    if (titleChosen === title) {
      this.setState({
        titleChosen: "",
      });
    } else {
      this.setState({
        titleChosen: title,
      });
    }
  }

  // title has been chosen for outline
  saveTitleToOutline() {
    this.props.saveOutlineTitle(this.props.report, this.state.titleChosen);
    this.props.handleClose();
  }

  render() {
    var that = this;
    var { error, isLoading, titles, titleChosen, idealQualities } = this.state;
    var { type, handleClose, report, title } = this.props;
    titles = _.uniq(_.compact(_.map(titles, (title) => cleanTitle(title))));

    const builderBodyClass =
      this.props.outline.titleBookmarks.length > 0
        ? "builder-popover-body-compact"
        : "builder-popover-body";

    return (
      <div>
        <div
          className={`builder-popover-rainbow ${isLoading ? "loading" : ""}`}
        />
        <div className="builder-popover-head mb-2">Rewrite Title</div>

        <OutlineRecentTitleBookmarks
          report={this.props.report}
          handleSelect={this.handleSelectTitle}
          selectedTitle={titleChosen}
        />

        <div className={isLoading ? "builder-popover-isloading" : ""}>
          {error && <div className="text-danger mb-2">{error}</div>}
          {isLoading && <div>Rewriting your title, "{title}"...</div>}
          {!isLoading && !_.isEmpty(titles) && (
            <div>
              <div className="mb-2">
                <h6 className="d-inline">New Ideas</h6>{" "}
                <button
                  className="btn btn-white text-bluegray-5 builder-popover-refresh"
                  onClick={this.generateTitleIdeas}
                >
                  <i className="fas fa-redo-alt" /> Refresh
                </button>
              </div>
              <div className={builderBodyClass}>
                {titles
                  .filter((t) => !this.props.outline.titleBookmarks.includes(t))
                  .map((title, i) => {
                    var titleIsChosen = titleChosen === title;
                    return (
                      <div
                        key={i}
                        className={`clickable p-2 mt-2 pr-4 mb-2 builder-popover-card animated fadeUp delay-${i} ${
                          titleIsChosen ? "text-bold active" : ""
                        }`}
                        onClick={that.handleSelectTitle.bind(that, title)}
                      >
                        {title}{" "}
                        {titleIsChosen && (
                          <i className="fas fa-check text-success ml-2" />
                        )}{" "}
                        <OutlineTitleBookmarkButton
                          report={this.props.report}
                          title={title}
                        />
                      </div>
                    );
                  })}

                {!_.isEmpty(idealQualities) && (
                  <div
                    className={`bg-blue-1 p-2 mt-3 rounded animated fadeUp delay-${titles.length}`}
                  >
                    <div className="text-bold">
                      Here are a few ideas on how to make your title stand out.
                    </div>
                    {_.map(idealQualities, (quality, i) => (
                      <div
                        key={i}
                        className="badge bg-blue-2 text-blue-9 mr-2 mt-2"
                      >
                        {quality}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="text-right">
                <div
                  className={`clickable text-right btn btn-primary mt-2 ${
                    _.isEmpty(titleChosen) ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (!_.isEmpty(titleChosen)) {
                      this.saveTitleToOutline();
                    }
                  }}
                >
                  Choose Title
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var props = {
    title: state.outline.title,
    outline: state.outline,
  };
  return props;
}

export default connect(mapStateToProps, { saveOutlineTitle })(
  OutlineBuilderPopoverTitle
);
