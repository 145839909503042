import React from "react";
import Select from "react-select";
import Loader from "./loader";
import TextLoader from "./loader_text";
import KeywordsDashboardRow from "./keywords_dashboard_row";

class KeywordsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      lastSearchedKeyword: "",
      research: null,
      isLoading: false,
      isLoadingMore: false,
      activeKeywordIndexes: [], // keeps track of which keywords are "open"
      location: {
        value: "us",
        label: "USA 🇺🇸",
        serpValue: "United States",
      },
      showLoadMore: true,
    };
    this.resetState = this.resetState.bind(this);
    this.handleClickRelated = this.handleClickRelated.bind(this);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleActiveKeywordIndex = this.toggleActiveKeywordIndex.bind(this);
    this.handleKeywordVideoClose = this.handleKeywordVideoClose.bind(this);
    this.formRef = React.createRef();
    this.node = React.createRef();
    this.locationSelectOptions = [
      {
        value: "us",
        label: "USA 🇺🇸",
        serpValue: "United States",
      },
      {
        value: "uk",
        label: "UK 🇬🇧",
        serpValue: "United Kingdom",
      },
      {
        value: "ca",
        label: "Canada 🇨🇦",
        serpValue: "Canada",
      },
      {
        value: "in",
        label: "India 🇮🇳",
        serpValue: "India",
      },
      {
        value: "ru",
        label: "Russia 🇷🇺",
        serpValue: "Russia",
      },
      {
        value: "de",
        label: "Germany 🇩🇪",
        serpValue: "Germany",
      },
      {
        value: "fr",
        label: "France 🇫🇷",
        serpValue: "France",
      },
      {
        value: "es",
        label: "Spain 🇪🇸",
        serpValue: "Spain",
      },
      {
        value: "it",
        label: "Italy 🇮🇹",
        serpValue: "Italy",
      },
      {
        value: "br",
        label: "Brazil 🇧🇷",
        serpValue: "Brazil",
      },
      {
        value: "au",
        label: "Australia 🇦🇺",
        serpValue: "Australia",
      },
      {
        value: "ar",
        label: "Argentina 🇦🇷",
        serpValue: "Argentina",
      },
      {
        value: "be",
        label: "Belgium 🇧🇪",
        serpValue: "Belgium",
      },
      {
        value: "ch",
        label: "Switzerland 🇨🇭",
        serpValue: "Switzerland",
      },
      {
        value: "dk",
        label: "Denmark 🇩🇰",
        serpValue: "Switzerland",
      },
      {
        value: "fi",
        label: "Finland 🇫🇮",
        serpValue: "Finland",
      },
      {
        value: "hk",
        label: "Hong Kong 🇭🇰",
        serpValue: "Hong Kong",
      },
      {
        value: "ie",
        label: "Ireland 🇮🇪",
        serpValue: "Ireland",
      },
      {
        value: "il",
        label: "Israel 🇮🇱",
        serpValue: "Israel",
      },
      {
        value: "mx",
        label: "Mexico 🇲🇽",
        serpValue: "Mexico",
      },
      {
        value: "nl",
        label: "Netherlands 🇳🇱",
        serpValue: "Netherlands",
      },
      {
        value: "no",
        label: "Norway 🇳🇴",
        serpValue: "Norway",
      },
      {
        value: "pl",
        label: "Poland 🇵🇱",
        serpValue: "Poland",
      },
      {
        value: "se",
        label: "Sweden 🇸🇪",
        serpValue: "Sweden",
      },
      {
        value: "sg",
        label: "Singapore 🇸🇬",
        serpValue: "Singapore",
      },
      {
        value: "tr",
        label: "Turkey 🇹🇷",
        serpValue: "Turkey",
      },
    ];
  }

  resetState() {
    this.setState({
      keyword: "",
      research: null,
      isLoading: false,
      isLoadingMore: false,
      activeKeywordIndexes: [],
    });
  }

  componentDidMount() {
    const that = this;
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();

    if (this.props.modalRef) {
      $(this.props.modalRef.current)
        .off("hidden.bs.modal", that.resetState)
        .on("hidden.bs.modal", that.resetState);
    }
  }

  componentDidUpdate() {
    const that = this;
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
    if (this.props.modalRef) {
      $(this.props.modalRef.current)
        .off("hidden.bs.modal", that.resetState)
        .on("hidden.bs.modal", that.resetState);
    }
  }

  searchKeyword(keyword, more) {
    const that = this;
    let keywordToSearch;
    if (_.isString(keyword)) {
      this.setState({ keyword, lastSearchedKeyword: keyword });
      keywordToSearch = keyword;
    } else {
      this.setState({ lastSearchedKeyword: that.state.keyword });
      keywordToSearch = that.state.keyword;
    }

    let endpoint = `/api/keyword_research/${encodeURIComponent(
      keywordToSearch
    )}?location=${this.state.location.value}`;
    if (more) {
      endpoint = `/api/keyword_research_more/${encodeURIComponent(
        keywordToSearch
      )}?location=${this.state.location.value}`;
      this.setState({
        showLoadMore: false,
        isLoadingMore: true,
        isLoading: false,
      });
    } else {
      this.setState({
        showLoadMore: true,
        isLoading: true,
        isLoadingMore: false,
      });
    }

    tanalytics.track("Keyword Searched", {
      keyword: keywordToSearch,
      location: this.state.location.value,
    });
    fetch(endpoint, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((response) => {
        that.setState({
          research: response,
          isLoading: false,
          isLoadingMore: false,
          activeKeywordIndexes: [],
        });
      });
  }

  validate(successFunction, event) {
    event.preventDefault();
    if (!this.state.isLoading && this.formRef.current.reportValidity()) {
      successFunction(event);
    }
  }

  toggleActiveKeywordIndex(index) {
    const { activeKeywordIndexes } = this.state;
    if (_.includes(activeKeywordIndexes, index)) {
      this.setState({
        activeKeywordIndexes: _.without(activeKeywordIndexes, index),
      });
    } else {
      this.setState({
        activeKeywordIndexes: _.uniq(_.concat(activeKeywordIndexes, index)),
      });
    }
  }

  handleClickRelated(related) {
    this.setState({
      keyword: related,
      research: null,
    });
    this.searchKeyword(related);
  }

  // this is a workaround to handle a bug where closing the keyword research video modal results in breaking scrolling for parent modal
  handleKeywordVideoClose() {
    $(".keywordModal").modal("hide");
    window.setTimeout(() => {
      $(".keywordModal").modal("show");
    }, 100);
  }

  render() {
    let keywords;
    let keywordMaxVolume;
    let relatedSearches;
    const that = this;
    const { handleCreateBrief } = this.props;
    const {
      activeKeywordIndexes,
      research,
      isLoading,
      isLoadingMore,
      lastSearchedKeyword,
      showLoadMore,
    } = this.state;
    const location = this.state.location.value;
    const locationKeywordReport = this.state.location.serpValue;

    if (research) {
      keywords = research.research;
      relatedSearches = research.related_searches;
      keywordMaxVolume =
        !_.isEmpty(keywords) &&
        _.maxBy(keywords, (keyword) => parseInt(keyword.vol, 10)).vol;
    }

    const isLoadingAtAll = isLoading || isLoadingMore;

    return (
      <div className="w-100 mb-5" ref={this.node}>
        <p>
          Find a focus keyword for your content that balances higher volume with
          lower difficulty.
        </p>
        <div
          style={{
            width: "460px",
            display: "inline-block",
            opacity: isLoading ? "0.5" : "1",
          }}
        >
          <form
            ref={this.formRef}
            onSubmit={this.validate.bind(null, this.searchKeyword)}
          >
            <div className="input-group">
              <input
                name="keyword"
                style={{ borderWidth: "1px", height: "38px" }}
                className="form-control"
                placeholder="Keyword to research"
                value={this.state.keyword}
                onChange={(e) => {
                  this.setState({ keyword: e.target.value });
                }}
                autoComplete="off"
                type="text"
                required
              />
              <div
                className=""
                style={{ width: "120px", marginLeft: "-1px", fontSize: "11px" }}
              >
                <Select
                  name="location"
                  style={{ width: "100%" }}
                  options={this.locationSelectOptions}
                  onChange={(selected) => {
                    this.setState({ location: selected });
                  }}
                  value={this.state.location}
                  classNamePrefix="keyword-research-location"
                  isSearchable={false}
                />
              </div>
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.validate.bind(null, this.searchKeyword)}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        {isLoading && (
          <div className="w-100 text-center p-4">
            <TextLoader
              lines={[
                "Identifying related keywords",
                "Finding search traffic",
                "Getting monthly trends",
                "Collecting CPC data",
                "Consolidating information",
              ]}
            />
          </div>
        )}
        {!isLoadingAtAll && !_.isEmpty(relatedSearches) && (
          <div className="mt-2" style={{ fontSize: "12px" }}>
            Also try:{" "}
            {_.map(_.take(relatedSearches, 3), (related, index) => (
              <div key={index} style={{ display: "inline-block" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    that.handleClickRelated(related);
                  }}
                >
                  {related}
                </a>
                {index < 2 && <span>,&nbsp;</span>}
              </div>
            ))}
          </div>
        )}
        {!isLoadingAtAll && research && _.isEmpty(research.research) && (
          <div className="text-center mt-5">
            No keyword data found, try another keyword.
          </div>
        )}
        {!isLoading && research && !_.isEmpty(research.research) && (
          <div className="mt-5 text-left">
            <table className="keyword-research-table table table-padded">
              <thead>
                <tr>
                  <th style={{ width: "160px" }}>Keyword</th>
                  <th
                    className="d-none"
                    style={{ width: "130px" }}
                    data-original-title="This is the average cost-per-click advertisers are paying on AdWords for this keyword."
                    data-toggle="tooltip"
                  >
                    CPC
                  </th>
                  <th
                    className="d-none"
                    style={{ width: "130px" }}
                    data-original-title="Competition as determined by Google AdWords. This is how heavily people are bidding for this keyword."
                    data-toggle="tooltip"
                  >
                    Competition
                  </th>
                  <th style={{ width: "100%" }}>
                    <span
                      data-original-title="This is approximately how many people search for this keyword every month."
                      data-toggle="tooltip"
                    >
                      Monthly Searches
                    </span>
                  </th>
                  <th className="pr-0" style={{ width: "100px" }}>
                    <span
                      data-original-title="This represents how the keyword volume has changed over the past 6 months."
                      data-toggle="tooltip"
                    >
                      Trend
                    </span>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {_.map(keywords, (keyword, index) => {
                  const volumePercent = Math.round(
                    (keyword.vol / keywordMaxVolume) * 100
                  );

                  return (
                    <KeywordsDashboardRow
                      key={index}
                      index={index}
                      keyword={keyword}
                      volumePercent={volumePercent}
                      handleSearchKeyword={this.searchKeyword}
                      handleCreateBrief={handleCreateBrief}
                      toggleActiveKeywordIndex={this.toggleActiveKeywordIndex}
                      isActive={_.includes(activeKeywordIndexes, index)}
                      location={location}
                      locationKeywordReport={locationKeywordReport}
                    />
                  );
                })}
              </tbody>
            </table>
            {showLoadMore && (
              <div className="text-center">
                <button
                  className="btn bg-primary-1 hover-bg-primary-2"
                  onClick={() => {
                    this.searchKeyword(lastSearchedKeyword, true);
                  }}
                >
                  Load More Keywords
                </button>
              </div>
            )}
          </div>
        )}
        {isLoadingMore && (
          <div className="w-100 text-center p-4">
            <TextLoader
              lines={[
                "Expanding keyword search",
                "Diving deeper into database",
                "Calling additional data sources",
                "Finding search traffic",
                "Getting monthly trends",
                "Collecting CPC data",
                "Consolidating information",
              ]}
            />
          </div>
        )}
        {!isLoadingAtAll && !research && (
          <div className="mt-4" style={{ fontSize: "12px" }}>
            <a
              href="#"
              className="text-bluegray-4"
              onClick={() => {
                $("#keyword-research-video").modal("show");
                $("#keyword-research-video").one(
                  "hidden.bs.modal",
                  this.handleKeywordVideoClose
                );
              }}
            >
              Learn how to pick the right keyword{" "}
              <img
                className="ml-2"
                src="/img/video-preview.png"
                alt="keyword research video"
                style={{ width: "60px", borderRadius: "4px", opacity: ".6" }}
              />
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default KeywordsDashboard;
