export const fetchTitles = async (serps) => {
  const response = await fetch(`/api/openai/serp_title_variations_v2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      serps,
    }),
  });
  return response.json();
};

export const fetchInitialBrainstorm = async (serps) => {
  if (!serps) {
    return;
  }
  const response = await fetch("/api/openai/initial_brainstorm", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      serps,
    }),
  });
  return response.json();
};
