import { getUrlParam } from "../helpers";

export const REQUEST_REPORTS = "REQUEST_REPORTS";
export const RECEIVE_REPORTS = "RECEIVE_REPORTS";
export const REQUEST_REPORT = "REQUEST_REPORT";
export const RECEIVE_REPORT = "RECEIVE_REPORT";
export const SET_ACTIVE_REPORT = "SET_ACTIVE_REPORT";
export const UPDATE_ACTIVE_REPORT_LOCALLY = "UPDATE_ACTIVE_REPORT_LOCALLY";
export const UPDATE_REPORT_LOCALLY = "UPDATE_REPORT_LOCALLY";
export const SAVE_REPORT = "SAVE_REPORT";
export const INVALIDATE_REPORTS = "INVALIDATE_REPORTS";
export const SET_IS_FETCHING = "SET_IS_FETCHING";
export const ADD_TAGS = "ADD_TAGS";
export const REMOVE_TAGS = "REMOVE_TAGS";
export const UPDATE_TAGS = "UPDATE_TAGS";

export const RECEIVE_OUTLINE = "RECEIVE_OUTLINE";
export const SET_OUTLINE_TITLE = "SET_OUTLINE_TITLE";
export const ADD_OUTLINE_TITLE_BOOKMARK = "ADD_OUTLINE_TITLE_BOOKMARK";
export const REMOVE_OUTLINE_TITLE_BOOKMARK = "REMOVE_OUTLINE_TITLE_BOOKMARK";
export const SET_OUTLINE_DESCRIPTION = "SET_OUTLINE_DESCRIPTION";
export const SET_OUTLINE_WORD_COUNT = "SET_OUTLINE_WORD_COUNT";
export const ADD_OUTLINE_DESCRIPTION_BOOKMARK =
  "ADD_OUTLINE_DESCRIPTION_BOOKMARK";
export const REMOVE_OUTLINE_DESCRIPTION_BOOKMARK =
  "REMOVE_OUTLINE_DESCRIPTION_BOOKMARK";
export const SHOW_OUTLINE_BOOKMARKS = "SHOW_OUTLINE_BOOKMARKS";
export const HIDE_OUTLINE_BOOKMARKS = "HIDE_OUTLINE_BOOKMARKS";
export const MOVE_CARD = "MOVE_CARD";
export const MOVE_SELECTED_CARDS = "MOVE_SELECTED_CARDS";
export const SELECT_CARD = "SELECT_CARD";
export const UNSELECT_ALL_CARDS = "UNSELECT_ALL_CARDS";
export const DISCARD_EDITS_ALL_CARDS = "DISCARD_EDITS_ALL_CARDS";
export const EDIT_CARD = "EDIT_CARD";
export const EDIT_CARDS = "EDIT_CARDS";
export const SHIFT_CARDS = "SHIFT_CARDS";
export const SHIFT_SELECTIONS = "SHIFT_SELECTIONS";
export const REMOVE_CARDS = "REMOVE_CARDS";
export const REMOVE_LOADING_CARDS = "REMOVE_LOADING_CARDS";
export const ADD_CARD = "ADD_CARD";
export const TOGGLE_CARD = "TOGGLE_CARD";
export const TOGGLE_HIGHLIGHTED_TOPIC = "TOGGLE_HIGHLIGHTED_TOPIC";
export const TOGGLE_HIGHLIGHTED_STATISTIC = "TOGGLE_HIGHLIGHTED_STATISTIC";
export const TOGGLE_HIGHLIGHTED_CITATION = "TOGGLE_HIGHLIGHTED_CITATION";
export const ADD_HIGHLIGHTED_TOPICS = "ADD_HIGHLIGHTED_TOPICS";
export const REMOVE_HIGHLIGHTED_TOPICS = "REMOVE_HIGHLIGHTED_TOPICS";
export const CLEAR_RECENTLY_TOGGLED = "CLEAR_RECENTLY_TOGGLED";
export const GENERATE_TAKEAWAYS = "GENERATE_TAKEAWAYS";

export const REQUEST_INVENTORIES = "REQUEST_INVENTORIES";
export const RECEIVE_INVENTORIES = "RECEIVE_INVENTORIES";
export const REQUEST_INVENTORY = "REQUEST_INVENTORY";
export const RECEIVE_INVENTORY = "RECEIVE_INVENTORY";
export const INVALIDATE_INVENTORIES = "INVALIDATE_INVENTORIES";
export const SET_ACTIVE_INVENTORY = "SET_ACTIVE_INVENTORY";
export const UPDATE_ACTIVE_INVENTORY_LOCALLY =
  "UPDATE_ACTIVE_INVENTORY_LOCALLY";

export const REQUEST_SEGMENTS = "REQUEST_SEGMENTS";
export const RECEIVE_SEGMENTS = "RECEIVE_SEGMENTS";
export const ADD_SEGMENT = "ADD_SEGMENT";
export const SET_ACTIVE_SEGMENT = "SET_ACTIVE_SEGMENT";
export const UPDATE_SEGMENT_LOCALLY = "UPDATE_SEGMENT_LOCALLY";
export const REMOVE_SEGMENT = "REMOVE_SEGMENT";
export const SET_SELECTED_SEGMENT = "SET_SELECTED_SEGMENT";

export const REQUEST_PAGES = "REQUEST_PAGES";
export const RECEIVE_PAGES = "RECEIVE_PAGES";
export const UPDATE_PAGE_SORT_ORDER = "UPDATE_PAGE_SORT_ORDER";
export const UPDATE_PAGE_EXTRA_FILTERS = "UPDATE_PAGE_EXTRA_FILTERS";
export const SET_SELECTED_PAGES = "SET_SELECTED_PAGES";

export const RECEIVE_REVISIONS = "RECEIVE_REVISIONS";
export const ADD_REVISIONS = "ADD_REVISIONS";
export const CLEAR_REVISIONS = "CLEAR_REVISIONS";

export const SHOW_TOAST = "SHOW_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";

export const UPDATE_SIGNALS = "UPDATE_SIGNALS";

export const BYPASS_INITIAL_BRAINSTORM = "BYPASS_INITIAL_BRAINSTORM";

function requestReports(report) {
  return {
    type: REQUEST_REPORTS,
  };
}

function receiveReports(json) {
  return {
    type: RECEIVE_REPORTS,
    reports: json.reports,
    total: json.total,
    users: json.users,
    briefsRemaining: json.briefs_remaining,
  };
}

function requestReport(report) {
  return {
    type: REQUEST_REPORT,
  };
}

function receiveReport(json) {
  return {
    type: RECEIVE_REPORT,
    report: json,
  };
}

function receiveRevisions(json) {
  return {
    type: RECEIVE_REVISIONS,
    revisions: json,
  };
}

function addRevision(json) {
  return {
    type: ADD_REVISIONS,
    revision: json,
  };
}

export function clearRevisions() {
  return {
    type: CLEAR_REVISIONS,
  };
}

// callback (optional) - run after fetch completes
export function fetchReport(reportId, callback) {
  return (dispatch) => {
    dispatch(requestReport());

    const secretParam = getUrlParam("secret");
    let secret = "";
    if (secretParam) {
      secret = `?${$.param({ secret: secretParam })}`;
    }

    return fetch(`/api/keyword_reports/${reportId}${secret}`, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveReport(json));
        if (json.id && json.outline) {
          dispatch(receiveOutline(json.id, json.outline));
        } else {
          dispatch(receiveOutline(json.id, {}));
        }

        if (_.isFunction(callback)) {
          callback();
        }
      });
  };
}

export function fetchReports(options) {
  // options is optional, can contain tag, filter (hash that can have user_id, search), sort, order
  let url = `/api/keyword_reports`;

  if (_.isObject(options)) {
    url += `?${$.param(options)}`;
  }

  return (dispatch) => {
    dispatch(requestReports());
    return fetch(url, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveReports(json)));
  };
}

function shouldFetchReports(state) {
  const { reports } = state;
  if (reports.didInvalidate && !reports.isFetching) {
    return true;
  }
  if (reports.didInvalidate) {
    return true;
  }
  return false;
}

function shouldFetchReport(state, reportId, updateCompetitors) {
  const reportObj = state.reports;
  if (reportObj.didInvalidate && !reportObj.isFetching) {
    return true;
  }
  if (reportObj.didInvalidate) {
    return true;
  }
  if (
    !_.find(reportObj.items, { id: reportId }) ||
    _.isEmpty(_.find(reportObj.items, { id: reportId }).report)
  ) {
    return true;
  }
  if (updateCompetitors) {
    return true;
  }
  return false;
}

export function fetchReportsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchReports(getState())) {
      return dispatch(fetchReports());
    }
  };
}

export function fetchReportIfNeeded(reportId, updateCompetitors = false) {
  return (dispatch, getState) => {
    if (shouldFetchReport(getState(), reportId, updateCompetitors)) {
      return dispatch(fetchReport(reportId));
    }
  };
}

export function setActiveReport(reportId) {
  return {
    type: SET_ACTIVE_REPORT,
    reportId,
  };
}

export function updateActiveReportLocally(report) {
  return {
    type: UPDATE_ACTIVE_REPORT_LOCALLY,
    report,
  };
}

export function updateReportLocally(report) {
  return {
    type: UPDATE_REPORT_LOCALLY,
    report,
  };
}

export function setIsFetching(isFetching) {
  return {
    type: SET_IS_FETCHING,
    isFetching,
  };
}

export function addTags(tag) {
  return {
    type: ADD_TAGS,
    tag,
  };
}

export function removeTags(tag) {
  return {
    type: REMOVE_TAGS,
    tag,
  };
}

export function updateTags(tag) {
  return {
    type: UPDATE_TAGS,
    tag,
  };
}

export function receiveOutline(reportId, outline) {
  return (dispatch) => {
    dispatch({ type: RECEIVE_OUTLINE, outline, reportId });
  };
}

export function saveOutlineTitle(report, title) {
  return (dispatch, getState) => {
    dispatch({ type: SET_OUTLINE_TITLE, title });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function addOutlineTitleBookmark(report, title) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_OUTLINE_TITLE_BOOKMARK, title });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function removeOutlineTitleBookmark(report, title) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_OUTLINE_TITLE_BOOKMARK, title });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function addOutlineDescriptionBookmark(report, description) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_OUTLINE_DESCRIPTION_BOOKMARK, description });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function removeOutlineDescriptionBookmark(report, description) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_OUTLINE_DESCRIPTION_BOOKMARK, description });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function showOutlineBookmarks() {
  return (dispatch) => {
    dispatch({ type: SHOW_OUTLINE_BOOKMARKS });
  };
}

export function hideOutlineBookmarks() {
  return (dispatch) => {
    dispatch({ type: HIDE_OUTLINE_BOOKMARKS });
  };
}

export function bypassInitialBrainstorm(report) {
  return (dispatch, getState) => {
    dispatch({ type: BYPASS_INITIAL_BRAINSTORM });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function setOutlineDescriptionBookmarks(report, descriptions) {
  return (dispatch, getState) => {
    dispatch({ type: SET_OUTLINE_DESCRIPTION_BOOKMARKS, descriptions });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function saveOutlineDescription(report, description) {
  return (dispatch, getState) => {
    dispatch({ type: SET_OUTLINE_DESCRIPTION, description });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function saveOutlineTitleAndDescription(report, title, description) {
  return (dispatch, getState) => {
    dispatch({ type: SET_OUTLINE_TITLE, title });
    dispatch({ type: SET_OUTLINE_DESCRIPTION, description });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function saveOutlineWordCount(report, wordCount) {
  return (dispatch, getState) => {
    dispatch({ type: SET_OUTLINE_WORD_COUNT, wordCount });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function saveOutlineIdea(report, idea) {
  const { title, description, outline } = idea;
  return (dispatch, getState) => {
    dispatch({ type: SET_OUTLINE_TITLE, title });
    dispatch({ type: SET_OUTLINE_DESCRIPTION, description });
    outline.forEach((line, i) => {
      dispatch({ type: ADD_CARD, index: i, card: { text: line } });
    });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function moveCard(lastY, nextY) {
  return (dispatch) => {
    dispatch({ type: MOVE_CARD, lastY, nextY });
  };
}

export function moveSelectedCards(nextY) {
  return (dispatch) => {
    dispatch({ type: MOVE_SELECTED_CARDS, nextY });
  };
}

export function selectCard(index, ctrl, shift) {
  return (dispatch) => {
    dispatch({ type: SELECT_CARD, index, ctrl, shift });
  };
}

export function unselectAllCards() {
  return (dispatch) => {
    dispatch({ type: UNSELECT_ALL_CARDS });
  };
}

export function discardEditsAllCards() {
  return (dispatch) => {
    dispatch({ type: DISCARD_EDITS_ALL_CARDS });
  };
}

export function editCard(report, index, edits) {
  return (dispatch, getState) => {
    dispatch({ type: EDIT_CARD, index, edits });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function editCards(report, edits) {
  return (dispatch, getState) => {
    dispatch({ type: EDIT_CARDS, edits });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function shiftCards(report, direction) {
  return (dispatch, getState) => {
    dispatch({ type: SHIFT_CARDS, direction });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function shiftSelections(report, direction) {
  return (dispatch, getState) => {
    dispatch({ type: SHIFT_SELECTIONS, direction });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function removeCards(report) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_CARDS });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function addCard(report, index, card) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_CARD, index, card });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function toggleCard(report, card) {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_CARD, card });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function toggleHighlightedTopic(report, topic) {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_HIGHLIGHTED_TOPIC, topic });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function toggleHighlightedStatistic(report, statistics) {
  // modify statistics from component to card
  statistics = {
    id: _.random(100000),
    originalText: statistics.statistics,
    indent: 0,
    text: statistics.statistics,
    url: statistics.source,
    type: "link",
    identifier: "statistics",
  };

  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_HIGHLIGHTED_STATISTIC, statistics });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function addHighlightedTopics(report, topics) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_HIGHLIGHTED_TOPICS, topics });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function toggleHighlightedCitations(report, citation) {
  // modify statistics from component to card
  citation = {
    id: _.random(100000),
    originalText: citation.title,
    indent: 0,
    text: citation.title,
    url: citation.url,
    type: "link",
    identifier: "citations",
  };
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_HIGHLIGHTED_CITATION, citation });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function removeHighlightedTopics(report, topics) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_HIGHLIGHTED_TOPICS, topics });
    dispatch(saveOutline(report, getState().outline));
  };
}

export function clearRecentlyToggled() {
  return (dispatch) => {
    dispatch({ type: CLEAR_RECENTLY_TOGGLED });
  };
}

export function showToast(toast) {
  return (dispatch) => {
    dispatch({ type: SHOW_TOAST, toast });
    window.setTimeout(() => {
      // for some reason, if I do not add a timeout, it does not call the componentDidUpdate method in the toasts view
      dispatch(clearToast());
    }, 10);
  };
}

export function clearToast() {
  return (dispatch) => {
    dispatch({ type: CLEAR_TOAST });
  };
}

// signalObj = {'report_id': signal}
export function updateSignals(signalObj) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SIGNALS, signalObj });
  };
}

export function ignoreTopic(report, topic) {
  return (dispatch, getState) => {
    const ignoredTopics = _.isArray(report.ignored_topics)
      ? report.ignored_topics.concat([topic])
      : [topic];
    const newReport = { ...report, ignored_topics: _.uniq(ignoredTopics) };
    dispatch(updateActiveReportLocally(newReport));
    dispatch(saveReport(newReport, true));
  };
}

export function deleteTopic(report, topic, callback) {
  report.delete_custom_topic = topic;
  return (dispatch, getState) => {
    dispatch(
      saveReport(report, false, (response) => {
        console.log("Successfully Deleted Manual Type Topic from the report.");
        if (callback && callback instanceof Function) {
          callback(response);
        }
      })
    );
  };
}

export function restoreTopic(report, topic) {
  return (dispatch) => {
    const newIgnoredTopics = _.filter(
      report.ignored_topics,
      (t) => t !== topic
    );
    const newReport = { ...report, ignored_topics: newIgnoredTopics };
    dispatch(updateActiveReportLocally(newReport));
    return dispatch(saveReport(newReport)); // we do sync so we can get the topic
  };
}

// saves outline to server
export function saveOutline(report, outline) {
  return (dispatch) => {
    const newReport = {
      id: report.id,
      outline: {
        cards: outline.cards,
        highlightedTopics: outline.highlightedTopics,
        title: outline.title,
        description: outline.description,
        wordCount: outline.wordCount,
        titleBookmarks: outline.titleBookmarks,
        descriptionBookmarks: outline.descriptionBookmarks,
        bypassBrainstorm: outline.bypassBrainstorm,
      },
    };
    dispatch(updateActiveReportLocally(newReport));
    dispatch(saveReport(newReport, true));
  };
}

export function createReportBulk(data, callback) {
  return (dispatch, getState) =>
    fetch("/api/keyword_reports", {
      method: "post",
      body: JSON.stringify({
        list: data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((responseObj) => {
        if (_.isFunction(callback)) {
          callback(responseObj);
        }
      });
}

// signal (optional) - used to abort requests if necessary
// async (optional) - true when saving the report asynchronously
export function saveReport(report, async, callback) {
  return (dispatch, getState) => {
    if (!async) {
      dispatch(setIsFetching(true));
    } else {
      report.async = true;
    }

    // cancel any previous fetch requests for this report
    const existingSignal = getState().reports.signals[report.id];
    if (existingSignal) {
      existingSignal.abort();
    }
    const signalObj = {};
    const abortController = new AbortController();
    signalObj[report.id] = abortController;
    dispatch(updateSignals(signalObj)); // save this signal for future calls

    return fetch(`/api/keyword_reports/${report.id}`, {
      signal: abortController.signal,
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify(report),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (!async) {
            dispatch(updateActiveReportLocally(response.report));
            dispatch(
              receiveOutline(response.report.id, response.report.outline)
            );
            // fire callback function with success
            if (callback && callback instanceof Function) {
              callback(true);
            }
          }
        } else {
          dispatch(
            showToast({
              message: "Something went wrong saving the brief.",
              options: { appearance: "error" },
            })
          );

          // fire callback function with failure
          if (callback && callback instanceof Function) {
            callback(false);
          }

          console.log("something went wrong saving the report ", response);
          throw "something went wrong saving the report";
        }
      })
      .catch((e) => {
        // fire callback function with failure
        if (callback && callback instanceof Function) {
          callback(false);
        }

        if (e.name === "AbortError") {
          console.log("Fetch aborted, not a problem");
        } else {
          console.log("Exception caught when saving report");
          AP.reportError(e);
        }
      });
  };
}

export function deleteArchiveReport(reportId) {
  return (dispatch) =>
    fetch(`/api/keyword_reports/${reportId}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    });
}

export function invalidateReports() {
  return {
    type: INVALIDATE_REPORTS,
  };
}

export function deleteReport(reportId) {
  return (dispatch) =>
    fetch(`/api/keyword_reports/delete_report/${reportId}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    });
}

export function refreshReport(reportId) {
  return (dispatch) =>
    fetch(`/api/keyword_reports/refresh_report/${reportId}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    });
}

// INVENTORIES

export function fetchInventories() {
  const url = `/api/inventories`;

  return (dispatch) => {
    dispatch(requestInventories());
    return fetch(url, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveInventories(json)));
  };
}

export function invalidateInventories() {
  return {
    type: INVALIDATE_INVENTORIES,
  };
}

function requestInventories() {
  return {
    type: REQUEST_INVENTORIES,
  };
}

function receiveInventories(json) {
  return {
    type: RECEIVE_INVENTORIES,
    inventories: json.inventories,
  };
}

export function fetchInventory(id) {
  const url = `/api/inventory/${id}`;

  return (dispatch) => {
    dispatch(requestInventory());
    return fetch(url, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveInventory(json)));
  };
}

function requestInventory() {
  return {
    type: REQUEST_INVENTORY,
  };
}

function receiveInventory(json) {
  return {
    type: RECEIVE_INVENTORY,
    inventory: json.success,
  };
}

export function deleteInventory(id) {
  return (dispatch) =>
    fetch(`/api/inventory/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    });
}

export function setActiveInventory(inventoryId) {
  return {
    type: SET_ACTIVE_INVENTORY,
    inventoryId,
  };
}

export function updateActiveInventoryLocally(inventory) {
  return {
    type: UPDATE_ACTIVE_INVENTORY_LOCALLY,
    inventory,
  };
}

// signal (optional) - used to abort requests if necessary
export function saveInventory(inventory) {
  return (dispatch, getState) => {
    dispatch(setIsFetching(true));

    // cancel any previous fetch requests for this inventory
    const existingSignal = getState().inventories.signals[inventory.id];
    if (existingSignal) {
      existingSignal.abort();
    }
    const signalObj = {};
    const abortController = new AbortController();
    signalObj[inventory.id] = abortController;
    dispatch(updateSignals(signalObj)); // save this signal for future calls

    return fetch(`/api/inventories/${inventory.id}`, {
      signal: abortController.signal,
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify(inventory),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch(updateActiveInventoryLocally(response.inventory));
        } else {
          dispatch(
            showToast({
              message: "Something went wrong saving the inventory.",
              options: { appearance: "error" },
            })
          );
          console.log("something went wrong saving the inventory ", response);
          throw "something went wrong saving the inventory";
        }
      })
      .catch((e) => {
        if (e.name === "AbortError") {
          console.log("Fetch aborted, not a problem");
        } else {
          console.log("Exception caught when saving inventory");
          AP.reportError(e);
        }
      });
  };
}

// SEGMENTS

// callback - optional
export function fetchSegments(inventory_id, callback) {
  const url = `/api/segments?inventory_id=${inventory_id}`;

  return (dispatch) => {
    dispatch(requestSegments());
    return fetch(url, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveSegments(json)))
      .then(() => {
        if (_.isFunction(callback)) {
          callback();
        }
      });
  };
}

function requestSegments(report) {
  return {
    type: REQUEST_SEGMENTS,
  };
}

function receiveSegments(json) {
  return {
    type: RECEIVE_SEGMENTS,
    segments: json.segments,
  };
}

export function addSegment(segment) {
  return {
    type: ADD_SEGMENT,
    segment,
  };
}

export function removeSegment(segmentId) {
  return {
    type: REMOVE_SEGMENT,
    segmentId,
  };
}

export function deleteSegment(id) {
  return (dispatch) =>
    fetch(`/api/segments/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch(removeSegment(id));
        }
      });
}

export function setActiveSegment(segmentId) {
  return {
    type: SET_ACTIVE_SEGMENT,
    segmentId,
  };
}

export function setSelectedSegment(segmentId) {
  return {
    type: SET_SELECTED_SEGMENT,
    segmentId,
  };
}

// updatedFromServer (optional) - true|false, allows save state to be tracked
export function updateSegmentLocally(segment, updatedFromServer) {
  if (updatedFromServer) {
    segment.saveState = "saved";
  }
  return {
    type: UPDATE_SEGMENT_LOCALLY,
    segment,
  };
}

export function saveSegment(segment, callback) {
  return (dispatch, getState) => {
    const url = segment.id ? `/api/segments/${segment.id}` : "/api/segments";
    const method = segment.id ? "PUT" : "POST";

    delete segment.id;
    delete segment.saveState;

    return fetch(url, {
      credentials: "same-origin",
      method,
      body: JSON.stringify(segment),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (response.segment) {
            updateSegmentLocally(response.segment, true);
          }
          if (_.isFunction(callback)) {
            callback(response.segment);
          }
        } else {
          dispatch(
            showToast({
              message: "Something went wrong saving the segment.",
              options: { appearance: "error" },
            })
          );
          console.log("something went wrong saving the segment ", response);
          throw "something went wrong saving the segment";
        }
      })
      .catch((e) => {
        if (e.name === "AbortError") {
          console.log("Fetch aborted, not a problem");
        } else {
          console.log("Exception caught when saving segment", e);
          AP.reportError(e);
        }
      });
  };
}

// PAGES

function requestPages() {
  return {
    type: REQUEST_PAGES,
  };
}

function receivePages(json) {
  return {
    type: RECEIVE_PAGES,
    pages: json.pages,
    total: json.total,
    clicks_delta_min_max: json.clicks_delta_min_max,
    impressions_delta_min_max: json.impressions_delta_min_max,
  };
}

export function setSelectedPages(pageIds) {
  pageIds = _.compact(pageIds);
  return {
    type: SET_SELECTED_PAGES,
    pageIds,
  };
}

export function updatePageSortOrder({ sort, order, offset, limit }) {
  return {
    type: UPDATE_PAGE_SORT_ORDER,
    sort,
    order,
    offset,
    limit,
  };
}

export function updatePageExtraFilters({ extraFilters }) {
  return {
    type: UPDATE_PAGE_EXTRA_FILTERS,
    extraFilters,
  };
}

export function fetchPages(inventory_id, segment_id, options) {
  // options is optional, can contain sort, order, etc
  const url = `/api/inventories/pages`;

  options = {
    segment_id,
    id: inventory_id,
    ...options,
  };

  // convert extra filters to object
  if (_.isArray(options.extraFilters) && !_.isEmpty(options.extraFilters)) {
    options.extra_filter = options.extraFilters[0];
    delete options.extraFilters;
  }

  return (dispatch) => {
    dispatch(requestPages());
    return fetch(url, {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => dispatch(receivePages(json)));
  };
}

export function fetchPagesForExport(
  inventory_id,
  segment_id,
  options,
  callback
) {
  // options is optional, can contain sort, order, etc
  const url = `/api/inventories/pages`;

  options = {
    segment_id,
    id: inventory_id,
    ...options,
  };

  // convert extra filters to object
  if (_.isArray(options.extraFilters) && !_.isEmpty(options.extraFilters)) {
    options.extra_filter = options.extraFilters[0];
    delete options.extraFilters;
  }

  return (dispatch) => {
    dispatch(requestPages());
    return fetch(url, {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then(callback);
  };
}

export function ignorePages(inventory_id, page_ids, segment_id, callback) {
  // options is optional, can contain sort, order, etc
  const url = `/api/inventories/blacklist/${inventory_id}`;

  return (dispatch) =>
    fetch(url, {
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify({
        page_ids,
        segment_id,
        limit: 10000,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(fetchSegments(inventory_id));
        if (_.isFunction(callback)) {
          callback(json);
        }
      });
}

export function restorePages(inventory_id, page_ids, segment_id, callback) {
  // options is optional, can contain sort, order, etc
  const url = `/api/inventories/blacklist/${inventory_id}`;

  return (dispatch) =>
    fetch(url, {
      credentials: "same-origin",
      method: "DELETE",
      body: JSON.stringify({
        page_ids,
        segment_id,
        limit: 10000,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(fetchSegments(inventory_id));
        if (_.isFunction(callback)) {
          callback(json);
        }
      });
}

export function addNewTopicToReport(report, newTopicObject, callback) {
  report.new_topic_object = newTopicObject;
  return (dispatch) => {
    dispatch(
      saveReport(report, false, (response) => {
        console.log("Successfully Added New Manual Type Topic to the report.");
        if (callback && callback instanceof Function) {
          callback(response);
        }
      })
    );
  };
}

export function addRemoveAlternativeSpellingToExistingTopic(
  report,
  modifiedExtistingTopicObject
) {
  report.modified_existing_topic = modifiedExtistingTopicObject;
  return (dispatch) => {
    const newRecommendedTopics = (() => {
      const { recommended_topics } = report.report;
      recommended_topics.map((topic, index) => {
        if (topic.name == modifiedExtistingTopicObject.name) {
          recommended_topics[index] = modifiedExtistingTopicObject;
        }
      });
      return recommended_topics;
    })();
    const newReport = {
      ...report,
      report: { recommended_topics: newRecommendedTopics },
    };
    newReport.modified_existing_topic = null; // make sure to remove this key before saving report locally
    dispatch(updateActiveReportLocally(newReport));
    dispatch(saveReport(report, false));
  };
}

export function requestContentRevisions(reportId, offset = 0, callback) {
  return (dispatch) =>
    fetch(`/api/content_revisions/${reportId}?offset=${offset}`, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveRevisions(json.revisions));
        const responseLength = json.revisions.length;
        if (_.isFunction(callback)) {
          callback(responseLength);
        }
      });
}

export function addContentRevisions(
  reportId,
  content,
  isImportConfirmed,
  callback
) {
  return (dispatch) =>
    fetch(
      `/api/content_revisions/${reportId}?is_imported=${isImportConfirmed}`,
      {
        method: "post",
        body: JSON.stringify({
          editor_content: content,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          dispatch(addRevision(json.success));
        }

        if (_.isFunction(callback)) {
          const status = !!json.success;
          callback(status);
        }
      })
      .catch((e) => {
        if (_.isFunction(callback)) {
          callback(false);
        }
      });
}
