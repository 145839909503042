import React from "react";
import Toggle from "react-toggle";
import _ from "lodash";
import Loader from "./loader";
import TextLoader from "./loader_text";

class ReportStatisticsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: false,
      orderBy: "asc",
      statisticsInProgress: false,
      statisticsKeyword: "",
      removeCompetitors: false,
      statisticsExpanded: false,
    };
    this.setStatisticsKeyword = this.setStatisticsKeyword.bind(this);
    this.startGetStatisticsApiCall = this.startGetStatisticsApiCall.bind(this);
    this.getStatistics = this.getStatistics.bind(this);
    this.removeCompetitorsFromStatistics =
      this.removeCompetitorsFromStatistics.bind(this);
    this.getStatisticSnippet = this.getStatisticSnippet.bind(this);
    this.maxInitialStatsPerDomain = 1;
  }

  setStatisticsKeyword(value) {
    this.setState({ statisticsKeyword: value });
  }

  getStatistics(e) {
    if (
      (e.key === "Enter" || e.type === "click") &&
      this.state.statisticsKeyword != ""
    ) {
      this.setState({
        statistics: [],
        statisticsExpanded: false,
      });
      this.startGetStatisticsApiCall(this.state.statisticsKeyword);
    }
  }

  startGetStatisticsApiCall(keyword) {
    this.setState({ statisticsInProgress: true });
    fetch(`/api/keyword_get_statistics/${keyword} (facts|statistics|studies)`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const stats = data;
          this.setState({ statistics: stats }, () => {
            this.setState({ statisticsInProgress: false });
          });
        } else {
          this.setState({ statisticsInProgress: false });
          console.log("something went wrong getting required keywords");
        }
      });
  }

  removeCompetitorsFromStatistics() {
    this.setState({ removeCompetitors: !this.state.removeCompetitors });
  }

  // returns the stat word plus the word before and after
  // used to increase hit rate of text fragment for linking
  getStatisticSnippet(stat) {
    const statWords = stat.split(" ");
    const statIndex = _.findIndex(statWords, (word) =>
      /percentage|percent|%|per-cent/.test(word)
    );
    const w1 = statWords[statIndex - 1] || "";
    const w2 = statWords[statIndex] || "";
    const w3 = statWords[statIndex + 1] || "";
    return `${w1} ${w2} ${w3}`;
  }

  render() {
    const that = this;
    const { highlightedStatistics } = this.props;
    const cols = [
      ["Statistics", "stat"],
      ["Action", "source"],
    ];
    const { statisticsKeyword } = this.state;

    let sortedStatistics = this.state.statistics;
    sortedStatistics = (() => {
      if (
        this.state.removeCompetitors &&
        this.props.competitorData &&
        this.state.statistics
      ) {
        const filteredStatistics = [];
        const competitorURLS = [];
        this.props.competitorData.map((singleCompetitor) => {
          const url = new URL(singleCompetitor.url);
          competitorURLS.push(url.host.toLowerCase());
        });
        this.state.statistics.map((singleStatistic) => {
          const url = new URL(singleStatistic.source);
          if (!competitorURLS.includes(url.host)) {
            filteredStatistics.push(singleStatistic);
          }
        });
        return filteredStatistics;
      }
      return this.state.statistics;
    })();

    let showExpand = false;
    _.each(sortedStatistics, (statObj) => {
      if (statObj.statistics.length > that.maxInitialStatsPerDomain) {
        showExpand = true;
      }
    });

    return (
      <div className="clearfix group">
        <div className="element-wrapper">
          <div className="clearfix">
            <div className="float-left">
              <div
                className="text-bold"
                style={{
                  paddingTop: "0.2rem",
                  marginRight: "0.2rem",
                  marginBottom: "0.4rem",
                }}
              >
                Find statistics about:
              </div>
              <div className="float-left input-group">
                <input
                  placeholder="keyword"
                  style={{
                    borderWidth: "1px",
                    height: "38px",
                    display: "inline",
                    borderRadius: "4px",
                  }}
                  className="form-control"
                  value={this.state.statisticsKeyword}
                  onChange={(event) =>
                    this.setStatisticsKeyword(event.target.value)
                  }
                  onKeyDown={this.getStatistics}
                  disabled={this.state.statisticsInProgress}
                />
                <button
                  className="btn btn-primary ml-1"
                  onClick={this.getStatistics}
                  disabled={this.state.statisticsInProgress}
                >
                  Search
                </button>
              </div>
              <div className="mt-2">
                <a
                  href="#"
                  className="text-m text-bluegray-4"
                  onClick={() => {
                    $("#statistics-video").modal("show");
                  }}
                >
                  How do I use this?
                </a>
              </div>
            </div>
            {sortedStatistics && (
              <div className="float-right">
                <div className="d-flex toggle-medium">
                  <label
                    className="clickable mr-2 text-bold"
                    htmlFor="exclude-competitors"
                  >
                    Exclude Competitor Domains
                  </label>
                  <Toggle
                    id="exclude-competitors"
                    checked={this.state.removeCompetitors}
                    icons={false}
                    onChange={() => this.removeCompetitorsFromStatistics()}
                  />
                  <a
                    className="ml-2"
                    data-original-title="This removes competitors from the citations & statistics results"
                    data-toggle="tooltip"
                    href="#"
                    title=""
                    style={{ color: "inherit" }}
                  >
                    <i className="fas fa-info-circle text-bluegray-4" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {this.state.statisticsInProgress && (
            <div className="p-2">
              Fetching statistics. Please wait...{" "}
              <Loader
                type="spinner"
                className="dark"
                style={{ paddingTop: "0.5rem" }}
              />
              <div className="mt-3">
                <TextLoader
                  lines={[
                    `Searching for "${statisticsKeyword} statistics"`,
                    "Extracting contents of articles",
                    "Identifying potential statistics",
                    "Removing irrelevant statements",
                    "Organizing data",
                  ]}
                />
              </div>
            </div>
          )}
          {_.isEmpty(this.state.statistics) &&
          !this.state.statisticsInProgress &&
          this.state.statistics ? (
            <div className="p-2">No Statistics found</div>
          ) : null}
          {!_.isEmpty(this.state.statistics) && (
            <table
              className="report-commonly-cited-links-table table table-striped"
              ref={this.statisticsRef}
            >
              <thead>
                <tr>
                  {cols.map((col, index) => {
                    const label = col[0];
                    const key = col[1];
                    let icon;
                    // We can reuse this functionality, if we decide to use sorting functionality in future
                    if (key === "domain_score") {
                      icon =
                        orderBy === "asc" ? (
                          <i className="fa fa-angle-down" />
                        ) : (
                          <i className="fa fa-angle-up" />
                        );
                    }
                    if (label === "Statistics" || label === "Source") {
                      return <th key={index}>{label}</th>;
                    }
                    return (
                      <th key={index} className="text-bold">
                        {label} {icon}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {_.map(
                  _.filter(sortedStatistics, "statistics"),
                  (stat, index1) => {
                    const url = new URL(stat.source);
                    return (
                      <React.Fragment key={index1}>
                        {stat.statistics.map((singleStatistic, index2) => {
                          let isHighlighted = false;
                          const linkToSource = `${
                            stat.source.split("#:~:text")[0]
                          }`;
                          const linkToFact = `${linkToSource}#:~:text=${encodeURIComponent(
                            that.getStatisticSnippet(singleStatistic)
                          )}`;

                          if (
                            (index2 > that.maxInitialStatsPerDomain ||
                              index1 > 3) &&
                            !this.state.statisticsExpanded
                          ) {
                            return;
                          }

                          highlightedStatistics.map((highlightedStatistic) => {
                            if (
                              singleStatistic === highlightedStatistic.text &&
                              linkToFact === highlightedStatistic.url
                            ) {
                              isHighlighted = true;
                            }
                          });

                          return (
                            <tr
                              className="white-row"
                              key={`${index1}${index2}`}
                            >
                              <td>
                                <div className="position-relative statistics-row">
                                  <p>
                                    <img
                                      className="pr-2"
                                      src={`https://www.google.com/s2/favicons?domain_url=${url.host}`}
                                    />{" "}
                                    <a
                                      href={linkToFact}
                                      target="_blank"
                                      className={`${
                                        isHighlighted ? "highlight" : ""
                                      }`}
                                      rel="noreferrer"
                                    >
                                      {singleStatistic} -{" "}
                                      <span
                                        className="text-blue-5"
                                        style={{
                                          fontSize: "12px",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {url.host.replace(/^www./, "")}
                                      </span>
                                    </a>{" "}
                                    <i className="fa fa-external-link" />
                                  </p>
                                </div>
                              </td>
                              <td>
                                <button
                                  className="btn bg-primary-2 hover-bg-primary-3"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    that.props.toggleHighlightedStatistic({
                                      statistics: singleStatistic,
                                      source: linkToFact,
                                    });
                                  }}
                                >
                                  {!isHighlighted ? "Add to Outline" : "Remove"}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
          {!this.state.statisticsInProgress && showExpand && (
            <div
              className="clickable bg-bluegray-1 p-3 text-center"
              onClick={() => {
                this.setState({
                  statisticsExpanded: !this.state.statisticsExpanded,
                });
              }}
            >
              {this.state.statisticsExpanded
                ? "Minimize statistics"
                : "Show all statistics"}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ReportStatisticsTable;
