import React from "react";
import Loader from "./loader";
import { getJSONFromLocalStorage, setJSONToLocalStorage } from "./helpers";

class EditorImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      extract_all_text: false,
    };
    this.validate = this.validate.bind(this);
    this.formRef = React.createRef();

    // this keeps a copy of the import url in the local storage between sessions, for user's convenience
    this.importUrlLocalStorageKey = "topic_importurl";
    this.cacheImportUrl = this.cacheImportUrl.bind(this);
    this.getCachedImportUrl = this.getCachedImportUrl.bind(this);
  }

  componentDidMount() {
    $(this.formRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    const { url } = this.state;
    if (url === "") {
      const cachedImportUrl = this.getCachedImportUrl();
      if (_.isString(cachedImportUrl) && !_.isEmpty(cachedImportUrl)) {
        this.setState({ url: cachedImportUrl });
      }
    }
    $(this.formRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  cacheImportUrl(url) {
    const cachedImportUrls =
      getJSONFromLocalStorage(this.importUrlLocalStorageKey) || {};
    cachedImportUrls[this.props.report.id] = url;
    setJSONToLocalStorage(this.importUrlLocalStorageKey, cachedImportUrls);
  }

  getCachedImportUrl() {
    const cachedImportUrls =
      getJSONFromLocalStorage(this.importUrlLocalStorageKey) || {};
    return cachedImportUrls[this.props.report.id];
  }

  validate(event) {
    const { url, extract_all_text } = this.state;
    const { report } = this.props;
    const hasContentInGrader =
      report && report.content && report.content.word_count > 10;
    if (this.formRef.current.reportValidity() && !this.props.showLoader) {
      if (
        !hasContentInGrader ||
        (hasContentInGrader &&
          confirm(
            "This will overwrite the existing content in the grader. Are you sure you want to continue?"
          ))
      ) {
        this.cacheImportUrl(url);
        this.props.onSubmit({
          url,
          extract_all_text,
        });
      }
    }
  }

  render() {
    const { extract_all_text, url } = this.state;
    return (
      <div
        aria-hidden="true"
        aria-labelledby="editorImportModal"
        className="modal fade editorImportModal onboarding-modal"
        role="dialog"
        tabIndex="-1"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div className="onboarding-content with-gradient">
              <h4 className="onboarding-title text-center">
                Import Content from URL
              </h4>
              <form ref={this.formRef}>
                <div className="form-group">
                  <label htmlFor="editor-import-url">
                    <span>URL</span>
                  </label>
                  <input
                    name="editor-import-url"
                    className="form-control"
                    placeholder="https://www.example.com/blog/post"
                    type="url"
                    value={this.state.url}
                    onChange={(e) => {
                      this.setState({ url: e.target.value });
                    }}
                    required
                  />
                </div>
                <div style={{ display: "none" }}>
                  Import Mode
                  <div>
                    Just Content
                    <p className="text-s">
                      Attempts to identify just the content of the page,
                      excluding extra text in the navigation and footer.
                      Includes formatting.
                    </p>
                  </div>
                  <div>
                    Entire Page
                    <p className="text-s">
                      Extracts text from the entire page, including the
                      navigation and footer. Excludse formatting.
                    </p>
                  </div>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={extract_all_text}
                    checked={extract_all_text}
                    onChange={() => {
                      this.setState({ extract_all_text: !extract_all_text });
                    }}
                    id="extract_all_text"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="extract_all_text"
                  >
                    Import Full Page{" "}
                    <a
                      data-original-title="Extracts text from the entire page, including the navigation, footer, and comments. Does not import formatting."
                      data-toggle="tooltip"
                      href="#"
                      title=""
                    >
                      <i className="fa fa-info-circle" />
                    </a>
                  </label>
                </div>
                <div className="form-buttons-w">
                  <button
                    onClick={this.validate}
                    className="btn btn-primary"
                    type="button"
                  >
                    Import Content
                  </button>{" "}
                  {this.props.showLoader && (
                    <Loader
                      type="spinner"
                      className="dark"
                      style={{ position: "absolute", marginLeft: "10px" }}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorImportModal;
