import React from "react";
import Loader from "./loader";

class TextLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linesShown: [props.lines[0]],
    };
    this.animationIntervalSpeed = 3000;
  }

  componentDidMount() {
    const that = this;
    this.animationInterval = window.setInterval(() => {
      const { linesShown } = that.state;
      if (linesShown.length < that.props.lines.length) {
        const newLinesShown = _.concat(linesShown, [
          that.props.lines[linesShown.length],
        ]);
        that.setState({
          linesShown: newLinesShown,
        });
      } else {
        window.clearInterval(that.animationInterval);
      }
    }, this.animationIntervalSpeed);
  }

  componentWillUnmount() {
    window.clearInterval(this.animationInterval);
  }

  render() {
    const { linesShown } = this.state;
    return (
      <div className="text-loader">
        {_.map(linesShown, (line, index) => {
          const showLoader = index === linesShown.length - 1;
          return (
            <div
              key={index}
              className={`fadeInDown mb-3 ${
                showLoader ? "text-primary-9" : "text-primary-5"
              }`}
            >
              {line}
              {showLoader && <Loader type="ellipsis" />}
            </div>
          );
        })}
      </div>
    );
  }
}

export default TextLoader;
