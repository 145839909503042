import React from "react";

function UpgradeModal() {
  return (
    <div
      aria-hidden="true"
      aria-labelledby="upgradeModal"
      className="modal fade upgradeModal text-center"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content d-flex flex-column p-3">
          <span style={{ fontSize: "32px" }} className="text-bold mt-2 mb-4">
            Upgrade to Unlock
          </span>
          <div>
            <a
              className="btn text-white btn-info btn-lg"
              href="https://www.usetopic.com/pricing"
              target="_blank"
              rel="noreferrer"
            >
              Choose a Plan
            </a>
          </div>
          <div className="mt-2">starts at $50 a month</div>
          <div className="mt-5" style={{ fontSize: "17px" }}>
            We asked our customers about their experiences after upgrading.
            Here's what they had to say:
          </div>
          <div className="d-flex text-left mt-3">
            <div className="p-3 m-3 upgrade-modal-testimonial">
              <div className="d-flex">
                <div className="upgrade-modal-testimonial-img">
                  <img src="https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5f0f543f34673831eb0bf75a_danny_wong.jpeg" />
                </div>
                <div className="upgrade-modal-testimonial-title">
                  Danny Wong
                  <br />
                  Senior Editor at Amerisleep
                </div>
              </div>
              <p>
                I love how Topic saves me time in creating SEO-optimized
                outlines and content, especially for longer-form articles.{" "}
                <span className="text-bold">We save hundreds of hours</span>{" "}
                each month.
              </p>
            </div>
            <div className="p-3 m-3 upgrade-modal-testimonial">
              <div className="d-flex">
                <div className="upgrade-modal-testimonial-img">
                  <img src="https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5f0f57524e50217470c58419_carrie_adkins.jpeg" />
                </div>
                <div className="upgrade-modal-testimonial-title">
                  Carrie Adkins
                  <br />
                  VP of Content, wikiHow
                </div>
              </div>
              <p>
                It's been a very positive experience for our team. Optimizing
                our content with Topic is{" "}
                <span className="text-bold">double or triple as fast</span> as
                how we did it before.
              </p>
            </div>
            <div className="p-3 m-3 upgrade-modal-testimonial">
              <div className="d-flex">
                <div className="upgrade-modal-testimonial-img">
                  <img src="https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5f0f53473d9cc622e9aacb5d_naomi_tepper.png" />
                </div>
                <div className="upgrade-modal-testimonial-title">
                  Naomi Tepper
                  <br />
                  Senior Manager, Magoosh
                </div>
              </div>
              <p>
                What impressed us the most about Topic is the fact that it
                effectively achieved better rankings without a lot of extra
                work. On one blog post, we{" "}
                <span className="text-bold">
                  increased new users by over 300%
                </span>{" "}
                year-over-year.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeModal;
