import React from "react";
import _ from "lodash";

class ReportOverviewQuestionsOld extends React.Component {
  constructor(props) {
    super(props);

    // populate initial visibility view with first 4 questions and their children
    const { questions } = this.props;
    this.isNewView =
      _.isObject(questions) &&
      questions.questions &&
      !_.isEmpty(questions.questions.children);
    const initialVisibilityMap = {};
    if (this.isNewView) {
      questions.questions.children.forEach((question) => {
        initialVisibilityMap[question.query] = true;
        question.children.forEach((subquestion) => {
          initialVisibilityMap[subquestion.query] = true;
        });
      });
    }

    this.state = {
      visibilityMap: initialVisibilityMap, // looks like {"Asking a question?": true}, used to control visibility of question tree
      showAllQuestions: false,
    };

    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.toggleAllQuestions = this.toggleAllQuestions.bind(this);
  }

  // toggles the visibility of the question's children
  toggleQuestion(e, question) {
    const that = this;
    const { visibilityMap } = this.state;
    question.children.forEach((child) => {
      if (!visibilityMap[child.query]) {
        visibilityMap[child.query] = true;
      } else {
        visibilityMap[child.query] = false;
      }
    });
    this.setState({ visibilityMap });
    e.stopPropagation();
  }

  toggleAllQuestions() {
    if (this.state.showAllQuestions) {
      // hide questions
      this.setState({
        visibilityMap: {},
        showAllQuestions: false,
      });
    } else {
      // expand questions
      const expandedVisibilityMap = {};
      this.props.questions.questions.children.forEach((question) => {
        expandedVisibilityMap[question.query] = true;
        question.children.forEach((subquestion) => {
          expandedVisibilityMap[subquestion.query] = true;
          subquestion.children.forEach((subsubquestion) => {
            expandedVisibilityMap[subsubquestion.query] = true;
          });
        });
      });
      this.setState({
        visibilityMap: expandedVisibilityMap,
        showAllQuestions: true,
      });
    }
  }

  render() {
    const that = this;
    const { questions } = this.props;
    const hasNoQuestions =
      _.isEmpty(questions) ||
      (_.isObject(questions) &&
        questions.questions &&
        !_.isObject(questions.questions));
    let questionView;
    if (hasNoQuestions) {
      questionView = <div>No related questions found</div>;
    } else if (this.isNewView) {
      // new view
      questionView = (
        <div>
          <div style={{ textAlign: "right" }}>
            <button
              className="btn btn-white mb-2"
              onClick={this.toggleAllQuestions}
            >
              {this.state.showAllQuestions
                ? "hide questions"
                : "expand questions"}
            </button>
          </div>
          <div className="d-flex">
            {questions.questions.children.map((question, index) => (
              <div
                key={index}
                className={`overview-questions-question p-2 flex-grow-1 ${
                  question.children.length > 0 ? "hoverable clickable" : ""
                }`}
                onClick={(e) => {
                  this.toggleQuestion(e, question);
                }}
              >
                <div className="p-2 text-bold">
                  {question.query}{" "}
                  {question.children.length > 0 && (
                    <span className="text-bluegray-3">
                      ({question.children.length})
                    </span>
                  )}
                </div>

                {!_.isEmpty(question.children) && (
                  <div className="overview-questions-children">
                    {question.children.map((subquestion, index) => (
                      <div
                        key={index}
                        className={`overview-questions-subquestion ${
                          question.children.length > 0
                            ? "hoverable clickable"
                            : ""
                        }`}
                        onClick={(e) => {
                          this.toggleQuestion(e, subquestion);
                        }}
                        style={{
                          display: that.state.visibilityMap[subquestion.query]
                            ? "block"
                            : "none",
                        }}
                      >
                        <div className="p-2">
                          {subquestion.query}{" "}
                          {subquestion.children.length > 0 && (
                            <span className="text-bluegray-3">
                              ({subquestion.children.length})
                            </span>
                          )}
                        </div>

                        {!_.isEmpty(subquestion.children) && (
                          <div className="overview-questions-children">
                            {subquestion.children.map(
                              (subsubquestion, index) => (
                                <div
                                  key={index}
                                  className="overview-questions-subsubquestion"
                                  style={{
                                    display: that.state.visibilityMap[
                                      subsubquestion.query
                                    ]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <div className="p-2">
                                    {subsubquestion.query}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      // old view
      questionView = (
        <table className="table">
          <thead>
            <tr>
              <th>Question</th>
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(questions) && (
              <tr className="text-center p-4">
                <td>No related questions found</td>
              </tr>
            )}
            {!_.isEmpty(questions) &&
              _.isArray(questions) &&
              questions.map((question, index) => (
                <tr key={index}>
                  <td className="nowrap">{question.question}</td>
                </tr>
              ))}
          </tbody>
        </table>
      );
    }

    return <div className="overview-questions">{questionView}</div>;
  }
}

export default ReportOverviewQuestionsOld;
