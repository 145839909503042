import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "./loader";
import _ from 'lodash';
import { timeAgo,downloadCSV } from "./helpers";

function Pregrading(props){
  const [newPregradeName , setNewPregradeName] = useState("");
  const [newPregradeJSON , setnewPregradeJSON] = useState([]);
  const [showPregradeMenu, setShowPregradeMenu] = useState(false);
  const [pregradingRuns, setPregradingRuns] = useState([]);
  const [requestInProcess, setRequestInProcess] = useState(false);

  // component did mount.
  useEffect(()=>{
    fetchAllPregradingJobs();
  },[])
  

  const fetchAllPregradingJobs = () => {
    const endpoint = '/api/pregrading'
    fetch(endpoint)
      .then((response) => response)
      .then((response) => response.json())
      .then((response) => {
        if(!response){
          return [];
        }
        setPregradingRuns(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportReport = (runId) => {
    const pregradingRun = pregradingRuns.filter( (run) => run.id === runId);
    const csvData = [["url", "keyword", "grade"]];
    if(pregradingRun[0].pregrading_run_status === "success"){
      pregradingRun[0].input.map((briefInfo) => {
        csvData.push([briefInfo.url, briefInfo.keyword,briefInfo.grade]);
      });
      downloadCSV(`${pregradingRun[0].name.replace(/ /g, "_")}.csv`,csvData);
    }else{
      console.log('Pregrading Run is not finished yet, Please wait & try again');
    }
  }

  const createNewPregradingRun = () => {
    if(!_.isEmpty(newPregradeName) && !_.isEmpty(newPregradeJSON)){
      setRequestInProcess(true);
      const endpoint = '/api/pregrading'
      fetch(endpoint, {
        method: "post",
        body: JSON.stringify({
          name: newPregradeName,
          input: newPregradeJSON,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
      .then((response) => response)
      .then((response) => response.json())
      .then((response) => {
        if(!response){
          console.log("Failed");
        }
        fetchAllPregradingJobs();
        setRequestInProcess(true);
        setNewPregradeName("");
        setnewPregradeJSON({});
        setShowPregradeMenu(false);
        console.log(response);
      })
      .catch((error) => {
        setRequestInProcess(false);
        console.log(error);
      });
    }else{
      alert("Please paste in valid CSV");
    }
  }

  const extractJSONFromCSV = (csvData) => {
    try{
      let dataArray = [];
      const eachRow = csvData.split("\n");
      eachRow.map((row) => {
        const rowData = row.split(",");
        if(rowData[0] && new URL(rowData[1])){
          dataArray.push({
            keyword: rowData[0],
            url : rowData[1]
          })
        }
      });
      setnewPregradeJSON(dataArray);
    }catch(e){
      console.log(e);
      return false;
    }
  }

  const capitalizeText = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  
  return (
    <div className="d-flex justify-content-center flex-nowrap" style={{position:'absolute', left:'50%', top:'50%',WebkitTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)'}}>
    <div className="mx-2 p-2">
    <div className="builder element-box fadeRight" style={{overflow: 'auto', minHeight:'auto', maxHeight:'45rem'}}>
        <div className="card-body">
          <h5 className="card-title">Pregrading <div className="btn btn-primary text-left" onClick={()=>{setShowPregradeMenu(true)}} style={{position:'absolute', right:'2rem'}}>New +</div></h5>
          <p className="card-text pt-2">Internal Tool Lets you pregrade existing content for a focused keyword.</p>
          <h5 className="card-title">Pregrading Runs</h5>
          <table className="table table-padded mt-4">
            <thead>
              <tr>
                <th className="clickable">Created </th>
                <th className="clickable ">Name </th>
                <th className="clickable text-bluegray-6">Status </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pregradingRuns.map((run) =>
                  <tr className="white-row reports-dashboard-row">
                    <td>{run.created_at && timeAgo( new Date(run.created_at)).replace("ago", "")}</td>
                    <td>{capitalizeText(run.name)}</td>
                    <td>{capitalizeText(run.pregrading_run_status)}</td>
                    {(run.pregrading_run_status == "success")?
                    <td className="reports-dashboard-row-actions"><a data-original-title="Add Tag" data-toggle="tooltip"><button className="btn btn-white action-secondary" onClick={()=>exportReport(run.id)}><i className="mr-1 fa fa-download" aria-hidden="true"></i></button></a></td> 
                    : 
                    <td className="reports-dashboard-row-actions"><Loader type="spinner" className="dark"/></td>
                    }
                  </tr>
              )}
              
              {/* <tr className="white-row reports-dashboard-row">
                <td>May 24, 2022</td>
                <td>My Pregrade Run</td>
                <td>Pending</td>
                <td className="reports-dashboard-row-actions"><Loader type="spinner" className="dark"/></td>
              </tr> */}
            </tbody>
          </table>
        </div>
    </div>
    </div>

    <Modal
      show={showPregradeMenu}
      animation={false}
      backdrop="static"
      onHide={() => {
        if(!requestInProcess){
          setNewPregradeName("");
          setnewPregradeJSON({});
          setShowPregradeMenu(false);
        }
      }}
    >
      <Modal.Header closeButton/>
      <Modal.Body className="pt-0">
        <div className="card-body">
          <h5 className="card-title">Pregrading Tool</h5>
          <p className="card-text">Internal Tool Lets you pregrade existing content for a focused keyword.</p>
          <label for="nameField">
            <span>Name (Pregrading Run Name) </span>
          </label>
          <input name="nameField" className="form-control" placeholder="My Pregrading Run" type="text" style={{marginBottom: '1rem'}} onChange={(e)=>{setNewPregradeName(e.target.value)}}/>
          <label for="csvFiled">
            <span>Pregrade CSV </span>
          </label>
          <textarea name="csvFiled" className="form-control" placeholder="Paste your CSV here in this format:&#10;Keyword 1 , Import URL&#10;Keyword 2 , Import URL" type="textarea" rows="5" style={{marginBottom: '1rem'}} onChange={(e)=>{extractJSONFromCSV(e.target.value)}} />
          <button onClick={()=>{createNewPregradingRun()}} className="btn btn-primary" disabled={requestInProcess}>{(requestInProcess) ? <Loader type="spinner" /> : 'Pregrade'}</button>
        </div>
      </Modal.Body>
    </Modal>
  </div>
  );
}

export default Pregrading;
