import React from "react";
import "mark.js/dist/jquery.mark.min.js";
import Toggle from "react-toggle";
import _ from "lodash";
import {
  hasUsedTopic,
  getShuffledClusterColors,
  triggerTopicClusters,
  downloadCSV,
} from "./helpers";
import Loader from "./loader";
import AddCustomTopicModal from "./add_custom_topic_modal";

class ReportOverviewTopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTopicIndexes: [],
      topicsExpanded: false,
      groupBySemanticSimilarityLoading: false,
      showAddTopicDialog: false,
      topicDeletionInProgress: false,
    };
    this.markTopic = this.markTopic.bind(this);
    this.handleTopicClick = this.handleTopicClick.bind(this);
    this.ignoreTopic = this.ignoreTopic.bind(this);
    this.deleteTopic = this.deleteTopic.bind(this);
    this.restoreTopic = this.restoreTopic.bind(this);
    this.downloadTopics = this.downloadTopics.bind(this);
    this.getUsageString = this.getUsageString.bind(this);
    this.enableGroupBySemanticSimilarity =
      this.enableGroupBySemanticSimilarity.bind(this);
    this.handleAddAllToOutline = this.handleAddAllToOutline.bind(this);
    this.handleRemoveAllFromOutline =
      this.handleRemoveAllFromOutline.bind(this);
    this.topicRef = React.createRef();
  }

  componentDidMount() {
    this.markTopic();
  }

  componentDidUpdate() {
    this.markTopic();
  }

  markTopic() {
    const that = this;
    if (this.props.report) {
      $(that.topicRef.current).unmark();
      this.state.activeTopicIndexes.forEach((activeTopicIndex) => {
        $(that.topicRef.current).mark(that.topics[activeTopicIndex].name, {
          separateWordSearch: false,
        });
      });
    }
  }

  handleTopicClick(index) {
    let newActiveTopicIndexes = this.state.activeTopicIndexes.slice(0);
    if (_.includes(this.state.activeTopicIndexes, index)) {
      newActiveTopicIndexes = _.filter(
        newActiveTopicIndexes,
        (i) => i !== index
      );
    } else {
      newActiveTopicIndexes.push(index);
    }
    this.setState({ activeTopicIndexes: newActiveTopicIndexes });
  }

  handleAddAllToOutline() {
    const { addHighlightedTopics, report } = this.props;
    const allTopicNames = _.map(report.report.recommended_topics, "name");
    addHighlightedTopics(report, allTopicNames);
  }

  handleRemoveAllFromOutline() {
    const { removeHighlightedTopics, report } = this.props;
    const allTopicNames = _.map(report.report.recommended_topics, "name");
    removeHighlightedTopics(report, allTopicNames);
  }

  ignoreTopic(topic) {
    this.props.ignoreTopic(this.props.report, topic);
  }

  deleteTopic(topic) {
    const { topicDeletionInProgress } = this.state;
    if (!topicDeletionInProgress) {
      this.setState({ topicDeletionInProgress: true });
      this.props.deleteTopic(this.props.report, topic, (response) => {
        if (response) {
          this.props.showToast({
            message: `Your Topic "${topic}" has been successfully deleted.`,
            options: { appearance: "success", autoDismiss: true },
          });
          this.setState({ topicDeletionInProgress: false });
        } else {
          this.props.showToast({
            message: `Error Deleting your Topic "${topic}".`,
            options: { appearance: "error", autoDismiss: true },
          });
          this.setState({ topicDeletionInProgress: false });
        }
      });
    }
  }

  restoreTopic(topic) {
    this.props.restoreTopic(this.props.report, topic);
  }

  // download CSV
  downloadTopics() {
    if (_.isArray(this.topics)) {
      const data = [["topic", "typical usage", "uses in heading", "relevance"]];
      _.each(this.topics, (topic) => {
        let headerCount = 0;
        _.map(topic.header_usage || [], (header_item) => {
          headerCount += header_item.sentences.length;
        });
        data.push([
          topic.name.replace(/,/g, ""),
          Math.round(_.mean(topic.count)),
          headerCount,
          Math.round(topic.score),
        ]);
      });

      downloadCSV(
        `Topics for ${this.props.report.report.focus_keyword}.csv`,
        data
      );
    }
  }

  getUsageString(topic) {
    const min = _.min(topic.count);
    const max = _.max(topic.count);
    let headerCount = 0;
    _.map(topic.header_usage || [], (header_item) => {
      headerCount += header_item.sentences.length;
    });
    const uses =
      min === max
        ? `${min === 1 ? "1 use" : `${min} uses`}`
        : `${min} - ${max} uses`;
    const headerUses =
      headerCount > 0
        ? `, ${headerCount} ${headerCount > 1 ? "uses" : "use"} in heading`
        : "";
    return `${uses} ${headerUses}`;
  }

  enableGroupBySemanticSimilarity() {
    if (this.props.isAnon) {
      $(".upgradeModal").modal("show");
      return;
    }

    // calls the API to get the topic relationships for this content brief
    const that = this;
    const reportId = this.props.report.id;
    const { groupBySemanticSimilarityLoading } = this.state;
    if (!groupBySemanticSimilarityLoading) {
      this.setState({ groupBySemanticSimilarityLoading: true });
      triggerTopicClusters(
        reportId,
        () => {
          that.props.fetchReport(reportId);
          that.props.setGroupBySemanticSimiliarity(true);
          that.setState({ groupBySemanticSimilarityLoading: false });
        },
        () => {
          that.props.showToast({
            message: `Something went wrong clustering the topics.`,
            options: { appearance: "error", autoDismiss: true },
          });
          that.setState({ groupBySemanticSimilarityLoading: false });
        }
      );
    }
  }

  render() {
    const that = this;
    const topicRowViews = [];
    const {
      groupBySemanticSimilarityLoading,
      topicsExpanded,
      showAddTopicDialog,
      topicDeletionInProgress,
    } = this.state;
    const { recommended_topics } = this.props.report.report;
    const {
      highlightedTopics,
      groupBySemanticSimilarity,
      setGroupBySemanticSimiliarity,
    } = this.props;
    const enableSortingToggle =
      !_.isEmpty(recommended_topics) &&
      _.isNumber(recommended_topics[0].cluster);

    // Sort By
    if (groupBySemanticSimilarity && enableSortingToggle) {
      this.topics = _.sortBy(recommended_topics, (topic) => topic.cluster);
      const colorSeed = this.props.report.name.length; // this ensures colors stay consistent within a brief
      var clusterColors = getShuffledClusterColors(colorSeed);
    } else {
      this.topics = _.sortBy(recommended_topics, (topic) => -1 * topic.score);
    }

    // Create Topic Views
    this.topics.forEach((topic, index) => {
      if (
        (!topicsExpanded && index > 10) ||
        that.props.report.ignored_topics.indexOf(topic.name) !== -1
      ) {
        return;
      }

      const topicIsActive = _.includes(that.state.activeTopicIndexes, index);
      const topicIsUsed = hasUsedTopic(that.props.report, topic);
      const isHighlighted = highlightedTopics.indexOf(topic.name) !== -1;
      var headerCount = 0;
      const showClusterColor = groupBySemanticSimilarity && enableSortingToggle;
      let clusterColor;
      if (showClusterColor) {
        clusterColor = clusterColors[topic.cluster % clusterColors.length];
      }

      _.map(topic.header_usage || [], (header_item) => {
        headerCount += header_item.sentences.length;
      });
      topicRowViews.push(
        <tr
          key={index}
          onClick={that.handleTopicClick.bind(that, index)}
          className={`white-row hoverable-row clickable ${
            topicIsActive ? "active bg-bluegray-1" : ""
          }`}
        >
          <td style={{ position: "relative" }}>
            {showClusterColor && (
              <div
                className={`report-overview-topic-row-cluster-color bg-${clusterColor}-5`}
              />
            )}
            <span className={`${isHighlighted ? "highlight" : ""}`}>
              {topic.name}
            </span>{" "}
            {topicIsUsed && (
              <div
                className="pl-1 text-green-4"
                style={{ display: "inline-block" }}
              >
                <i className="fa fa-check" />
              </div>
            )}
            <div
              className="d-flex"
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <div
                className="topic-table-btn btn-sm clickable btn btn-white text-bluegray-5"
                onClick={(e) => {
                  e.stopPropagation();
                  that.props.toggleHighlightedTopic(topic.name);
                }}
              >
                {isHighlighted ? "Remove from Outline" : "Add to Outline"}
              </div>
              {!topic.manually_added && (
                <div
                  className="topic-table-btn btn-sm clickable btn btn-white text-bluegray-5"
                  onClick={(e) => {
                    e.stopPropagation();
                    that.ignoreTopic(topic.name);
                  }}
                >
                  Ignore Topic
                </div>
              )}
              {topic.manually_added && (
                <div
                  className="topic-table-btn btn-sm clickable btn btn-white text-bluegray-5"
                  onClick={(e) => {
                    e.stopPropagation();
                    that.deleteTopic(topic.name);
                  }}
                >
                  Delete Topic
                </div>
              )}
            </div>
          </td>
          {topic.manually_added ? (
            <td>
              <span className="font-italic">Manually Added</span>
            </td>
          ) : (
            <td>
              <span className="font-italic">{that.getUsageString(topic)}</span>
            </td>
          )}
          <td>
            <div className="progress" style={{ marginRight: "30px" }}>
              <div
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow={`${topic.score}`}
                className="progress-bar bg-bluegray-4"
                role="progressbar"
                style={{ width: `${topic.score}%` }}
              />
            </div>
          </td>
        </tr>
      );

      // active topic view
      if (topicIsActive) {
        var headerCount = 0;
        if (_.isArray(topic.header_usage)) {
          _.each(topic.header_usage, (header_use) => {
            headerCount += header_use.sentences.length;
          });
        }
        topicRowViews.push(
          <tr key={(index + 1) * -1} className="bg-bluegray-1">
            <td colSpan="3">
              <div className="p-4">
                <h4 style={{ fontWeight: "normal" }}>
                  Examples of {topic.name}
                </h4>
                {_.isArray(topic.header_usage) &&
                  !_.isEmpty(topic.header_usage) && (
                    <div className="mt-4">
                      <h5>
                        <i className="fa fa-header" />{" "}
                        <span className="text-bold">
                          Used in {headerCount}{" "}
                          {headerCount > 1 ? "Headings" : "Heading"}{" "}
                        </span>
                      </h5>
                      {topic.header_usage.map((header_use, index) => (
                        <div key={index} className="pt-1 pb-1">
                          <div className="text-bold pt-2">
                            {header_use.title}
                          </div>
                          <div className="text-muted font-sm pt-2">
                            <img
                              className="pr-2"
                              src={`https://www.google.com/s2/favicons?domain_url=${
                                new URL(header_use.url).host
                              }`}
                            />{" "}
                            <a
                              href={header_use.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {new URL(header_use.url).host}{" "}
                              <i className="fa fa-external-link" />
                            </a>
                          </div>
                          <div className="pt-2">
                            <ul className="pl-4">
                              {header_use.sentences.map((sentence, index) => {
                                if (index < 4) {
                                  return <li key={index}>{sentence}</li>;
                                }
                                return undefined;
                              })}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                {_.isArray(topic.summary) && (
                  <div className="mt-4">
                    <h5>
                      <i className="fa fa-paragraph" />{" "}
                      <span className="text-bold">
                        Used in {topic.summary.length}{" "}
                        {topic.summary.length > 1 ? "Sources" : "Source"}{" "}
                      </span>
                    </h5>
                    {topic.summary.map((summary, index) => (
                      <div key={index} className="pt-1 pb-1">
                        <div className="text-bold pt-2">{summary.title}</div>
                        <div className="text-muted font-sm pt-2">
                          <img
                            className="pr-2"
                            src={`https://www.google.com/s2/favicons?domain_url=${
                              new URL(summary.url).host
                            }`}
                          />{" "}
                          <a
                            href={summary.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {new URL(summary.url).host}{" "}
                            <i className="fa fa-external-link" />
                          </a>
                        </div>
                        <div className="pt-2">
                          <ul className="pl-4">
                            {summary.sentences.map((sentence, index) => {
                              if (index < 4) {
                                return <li key={index}>{sentence}</li>;
                              }
                              return undefined;
                            })}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {!_.isArray(topic.summary) && (
                  <div className="mt-4">
                    {topic.manually_added && (
                      <span>
                        This is a manually added topic, and at the moment we do
                        not support the ability to see examples of manually
                        added topics within competitor content. If this feature
                        would be useful to you, let us know in the in-app chat!
                      </span>
                    )}
                    {!topic.manually_added && (
                      <span>
                        We were unfortunately unable to extract specific
                        examples of "{topic.name}" within competitor content.
                        Our apologies!
                      </span>
                    )}
                  </div>
                )}
              </div>
            </td>
          </tr>
        );
      }
    });

    // add custom topic row
    if (topicsExpanded) {
      topicRowViews.push(
        <tr
          key="custom_topic"
          onClick={() => {
            this.setState({ showAddTopicDialog: true });
          }}
          className="white-row hoverable-row clickable text-primary-7 font-italic"
        >
          <td colSpan="3">
            {topicDeletionInProgress ? (
              <span style={{ display: "flex", alignItems: "center" }}>
                Deleting Your Custom Topic ...{" "}
                <Loader type="spinner" className="dark" />
              </span>
            ) : (
              "+ add custom topic"
            )}
          </td>
        </tr>
      );
    }

    const ignoredTopicsView = (
      <div className="p-3">
        <h5>Ignored Topics</h5>
        <table className="table">
          <colgroup>
            <col span="1" style={{ width: "50%" }} />
            <col span="1" style={{ width: "50%" }} />
          </colgroup>
          <tbody>
            {this.props.report.ignored_topics.map((ignoredTopic, index) => (
              <tr key={index}>
                <td>{ignoredTopic}</td>
                <td>
                  <button
                    className="btn btn-white"
                    onClick={() => {
                      that.restoreTopic(ignoredTopic);
                    }}
                  >
                    Restore Topic
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    return (
      <div>
        <button
          className="btn btn-white"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            opacity: "0.5",
          }}
          onClick={this.downloadTopics}
        >
          <i className="fa fa-download" /> Export CSV
        </button>
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="mb-3">
              <div className="d-flex toggle-medium">
                <label
                  className="clickable mr-2 text-bold"
                  htmlFor="group-by-semantic"
                >
                  Group By Theme
                </label>
                <Toggle
                  id="group-by-semantic"
                  checked={groupBySemanticSimilarity}
                  icons={false}
                  onChange={() => {
                    if (enableSortingToggle) {
                      setGroupBySemanticSimiliarity(!groupBySemanticSimilarity);
                    } else {
                      that.enableGroupBySemanticSimilarity();
                    }
                  }}
                />
                <a
                  className="ml-2"
                  data-original-title="This organizes the list into groups of topics that are frequently used together"
                  data-toggle="tooltip"
                  href="#"
                  title=""
                  style={{ color: "inherit" }}
                >
                  <i className="fas fa-info-circle text-bluegray-4" />
                </a>
                {groupBySemanticSimilarityLoading && (
                  <div className="ml-3" style={{ marginTop: "-3px" }}>
                    <Loader type="spinner" className="dark" />{" "}
                    <div
                      className="text-muted"
                      style={{ marginTop: "-30px", marginLeft: "35px" }}
                    >
                      analyzing Topics...{" "}
                      <span className="text-s">(this may take a minute)</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            {_.isEmpty(highlightedTopics) && (
              <button
                className="btn bg-primary-1 hover-bg-primary-2"
                style={{ marginTop: "-5px" }}
                onClick={this.handleAddAllToOutline}
              >
                Add All To Outline
              </button>
            )}
            {!_.isEmpty(highlightedTopics) && (
              <button
                className="btn bg-primary-1 hover-bg-primary-2"
                style={{ marginTop: "-5px" }}
                onClick={this.handleRemoveAllFromOutline}
              >
                Remove All From Outline
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table
              className="table table-padded report-overview-topic-table"
              ref={this.topicRef}
            >
              <colgroup>
                <col span="1" style={{ width: "33%" }} />
                <col span="1" style={{ width: "33%" }} />
                <col span="1" style={{ width: "33%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Key Topics</th>
                  <th>Typical Usage</th>
                  <th>Relevance</th>
                </tr>
              </thead>
              <tbody>{topicRowViews}</tbody>
            </table>
            {!this.props.isAnon && (
              <div
                className="clickable bg-bluegray-1 p-3 text-center"
                onClick={() => {
                  this.setState({ topicsExpanded: !topicsExpanded });
                }}
              >
                {topicsExpanded ? "Minimize Topics" : "Show all topics"}
              </div>
            )}
            {this.props.isAnon && (
              <div
                className="clickable bg-bluegray-1 p-3 text-center"
                onClick={() => {
                  $(".upgradeModal").modal("show");
                }}
              >
                Unlock all topics <i className="far fa-lock-alt" />
              </div>
            )}

            {topicsExpanded &&
              _.isArray(this.props.report.ignored_topics) &&
              this.props.report.ignored_topics.length > 0 &&
              ignoredTopicsView}
          </div>
        </div>
        <AddCustomTopicModal
          show={showAddTopicDialog}
          report={this.props.report}
          onHide={() => {
            that.setState({ showAddTopicDialog: false });
          }}
        />
      </div>
    );
  }
}

export default ReportOverviewTopics;
