import React from "react";
import { connect } from "react-redux";
import { saveOutlineTitle, saveOutlineDescription } from "./redux/actions";

class OutlineEditableField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.text,
      isEditing: false,
    };
    this.discardEdits = this.discardEdits.bind(this);
    this.saveEdits = this.saveEdits.bind(this);
    this.handleGetIdeas = this.handleGetIdeas.bind(this);
    this.handleEnableEditing = this.handleEnableEditing.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.saveEdits);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.saveEdits);
  }

  componentDidUpdate() {
    if (this.state.isEditing) {
      const textarea = $(this.ref.current).find("textarea");
      textarea.trigger("focus");
      textarea.css("height", `${textarea.prop("scrollHeight")}px`);
    } else if (this.props.text !== this.state.text) {
      this.setState({ text: this.props.text });
    }
  }

  discardEdits() {
    this.setState({
      isEditing: false,
      text: this.props.text,
    });
  }

  saveEdits() {
    if (this.state.isEditing) {
      this.props.saveEdits(this.state.text);
      this.setState({
        isEditing: false,
      });
    }
  }

  handleGetIdeas(e, type) {
    e.stopPropagation();
    if (this.state.isEditing) {
      this.saveEdits();
    }
    this.props.onShowPopover(e, type);
  }

  handleEnableEditing(e) {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
    this.props.unselectAllCards();
  }

  render() {
    const that = this;
    const { isEditing, text } = this.state;
    const { label, title } = this.props; // title is passed in as a prop for description since idea button depends on it
    const inputId = `outline-${label}`;
    const isEmpty = _.isEmpty(text);
    const shouldShowContentIdeas =
      isEmpty || text.split(" ").length < 3 || text.length < 15; // only show "rewrite title" if title is substantial enough

    let ideaButton;
    if (label === "Title" && shouldShowContentIdeas) {
      ideaButton = (
        <button
          className="btn btn-sm btn-white text-bluegray-5 builder-idea-btn"
          onClick={(e) => {
            this.handleGetIdeas(e, "contentIdeas");
          }}
        >
          <i className="fas fa-lightbulb" /> Brainstorm Ideas
        </button>
      );
    } else if (label === "Title" && !shouldShowContentIdeas) {
      ideaButton = (
        <button
          className="btn btn-sm btn-white text-bluegray-5 builder-idea-btn"
          onClick={(e) => {
            this.handleGetIdeas(e, "title");
          }}
        >
          <i className="fas fa-lightbulb" /> Rewrite Title
        </button>
      );
    } else if (label === "Description" && _.isEmpty(title)) {
      ideaButton = (
        <div className="d-inline">
          <div
            className="d-inline"
            data-original-title="Add a title first"
            data-toggle="tooltip"
            title=""
          >
            <button
              className="btn btn-sm btn-white text-bluegray-5 builder-idea-btn"
              style={{ opacity: 0.3 }}
            >
              <i className="fas fa-lightbulb" /> Rewrite Description
            </button>
          </div>
        </div>
      );
    } else if (label === "Description" && !_.isEmpty(title)) {
      ideaButton = (
        <button
          className="btn btn-sm btn-white text-bluegray-5 builder-idea-btn"
          onClick={(e) => {
            this.handleGetIdeas(e, "description");
          }}
        >
          <i className="fas fa-lightbulb" />{" "}
          {_.isEmpty(text) ? "Get Description Ideas" : "Rewrite Description"}
        </button>
      );
    }

    return (
      // we stop propagation to prevent the global click listener from automatically saving edits for clicks happening within the component
      <div
        ref={this.ref}
        className="mb-3 builder-card"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <label htmlFor={inputId} className="text-bold">
            {label}
          </label>{" "}
          {ideaButton}
        </div>
        {!isEditing && isEmpty && (
          <div className="d-flex flex-row">
            <div
              className="flex-grow-1 text-bluegray-4 clickable mt-1 mb-2"
              onClick={this.handleEnableEditing}
              style={{
                overflowWrap: "anywhere",
                whiteSpace: "pre-line",
                borderBottom: "1px solid #91a9c3",
              }}
            >
              click to add {label}
            </div>
          </div>
        )}
        {!isEmpty && !isEditing && (
          <div
            className="d-flex flex-row clickable"
            onClick={this.handleEnableEditing}
          >
            <div
              className="flex-grow-1"
              style={{ overflowWrap: "anywhere", whiteSpace: "pre-line" }}
            >
              {text}
            </div>
            <i className="fas fa-pencil-alt clickable" />
          </div>
        )}
        {isEditing && (
          <div className="d-flex flex-row">
            <div className="flex-grow-1 pr-3">
              <textarea
                className="w-100"
                id={inputId}
                cols="28"
                rows="1"
                value={text}
                onChange={(e) => {
                  this.setState({ text: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    that.saveEdits(e);
                  }
                }}
              />
            </div>
            <div>
              <i
                className="fas fa-check pr-2 text-success clickable"
                onClick={this.saveEdits}
              />
              <i
                className="fas fa-times pr-1 clickable"
                onClick={this.discardEdits}
              />
            </div>
          </div>
        )}
        {!isEmpty && (
          <OutlineBuilderCounter
            text={text}
            max={label === "Title" ? 60 : 160}
            min={label === "Title" ? 50 : 50}
          />
        )}
      </div>
    );
  }
}

class OutlineBuilderCounter extends React.Component {
  render() {
    const { text, max, min } = this.props;
    let className = "text-success";
    if (text.length > max) {
      className = "text-warning-dark";
    } else if (text.length < min) {
      className = "text-danger";
    }

    return (
      <div style={{ fontSize: "11px" }} className={className}>
        {text.length}/{max}
      </div>
    );
  }
}

class OutlineBuilderTitleAndDescription extends React.Component {
  render() {
    const { title, description, onShowPopover, unselectAllCards } = this.props;

    return (
      <div>
        <OutlineEditableField
          label="Title"
          unselectAllCards={unselectAllCards}
          onShowPopover={(e, type) => {
            onShowPopover(e, type);
          }}
          saveEdits={(title) => {
            this.props.saveOutlineTitle(this.props.report, title);
          }}
          text={title}
        />
        <OutlineEditableField
          label="Description"
          unselectAllCards={unselectAllCards}
          onShowPopover={(e) => {
            onShowPopover(e, "description");
          }}
          saveEdits={(description) => {
            this.props.saveOutlineDescription(this.props.report, description);
          }}
          text={description}
          title={title}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    title: state.outline.title,
    description: state.outline.description,
  };
  return props;
}

export default connect(mapStateToProps, {
  saveOutlineTitle,
  saveOutlineDescription,
})(OutlineBuilderTitleAndDescription);
