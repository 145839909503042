import React from "react";
import OutlineDescriptionBookmarkButton from "./bookmark_button";

/**
 * Renders a list item for the description bookmarks
 * @param {object} props
 * @returns
 */
function OutlineDescriptionBookmarkListItem({
  report,
  description,
  i,
  handleSelect,
  selectedDescription,
  smallText,
}) {
  const onClick = () => {
    if (handleSelect) {
      handleSelect(description);
    }
  };

  const descriptionIsChosen = selectedDescription === description;
  return (
    <div
      onClick={onClick}
      key={i}
      className={`clickable p-2 pr-4 mt-2 mb-2 builder-popover-card animated fadeUp delay-${i} ${
        smallText ? "text-s" : ""
      } ${descriptionIsChosen ? "text-bold active" : ""}`}
    >
      {description}
      {descriptionIsChosen && <i className="fas fa-check text-success ml-2" />}
      <OutlineDescriptionBookmarkButton
        report={report}
        description={description}
      />
    </div>
  );
}

export default OutlineDescriptionBookmarkListItem;
