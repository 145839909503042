import React from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import {
  addRemoveAlternativeSpellingToExistingTopic,
  showToast,
} from "./redux/actions";

class EditAlternateSpellingModal extends React.Component {
  constructor(props) {
    super(props);
    this.addRemoveAlternateToExistingTopic =
      this.addRemoveAlternateToExistingTopic.bind(this);
  }

  componentDidMount() {
    const that = this;
  }

  addRemoveAlternateToExistingTopic(activeTopic, alternatesArrays, report) {
    const {
      showToast,
      addRemoveAlternativeSpellingToExistingTopic,
      setTopicAlternative,
      onHide,
    } = this.props;
    if (activeTopic) {
      const modifiedSimilarKeywords = [];
      alternatesArrays.map((alternate) => {
        modifiedSimilarKeywords.push(alternate.value);
      });
      activeTopic.similar_keywords = modifiedSimilarKeywords;
      onHide();
      setTopicAlternative([]);
      showToast({
        message: `Changes to your topic have been saved.`,
        options: { appearance: "success", autoDismiss: true },
      });
      addRemoveAlternativeSpellingToExistingTopic(report, activeTopic);
    }
  }

  render() {
    const {
      show,
      onHide,
      activeTopic,
      report,
      topicAlternatives,
      setTopicAlternative,
    } = this.props;
    return (
      <div className="add-new-topic-modal">
        <Modal
          show={show}
          animation={false}
          onHide={() => {
            onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="text-center">Edit Alternate Spelling</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-row">
              <div className="flex-grow-1 p-3">
                <div className="p-1">
                  <label className="text-bold">Alternate Spellings</label>
                  <CreatableSelect
                    isMulti
                    formatCreateLabel={(word) => `Add spelling: ${word}`}
                    onChange={(value) => {
                      if (value) {
                        setTopicAlternative(value);
                      } else {
                        setTopicAlternative([]);
                      }
                    }}
                    value={topicAlternatives}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="btn btn-primary"
              onClick={() => {
                this.addRemoveAlternateToExistingTopic(
                  activeTopic,
                  topicAlternatives,
                  report
                );
              }}
            >
              Save
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps, {
  addRemoveAlternativeSpellingToExistingTopic,
  showToast,
})(EditAlternateSpellingModal);
