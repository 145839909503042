import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOutlineTitleBookmark,
  removeOutlineTitleBookmark,
} from "../../../../../redux/actions";

/**
 * Renders the bookmark icon next to generated/bookmarked titles
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineTitleBookmarkButton({ report, title }) {
  const dispatch = useDispatch();
  const { titleBookmarks } = useSelector((state) => state.outline);

  const titleAlreadyBookmarked = titleBookmarks.includes(title);
  const onClick = (e) => {
    e.stopPropagation();
    if (titleAlreadyBookmarked) {
      dispatch(removeOutlineTitleBookmark(report, title));
    } else {
      dispatch(addOutlineTitleBookmark(report, title));
    }
  };

  return (
    <i
      className={`builder-popover-card-bookmark fa-bookmark ${
        titleAlreadyBookmarked ? "fas" : "far"
      }`}
      onClick={onClick}
    />
  );
}

export default OutlineTitleBookmarkButton;
