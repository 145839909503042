import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HIDE_OUTLINE_BOOKMARKS,
  SHOW_OUTLINE_BOOKMARKS,
} from "../../../redux/actions";

/**
 * OutlineBookmarksToggle
 * Renders the toggle to show/hide the bookmarks
 * @returns {JSX.Element}
 */
function OutlineBookmarksToggle() {
  const dispatch = useDispatch();
  const { showBookmarks } = useSelector((state) => state.outline);

  const toggleBookmarks = () => {
    if (showBookmarks) {
      dispatch({ type: HIDE_OUTLINE_BOOKMARKS });
    } else {
      dispatch({ type: SHOW_OUTLINE_BOOKMARKS });
    }
  };
  return (
    <div className="builder-popover-bookmarks-toggle-container">
      <button
        className={`btn btn-sm  ${
          showBookmarks ? "btn-primary text-white" : "btn-white text-bluegray-5"
        }`}
        onClick={toggleBookmarks}
      >
        <i className={`${showBookmarks ? "fas" : "far"} fa-bookmark`} />{" "}
        Bookmarks
      </button>
    </div>
  );
}

export default OutlineBookmarksToggle;
