import React from "react";
import { Line } from "react-chartjs-2";
import Loader from "./loader";
import { formattedNumber } from "./helpers";

class KeywordsDashboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serp: null,
      isLoading: false,
    };

    this.handleSeeCompetitors = this.handleSeeCompetitors.bind(this);
    this.searchKeywordSERP = this.searchKeywordSERP.bind(this);
    this.kdToHardness = this.kdToHardness.bind(this);
    this.kdToColor = this.kdToColor.bind(this);
    this.isPositivelyTrending = this.isPositivelyTrending.bind(this);
    this.node = React.createRef();
  }

  componentDidMount() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  searchKeywordSERP() {
    const that = this;
    const { serp } = this.state;
    const { keyword, location } = this.props;
    if (!serp) {
      this.setState({ isLoading: true });
      tanalytics.track("Keyword SERP Searched", { keyword: keyword.keyword });
      fetch(
        `/api/keyword_research_serp/${encodeURIComponent(
          keyword.keyword
        )}?location=${location}`,
        {
          credentials: "same-origin",
        }
      )
        .then((response) => response.json())
        .then((response) => {
          that.setState({ serp: response, isLoading: false });
        });
    }
  }

  kdToHardness(kd) {
    var kd = parseFloat(kd);
    if (kd > 0.9) {
      return "High";
    }
    if (kd > 0.79) {
      return "High";
    }
    if (kd > 0.66) {
      return "High";
    }
    if (kd > 0.33) {
      return "Medium";
    }
    return "Low";
  }

  kdToColor(kd) {
    var kd = parseFloat(kd);
    if (kd > 0.9) {
      return "primary";
    }
    if (kd > 0.79) {
      return "primary";
    }
    if (kd > 0.66) {
      return "primary";
    }
    if (kd > 0.33) {
      return "primary";
    }
    return "primary";
  }

  shortenURL(urlString) {
    const maxLength = 25;
    const url = new URL(urlString).host;
    return url.replace(/^www./, "").slice(0, maxLength);
  }

  handleSeeCompetitors() {
    const { isLoading } = this.state;
    const { index, toggleActiveKeywordIndex, isActive } = this.props;
    if (!isLoading) {
      if (!isActive) {
        this.searchKeywordSERP();
      }
      toggleActiveKeywordIndex(index);
    }
  }

  isPositivelyTrending(trend) {
    // take a look at the diff between each number
    const diffs = [];
    _.each(trend, (point, i) => {
      if (!_.isUndefined(trend[i + 1])) {
        diffs.push(trend[i + 1] - point);
      }
    });

    // not positively trending if not enough data
    if (trend.length < 4) {
      return false;
    }

    // not positively trending if more than one diff is negative
    if (_.filter(diffs, (d) => d < 0).length > 1) {
      return false;
    }

    // not positively trending if first point is greater than zero and last point is not double the first point
    if (_.head(trend) > 0 && _.last(trend) / _.head(trend) < 2) {
      return false;
    }

    // not positively trending if first and last data is zero
    if (_.head(trend) <= 0 && _.last(trend) <= 0) {
      return false;
    }

    return true;
  }

  render() {
    const that = this;

    const {
      keyword,
      volumePercent,
      handleSearchKeyword,
      handleCreateBrief,
      isActive,
      locationKeywordReport,
    } = this.props;
    const { serp, isLoading } = this.state;

    const lookAtLastNMonths = 6;
    const kdi = keyword.competition;
    const { cpc } = keyword;
    const searches = _.takeRight(
      _.map(keyword.trend, "value"),
      lookAtLastNMonths
    );
    const isTrending = this.isPositivelyTrending(searches);
    const monthlySearchData = {
      labels: _.range(searches.length),
      datasets: [
        {
          data: searches,
          fill: true,
          backgroundColor: "#e9ecef",
          borderColor: "rgba(35, 123, 230, .5)",
          pointRadius: 0,
          pointHoverRadius: 0,
        },
      ],
    };
    const lineOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            display: false,
          },
        ],
        xAxes: [
          {
            display: false,
          },
        ],
      },
      layout: {
        padding: {
          top: 5,
        },
      },
    };
    const row = (
      <tr
        className={`white-row hoverable-row clickable keyword-research-table-row ${
          isActive ? "active bg-bluegray-1" : ""
        }`}
        onClick={this.handleSeeCompetitors}
        style={{ transform: "none" }}
      >
        <td
          className="text-bold clickable"
          onClick={(e) => {
            e.stopPropagation();
            handleSearchKeyword(keyword.keyword);
          }}
        >
          {keyword.keyword}
        </td>
        <td className="d-none">
          {cpc && parseInt(cpc.value, 10) !== 0 ? (
            <div>${parseInt(cpc.value, 10)}</div>
          ) : (
            "-"
          )}
        </td>
        <td className="d-none">
          {kdi ? <div>{that.kdToHardness(kdi)}</div> : "-"}
        </td>
        <td>
          <div className="progress">
            <div
              aria-valuemax="100"
              aria-valuemin="0"
              aria-valuenow={`${volumePercent}`}
              className="progress-bar bg-bluegray-4"
              role="progressbar"
              style={{ width: `${volumePercent}%`, minWidth: "15%" }}
            >
              {keyword.vol === 0 ? "< 10" : formattedNumber(keyword.vol, 3)}
            </div>
          </div>
          <div className="progress-alternate">
            {keyword.vol === 0 ? "< 10" : formattedNumber(keyword.vol, 3)}
          </div>
        </td>
        <td style={{ padding: 0 }}>
          <div style={{ position: "relative" }}>
            <div style={{ padding: "0.9rem 1.1rem" }}>
              <div
                className="keyword-research-row-trends"
                style={{ position: "relative", height: "30px", width: "100px" }}
              >
                <Line data={monthlySearchData} options={lineOptions} />
              </div>
              {isTrending && (
                <div
                  className="badge text-green-8 bg-green-1"
                  style={{ position: "absolute", top: "5px", fontSize: "10px" }}
                >
                  <i className="far fa-chart-line mr-1" />
                  trending
                </div>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className="keyword-research-row-actions text-right d-flex">
            <button
              className="btn bg-primary-1 hover-bg-primary-2 mr-2"
              onClick={this.handleSeeCompetitors}
            >
              {isActive ? "Minimize SERP" : "Preview SERP"}
            </button>{" "}
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.stopPropagation();
                handleCreateBrief(keyword.keyword, locationKeywordReport);
              }}
            >
              Create Brief
            </button>
          </div>
        </td>
      </tr>
    );

    if (isActive || isLoading) {
      let serpTable;
      if (serp) {
        serpTable = (
          <table className="keyword-research-serp-table">
            <thead>
              <tr>
                <th>Position</th>
                <th>Content</th>
                <th
                  data-toggle="tooltip"
                  data-html="true"
                  data-original-title="Domain Authority (higher is more authoritative)<br /> source: <img src='/img/moz-logo-blue.svg' height='18px' />"
                >
                  <a href="https://moz.com" target="_blank" rel="noreferrer">
                    DA
                  </a>
                </th>
                <th
                  data-toggle="tooltip"
                  data-html="true"
                  data-original-title="Page Authority (higher is more authoritative)<br /> source: <img src='/img/moz-logo-blue.svg' height='18px' /> "
                >
                  <a href="https://moz.com" target="_blank" rel="noreferrer">
                    PA
                  </a>
                </th>
                <th
                  data-toggle="tooltip"
                  data-original-title="Number of unique domains linking to this page"
                >
                  Backlinks
                </th>
                <th
                  data-toggle="tooltip"
                  data-original-title="Word count of the page"
                >
                  WC
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(serp.results, (item, index) => {
                console.log(item);
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="p-1 bg-bluegray-2 text-center"
                        style={{
                          borderRadius: "60px",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        {item.position}
                      </div>
                    </td>
                    <td>
                      <div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.title}
                              </a>
                            </div>
                            <div className="text-s">
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {this.shortenURL(item.link)}
                              </a>{" "}
                              {item.position === 0 && (
                                <div className="text-s badge bg-bluegray-2 ml-2 mr-0">
                                  <i className="fa fa-book" /> featured snippet
                                </div>
                              )}
                            </div>
                          </div>
                          {_.isString(item.thumbnail) &&
                            !_.includes(item.thumbnail, "loading") && (
                              <div>
                                <div
                                  style={{
                                    width: "100px",
                                    height: "60px",
                                    backgroundImage: `url(${item.thumbnail})`,
                                    backgroundSize: "cover",
                                    borderRadius: "3px",
                                  }}
                                />
                              </div>
                            )}
                        </div>
                        {item.snippet && _.isString(item.snippet) && (
                          <div className="text-s mt-1">{item.snippet}</div>
                        )}
                      </div>
                    </td>
                    <td>
                      {!_.isNumber(item.domain_score) ? (
                        "-"
                      ) : (
                        <div>{item.domain_score}</div>
                      )}
                    </td>
                    <td>
                      {!_.isNumber(item.page_score) ? (
                        "-"
                      ) : (
                        <div>{item.page_score}</div>
                      )}
                    </td>
                    <td>
                      {!_.isNumber(item.page_linking_domains) ? (
                        "-"
                      ) : (
                        <div>{formattedNumber(item.page_linking_domains)}</div>
                      )}
                    </td>
                    <td>
                      {!item.word_count ? "-" : <div>{item.word_count}</div>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }

      let serpHasThumbnail = false;
      if (serp && serp.results) {
        _.each(serp.results, (item) => {
          if (_.isString(item.thumbnail)) {
            serpHasThumbnail = true;
          }
        });
      }

      const detailRow = (
        <tr className="white-row bg-bluegray-1" ref={this.node}>
          <td colSpan="5">
            {!isLoading && serp && (
              <div>
                <h5 className="mt-2 mb-4">
                  Organic results for "{keyword.keyword}"
                </h5>
                <div className="keyword-research-serp-features mb-3">
                  <span
                    data-toggle="tooltip"
                    data-original-title="These are unique aspects of this search results page that can help indicate the kind of content searchers are expecting."
                  >
                    SERP Features{" "}
                    <i className="fas fa-info-circle text-bluegray-4 text-s" />
                  </span>
                  :
                  <div
                    className={`keyword-research-serp-feature ${
                      !_.isEmpty(serp.inline_images) || serpHasThumbnail
                        ? "active"
                        : ""
                    }`}
                    data-toggle={
                      !_.isEmpty(serp.inline_images) || serpHasThumbnail
                        ? "tooltip"
                        : ""
                    }
                    data-original-title="Google is showing an image gallery of thumbnail images in the results, indicating that users are looking for visual content. You should plan on adding visual content to your content."
                  >
                    <i className="fas fa-image-polaroid" /> Images
                  </div>
                  <div
                    className={`keyword-research-serp-feature ${
                      !_.isEmpty(serp.inline_videos) ? "active" : ""
                    }`}
                    data-toggle={
                      !_.isEmpty(serp.inline_videos) ? "tooltip" : ""
                    }
                    data-original-title="Google is showing videos in the search results. You should consider adding a video to your content."
                  >
                    <i className="fab fa-youtube" /> Video
                  </div>
                  <div
                    className={`keyword-research-serp-feature ${
                      !_.isEmpty(serp.shopping_results) ||
                      !_.isEmpty(serp.inline_products) ||
                      !_.isEmpty(serp.refine_by_brand) ||
                      !_.isEmpty(serp.refine_by_department)
                        ? "active"
                        : ""
                    }`}
                    data-toggle={
                      !_.isEmpty(serp.shopping_results) ||
                      !_.isEmpty(serp.inline_products) ||
                      !_.isEmpty(serp.refine_by_brand) ||
                      !_.isEmpty(serp.refine_by_department)
                        ? "tooltip"
                        : ""
                    }
                    data-original-title="Google is showing an ecommerce widget in the search results. Check the search results carefully to see whether they match the content you intend to create."
                  >
                    <i className="fas fa-shopping-cart" /> Shopping
                  </div>
                  <div
                    className={`keyword-research-serp-feature ${
                      !_.isEmpty(serp.local_map) ||
                      !_.isEmpty(serp.local_results)
                        ? "active"
                        : ""
                    }`}
                    data-toggle={
                      !_.isEmpty(serp.local_map) ||
                      !_.isEmpty(serp.local_results)
                        ? "tooltip"
                        : ""
                    }
                    data-original-title="Google is showing local results in the search results, indicating that some users are interested in local information."
                  >
                    <i className="fas fa-map-marker-alt" /> Local
                  </div>
                  <div
                    className={`keyword-research-serp-feature ${
                      !_.isEmpty(serp.top_stories) ? "active" : ""
                    }`}
                    data-toggle={!_.isEmpty(serp.top_stories) ? "tooltip" : ""}
                    data-original-title="Google is showing news articles in the search results. You should consider a different keyword unless you are writing a news article."
                  >
                    <i className="far fa-newspaper" /> News
                  </div>
                </div>
                {serpTable}
              </div>
            )}
            {isLoading && (
              <div className="w-100 text-center p-3">
                <Loader type="spinner" className="dark p-2" />
              </div>
            )}
          </td>
        </tr>
      );
      return (
        <>
          {row}
          {detailRow}
        </>
      );
    }
    return row;
  }
}

export default KeywordsDashboardRow;
