import { RECEIVE_REVISIONS, ADD_REVISIONS, CLEAR_REVISIONS } from "../actions";

export function revisions(state = [], action) {
  switch (action.type) {
    case RECEIVE_REVISIONS: {
      let newState = [...state, ...action.revisions]; // Append to the state

      // Filtering Unique Revisions for edge cases, Where duplicate might occur
      const hashState = {};
      newState = newState.filter((revision) => {
        if (!hashState[revision.rev_id]) {
          hashState[revision.rev_id] = true;
          return revision;
        }
      });
      return newState;
    }
    case ADD_REVISIONS: {
      return [action.revision, ...state];
    }
    case CLEAR_REVISIONS: {
      return [];
    }
    default: {
      return state;
    }
  }
}

export default revisions;
