import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOutlineDescriptionBookmark,
  removeOutlineDescriptionBookmark,
} from "../../../../../redux/actions";

/**
 * Renders the bookmark icon next to generated/bookmarked descriptions
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineDescriptionBookmarkButton({ report, description }) {
  const dispatch = useDispatch();
  const { descriptionBookmarks } = useSelector((state) => state.outline);

  const descriptionAlreadyBookmarked =
    descriptionBookmarks.includes(description);
  const onClick = (e) => {
    e.stopPropagation();
    if (descriptionAlreadyBookmarked) {
      dispatch(removeOutlineDescriptionBookmark(report, description));
    } else {
      dispatch(addOutlineDescriptionBookmark(report, description));
    }
  };

  return (
    <i
      className={`builder-popover-card-bookmark fa-bookmark ${
        descriptionAlreadyBookmarked ? "fas" : "far"
      }`}
      onClick={onClick}
    />
  );
}

export default OutlineDescriptionBookmarkButton;
