import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DNDTypes } from "./dndTypes";

class OutlineBuilderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.type != "link" ? props.text : "", // a local version of the text for use when editing,
      url: "",
    };
    this.ref = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.saveEdits = this.saveEdits.bind(this);
    this.discardEdits = this.discardEdits.bind(this);
    this.removeCard = this.removeCard.bind(this);
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
      });
    }

    if (this.props.editing) {
      if (this.props.type != "link") {
        $(this.ref.current).find("textarea").trigger("focus");
      } else {
        $(this.ref.current).find("textarea").first().trigger("focus");
      }
    }

    window.addEventListener("click", this.saveEdits);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.saveEdits);
  }

  componentDidUpdate(prevProps) {
    const isEditingCard = this.props.editing;
    const isLinkCard = this.props.type === "link";
    if (isEditingCard) {
      if (!isLinkCard) {
        const textarea = $(this.ref.current).find("textarea");
        textarea.trigger("focus");
        textarea.css("height", `${textarea.prop("scrollHeight")}px`);
      } else {
        const textAreas = $(this.ref.current).find("textarea");
        textAreas.each((index) => {
          const textarea = $(this.ref.current).find("textarea").eq(index);
          textarea.css("height", `${textarea.prop("scrollHeight")}px`);
        });
      }
    } else if (
      this.props.text !== this.state.text ||
      (isLinkCard && this.props.url !== this.state.url)
    ) {
      const newState = { text: this.props.text };
      if (isLinkCard) {
        newState.url = this.props.url;
      }
      this.setState(newState);
    }

    if (prevProps.editing && !this.props.editing) {
      // discard edits when editing is turned off from redux
      this.setState({ text: "" });
    }
  }

  handleClick(e) {
    if (this.props.selectable) {
      this.props.selectCard(
        this.props.index,
        e.ctrlKey || e.metaKey,
        e.shiftKey
      );
    }
  }

  handleDoubleClick(e) {
    e.stopPropagation();
    if (!this.props.editing && !this.props.disableOutlineFeatures) {
      this.props.unselectAllCards();
      this.props.editCard(this.props.index, { selected: false, editing: true });
    }
  }

  toggleEdit(e) {
    e.stopPropagation();
    this.props.unselectAllCards();
    this.props.editCard(this.props.index, {
      selected: false,
      editing: !this.props.editing,
    });
  }

  saveEdits(e) {
    e.stopPropagation();
    if (this.props.editing) {
      if (
        (this.state.text.trim() === "" && this.props.text.trim() === "") ||
        (this.props.type === "link" &&
          this.state.url.trim() === "" &&
          this.props.url.trim() === "")
      ) {
        this.discardEdits(e);
      } else {
        this.props.editCard(this.props.index, {
          selected: true,
          editing: false,
          text: this.state.text,
          url: this.state.url,
          id: this.props.id,
        });
      }
    }
  }

  discardEdits(e) {
    e.stopPropagation();
    this.setState({
      text: this.props.text,
    });
    this.props.editCard(this.props.index, { editing: false });
  }

  removeCard(e) {
    e.stopPropagation();
    this.props.unselectAllCards();
    this.props.selectCard(this.props.index, false, false);
    this.props.removeCards(this.props.report);
    this.props.unselectAllCards();
  }

  urlToDomain(url) {
    try {
      return new URL(url).host.replace("www.", "");
    } catch (e) {
      return url;
    }
  }

  validUrlLink(url) {
    return url;
    // try{
    //   var prefix = 'http://';
    //   if (url.substr(0, prefix.length) !== prefix){url = prefix + url;}
    //   return url;
    // } catch(e){
    //   return url;
    // }
  }

  render() {
    const that = this;
    const {
      text,
      type,
      indent,
      index,
      isDragging,
      connectDragSource,
      connectDropTarget,
      selected,
      editing,
      url,
    } = this.props;

    const style = {
      padding: "0.5rem 0",
      cursor: "move",
    };

    const opacity = isDragging ? 0 : 1;
    const backgroundColor = selected ? "#ffff0038" : "#ffffff";
    const isLink = type === "link";

    // https://github.com/react-dnd/react-dnd/issues/260 (know issue for FIREFOX, fixing for PENG2-2013)
    if (editing) {
      connectDragSource(false);
    } else {
      connectDragSource(this.ref);
    }

    connectDropTarget(this.ref);

    // type - heading, point, custom, loading

    return (
      <div
        className={`builder-card builder-card-indent-${indent}`}
        ref={this.ref}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
      >
        <div style={{ ...style, opacity, backgroundColor }}>
          {type === "loading" && (
            <div className="d-flex flex-row">
              <div className="builder-card-shimmer">
                <div className="builder-card-shimmer-inner" />
              </div>
            </div>
          )}
          {type !== "loading" && !editing && (
            <div className="d-flex flex-row">
              {type !== "point" && type != "link" && indent < 3 && (
                <i
                  className={`far fa-h${indent + 2} mr-2`}
                  style={{ lineHeight: 1.5, opacity: 0.6 }}
                />
              )}
              {type === "point" && <i className="fas fa-circle" />}
              {type === "link" && (
                <i
                  className="fas fa-link mr-2"
                  style={{ lineHeight: 1.5, opacity: 0.6 }}
                />
              )}
              <div
                className="flex-grow-1"
                style={{ overflowWrap: "anywhere", whiteSpace: "pre-line" }}
              >
                {text}{" "}
                {isLink && (
                  <>
                    -{" "}
                    <a
                      href={that.validUrlLink(url)}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      rel="noreferrer"
                    >
                      {that.urlToDomain(url)}{" "}
                      <i className="fa fa-external-link" />
                    </a>
                  </>
                )}
              </div>
              <i
                className={`fas fa-trash mr-2 clickable ${
                  selected ? "d-none" : "d-none"
                }`}
                onClick={this.removeCard}
                data-original-title="Remove"
                data-toggle="tooltip"
              />
              <i
                className="fas fa-pencil-alt clickable"
                onClick={this.toggleEdit}
                data-original-title="Edit<br /><span style='font-size:8px'>Shortcut: Shift+Enter</span>"
                data-html="true"
                data-toggle="tooltip"
              />
            </div>
          )}
          {editing && (
            <div
              className="d-flex flex-row"
              onClick={(e) => {
                e.stopPropagation(); // prevent click events in input from triggering global save event
              }}
            >
              {type != "link" ? (
                <div className="flex-grow-1 pr-3">
                  <textarea
                    className="w-100"
                    cols="28"
                    rows="1"
                    value={this.state.text}
                    onChange={(e) => {
                      this.setState({ text: e.target.value });
                    }}
                    placeholder="add text here"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        that.saveEdits(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      // react doesn't fire escape on keypress apparently
                      if (e.key === "Escape" || e.key === "Esc") {
                        that.discardEdits(e);
                      }
                    }}
                  />
                </div>
              ) : (
                <div className="flex-grow-1 pr-3">
                  <textarea
                    className="w-100"
                    cols="28"
                    rows="1"
                    value={this.state.text}
                    onChange={(e) => {
                      this.setState({ text: e.target.value });
                    }}
                    placeholder="add text here"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        that.saveEdits(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      // react doesn't fire escape on keypress apparently
                      if (e.key === "Escape" || e.key === "Esc") {
                        that.discardEdits(e);
                      }
                    }}
                  />
                  <textarea
                    className="w-100 text-blue-5"
                    cols="28"
                    rows="1"
                    value={this.state.url}
                    onChange={(e) => {
                      this.setState({ url: e.target.value });
                    }}
                    placeholder="url"
                    style={{ fontSize: "12px" }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        that.saveEdits(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      // react doesn't fire escape on keypress apparently
                      if (e.key === "Escape" || e.key === "Esc") {
                        that.discardEdits(e);
                      }
                    }}
                  />
                </div>
              )}
              <div>
                <i
                  className="fas fa-check pr-2 text-success clickable"
                  onClick={this.saveEdits}
                />
                <i
                  className="fas fa-times pr-1 clickable"
                  onClick={this.discardEdits}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const dropSpec = {
  hover: (props, monitor, component) => {
    if (!component) {
      return null;
    }
    // node = HTML Div element from imperative API
    const node = component.ref.current;
    if (!node) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = node.getBoundingClientRect();
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    // Time to actually perform the action
    if (_.isFunction(props.moveCard)) {
      props.moveCard(dragIndex, hoverIndex); // this is a method defined in redux
    }

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
  drop: (props, monitor, component) => {},
};

const dragSpec = {
  beginDrag: (props, monitor, component) => {
    AP.cardDragSelected = props.selected;
    return {
      props,
      ref: component.ref,
      index: props.index,
      id: props.id,
    };
  },
  isDragging: (props, monitor) =>
    // If your component gets unmounted while dragged
    // (like a card in Kanban board dragged between lists)
    // you can implement something like this to keep its
    // appearance dragged:
    monitor.getItem().props.id === props.id,
  canDrag: (props, monitor, component) => props.draggable,
  endDrag: (props, monitor, component) => {
    // using this instead of drop, because drop is not guaranteed to be called if not on a valid droptarget
    const dragIndex = monitor.getItem().index;
    if (AP.cardDragSelected) {
      // using a global because props is not what you would expect
      // drop a selected card -> move all selected cards
      if (_.isFunction(props.moveSelectedCards)) {
        props.moveSelectedCards(dragIndex);
      }
    }
    props.dropCard();
  },
};

export default DropTarget(DNDTypes.CARD, dropSpec, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource(DNDTypes.CARD, dragSpec, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(OutlineBuilderCard)
);
