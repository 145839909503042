import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import Select from "react-select";
import { DebounceInput } from "react-debounce-input";
import ReactPaginate from "react-paginate";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import ReportTagModal from "./report_tag_modal";
import Loader from "./loader";
import {
  dateToPrettyString,
  gradeToColor,
  timeAgo,
  getUrlParam,
  downloadCSV,
} from "./helpers";
import {
  fetchReports,
  invalidateReports,
  setActiveReport,
  saveReport,
  deleteArchiveReport,
  deleteReport,
  refreshReport,
  updateReportLocally,
  clearRevisions,
} from "./redux/actions";

class ReportsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        user_id: "all",
        search: "",
      }, // ex: {search: "foo", user_id: 1} - applies additional filters to the briefs
      sort: "created_at",
      order: "desc",
      offset: 0,
      reportIdSelectedForDeletion: "",
      refreshInProgress: {},
      exportBriefsInProgress: false,
    };

    this.defaultState = { ...this.state };

    const filtersInURL = this.extractFilterFromURL();
    if (!_.isEmpty(filtersInURL)) {
      this.state = {
        ...this.state,
        filter: filtersInURL.filter,
        sort: filtersInURL.sort,
        order: filtersInURL.order,
      };
    }

    this.node = React.createRef();
    this.handleArchiveReport = this.handleArchiveReport.bind(this);
    this.handleUnarchiveReport = this.handleUnarchiveReport.bind(this);
    this.handleDeleteArchiveReport = this.handleDeleteArchiveReport.bind(this);
    this.fetchReports = this.fetchReports.bind(this);
    this.startReportStatusPollIfNeeded =
      this.startReportStatusPollIfNeeded.bind(this);
    this.handleSortClick = this.handleSortClick.bind(this);
    this.updateURLwithFilter = this.updateURLwithFilter.bind(this);
    this.extractFilterFromURL = this.extractFilterFromURL.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.getToExpireDays = this.getToExpireDays.bind(this);
    this.handleDeleteReport = this.handleDeleteReport.bind(this);
    this.handleReportRefresh = this.handleReportRefresh.bind(this);
    this.exportAllBriefs = this.exportAllBriefs.bind(this);
  }

  fetchReports() {
    const { tag } = this.props.match.params;
    const { filter, sort, order, offset } = this.state;
    const limit = this.props.perPage;
    if (_.isString(tag)) {
      this.props.fetchReports({ tag, filter, sort, order, offset, limit });
    } else {
      this.props.fetchReports({ filter, sort, order, offset, limit });
    }
  }

  componentDidMount() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip({
      container: this.node.current,
    });
    this.fetchReports();
  }

  componentWillUnmount() {
    // Clear the state of Revisions once we hit dashboard
    this.props.clearRevisions();
  }

  componentDidUpdate(prevProps, prevState) {
    const newTag = this.props.match.params.tag;
    const hasNewTag = prevProps.match.params.tag !== newTag;
    const hasNewFilter = !_.isEqual(prevState.filter, this.state.filter);
    const hasNewSort =
      prevState.sort !== this.state.sort ||
      prevState.order !== this.state.order;
    if (hasNewTag || hasNewFilter || hasNewSort) {
      this.fetchReports();
      this.updateURLwithFilter();
    }

    $(".tooltip").remove();
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip({
      container: this.node.current,
    });
    this.startReportStatusPollIfNeeded();
  }

  // store the filter as a param in the URL
  updateURLwithFilter() {
    const { filter, sort, order } = this.state;
    const isInDefaultState =
      _.isEqual(filter, this.defaultState.filter) &&
      _.isEqual(sort, this.defaultState.sort) &&
      _.isEqual(order, this.defaultState.order);
    if (isInDefaultState) {
      this.props.history.push(this.props.location.pathname);
    } else {
      const filterParam = { filter, sort, order };
      this.props.history.push(
        `${this.props.location.pathname}?filters=${encodeURIComponent(
          JSON.stringify(filterParam)
        )}`
      );
    }
  }

  // if there is a filter in the URL, return it
  extractFilterFromURL() {
    try {
      return JSON.parse(decodeURIComponent(getUrlParam("filters")));
    } catch (e) {
      return undefined;
    }
  }

  handleDeleteArchiveReport(id) {
    const that = this;
    this.props
      .deleteArchiveReport(id)
      .then((response) => response.json())
      .then(() => {
        that.props.invalidateReports();
        that.fetchReports();
      });
  }

  handleArchiveReport(id) {
    const that = this;
    this.props.saveReport({ id, mode: "ARCHIVED" }).then(() => {
      that.props.invalidateReports();
      that.fetchReports();
    });
  }

  handleUnarchiveReport(id) {
    const that = this;
    this.props.saveReport({ id, mode: "PRIVATE" }).then(() => {
      that.props.invalidateReports();
      that.fetchReports();
    });
  }

  handleSortClick(newSort) {
    const { sort, order } = this.state;
    if (newSort === sort) {
      this.setState({
        order: order === "desc" ? "asc" : "desc",
      });
    } else {
      // name gets default asc sort
      this.setState({
        sort: newSort,
        order: newSort === "name" ? "asc" : "desc",
      });
    }
  }

  handleDeleteReport() {
    const that = this;
    if (
      AP.currentUser.delete_credits_remaining > 0 &&
      this.state.reportIdSelectedForDeletion != ""
    ) {
      this.props
        .deleteReport(this.state.reportIdSelectedForDeletion)
        .then((response) => response.json())
        .then(() => {
          this.setState({ reportIdSelectedForDeletion: "" });
          AP.currentUser.delete_credits_remaining -= 1;
          that.props.invalidateReports();
          that.fetchReports();
        });
    }
  }

  handleReportRefresh(id) {
    const that = this;
    this.setState(
      (prevState) => ({
        refreshInProgress: {
          // object that we want to update
          ...prevState.refreshInProgress, // keep all other key-value pairs
          [id]: true, // update the value of specific key
        },
      }),
      () => {
        this.props
          .refreshReport(id)
          .then((response) => response.json())
          .then(() => {
            that.props.invalidateReports();
            that.fetchReports();
          });
      }
    );
  }

  handlePageClick(data) {
    const { selected } = data;
    const offset = Math.ceil(selected * this.props.perPage);

    this.setState({ offset }, () => {
      this.fetchReports();
    });
  }

  getToExpireDays(endDate) {
    const one_day = 1000 * 60 * 60 * 24;
    const today = new Date();
    const expirationDate = new Date(endDate.replace(/-/g, "/"));

    return Math.ceil((expirationDate.getTime() - today.getTime()) / one_day);
  }

  startReportStatusPollIfNeeded() {
    try {
      const pollInterval = 7000;
      const that = this;
      if (this.reportStatusPollInterval) {
        window.clearInterval(this.reportStatusPollInterval);
      }
      // check to see if any of the reports have a non-Success status
      let { pendingReports } = this.props;
      if (_.isArray(pendingReports) && pendingReports.length > 0) {
        this.reportStatusPollInterval = window.setInterval(() => {
          fetch(
            `/api/keyword_reports/status/${_.map(pendingReports, "id").join(
              ","
            )}`,
            {
              credentials: "same-origin",
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
            .then((response) => response.json())
            .then((response) => {
              if (_.isArray(response)) {
                // update pending reports with new statuses
                pendingReports = _.map(pendingReports, (pendingReport) => {
                  // find the corresponding status in the response
                  // which looks like [[199, "Success"],[198, "Success"]]
                  const newStatusArr = _.find(
                    response,
                    (statusItem) => statusItem[0] === pendingReport.id
                  );
                  if (_.isArray(newStatusArr) && newStatusArr.length === 2) {
                    pendingReport.status = newStatusArr[1];
                  }
                  // After getting the first poll request from server about the brief that has been
                  // refreshed we remove the refresh in process flag
                  if (
                    that.state.refreshInProgress.hasOwnProperty(
                      pendingReport.id
                    ) &&
                    pendingReport.status != "Error"
                  ) {
                    const refreshInProgress = {
                      ...that.state.refreshInProgress,
                    };
                    delete refreshInProgress[pendingReport.id];
                    that.setState({ refreshInProgress });
                  }

                  return pendingReport;
                });
                that.props.updateReportLocally(pendingReports);
              } else {
                console.log(
                  "something went wrong polling the status",
                  response
                );
              }
            });
        }, pollInterval);
      }

      // check to see if any of the reports have a non-Success status and are OLD
      const oldPendingReports = _.filter(this.props.reports, (report) => {
        const timeElapsed =
          new Date().getTime() - new Date(report.created_at).getTime();
        const olderThanTenMinutes = timeElapsed > 60 * 10 * 1000;
        return report.status !== "Success" && olderThanTenMinutes;
      });
      if (_.isArray(oldPendingReports) && oldPendingReports.length > 0) {
        const reportString = _.map(
          oldPendingReports,
          (r) => `["${r.name}", ${r.id}]`
        ).join(", ");
        throw `Stagnant brief${oldPendingReports.length > 1 ? "s" : ""} for ${
          AP.currentUser.email
        }(${AP.currentUser.id}) - ${reportString}`;
      }
    } catch (e) {
      AP.reportError(e);
    }
  }

  exportAllBriefs() {
    const currentUrl = new URL(window.location.href).origin;
    const csvData = [
      [
        "Focus Keyword",
        "Link",
        "Grade",
        "Created By",
        "Type",
        "Created at",
        "Last Modifed",
        "Tags",
      ],
    ];
    this.setState({ exportBriefsInProgress: true }, () => {
      fetch(`/api/keyword_reports/export`, {
        credentials: "same-origin",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            this.setState({ exportBriefsInProgress: false });
            return;
          }
          this.setState({ exportBriefsInProgress: false }, () => {
            response.map((brief) => {
              const currentBriefLink = `${currentUrl}/briefs/${brief.id}/overview`;
              csvData.push([
                brief.name.replace(",", " "), // Focus Keyword
                currentBriefLink, // Brief Link
                brief.grade, // Brief Grade
                brief.email, // Created by Email
                brief.mode, // Public || Private || Archived
                new Date(brief.created_at).toLocaleString().replace(",", " "), // Created At
                new Date(brief.updated_at).toLocaleString().replace(",", " "), // Updated At
                brief.tags && brief.tags.join(" ").replace(",", " "), // Tags
              ]);
            });
            downloadCSV(
              `briefs_details_${new Date().toLocaleString()}.csv`,
              csvData
            );
          });
        });
    });
  }

  render() {
    const that = this;
    const { reports, pendingReports, tags, users, briefsRemaining } =
      this.props;
    const { filter, sort, order, exportBriefsInProgress } = this.state;
    const emptyState = (
      <div className="row">
        <div className="col-md-4 offset-4 text-center">
          <img
            className="mt-5 mb-3"
            src="/img/logo_large_icon.png"
            style={{ width: "100px" }}
          />
          <h1>Welcome to Topic</h1>
          <p className="text-l mb-5 mt-2">
            We're excited to help you write more comprehensive content. To get
            started, click the button below to create your first content brief.
          </p>
          <button
            className="btn btn-primary btn-lg"
            onClick={() => {
              $(".reportReportModal").modal("show");
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    );

    const showEmptyState = !_.isArray(reports) || reports.length === 0;
    const showTags = tags.length > 0;
    const tagSelectOptions = _.map(tags, (tag) => ({ value: tag, label: tag }));
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const resetMonth = monthNames[(new Date().getMonth() + 1) % 12];
    const showEmail = users.length > 1;
    const cols = showEmail ? 6 : 5;
    const emailSelectOptions = _.concat(
      [{ value: "all", label: "All Users" }],
      _.map(users, (user) => ({ value: user[0], label: user[1] }))
    );
    const pageCount = Math.ceil(this.props.total / this.props.perPage);
    const isOnPaidTrial =
      !AP.currentUser.trialExpired &&
      AP.currentUser.plan &&
      AP.currentUser.plan.associated_role == "ptrial";

    if (
      !isOnPaidTrial &&
      !AP.currentUser.cancelled &&
      !AP.currentUser.trialExpired &&
      briefsRemaining <= 2
    ) {
      // tanalytics.track('Credits Depleted');
    }

    let tableColumns = [
      ["Created", "created_at"],
      ["Last Modified", "updated_at"],
      ["Focus Keyword", "name"],
      ["Content Grade", "grade"],
    ];
    if (showEmail) {
      tableColumns = [
        ["Created", "created_at"],
        ["Last Modified", "updated_at"],
        ["Created By", "user_id"],
        ["Focus Keyword", "name"],
        ["Content Grade", "grade"],
      ];
    }
    const deletionPopover = (
      <Popover id="popover-basic" className="popover-small">
        <Popover.Title as="h4">
          Made a mistake when creating a brief?
        </Popover.Title>
        <Popover.Content>
          <p>
            You can permanently delete up to 3 briefs in a billing cycle (
            {AP.currentUser.delete_credits_remaining} remaining). Deleting a
            brief will restore a brief credit. Are you sure you want to delete
            this one?
          </p>
          <div>
            <button
              className="btn btn-danger mr-1 btn-sm"
              onClick={() => {
                this.handleDeleteReport();
                $(".popover").hide();
              }}
            >
              Yes, Delete
            </button>
            <button
              className="btn btn-white mr-1 btn-sm"
              onClick={() => {
                $(".popover").hide();
              }}
            >
              Cancel
            </button>
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <div className="w-100" ref={this.node}>
        <div className="element-wrapper" style={{ position: "relative" }}>
          <h4>Content Briefs</h4>
          <ul
            className="nav nav-tabs upper mt-4 pt-2"
            style={{ paddingRight: showEmail ? "500px" : "220px" }}
          >
            {["all", "archived"].map((tag, index) => {
              const isActive =
                tag === that.props.match.params.tag ||
                (tag === "all" && _.isEmpty(that.props.match.params.tag));
              const pathname = tag === "all" ? "/briefs" : `/briefs/tag/${tag}`;
              let icon = <span />;
              if (tag === "archived") {
                icon = (
                  <i
                    className="fa fa-archive"
                    style={{ color: "inherit", fontSize: "inherit" }}
                  />
                );
              } else if (index > 0) {
                icon = (
                  <i
                    className="fa fa-tag"
                    style={{ color: "inherit", fontSize: "inherit" }}
                  />
                );
              }
              return (
                <li className="nav-item" key={index}>
                  <Link
                    to={{ pathname }}
                    onClick={() => {
                      that.setState({ offset: 0 });
                    }}
                    className={cx(
                      "nav-link",
                      "filter",
                      "clickable",
                      isActive && "active"
                    )}
                  >
                    {icon} {tag}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="clearfix" style={{ marginTop: "-46px" }}>
            {!showEmptyState && (
              <div className="float-right pr-3" style={{ fontSize: "12px" }}>
                <div
                  className="reports-dashboard-row"
                  style={{ display: "inline-block" }}
                >
                  <a
                    data-original-title={
                      !exportBriefsInProgress
                        ? "Export Briefs Information"
                        : "Exporting..."
                    }
                    data-toggle="tooltip"
                    style={{
                      position: "absolute",
                      top: "8rem",
                      right: "0.1rem",
                    }}
                  >
                    {!exportBriefsInProgress ? (
                      <i
                        style={{ fontSize: "1.1rem" }}
                        onClick={() => {
                          this.exportAllBriefs();
                        }}
                        className="mr-1 fa fa-download action-secondary"
                      />
                    ) : (
                      <Loader type="spinner" className="dark" />
                    )}
                  </a>
                </div>
              </div>
            )}

            <div className="element-search float-right small">
              <DebounceInput
                minLength={3}
                debounceTimeout={300}
                onChange={(e) => {
                  that.setState({
                    offset: 0,
                    filter: { ...filter, search: e.target.value },
                  });
                }}
                style={{ backgroundColor: "white" }}
                placeholder="search briefs"
                type="text"
                value={filter.search}
              />
            </div>
            {showTags && (
              <div className="float-right pr-3" style={{ fontSize: "12px" }}>
                Show Briefs With Tag:{" "}
                <div style={{ display: "inline-block", width: "170px" }}>
                  <Select
                    classNamePrefix="react-select-small"
                    options={tagSelectOptions}
                    onChange={(selected) => {
                      that.setState({ offset: 0 });
                      if (selected && selected.value) {
                        that.props.history.push(
                          `/briefs/tag/${selected.value}`
                        );
                      } else {
                        that.props.history.push(`/briefs`);
                      }
                    }}
                    value={
                      _.find(tagSelectOptions, {
                        value: that.props.match.params.tag,
                      }) || null
                    }
                    isClearable
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: "none",
                        borderRadius: "40px",
                      }),
                    }}
                  />
                </div>
              </div>
            )}
            {showEmail && (
              <div className="float-right pr-3" style={{ fontSize: "12px" }}>
                Show Briefs Created By:{" "}
                <div style={{ display: "inline-block", width: "170px" }}>
                  <Select
                    classNamePrefix="react-select-small"
                    options={emailSelectOptions}
                    onChange={(selected) => {
                      that.setState({
                        offset: 0,
                        filter: {
                          ...that.state.filter,
                          user_id: selected.value,
                        },
                      });
                    }}
                    value={_.find(emailSelectOptions, {
                      value: that.state.filter.user_id,
                    })}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: "none",
                        borderRadius: "40px",
                      }),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {!AP.currentUser.cancelled ? (
            <div>
              <button
                className="mr-2 mb-2 btn btn-grey"
                style={{ position: "absolute", right: "127px", top: "10px" }}
                onClick={() => {
                  $(".keywordModal").modal("show");
                }}
              >
                Keyword Research <i className="fa fa-search" />
              </button>
              <button
                className="mr-2 mb-2 btn btn-primary"
                style={{ position: "absolute", right: "0", top: "10px" }}
                onClick={() => {
                  $(".reportReportModal").modal("show");
                }}
              >
                Create Brief +
              </button>
              <div
                className="text-bluegray-4"
                style={{ position: "absolute", right: "9px", top: "-21px" }}
              >
                {AP.currentUser.trialExpired && (
                  <span className="text-n text-danger">
                    Your trial is expired.{" "}
                    <a className="text-bold" href="/subscribe">
                      Choose a plan
                    </a>{" "}
                    to continue using Topic.
                  </span>
                )}
                {isOnPaidTrial ? (
                  <a href="/profile/edit">
                    <span>
                      {briefsRemaining} briefs left on your{" "}
                      {_.find(AP.plans, { id: AP.currentUser.planId }).name}{" "}
                      Trial Plan (Expires in{" "}
                      {this.getToExpireDays(
                        AP.currentUser.paid_trial_expiry_date
                      )}{" "}
                      Days)
                    </span>
                  </a>
                ) : (
                  <a
                    data-original-title={`${AP.currentUser.roll_over_briefs_count} rollover, ${AP.currentUser.current_month_briefs_remaining} regular briefs remaining`}
                    data-toggle="tooltip"
                    href="/profile/edit"
                    title=""
                  >
                    <span>
                      {briefsRemaining} briefs left on your{" "}
                      {_.find(AP.plans, { id: AP.currentUser.planId }).name}{" "}
                      {AP.currentUser.currentPeriodStart
                        ? `plan (resets in ${this.getToExpireDays(
                            AP.currentUser.currentPeriod
                          )} Days)`
                        : `plan (resets 1st of ${resetMonth})`}
                    </span>
                  </a>
                )}
              </div>
            </div>
          ) : null}
          <div className="element-wrapper">
            {this.props.isFetching && showEmptyState && (
              <div className="text-center p-5">
                <Loader type="spinner" className="dark" />
              </div>
            )}
            {!this.props.isFetching &&
              showEmptyState &&
              AP.currentUser.reportsCreated === 0 &&
              emptyState}
            {!this.props.isFetching &&
              showEmptyState &&
              AP.currentUser.reportsCreated !== 0 && (
                <div className="text-center p-5">No content briefs found</div>
              )}
            {!showEmptyState && (
              <div
                className="element-box-tp"
                style={{ opacity: this.props.isFetching ? "0.5" : "1" }}
              >
                <div
                  className="table-responsive"
                  style={{ minHeight: "150px" }}
                >
                  <table className="table table-padded mt-4">
                    <thead>
                      <tr>
                        {tableColumns.map((item, i) => {
                          let caret = (
                            <i
                              className="show-on-parent-hover fas fa-sort-down"
                              style={{ opacity: "0.5" }}
                            />
                          );
                          let thClass = "";
                          if (sort === item[1]) {
                            caret =
                              order === "desc" ? (
                                <i className="fas fa-sort-down" />
                              ) : (
                                <i className="fas fa-sort-up" />
                              );
                            thClass = "text-bluegray-6";
                          }
                          return (
                            <th
                              className={`clickable ${thClass}`}
                              key={i}
                              onClick={() => {
                                that.handleSortClick(item[1]);
                              }}
                            >
                              {item[0]} {caret}
                            </th>
                          );
                        })}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isArray(reports) &&
                        reports.map((report, i) => {
                          const locationString = _.isEmpty(report.location)
                            ? ""
                            : `${report.location.canonical_name} (${report.location.domain})`;
                          const hasTags =
                            _.isArray(report.tags) && report.tags.length > 0;

                          const loadingReportRow = (
                            <tr
                              key={i}
                              className="white-row hoverable-row reports-dashboard-row"
                            >
                              <td colSpan={cols}>
                                <div style={{ float: "left" }}>
                                  Creating Content Brief for{" "}
                                  <span className="text-bold">
                                    {report.name}
                                  </span>
                                </div>
                                {report.status == "Error" &&
                                !that.state.refreshInProgress.hasOwnProperty(
                                  report.id
                                ) ? (
                                  <button
                                    className="btn btn-grey ml-3"
                                    style={{ float: "right" }}
                                    onClick={(e) => {
                                      that.handleReportRefresh(report.id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    Refresh
                                  </button>
                                ) : null}
                                {that.state.refreshInProgress.hasOwnProperty(
                                  report.id
                                ) ? (
                                  <div
                                    className="text-bluegray-4"
                                    style={{ float: "right" }}
                                  >
                                    Status: Initializing , Time elapsed:{" "}
                                    {timeAgo(new Date(Date.now())).replace(
                                      "ago",
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className="text-bluegray-4"
                                    style={{ float: "right" }}
                                  >
                                    {new Date() >
                                      moment(report.created_at)
                                        .add(10, "m")
                                        .toDate() &&
                                    report.status != "Error" ? (
                                      <div>
                                        <button
                                          className="btn btn-grey ml-3"
                                          style={{ float: "right" }}
                                          onClick={(e) => {
                                            that.handleReportRefresh(report.id);
                                            e.stopPropagation();
                                          }}
                                        >
                                          Retry
                                        </button>
                                        Brief is taking longer than expected
                                      </div>
                                    ) : (
                                      `Status: ${
                                        report.status || "Initializing"
                                      }, Time elapsed: ${timeAgo(
                                        new Date(report.created_at)
                                      ).replace("ago", "")}`
                                    )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          );

                          let deleteButton = (
                            <OverlayTrigger
                              rootClose
                              trigger="click"
                              placement="top"
                              overlay={deletionPopover}
                            >
                              <button
                                data-original-title="Delete"
                                data-toggle="tooltip"
                                className="btn btn-white action-secondary"
                                onClick={() => {
                                  this.setState({
                                    reportIdSelectedForDeletion: report.id,
                                  });
                                }}
                                href="#"
                              >
                                <i className="mr-1 fa fa-trash" />
                              </button>
                            </OverlayTrigger>
                          );
                          if (AP.currentUser.delete_credits_remaining <= 0) {
                            deleteButton = (
                              <button
                                data-original-title="Only 3 briefs can be deleted in a billing cycle."
                                data-toggle="tooltip"
                                className="btn btn-white action-secondary disabled"
                                href="#"
                              >
                                <i className="mr-1 fa fa-trash" />
                              </button>
                            );
                          } else if (
                            AP.currentUser.currentPeriodStart &&
                            report.created_at &&
                            new Date(
                              AP.currentUser.currentPeriodStart
                            ).getTime() > new Date(report.created_at).getTime()
                          ) {
                            deleteButton = (
                              <button
                                data-original-title="Only briefs created in the current billing cycle can be deleted."
                                data-toggle="tooltip"
                                className="btn btn-white action-secondary disabled"
                                href="#"
                              >
                                <i className="mr-1 fa fa-trash" />
                              </button>
                            );
                          }

                          const reportRow = (
                            <tr
                              key={i}
                              className="white-row hoverable-row reports-dashboard-row"
                            >
                              <td>{dateToPrettyString(report.created_at)}</td>
                              <td>{timeAgo(report.updated_at)}</td>
                              {showEmail && <td>{report.email || ""}</td>}
                              <td>
                                <Link
                                  to={{
                                    pathname: `/briefs/${report.id}/overview`,
                                    search: this.props.location.search,
                                  }}
                                >
                                  <span className="text-bold">
                                    {report.name}
                                  </span>
                                </Link>
                                <span className="text-s pl-2">
                                  {locationString}
                                </span>
                                {hasTags && (
                                  <div className="d-inline ml-1">
                                    {report.tags.map((tag, index) => (
                                      <Link
                                        key={index}
                                        to={{
                                          pathname: `/briefs/tag/${tag}`,
                                        }}
                                      >
                                        <div className="reports-dashboard-row-tag badge text-s bg-bluegray-2 clickable">
                                          <i className="fa fa-tag" /> {tag}
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                )}
                              </td>
                              <td>
                                {report.content.word_count > 0 && (
                                  <span
                                    className={`badge badge-pill text-bluegray-6 bg-${gradeToColor(
                                      report.content.grade
                                    )}-2 text-${gradeToColor(
                                      report.content.grade
                                    )}-10`}
                                  >
                                    {report.content.grade}
                                  </span>
                                )}
                              </td>
                              <td className="reports-dashboard-row-actions">
                                <Link
                                  to={{
                                    pathname: `/briefs/${report.id}/overview`,
                                    search: this.props.location.search,
                                  }}
                                  className="mr-3"
                                >
                                  <button className="btn btn-grey">Open</button>
                                </Link>
                                <a
                                  data-original-title={
                                    hasTags ? "Edit Tags" : "Add Tag"
                                  }
                                  data-toggle="tooltip"
                                >
                                  <button
                                    className="btn btn-white action-secondary"
                                    href="#"
                                    onClick={() => {
                                      this.props.setActiveReport(report.id);
                                      $(".report-tag-modal").modal("show");
                                    }}
                                  >
                                    <i className="mr-1 fa fa-tag" />
                                  </button>
                                </a>
                                {report.mode !== "ARCHIVED" && (
                                  <button
                                    data-original-title="Archive"
                                    data-toggle="tooltip"
                                    className="btn btn-white action-secondary"
                                    href="#"
                                    onClick={(e) => {
                                      this.handleArchiveReport(report.id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <i className="mr-1 fa fa-archive" />
                                  </button>
                                )}
                                {report.mode !== "ARCHIVED" && deleteButton}
                                {report.mode === "ARCHIVED" && (
                                  <button
                                    data-original-title="Unarchive"
                                    data-toggle="tooltip"
                                    className="btn btn-white action-secondary"
                                    href="#"
                                    onClick={(e) => {
                                      this.handleUnarchiveReport(report.id);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <i className="mr-1 fas fa-undo" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          );

                          if (report.status === "Success") {
                            return reportRow;
                          }
                          return loadingReportRow;
                        })}
                    </tbody>
                  </table>
                </div>
                {isOnPaidTrial && pendingReports.length > 0 && (
                  <div className="text-center">
                    <div className="d-inline-block text-left p-3 rounded element-box">
                      <div className="d-flex" style={{ width: "600px" }}>
                        <div className="p-3" style={{ maxWidth: "300px" }}>
                          <h3>Become a Topic Pro</h3>
                          <p>
                            Generating a content brief can take a few minutes.
                            While you're waiting, watch this video to learn how
                            to make the most of our platform.
                          </p>
                          <button
                            className="btn bg-primary-2 hover-bg-primary-3 btn-sm"
                            onClick={() => {
                              $("#topic-pro-video").modal("show");
                            }}
                          >
                            Watch Video (11 mins){" "}
                            <i className="far fa-play-circle" />
                          </button>
                        </div>
                        <div className="p-3">
                          <img
                            className="clickable rounded-lg w-100"
                            onClick={() => {
                              $("#topic-pro-video").modal("show");
                            }}
                            alt="video preview thumbnail"
                            src="/img/demo-thumbnail.gif"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!showEmptyState && pageCount > 1 && (
              <div className="text-center">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
            )}
          </div>
        </div>
        <ReportTagModal
          onSave={() => {
            console.log("saved");
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { reports, tags } = state;
  const pendingReports = _.filter(reports.items, (report) => {
    const timeElapsed =
      new Date().getTime() - new Date(report.created_at).getTime();
    const newerThanTenMinutes = timeElapsed < 60 * 10 * 1000;
    return report.status !== "Success" && newerThanTenMinutes;
  });
  return {
    reports: reports.items,
    pendingReports,
    total: reports.total,
    users: reports.users,
    briefsRemaining: reports.briefsRemaining,
    isFetching: reports.isFetching,
    tags,
    lastUpdated: reports.forceRefreshRandom,
    reportStatuses: _.map(reports.items, "status"),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchReports,
    invalidateReports,
    setActiveReport,
    saveReport,
    deleteArchiveReport,
    deleteReport,
    refreshReport,
    updateReportLocally,
    clearRevisions,
  })(ReportsDashboard)
);
