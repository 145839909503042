import React from "react";

function RefreshButton({ onClick }) {
  return (
    <button
      className="btn btn-white text-bluegray-5 builder-popover-refresh"
      onClick={onClick}
    >
      <i className="fas fa-redo-alt" /> Refresh
    </button>
  );
}

export default RefreshButton;
