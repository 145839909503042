import React, { Component } from "react";
import NewWindow from "react-new-window";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

class InventoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formError: null,
      showGSCPopup: false,
      availableSites: [],
      name: "",
      siteToAdd: null,
      pathsInputValue: "",
      paths: [],
    };
    this.baseState = this.state;
    this.resetState = this.resetState.bind(this);
    this.validate = this.validate.bind(this);
    this.saveInventory = this.saveInventory.bind(this);
    this.openGSCPopup = this.openGSCPopup.bind(this);
    this.pollUntilGSCPopupClosed = this.pollUntilGSCPopupClosed.bind(this);
    this.onConnectPopupClose = this.onConnectPopupClose.bind(this);
    this.fetchAvailableSites = this.fetchAvailableSites.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handlePathsKeyDown = this.handlePathsKeyDown.bind(this);
    this.handlePathsBlur = this.handlePathsBlur.bind(this);
    this.modalRef = React.createRef();
    this.formRef = React.createRef();
    this.popupRef = React.createRef();
  }

  resetState() {
    this.setState(this.baseState);
  }

  componentDidMount() {
    this.mounted = true;
    const that = this;
    $(this.modalRef.current).on("hidden.bs.modal", () => {
      that.resetState();
    });
    $(this.modalRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    this.pollUntilGSCPopupClosed();
    $(this.modalRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.resetState();
    if (this.gscPollInterval) {
      window.clearInterval(this.gscPollInterval);
    }
  }

  validate(successFunction, event) {
    event.preventDefault();
    if (this.formRef.current.reportValidity()) {
      // only allow alphanumeric, underscore, and spaces
      let onlyAlphaNumericUnderscoreSpaces = true;
      if (this.state.name) {
        if (!/^([A-Za-z]|[0-9]|_| )+$/.test(this.state.name)) {
          onlyAlphaNumericUnderscoreSpaces = false;
        }
      }
      if (!onlyAlphaNumericUnderscoreSpaces) {
        this.setState({
          formError:
            "Only letters, numbers, underscores, and spaces are allowed.",
        });
        return;
      }

      successFunction(event);
    }
  }

  openGSCPopup() {
    this.setState({ showGSCPopup: true });
  }

  pollUntilGSCPopupClosed() {
    // this is required because of a Safari bug which prevents us from using onUnload
    const { showGSCPopup } = this.state;
    const that = this;
    if (showGSCPopup && !this.gscPollInterval) {
      this.gscPollInterval = window.setInterval(() => {
        const isOpen =
          that.popupRef &&
          that.popupRef.current &&
          that.popupRef.current.window &&
          !that.popupRef.current.window.closed;
        if (!isOpen) {
          window.clearInterval(that.gscPollInterval);
          that.gscPollInterval = undefined;
          that.onConnectPopupClose();
        }
      }, 500);
    }
  }

  onConnectPopupClose() {
    this.fetchAvailableSites();
    this.setState({ showGSCPopup: false });
  }

  fetchAvailableSites() {
    const that = this;
    fetch(`/api/sites/list`, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then(
        (json) =>
          json.sites &&
          this.mounted &&
          this.setState({
            availableSites: _.difference(json.sites, that.props.sites),
          })
      );
  }

  handleSiteChange(selectedSite) {
    this.setState({ siteToAdd: selectedSite.value });
  }

  handlePathsKeyDown(e) {
    const { pathsInputValue, paths } = this.state;
    if (!pathsInputValue) return;
    switch (e.key) {
      case "Enter":
      case "Tab":
        var newPaths = _.concat(paths, [
          { value: pathsInputValue, label: pathsInputValue },
        ]);
        this.setState({
          pathsInputValue: "",
          paths: newPaths,
        });
        e.preventDefault();
    }
  }

  handlePathsBlur(e) {
    const { pathsInputValue, paths } = this.state;
    if (!pathsInputValue) return;
    const newPaths = _.concat(paths, [
      { value: pathsInputValue, label: pathsInputValue },
    ]);
    this.setState({
      pathsInputValue: "",
      paths: newPaths,
    });
    e.preventDefault();
  }

  saveInventory(e) {
    const { siteToAdd, name, paths } = this.state;
    const { onAdd } = this.props;
    if (siteToAdd) {
      const inventoryData = {
        url: siteToAdd,
        name,
      };
      if (!_.isEmpty(paths)) {
        inventoryData.rules = {
          url_whitelist: _.map(paths, "value"),
        };
      }
      fetch("/api/inventories", {
        method: "post",
        body: JSON.stringify(inventoryData),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).then(() => {
        if (_.isFunction(onAdd)) {
          onAdd();
        }
      });
    }
    e.preventDefault();
  }

  render() {
    const that = this;
    const { showGSCPopup, availableSites, name, pathsInputValue, paths } =
      this.state;
    let siteFormView;

    if (availableSites && availableSites.length > 0) {
      const options = availableSites.map((site) => ({
        value: site,
        label: site,
      }));
      siteFormView = (
        <div>
          <div className="form-group text-left">
            <label htmlFor="inventory-name">
              <span>Name</span>
            </label>
            <input
              className="form-control"
              id="inventory-name"
              type="text"
              value={name}
              onChange={(e) => {
                that.setState({ name: e.target.value });
              }}
              required
            />
          </div>
          <div className="form-group text-left">
            <label htmlFor="inventory-site">Site</label>
            <Select
              id="inventory-site"
              options={options}
              isClearable={false}
              name="available-sites"
              className="site-settings-select"
              placeholder="Select Site..."
              onChange={this.handleSiteChange}
            />
          </div>
          <div className="form-group text-left">
            <label htmlFor="inventory-site">
              Paths (optional){" "}
              <a
                data-original-title="Use this if you only want to import data from specific URL paths on your site. For example, /blog or /guides"
                data-toggle="tooltip"
                href="#"
                title=""
              >
                <i className="fa fa-info-circle" />
              </a>
            </label>
            <CreatableSelect
              components={{ DropdownIndicator: null }}
              inputValue={pathsInputValue}
              isMulti
              menuIsOpen={false}
              onChange={(paths) => {
                that.setState({ paths });
              }}
              onInputChange={(paths) => {
                that.setState({ pathsInputValue: paths });
              }}
              onBlur={this.handlePathsBlur}
              onKeyDown={this.handlePathsKeyDown}
              placeholder="Type, then press enter or tab..."
              value={paths}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={this.validate.bind(null, this.saveInventory)}
            >
              Add Site
            </button>
          </div>
        </div>
      );
    } else if ((availableSites && availableSites.length === 0) || true) {
      siteFormView = (
        <div>
          <p>Use the buttom below to get started.</p>
          <p className="text-left text-s">
            You will be asked to give permission for Topic to access your Google
            Search Console. We use the data to identify your site's pages and
            find optimization opportunities.
          </p>
          <div
            onClick={this.openGSCPopup}
            className="clickable"
            style={{
              backgroundImage: "url(/img/connect-gsc.png)",
              borderRadius: "3px",
              width: "100%",
              height: "50px",
              boxShadow: "0px 2px 3px #b6bcce85",
            }}
          />
        </div>
      );
    }

    return (
      <div
        ref={this.modalRef}
        aria-hidden="true"
        aria-labelledby="inventoryModal"
        className="modal fade inventoryModal text-center"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content d-flex flex-column p-3">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div className="onboarding-content" style={{ marginTop: "-38px" }}>
              <h4 className="onboarding-title">Add Site</h4>
              <form ref={this.formRef}>
                <div className="row">
                  <div className="col-sm-12">
                    {siteFormView}
                    {this.state.formError && (
                      <div className="row">
                        <div className="col-sm-12 text-danger">
                          {this.state.formError}
                        </div>
                      </div>
                    )}
                    {showGSCPopup && (
                      <NewWindow
                        ref={this.popupRef}
                        url="/auth/google_oauth2"
                        features={{ height: 600, width: 400 }}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InventoryModal;
