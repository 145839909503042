import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./loader";
import { updateReportLocally, requestContentRevisions } from "./redux/actions";
import { timeAgo, gradeToColor } from "./helpers";

class EditorContentRevision extends React.Component {
  constructor(props) {
    super(props);
    this.previewContent = this.previewContent.bind(this);
    this.fetchNewRevisions = this.fetchNewRevisions.bind(this);

    this.state = {
      hasMoreRevisions: true,
    };
  }

  previewContent(content, revisionId, revisionName) {
    const modifiedReport = this.props.report;
    modifiedReport.content = content;
    // Update content object locally to reflect grading & topics covered
    this.props.updateReportLocally(modifiedReport);
    // Set Html in the editor
    AP.editor.html.set(content.html);
    // Disable Edits on the editor
    AP.editor.edit.off();
    // Set the Revision Preview Flag & Active Revision Id
    this.props.revisionPreview(revisionId, revisionName);
  }

  fetchNewRevisions() {
    const limit = 5;
    const { revisions, match, requestContentRevisions } = this.props;
    // An Action that accepts the offset and gets the data & appends it into the revisions state
    const offset = Math.floor(revisions.length / limit);
    setTimeout(() => {
      requestContentRevisions(match.params.id, offset, (responseLength) => {
        if (!responseLength || responseLength % 5 != 0) {
          this.setState({ hasMoreRevisions: false });
        }
      });
    }, 500);
  }

  render() {
    const that = this;
    const { show, revisions, activeRevision } = this.props;
    const hasRevisions = revisions.length > 0;

    const revisionsView = (revision, i) => {
      const { grade, word_count, last_modified_at } = revision.content;
      const name = `Version from ${timeAgo(new Date(last_modified_at))}`;
      return (
        <div className="container" key={i}>
          <div className="row mt-2">
            <div className="col-8 pl-0 pr-0">
              <div className="text-bold" style={{ fontSize: "13px" }}>
                {name}
              </div>
              <div className="text mt-2">
                {word_count} words,{" "}
                <div
                  className={`badge bg-${gradeToColor(
                    grade
                  )}-2 text-${gradeToColor(grade)}-10 ml-2 pr-2 pl-2`}
                  style={{ lineHeight: "1.1" }}
                >
                  {grade}
                </div>
              </div>
            </div>
            <div className="col-4">
              {revision.rev_id == activeRevision.rev_id ? (
                <div className="btn btn-success mt-3" disabled>
                  Selected
                </div>
              ) : (
                <div
                  className="btn btn-grey mt-2"
                  onClick={() => {
                    this.previewContent(
                      revision.content,
                      revision.rev_id,
                      name
                    );
                  }}
                >
                  Preview
                </div>
              )}
            </div>
          </div>
          <div className="dropdown-divider mb-3" />
        </div>
      );
    };
    const style = {
      height: 30,
      border: "1px solid green",
      margin: 6,
      padding: 8,
    };
    return (
      <div className="revision" hidden={!show}>
        <div className="revision-info">
          <div className="revision-info-header-bg text-center text-s">
            <p className="revision-info-header p-3 text-bold">
              Versions are automatically stored every 10 minutes, and deleted
              after 30 days{" "}
            </p>
          </div>
          <div
            className="row revisions-section"
            id="scrollable-revisions-section"
          >
            <div className="col pr-5 pl-5">
              {hasRevisions && (
                <>
                  <div className="text-right">
                    <button
                      className="btn btn-sm btn-grey"
                      onClick={() => {
                        that.props.handleAddContentRevision(false);
                      }}
                    >
                      + Create New Version
                    </button>
                  </div>
                  <InfiniteScroll
                    dataLength={revisions.length}
                    next={this.fetchNewRevisions}
                    hasMore={this.state.hasMoreRevisions}
                    loader={<Loader type="spinner" className="dark" />}
                    scrollableTarget="scrollable-revisions-section"
                  >
                    <div className="dropdown-divider mb-3" />
                    {revisions.map((revision, i) => revisionsView(revision, i))}
                  </InfiniteScroll>
                </>
              )}
              {!hasRevisions && (
                <div className="p-3 pb-4">
                  <p className="text-bold">
                    No version history is available for this brief yet.
                  </p>
                  <button
                    className="btn btn-sm btn-grey"
                    onClick={() => {
                      that.props.handleAddContentRevision(false);
                    }}
                  >
                    + Create New Version
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    revisions: state.revisions,
  };
}

export default withRouter(
  connect(mapStateToProps, { updateReportLocally, requestContentRevisions })(
    EditorContentRevision
  )
);
