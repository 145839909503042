import React from "react";
import { connect } from "react-redux";
import {
  updateSegmentLocally,
  saveSegment,
  setActiveSegment,
} from "./redux/actions";
import { getActiveSegment } from "./redux/selectors";
import InventorySegmentLabel from "./inventory_segment_label";
import { getSegmentLabel } from "./helpers";

class InventorySegmentbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSegmentSelector: false,
      search: "",
    };
    this.handleSelectSegment = this.handleSelectSegment.bind(this);
    this.handleToggleSegmentSelector =
      this.handleToggleSegmentSelector.bind(this);
    this.ref = React.createRef();
  }

  handleToggleSegmentSelector(e) {
    const { showSegmentSelector } = this.state;
    const that = this;

    // this handler hides tooltip if anything is clicked on
    if (!showSegmentSelector) {
      $(window).one("click", () => {
        that.setState({ showSegmentSelector: false });
      });
    }

    that.setState({ showSegmentSelector: !showSegmentSelector });
    e.stopPropagation();
  }

  handleSelectSegment(segment) {
    const { setActiveSegment } = this.props;
    setActiveSegment(segment.id);
    this.setState({ showSegmentSelector: false });
  }

  render() {
    const that = this;
    let { activeSegment, segments, segmentLabels } = this.props;
    const { showSegmentSelector, search } = this.state;

    if (!_.isEmpty(search)) {
      segments = _.filter(
        segments,
        (segment) =>
          segment &&
          segment.name &&
          _.includes(segment.name.toLowerCase(), search.toLowerCase())
      );
    }

    const showEmptyState = segments.length === 0;

    const label = getSegmentLabel(segmentLabels, activeSegment);

    return (
      <div
        ref={this.ref}
        className="inventory-segmentbar d-flex mt-4 pt-2 pb-2"
      >
        <div className="flex-grow-1" style={{ position: "relative" }}>
          <div style={{ fontSize: "20px" }} className="d-flex">
            <InventorySegmentLabel label={label} />
            <div className="ml-2">{activeSegment.name}</div>
          </div>
          {showSegmentSelector && (
            <div
              className="inventory-segmentbar-selector"
              style={{ paddingBottom: "0" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="inventory-segmentbar-selector-search">
                <input
                  onChange={(e) => {
                    that.setState({ search: e.target.value });
                  }}
                  style={{ backgroundColor: "white" }}
                  placeholder="search segments"
                  type="text"
                  value={search}
                />
              </div>
              {showEmptyState && (
                <div className="p-3 text-center">No segments found</div>
              )}
              {!showEmptyState && (
                <div className="inventory-segmentbar-selector-list">
                  <ul>
                    {_.map(segments, (segment, index) => (
                      <li
                        key={index}
                        className="clickable"
                        onClick={() => {
                          that.handleSelectSegment(segment);
                        }}
                      >
                        {segment.name}{" "}
                        {_.isNumber(segment.page_count) &&
                          `(${segment.page_count})`}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!showEmptyState && (
                <div className="clickable inventory-segmentbar-selector-manage">
                  Manage Segments
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { segments, pages } = state;
  const activeSegment = getActiveSegment(state) || segments.defaultSegment;
  return {
    activeSegment,
    segments,
    isFetching: segments.isFetching,
    sort: pages.sort,
    order: pages.order,
    extraFilters: pages.extraFilters,
    segmentLabels: segments.segmentLabels,
  };
}

export default connect(mapStateToProps, {
  saveSegment,
  updateSegmentLocally,
  setActiveSegment,
})(InventorySegmentbar);
