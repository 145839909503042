import {
  REQUEST_REPORTS,
  RECEIVE_REPORTS,
  REQUEST_REPORT,
  RECEIVE_REPORT,
  SET_ACTIVE_REPORT,
  UPDATE_ACTIVE_REPORT_LOCALLY,
  UPDATE_REPORT_LOCALLY,
  INVALIDATE_REPORTS,
  SET_IS_FETCHING,
  UPDATE_SIGNALS,
} from "../actions";

export function reports(
  state = {
    isFetching: false,
    items: [],
    didInvalidate: true,
    signals: {}, // maps report ids to signals so they can be aborted when making lots of requests
    total: null, // number of total reports
    users: [], // all users on current account (used to show filter options)
    briefsRemaining: 0,
  },
  action
) {
  switch (action.type) {
    case REQUEST_REPORTS:
      return { ...state, isFetching: true };
    case RECEIVE_REPORTS:
      return {
        ...state,
        isFetching: false,
        items: action.reports,
        didInvalidate: false,
        total: action.total,
        users: action.users,
        briefsRemaining: action.briefsRemaining,
      };
    case REQUEST_REPORT:
      return { ...state, isFetching: true };
    case RECEIVE_REPORT:
      var newReports;
      if (!_.isEmpty(state.items)) {
        if (_.isUndefined(_.find(state.items, { id: action.report.id }))) {
          // received new report
          newReports = state.items.concat([action.report]);
        } else {
          // received existing report
          newReports = _.map(state.items, (report) => {
            if (report.id === action.report.id) {
              return action.report;
            }
            return report;
          });
        }
      } else {
        newReports = [action.report];
      }
      return {
        ...state,
        isFetching: false,
        items: newReports,
        didInvalidate: false,
      };
    case UPDATE_ACTIVE_REPORT_LOCALLY:
      // replace the active report locally
      // used by the editor to update the grade
      // always listen to server for a few select fields such as ignored_topics, tags, recommended_topics
      const index = _.findIndex(
        state.items,
        (item) => item.id === action.report.id
      );
      var stateCopy = { ...state };
      var newItem = _.merge({}, stateCopy.items[index], action.report); // do a deep merge to retain any data not sent back by server
      if (_.isArray(action.report.ignored_topics)) {
        newItem.ignored_topics = action.report.ignored_topics; // do not do a merge for ignored_topics, always listen to the server
      }
      if (_.isArray(action.report.tags)) {
        newItem.tags = action.report.tags; // do not do a merge for tags, always listen to the server
      }
      if (
        action.report.report &&
        _.isArray(action.report.report.recommended_topics)
      ) {
        newItem.report.recommended_topics =
          action.report.report.recommended_topics; // do not do a merge for recommended_topics, always listen to the server
      }
      if (!_.isEmpty(action.report.content)) {
        newItem.content = action.report.content; // do not do a merge for content, always listen to the server
      }
      if (!_.isEmpty(newItem.new_topic_object)) {
        delete newItem.new_topic_object; // If Existing new_topic_object exists in the report, Delete that
      }
      if (!_.isEmpty(newItem.delete_custom_topic)) {
        delete newItem.delete_custom_topic; // If Existing delete_custom_topic exists in the report, Delete that
      }
      stateCopy.items[index] = newItem;
      stateCopy.isFetching = false;
      return stateCopy;
    case UPDATE_REPORT_LOCALLY:
      // replace the active report locally (in a naiive way, just do a deep merge)
      // also accepts array of reports
      var stateCopy = { ...state };
      if (_.isArray(action.report)) {
        action.report.forEach((report) => {
          const index = _.findIndex(
            state.items,
            (item) => item.id === report.id
          );
          const newItem = _.merge({}, stateCopy.items[index], report); // do a deep merge to retain any data not sent back by server
          stateCopy.items[index] = newItem;
        });
      } else {
        const index = _.findIndex(
          state.items,
          (item) => item.id === action.report.id
        );
        var newItem = _.merge({}, stateCopy.items[index], action.report); // do a deep merge to retain any data not sent back by server
        stateCopy.items[index] = newItem;
      }
      return stateCopy;
    case INVALIDATE_REPORTS:
      return { ...state, didInvalidate: true };
    case SET_IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case UPDATE_SIGNALS:
      var newSignals = { ...state.signals, ...action.signalObj };
      return { ...state, signals: newSignals };
    default:
      return state;
  }
}

export function activeReport(state = null, action) {
  if (action.type === SET_ACTIVE_REPORT) {
    return action.reportId;
  }
  return state;
}
