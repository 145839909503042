import React from "react";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { saveReport } from "./redux/actions";
import { getReportById } from "./redux/selectors";
import Loader from "./loader";

class ShareToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareTooltip: false,
    };
    this.inputRef = React.createRef();
    this.node = React.createRef();
    this.handleShareChange = this.handleShareChange.bind(this);
    this.copyURLToClipboard = this.copyURLToClipboard.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  handleShareChange() {
    const newReport = {
      id: this.props.report.id,
      mode: this.props.report.mode === "PUBLIC" ? "PRIVATE" : "PUBLIC",
    };
    this.props.saveReport(newReport);
  }

  toggleTooltip() {
    const that = this;
    if (!this.state.showShareTooltip) {
      // this handler hides tooltip if anything is clicked on
      $(window).one("click", () => {
        that.setState({ showShareTooltip: false });
      });
    }
    this.setState({ showShareTooltip: !this.state.showShareTooltip });
  }

  copyURLToClipboard(e) {
    this.inputRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }

  render() {
    if (!this.props.report) {
      return null;
    }

    const isLoggedOut = _.isEmpty(AP.currentUser.email);
    const isSharable = this.props.report.mode === "PUBLIC";
    const currentUrl = new URL(window.location.href);
    const sharableUrl = currentUrl.origin + currentUrl.pathname;

    return (
      <div
        className="share-toggle position-relative"
        ref={this.node}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          type="button"
          className="btn btn-grey"
          onClick={this.toggleTooltip}
        >
          <i className="fa fa-share-square-o mr-1" />
          Share
        </button>
        <div
          className="share-toggle-popover popover bs-popover-bottom"
          style={{ display: this.state.showShareTooltip ? "block" : "none" }}
        >
          <div className="arrow" />
          <div>
            {!isLoggedOut && (
              <>
                <Toggle
                  id="sharable-link"
                  defaultChecked={isSharable}
                  onChange={this.handleShareChange}
                />
                <label htmlFor="sharable-link">Enable Shareable Link</label>
              </>
            )}
            {this.props.isFetching && (
              <Loader
                type="spinner"
                className="dark"
                style={{ marginLeft: "5px" }}
              />
            )}
            <div>
              {!isSharable && (
                <span>
                  This report is currently private and only accessible to you
                </span>
              )}
              {isSharable && (
                <div className="mt-1">
                  <div>Anyone with the link can view and edit</div>
                  <div className="input-group mt-3">
                    <input
                      ref={this.inputRef}
                      className="form-control"
                      value={sharableUrl}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        data-original-title="Copy to clipboard"
                        data-toggle="tooltip"
                        className="btn btn-primary"
                        onClick={this.copyURLToClipboard}
                      >
                        <i className="fa fa-clipboard" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    report: getReportById(state, ownProps.match.params.id),
    isFetching: state.reports.isFetching,
  };
}

export default withRouter(
  connect(mapStateToProps, { saveReport })(ShareToggle)
);
