import React from "react";
import { v4 as uuidv4 } from 'uuid';
import Loader from "./loader";
import { trackEvent } from './keen';
import ResponseFeedback from './components/common/response_feedback';

class EditorSidebarBrainstorm extends React.Component {
  constructor(props) {
    super(props);

    let report = props.report;
    const allQuestions = _.filter(
      _.concat(
        _.get(report, 'report.related_questions_quora.questions', null),
        _.get(report, 'report.related_questions_autocomplete.questions', null), 
        _.get(report, 'report.related_questions.questions', null))
    ).map((q) => q.keyword || q.question );

    this.state = {
      isLoading: false,
      messages: [],
      inputMessage: '',
      exampleMessages: [
        `How do I best incorporate the topic of "${_.sample(report.report.recommended_topics).name}" into this post?`,
        `How would I answer "${_.sample(allQuestions)}"`,
        `What is still missing from this post?`
      ],
      threadId : uuidv4(),
      disableBrainstorm: false,
    };

    this.editorSidebarBrainstormRef = React.createRef();
    this.editorSidebarBrainstormMessagesRef = React.createRef();

    this.handleInputMessageChange = this.handleInputMessageChange.bind(this);
    this.fetchResponse = this.fetchResponse.bind(this);
    this.bindEditorEvents = this.bindEditorEvents.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleClickExample = this.handleClickExample.bind(this);
    this.resetChat = this.resetChat.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.trackChatEvent = this.trackChatEvent.bind(this);
  }

  bindEditorEvents() {
    const that = this;
    $(this.editorSidebarBrainstormRef.current).bind("keydown", this.handleKeydown);
  }

  componentDidMount() {
    this.bindEditorEvents();
  }

  componentWillUnmount() {
    $(this.editorSidebarBrainstormRef.current).unbind("keydown", this.handleKeydown);
  }

  handleKeydown(e){
    if(e && e.keyCode === 13){
      // enter
      this.fetchResponse();
    }
  }

  handleInputMessageChange(e){
    this.setState({
        inputMessage: e.target.value.replace(/\n+/g, '')
    });
  }

  handleClickExample(e){
    this.setState({
      inputMessage: e.target.innerText
    }, this.fetchResponse);

  }

  resetChat(e){
    this.setState({
      inputMessage: '',
      messages: [],
      threadId : uuidv4(),
      disableBrainstorm: false,
    })
  }

  scrollToBottom(){
    let messagesRef = $(this.editorSidebarBrainstormMessagesRef.current);
    if(messagesRef){
      messagesRef.animate({ scrollTop: messagesRef[0].scrollHeight}, "slow");
    }
  }

  fetchResponse(){
    var that = this;

    if(this.state.inputMessage.trim().length < 3){
      return;
    }

    this.scrollToBottom();
    const newMessage = {role: 'user', content: this.state.inputMessage};
    var newMessages = this.state.messages.concat([newMessage]);
    // Track Chat Event (Human Input)
    this.trackChatEvent('human', newMessage.content)
    this.setState({
      isLoading: true,
      inputMessage: '',
      messages: newMessages
    })
    const endpoint = `/api/openai/chat`;
    fetch(endpoint,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        messages: newMessages,
        content: $('.fr-view').text(),
        model: this.props.chosenModel
      })
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        that.setState({
          isLoading: false,
          messages: response.messages
        });
        // Last Message here is the bot's response
        const lastMessage = _.last(response.messages);
            // Track Chat Event (Bot Output)
        this.trackChatEvent('bot', lastMessage.content);
        if(lastMessage.error){
          that.setState({disableBrainstorm: true});
        }
        window.setTimeout(that.scrollToBottom, 10);
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Too Many Requests") {
          alert("Too many requests. Wait a minute before trying again.");
        }
        that.setState({isLoading: false});
      });
  }

  trackChatEvent(role, message) {
    const { report, sessionId } = this.props;
    const { threadId } = this.state;

    trackEvent('Brainstorm', {
      event: "Chat Message Sent",
      siteId: report.tags.join(),
      briefId: report.id,
      type: role,
      message,
      threadId,
      sessionId,      
    });
  }


  render() {
    var that = this;
    const { isLoading, inputMessage, messages, exampleMessages,threadId, disableBrainstorm } =
      this.state;
    const { report,sessionId } = this.props;
    const isEmpty = messages.length === 0;

    return (
      <div className="p-0 w-100" ref={this.editorSidebarBrainstormRef}>
        <div className="d-flex flex-column">
          <div ref={this.editorSidebarBrainstormMessagesRef} className="d-flex flex-column flex-grow px-2" style={{height: 'calc(100vh - 270px)', overflowY: 'scroll', justifyContent: isEmpty ? 'center' : ''}}>
            {
              isEmpty && <div>
                <div>
                  <p>
                    This is an experimental feature to help you brainstorm ideas on ways to improve your content. Note: The system may produce inaccurate information about people, places, or facts.
                  </p>
                  <p>
                    Examples of what you can ask:
                  </p>
                </div>
                <div>
                  {_.map(exampleMessages, (exampleMessage, i) => (
                      <div
                        key={i}
                        className="bg-primary-1 hover-bg-primary-2 clickable rounded p-2 mt-3 shadow-sm"
                        onClick={that.handleClickExample}
                      >
                        {exampleMessage}
                      </div>
                    )
                  )}
                </div>
              </div>
            }
            {!isEmpty && <div>
              <div className="text-primary-5 text-sm text-right py-2 clickable sticky d-flex justify-end" onClick={this.resetChat} style={{top: '10px'}}>
                <div className="bg-white p-1 px-2 rounded shadow-sm hover-bg-primary-1" style={{width: 'fit-content'}}>
                  reset chat <i className="fa fa-refresh" />
                </div>
              </div>
              {_.map(messages, (message, i) => {
                  if (typeof message.content === "string" && message.content.startsWith("\n")) {
                    message.content = message.content.substring(2);
                  }
                  return (<div 
                      key={i}
                      className={(message.role === 'assistant') ? "mr-5 p-2 rounded mt-3 bg-primary-2 editor-brainstorm-chat-message" : "ml-5 bg-primary p-2 rounded text-white mt-3 editor-brainstorm-chat-message"}>
                      <p dangerouslySetInnerHTML={{__html: message.content.replace(/\n/gi, '<br />')}}/>
                      <ResponseFeedback
                        userInput={messages[i-1]}
                        output={message}
                        sessionId={sessionId}
                        threadId={threadId}
                        eventName="Brainstorm Feedback"
                      />
                    </div>);
              })}
              {isLoading && <div className="mr-5 p-2 pl-4 rounded mt-3 bg-primary-2" style={{width: '58px'}}>
                <Loader type="dot-flashing"/>
              </div>}
            </div>
            }
          </div>
          <div className="d-flex mt-2">
            <textarea 
              placeholder="Let's brainstorm together"
              value={inputMessage}
              onChange={this.handleInputMessageChange}
              disabled={disableBrainstorm}
              className="editor-sidebar-brainstorm-textarea flex-grow flex-grow-1 bg-primary-1 border-0 p-2 no-focus-border"
              style={{resize: 'none'}}
            />
            <div>
              <button onClick={this.fetchResponse} className="w-100 h-100 border-0 bg-primary-3 px-2 clickable" disabled={disableBrainstorm}>
                <i className="fas fa-paper-plane" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorSidebarBrainstorm;