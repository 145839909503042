import React from "react";

/**
 * Renders a no bookmarks found message
 * @returns {JSX.Element}
 */
function OutlineBookmarksNotFound() {
  return (
    <div className="pt-2">
      <center>
        <p>No bookmarks found</p>
      </center>
    </div>
  );
}

export default OutlineBookmarksNotFound;
