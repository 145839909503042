import React, { useEffect, useState } from "react";
import { trackEvent } from '../../keen';

function ResponseFeedback({ userInput, output, sessionId, threadId, eventName }){
  /*
    here userInput & output are objects with the following structure:
    {
      content: "some text",
      role: "assistant" or "user"
    }

    this is a standard we use across creatorbot & topic app wherever we have to implement a bot interface. 

    context is the text of the message that was sent by role.
    role could be "assistant" or "user" in most cases.
  */
  const [clickedPositive, setClickedPositive] = useState(false);
  const [clickedNegative, setClickedNegative] = useState(false);

  const handleFeedback = (userMessage, messageContent, sentiment) => {
    if (sentiment === "positive") {
      setClickedPositive(true);
      setClickedNegative(false);
    } else {
      setClickedPositive(false);
      setClickedNegative(true);
    }

    const keenEventName =
      sentiment === "positive"
        ? `${eventName} Positive Feedback Sent`
        : `${eventName} Negative Feedback Sent`;
    // Send Keen tracking event
    trackEvent(keenEventName, {
      sessionId,
      threadId,
      message: messageContent,
      userInput: userMessage,
    });
  };

  return (
    <div>
      {output?.role === "assistant" && (
        <div className="tw-flex tw-justify-end">
          <div className="tw-text-slate-500 tw-text-sm tw-pr-1">
            Did this response answer your question?
          </div>
          <button
            onClick={() => {
              handleFeedback(userInput.content, output.content, "positive");
            }}
            className="tw-feedback-button tw-p-0"
          >
            <svg
              className={`tw-feedback-icon tw-positive ${clickedPositive ? 'stroke-green-700' : 'tw-text-slate-500'}`}
              height="1.2em"
              width="1.2em"
              xmlns="http://www.w3.org/2000/svg"
              id="refresh"
              x="0"
              y="0"
              version="1.1"
              viewBox="0 0 29 29"
              xmlSpace="preserve"
            >
              <path d="M21.3,10.08A3,3,0,0,0,19,9H14.44L15,7.57A4.13,4.13,0,0,0,11.11,2a1,1,0,0,0-.91.59L7.35,9H5a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H17.73a3,3,0,0,0,2.95-2.46l1.27-7A3,3,0,0,0,21.3,10.08ZM7,20H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H7Zm13-7.82-1.27,7a1,1,0,0,1-1,.82H9V10.21l2.72-6.12A2.11,2.11,0,0,1,13.1,6.87L12.57,8.3A2,2,0,0,0,14.44,11H19a1,1,0,0,1,.77.36A1,1,0,0,1,20,12.18Z" />
            </svg>
          </button>
          <button
            onClick={() => {
              handleFeedback(userInput.content, output.content, "negative");
            }}
            className="tw-feedback-button tw-p-0"
          >
            <svg
              className={`tw-feedback-icon negative ${clickedNegative? 'stroke-red-700' : 'tw-text-slate-500'}`}
              height="1.2em"
              width="1.2em"
              xmlns="http://www.w3.org/2000/svg"
              id="refresh"
              x="0"
              y="0"
              version="1.1"
              viewBox="0 0 29 29"
              xmlSpace="preserve"
            >
              <path d="M19,2H6.27A3,3,0,0,0,3.32,4.46l-1.27,7A3,3,0,0,0,5,15H9.56L9,16.43A4.13,4.13,0,0,0,12.89,22a1,1,0,0,0,.91-.59L16.65,15H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM15,13.79l-2.72,6.12a2.13,2.13,0,0,1-1.38-2.78l.53-1.43A2,2,0,0,0,9.56,13H5a1,1,0,0,1-.77-.36A1,1,0,0,1,4,11.82l1.27-7a1,1,0,0,1,1-.82H15ZM20,12a1,1,0,0,1-1,1H17V4h2a1,1,0,0,1,1,1Z" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default ResponseFeedback;
