import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { trackEvent } from './keen';
import EditorSidebar from "./editor_sidebar";
import "mark.js/dist/jquery.mark.min.js";
import { getReportById } from "./redux/selectors";
import {
  fetchReportIfNeeded,
  fetchReport,
  updateActiveReportLocally,
  saveReport,
  showToast,
  requestContentRevisions,
  addContentRevisions,
} from "./redux/actions";
import {
  wordCountToColor,
  htmlTextIsDifferent,
  timesTopicIsUsed,
  setJSONToLocalStorage,
  getJSONFromLocalStorage,
  htmlMarksAreDifferent,
  isImportedAndNotConfirmed,
  checkIfRevisionContentHasChanged,
  updateContentWithRevision,
  getUrlParam
} from "./helpers";
import EditorImportModal from "./editor_import_modal";
import Loader from "./loader";
import EditorProgressbar from "./editor_progressbar";
import FroalaEditorWrapper from "./editor_froala_wrapper";
import EditorCopilot from "./editor_copilot";
import EditorImportInfoModal from "./editor_import_info_modal";
import EditorContentRevision from "./editor_content_revision";
import EditorDisconnectModal from "./editor_disconnect_modal";

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleTopicPopover = this.handleTopicPopover.bind(this);
    this.handleVersionHistoryPopover =
      this.handleVersionHistoryPopover.bind(this);
    this.importContent = this.importContent.bind(this);
    this.handleToggleCopilot = this.handleToggleCopilot.bind(this);
    this.unmarkHtml = this.unmarkHtml.bind(this);
    this.unmarkElement = this.unmarkElement.bind(this);
    this.markText = this.markText.bind(this);
    this.confirmImportInformation = this.confirmImportInformation.bind(this);
    this.froalaRef = React.createRef();
    this.node = React.createRef();
    this.startContentRevisionUpdatePoll =
      this.startContentRevisionUpdatePoll.bind(this);
    this.lastMarkedHTML = ""; // used by markText to avoid marking text too much
    this.pollInterval = 600000; // 10 minutes Interval
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.confirmRevisionUpdate = this.confirmRevisionUpdate.bind(this);
    this.handleAddContentRevision = this.handleAddContentRevision.bind(this);
    this.getEditorAndSidebarWidthClasses = this.getEditorAndSidebarWidthClasses.bind(this);
    this.observeTabChanges = this.observeTabChanges.bind(this);
    this.checkUserRewriteLikeMeAccess = this.checkUserRewriteLikeMeAccess.bind(this);

    this.copilotEnabledLocalStorageKey = "topic_copilotenabled";
    const copilotEnabled =
      getJSONFromLocalStorage(this.copilotEnabledLocalStorageKey) || false;

    this.state = {
      sidebarActiveTab: "topics", // research, researchDetail, topicDetail, questions
      showContentGradeTooltip: false,
      copyMode: true,
      copyModeDisabled: false, // some briefs do not support highlighting due to HTML parsing issues
      activeTopicName: "",
      saveState: "saved", // saving, saved, error,
      saveError: "",
      showImportModalLoader: false,
      isAnon: false,
      copilotEnabled,
      copilotIsFetching: false,
      initialized: false,
      isGradeLoading: false,
      showRevisions: false,
      activeRevisionInPreview: {
        mode: false,
        rev_id: "",
        name: "",
      },
      brainstormingSession : {
        tracking: false,
        sessionId: uuidv4(), // Generate Session ID (since no auth this will be renewed on refresh)
      }
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.report && !_.isEmpty(nextProps.report.anon_email)) {
      const derivedState = {
        isAnon: true,
      };
      return derivedState;
    }
    return null;
  }

  unmarkHtml(html) {
    const ownerDocument = document.implementation.createHTMLDocument("virtual"); // prevents images from reloading
    const memoryDiv = $("<div>", ownerDocument);
    memoryDiv.html(html).find("mark").contents().unwrap();
    return memoryDiv.html();
  }

  unmarkElement(el) {
    el.find("mark").contents().unwrap();
    return el;
  }

  handleModelChange(content) {
    if (htmlTextIsDifferent(content, this.props.report.content.html)) {
      const reportCopy = { ...this.props.report };
      reportCopy.content.html = this.unmarkHtml(content);
      this.props.updateActiveReportLocally(reportCopy);
      this.setState({
        saveState: "saving",
      });
    }
  }

  componentDidUpdate() {
    window.setTimeout(this.updateDimensions, 100);
    this.markText();
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidMount() {
    window.setTimeout(this.updateDimensions, 10);
    this.props.fetchReportIfNeeded(this.props.match.params.id);
    this.props.requestContentRevisions(this.props.match.params.id); // For Receiving Revisions for the Report.
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("click", this.handleTopicPopover);
    window.addEventListener("click", this.handleVersionHistoryPopover);
    // Handle Internet Disconnection
    // window.addEventListener('online', this.handleConnectionChange);
    // window.addEventListener('offline', this.handleConnectionChange);
    this.markText();
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
    this.startContentRevisionUpdatePoll();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("click", this.handleTopicPopover);
    window.removeEventListener("click", this.handleVersionHistoryPopover);
    // Handle Internet Disconnection
    // window.removeEventListener('online', this.handleConnectionChange);
    // window.removeEventListener('offline', this.handleConnectionChange);
    window.clearInterval(this.pollInterval);
  }

  updateDimensions() {
    const navTopHeight = $(".top-bar").outerHeight();
    const toolbarHeight = $(".fr-toolbar").outerHeight();
    const bottomBarHeight = $(".editor-bottom-bar").outerHeight();
    const windowHeight = $(window).height();
    const editorProgressbarHeight = $(".editor-progressbar").outerHeight();
    const editorSidebarNavHeight = $(".editor-sidebar-nav").outerHeight();
    const froalaWrapperHeight =
      windowHeight - navTopHeight - bottomBarHeight - toolbarHeight - 1;
    const editorSidebarHeight = windowHeight - navTopHeight - 1;
    const editorSidebarScrollHolderHeight =
      windowHeight -
      navTopHeight -
      editorProgressbarHeight -
      editorSidebarNavHeight;

    $(this.froalaRef.current)
      .find(".fr-wrapper")
      .css({ height: froalaWrapperHeight });
    $(".editor-sidebar").css({ height: editorSidebarHeight });
    $(".editor-sidebar-scroll-holder").css({
      height: editorSidebarScrollHolderHeight,
    });
  }

  handleTopicPopover(e) {
    const that = this;
    if (e.target.tagName.toLowerCase() === "mark") {
      // clicked on the mark, so hide all popovers and show the clicked one
      $(".topic-popover").remove();
      if (
        !$(e.target).parent().is("a") &&
        $(e.target).parents(".editor-sidebar").length === 0
      ) {
        // don't show popover if highlight is in link or in sidebar
        const markText = $(e.target).text().toLowerCase();

        // find the topic object
        let targetTopic;
        let targetIndex;
        this.props.report.report.recommended_topics.forEach((topic, index) => {
          const matchInSimilarKeywords = _.find(
            topic.similar_keywords,
            (keyword) => markText === keyword.toLowerCase()
          );
          if (markText === topic.name.toLowerCase() || matchInSimilarKeywords) {
            targetTopic = topic;
            targetIndex = index;
          }
        });

        if (targetTopic) {
          const uses = timesTopicIsUsed(that.props.report, targetTopic);
          const mean = Math.round(_.mean(targetTopic.count));
          const useString = mean > 1 ? `${mean} uses` : `${mean} use`;

          let relevanceString = "";
          let typicalString = "";
          let examplesString = "";
          if (_.isNumber(targetTopic.relevance)) {
            relevanceString = `<div class="pt-2"><b>Relevance</b>: ${Math.round(
              parseInt(targetTopic.relevance, 10)
            )}%</div>`;
          }
          if (_.isFinite(mean)) {
            typicalString = `<div><b>Typical</b>: ${useString}</div>`;
          }
          if (!_.isEmpty(targetTopic.count)) {
            examplesString = `<div class="pt-2">
                <button class="btn btn-outline-secondary editor-see-example" data-name="${targetTopic.name}">See Examples</button>
              </div>`;
          }

          $("body").append(
            $(`<div class="topic-popover" style="left:${e.clientX}px; top:${
              e.clientY
            }px;">
              <div style="font-style: italic;">${targetTopic.name}</div>
              ${relevanceString}
              ${typicalString}
              <div><b>Currently</b>: ${uses} use${uses > 1 ? "s" : ""} ${
              uses >= mean ? '<i class="fa fa-check text-success" ></i>' : ""
            }</div>
              ${examplesString}
            </div>`)
          );
        }
      }
    } else if ($(e.target).hasClass("editor-see-example")) {
      // clicked on the see example button on the popover
      $(".topic-popover").remove();

      // set the editor's selection at the end so that the
      // highlight that was clicked on does not disappear
      // (the editor markers cause markjs to hide the clicked highlight)
      AP.editor.selection.setAtEnd(AP.editor.$el.get(0));
      AP.editor.selection.restore();

      this.setState({
        sidebarActiveTab: "topicDetail",
        activeTopicName: $(e.target).attr("data-name"),
      });
    } else if ($(e.target).parents(".popover").length > 0) {
      // clicked on a popover, so do nothing
    } else {
      // clicked on anything else, so hide all popovers
      $(".topic-popover").remove();
    }
  }

  handleVersionHistoryPopover(e) {
    if ($(e.target).parents(".revision, .revision-button, .modal").length > 0) {
      // do nothing
    } else if (this.state.showRevisions) {
      this.setState({
        showRevisions: false,
      });
    }
  }

  filterOutTopicsWithCharsLessThan(topics, threshhold) {
    return _.compact(
      _.map(topics, (topic) => {
        if (topic.replace(/[^a-z\d\-\s']/gi, "").length < threshhold) {
          return null;
        }
        return topic;
      })
    );
  }

  markText() {
    const that = this;
    AP.editor && AP.editor.selection.save();

    const frView = $(that.froalaRef.current).find(".fr-view");
    const currentHTML = frView.html();

    const ownerDocument = document.implementation.createHTMLDocument("virtual");

    if (this.state.copyMode) {
      if (frView.find("mark").length > 0) {
        // only change html if we need to remove marks
        this.unmarkElement(frView);
      }
    } else if (
      !this.state.copyMode &&
      this.props.report &&
      !_.isEmpty(this.props.report.content)
    ) {
      const startTime = new Date().getTime();
      this.lastMarkedHTML = currentHTML;

      let recommended_topics = this.props.report.report.recommended_topics.map(
        (topicObj) => {
          if (_.includes(this.props.report.ignored_topics, topicObj.name)) {
            // ignore ignored topics
            return null;
          }

          let res = [topicObj.name];
          if (_.isArray(topicObj.similar_keywords)) {
            res = _.concat(res, topicObj.similar_keywords);
          }
          return res;
        }
      );
      recommended_topics = _.compact(_.flatten(recommended_topics));
      const recommended_topics_sorted_by_length = _.sortBy(
        recommended_topics,
        (topic) => topic.length * -1
      );

      const getTopicsRegex = function (topics) {
        return new RegExp(
          `\\b${topics
            .map((topic) => `(${topic.replace(/[^a-z\d\-\s&']/gi, "")})`)
            .join("(?![\\w=>])|\\b")}(?![\\w=>])`,
          "gi"
        );
      };

      const markTextInElement = function (element) {
        element = that.unmarkElement(element);

        element.markRegExp(
          getTopicsRegex(recommended_topics_sorted_by_length),
          {
            each: (mark) => {
              $(mark).css("background-color", "#c8deff");
            },
          }
        );
        element.find("mark mark").each((index, mark) => {
          const parent = $(mark).parent();
          parent.html(parent.text());
        });
        return element;
      };

      const virtualFrView = markTextInElement(
        $("<div>", ownerDocument).html(currentHTML)
      );

      if (htmlMarksAreDifferent(virtualFrView.html(), currentHTML)) {
        // this is an expensive operations so only remark when marks are different
        markTextInElement(frView);

        var endTime = new Date().getTime();
        console.log(`mark done, ${(endTime - startTime) / 1000}s elapsed`);
      } else {
        var endTime = new Date().getTime();
        console.log(
          `mark was not done, ${(endTime - startTime) / 1000}s elapsed`
        );
      }
    }

    AP.editor && AP.editor.selection.restore();
  }

  // imports content from a URL
  importContent({ url, extract_all_text }) {
    const that = this;
    this.setState({ showImportModalLoader: true });
    const newReport = { ...this.props.report, explicit_import_url: url };
    if (extract_all_text) {
      newReport.extract_all_text = true;
    }
    this.props.saveReport(newReport).then(() => {
      AP.editor.html.set(that.props.report.content.html);
      that.markText();
      that.setState({ showImportModalLoader: false });
      $(".editorImportModal").modal("hide");
    });
  }

  handleToggleCopilot() {
    this.setState({ copilotEnabled: !this.state.copilotEnabled });
    setJSONToLocalStorage(
      this.copilotEnabledLocalStorageKey,
      !this.state.copilotEnabled
    );
  }

  confirmImportInformation(confirmationValue) {
    if (confirmationValue == "NO") {
      $(".editorImportInfoModal").modal("show");
    } else {
      // Make API call to check confirmation status.
      // After Success refresh the reports.
      this.setState({
        isGradeLoading: true,
      });
      const newReport = { ...this.props.report, confirmed: true };
      this.props.saveReport(newReport).then(() => {
        this.setState({
          isGradeLoading: false,
        });
        this.props.fetchReportIfNeeded(this.props.match.params.id, true);
        this.handleAddContentRevision(false, true); // False for skipping content check & true for isImportConfirmed Flag
      });
    }
  }

  startContentRevisionUpdatePoll() {
    const that = this;
    const intervalFunction = function () {
      that.handleAddContentRevision(true);
    };
    this.pollInterval = window.setInterval(intervalFunction, this.pollInterval);
  }

  handleAddContentRevision(checkContentChanged, isImportConfirmed = false) {
    const reportId = this.props.match.params.id;
    const { addContentRevisions } = this.props;
    const { activeRevisionInPreview } = this.state;
    if (AP.editor && AP.editor.html && AP.editor.html.get()) {
      const content = AP.editor.html.get();
      if (checkContentChanged && !checkIfRevisionContentHasChanged(content)) {
        this.props.showToast({
          message: `Content was almost the same as previous version, so did not save new version.`,
          options: { appearance: "info", autoDismiss: true },
        });
      } else {
        // If The content is in Preview Mode, Skip Addition of the revision
        if (activeRevisionInPreview.mode) {
          return;
        }
        // For Updating Content for Revisions
        addContentRevisions(reportId, content, isImportConfirmed, (status) => {
          // if(!status){
          //   $('.EditorDisconnectModal').modal('show');
          // }
        });
        this.props.showToast({
          message: `Successfully saved new version.`,
          options: { appearance: "success", autoDismiss: true },
        });
      }
    }
  }

  handleConnectionChange() {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            $(".EditorDisconnectModal").modal("hide");
            return clearInterval(webPing);
          })
          .catch(() => $(".EditorDisconnectModal").modal("show"));
      }, 2000);
      return;
    }
    return $(".EditorDisconnectModal").modal("show");
  }

  confirmRevisionUpdate(prompt) {
    const that = this;
    if (prompt == "NO") {
      this.props.fetchReport(this.props.match.params.id, () => {
        const { content } = this.props.report;
        this.setState(
          { activeRevisionInPreview: { mode: false, rev_id: "", name: "" } },
          () => {
            // Set Html in the editor
            AP.editor.html.set(content.html);
            that.markText();
            // Disable Edits on the editor
            AP.editor.edit.on();
          }
        );
      });
    } else if (prompt == "YES") {
      // Update the Content with that of the revision
      updateContentWithRevision(
        this.props.match.params.id,
        this.state.activeRevisionInPreview.rev_id
      )
        .then((report) => {
          this.props.updateActiveReportLocally(report);
          this.props.showToast({
            message: `Successfully restored document to  ${this.state.activeRevisionInPreview.name}.`,
            options: { appearance: "success", autoDismiss: true },
          });
          this.setState(
            {
              showRevisions: false,
              activeRevisionInPreview: { mode: false, rev_id: "", name: "" },
            },
            () => {
              // Disable Edits on the editor
              AP.editor.edit.on();
            }
          );
        })
        .catch((e) => {
          this.props.showToast({
            message: `Error restoring document to  ${this.state.activeRevisionInPreview.name}.`,
            options: { appearance: "error", autoDismiss: true },
          });
          confirmRevisionUpdate("NO"); // Take it back to original State,
        });
    }
  }

  getEditorAndSidebarWidthClasses(){
    let {sidebarActiveTab} = this.state;
    if(/topicDetail/.test(sidebarActiveTab)){
      return {
        editor: 'col-7 col-xl-7 editor-hide-extra-bottom-bar-buttons',
        sidebar: 'col-5 col-xl-5'
      }
    } else if(/brainstorm/.test(sidebarActiveTab)){
      return {
        editor: 'col-8 col-xl-8 editor-hide-extra-bottom-bar-buttons',
        sidebar: 'col-4 col-xl-4'
      }
    } else {
      return {
        editor: 'col-9 col-xl-9-5',
        sidebar:'col-3 col-xl-2-5'
      }
    }
  }

  observeTabChanges(){
    const {sidebarActiveTab,brainstormingSession} = this.state;
    const {report} = this.props;
    if(sidebarActiveTab === 'brainstorm'){
      trackEvent('Brainstorm', {
        event: 'Brainstorming Tab Opened',
        siteId: report.tags.join(),
        briefId: report.id,
        sessionId: brainstormingSession.sessionId,
      });
    }
  }

  checkUserRewriteLikeMeAccess(){
    const access = _.intersection(_.get(this.props, 'report.tags', []), [
        '62018b0492eb9f76176da22e',
        '52e41fac28963d1e058a10a8',
        '58acab12aa5ec91288e8b076',
        '58adff0b42b971433a846d52',
        '5e66587b3b6b4e48c9675167',
        '570fd6a321c81564793179da',
        '640ab0c11a71b14f774f86a2',
        ]).length > 0 ||
        getUrlParam('writelikeme');

    return access;
  }

  render() {
    const that = this;
    AP.report = this.props.report;
    const {
      copyModeDisabled,
      copilotEnabled,
      initialized,
      copilotIsFetching,
      isGradeLoading,
      activeRevisionInPreview,
      brainstormingSession,
    } = this.state;
    const showEmptyState =
      !this.props.report ||
      (this.props.report &&
        (_.isEmpty(this.props.report.content) ||
          this.props.report.content.html.trim().length === 0));
    const isImportedAndNotConfirmedState = isImportedAndNotConfirmed(
      this.props.report
    );
    const isPending =
      _.isEmpty(this.props.report) || _.isEmpty(this.props.report.report);
    const userHasCopilotAccess =
      AP &&
      AP.currentUser &&
      AP.currentUser.plan &&
      AP.currentUser.plan.meta &&
      AP.currentUser.plan.meta.copilot;
    const userHasRewriteLikeMeAccess = this.checkUserRewriteLikeMeAccess();
    const userIsPayingUsEnough =
      AP &&
      AP.currentUser &&
      AP.currentUser.plan &&
      AP.currentUser.plan.amount >= 10000;
    this.lastSaveAttemptTimestamp = new Date().getTime();
    let wordCountColor;
    if (this.props.report && this.props.report.report) {
      wordCountColor = wordCountToColor(
        this.props.report.content.word_count,
        this.props.report.report.recommended_word_count
      );
    }

    const previewView = (
      <div className="row">
        <div className="col-md-8 offset-2 text-center pt-5">
          <h2>Content Grader</h2>
          <img src="/img/grader-illustration.png" className="w-100" />
          <a
            className="btn btn-outline-primary "
            target="_blank"
            href="https://app.usetopic.com/briefs/588db623/editor"
            rel="noreferrer"
          >
            Preview Content Grader
          </a>
          <a className="btn btn-primary text-white ml-2" href="/subscribe">
            Upgrade to Access
          </a>
        </div>
      </div>
    );

    const editorPrompts = () => {
      if (activeRevisionInPreview.mode) {
        return (
          <div
            className={`editor-empty-state p-3 animate-all import-confirmation ${
              isGradeLoading ? "" : "active"
            }`}
          >
            <p className="text-bold m-0 mr-2">
              {" "}
              <i className="fa fa-arrow-down pr-2" /> Would you like to restore
              the document to this version?{" "}
              <button
                className="btn btn-primary btn-sm mr-2 ml-2"
                onClick={() => {
                  this.confirmRevisionUpdate("YES");
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-primary btn-sm mr-2"
                onClick={() => {
                  this.confirmRevisionUpdate("NO");
                }}
              >
                No
              </button>
            </p>
          </div>
        );
      }
      return (
        <div
          className={`editor-empty-state p-3 animate-all ${
            this.props.report &&
            (_.isEmpty(this.props.report.content) ||
              this.props.report.content.html.trim().length === 0)
              ? "active"
              : ""
          }`}
        >
          <p className="m-0">
            <i className="fa fa-arrow-down pr-2" /> To get started paste a draft
            into the text area below, or{" "}
            <a
              href="#"
              style={{ textDecoration: "underline" }}
              onClick={() => {
                $(".editorImportModal").modal("show");
              }}
            >
              import content from a URL
            </a>
            .
          </p>
        </div>
      );
    };

    const editorView = (
      <div className="d-flex flex-grow-1">
        {!this.props.report && (
          <div className="p-4 text-center">
            <Loader type="spinner" className="dark" />
          </div>
        )}
        {this.props.report && isPending && (
          <div className="p-4 text-center w-100">
            It looks like your brief is still being created. Come back in a
            couple of minutes.
          </div>
        )}
        {this.props.report && !isPending && (
          <div className="container-fluid p-0 d-flex editor" ref={this.node}>
            <div className="d-flex flex-grow-1 flex-column">
              <div className="d-flex">
                <div
                  className={`flex-column d-flex p-0 animate-all editor-froala-container ${this.getEditorAndSidebarWidthClasses().editor}`}
                  ref={this.froalaRef}
                >
                  {userHasCopilotAccess && (
                    <button
                      className="btn bg-primary-1 hover-bg-primary-2 editor-copilot-toggle"
                      onClick={this.handleToggleCopilot}
                    >
                      <i className="fad fa-steering-wheel mr-2" />
                      {copilotEnabled ? "Disable" : "Enable"} Copilot
                      <div
                        className="badge badge-pill bg-green-2 text-green-8 text-s"
                        style={{
                          position: "absolute",
                          top: "-9px",
                          right: "-10px",
                          borderRadius: "3px",
                        }}
                      >
                        beta
                      </div>
                    </button>
                  )}
                  {!userHasCopilotAccess && userIsPayingUsEnough && (
                    <a
                      className="btn bg-primary-1 hover-bg-primary-2 editor-copilot-toggle"
                      target="_blank"
                      href="https://copilot.paperform.co"
                      rel="noreferrer"
                    >
                      <i className="fad fa-steering-wheel mr-2" />
                      Enable Copilot
                      <div
                        className="badge badge-pill bg-green-2 text-green-8 text-s"
                        style={{
                          position: "absolute",
                          top: "-9px",
                          right: "-10px",
                          borderRadius: "3px",
                        }}
                      >
                        beta
                      </div>
                    </a>
                  )}
                  {!!this.props.report.content.word_count && (
                    <div className="editor-word-count rounded">
                      <span
                        className={`badge badge-pill bg-${wordCountColor}-2 text-${wordCountColor}-10`}
                      >
                        {this.props.report.content.word_count}
                      </span>{" "}
                      Words
                      <div className="text-s" style={{ opacity: "0.5" }}>
                        {Math.round(
                          this.props.report.report.recommended_word_count / 100
                        ) * 100}
                        + Recommended
                      </div>
                    </div>
                  )}
                  <div className="flex-grow-1 editor-fr-container position-relative">
                    <div className="d-flex w-100 editor-alerts">
                      {editorPrompts()}
                    </div>
                    <FroalaEditorWrapper
                      initialized={initialized}
                      updateParentState={(status) => {
                        that.setState(status);
                      }}
                      copilotIsFetching={copilotIsFetching}
                      revisionPreview={this.state.activeRevisionInPreview.mode}
                      handleAddContentRevision={this.handleAddContentRevision}
                    />
                    <EditorCopilot
                      enabled={userHasRewriteLikeMeAccess} // Disable Rewrite Like Me functionality to deploy brainstorming for all
                      initialized={initialized}
                      report={this.props.report}
                      isFetching={copilotIsFetching}
                      setIsFetching={(isFetching) => {
                        this.setState({ copilotIsFetching: isFetching });
                      }}
                    />
                  </div>
                  <div className="editor-bottom-bar">
                    <div
                      className="custom-control custom-switch d-inline-block"
                      style={{ opacity: copyModeDisabled ? 0.5 : 1 }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="copy-mode-switch"
                        checked={!this.state.copyMode}
                        onChange={() => {
                          this.setState({ copyMode: !this.state.copyMode });
                        }}
                      />
                      <label
                        className="custom-control-label clickable"
                        htmlFor="copy-mode-switch"
                        data-original-title="Toggle highlighting for copy and pasting into other editors."
                        data-toggle="tooltip"
                      >
                        Highlight Topics
                      </label>
                      {copyModeDisabled && (
                        <i
                          className="fa fa-exclamation-triangle ml-2"
                          data-original-title="Occasionally we are not able to highlight Topics for specific briefs for technical reasons. This does not affect grading."
                          data-toggle="tooltip"
                        />
                      )}
                    </div>
                    <div className="float-right pt-1">
                      {this.state.saveState === "saved" && (
                        <div>
                          Saved <i className="fa fa-check text-success" />
                        </div>
                      )}
                      {this.state.saveState === "saving" && (
                        <div>Saving...</div>
                      )}
                      {this.state.saveState === "error" && (
                        <div
                          data-original-title={this.state.saveError}
                          data-toggle="tooltip"
                        >
                          Save Error
                        </div>
                      )}
                    </div>
                    <div className="float-right mr-3 revision-button">
                      <button
                        className={`btn btn-white text-muted ${
                          this.state.showRevisions ? "active" : ""
                        }`}
                        onClick={() => {
                          this.setState({
                            showRevisions: !this.state.showRevisions,
                          });
                        }}
                      >
                        <i className="mr-1 fad fa-clock" /> Version History
                      </button>
                    </div>
                    <div className="float-right mr-4">
                      <button
                        className="btn btn-white text-muted"
                        onClick={() => {
                          $(".editorImportModal").modal("show");
                        }}
                      >
                        <i className="mr-1 fad fa-file-import" /> Import from
                        URL
                      </button>
                    </div>
                    <div className="float-right mr-1">
                      <a
                        className="btn btn-white text-muted editor-google-docs-add-on"
                        href="https://help.adthrive.com/hc/en-us/articles/4406596212763-How-to-use-Topic-in-WordPress-and-Google-Docs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="mr-1 fad fa-file-alt" /> GDocs Add-On
                      </a>
                    </div>
                    <div className="float-right mr-1">
                      <a
                        className="btn btn-white text-muted editor-wordpress-plugin"
                        href="https://help.adthrive.com/hc/en-us/articles/4406596212763-How-to-use-Topic-in-WordPress-and-Google-Docs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="mr-1 fab fa-wordpress-simple" /> Wordpress
                        Plugin
                      </a>
                    </div>
                    <div className="float-right mr-1">
                      <button
                        className="btn btn-white text-muted editor-how-to-use-grader"
                        onClick={() => {
                          $("#grader-video").modal("show");
                        }}
                      >
                        <i className="mr-1 fab fa-youtube" /> How to Use The
                        Grader
                      </button>
                    </div>
                    <EditorContentRevision
                      show={this.state.showRevisions}
                      report={this.props.report}
                      revisionPreview={(rev_id, name) => {
                        this.setState({
                          activeRevisionInPreview: {
                            mode: true,
                            rev_id,
                            name,
                          },
                        });
                      }}
                      activeRevision={this.state.activeRevisionInPreview}
                      handleAddContentRevision={this.handleAddContentRevision}
                    />
                  </div>
                </div>
                <div
                  className={`animate-all ${this.getEditorAndSidebarWidthClasses().sidebar}`}
                >
                  <EditorSidebar
                    report={this.props.report}
                    activeTab={this.state.sidebarActiveTab}
                    onTabChange={(tab) => {
                      this.setState({ sidebarActiveTab: tab }, this.observeTabChanges);
                    }}
                    activeTopicName={this.state.activeTopicName}
                    onActiveTopicNameChange={(name) => {
                      this.setState({ activeTopicName: name });
                    }}
                    isAnon={this.state.isAnon}
                    showToast={this.props.showToast}
                    gradingProgressBar={
                      <EditorProgressbar
                        saveState={this.state.saveState}
                        report={this.props.report}
                        isGradeLoading={isGradeLoading}
                        showEmptyState={showEmptyState}
                        confirmImportInformation={this.confirmImportInformation}
                      />
                    }
                    brainstormSessionId={brainstormingSession.sessionId}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <EditorImportModal
          onSubmit={this.importContent}
          showLoader={this.state.showImportModalLoader}
          report={this.props.report}
        />
        <EditorImportInfoModal report={this.props.report} />
        <EditorDisconnectModal />
        <div className="editor-screen-dimension-alert">
          <div className="element-box">
            Please expand your window. The grader does not support screen
            resolutions smaller than 768px by 768px
          </div>
        </div>
      </div>
    );

    if (!AP.currentUser.plan || AP.currentUser.plan.meta.content_grader) {
      return editorView;
    }
    return previewView;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    report: getReportById(state, ownProps.match.params.id),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchReportIfNeeded,
    fetchReport,
    updateActiveReportLocally,
    saveReport,
    showToast,
    requestContentRevisions,
    addContentRevisions,
  })(Editor)
);
