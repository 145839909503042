import React from "react";
import { useSelector } from "react-redux";
import OutlineTitleBookmarkListItem from "../content/title/list_item";

/**
 * Renders the 3 most recent title bookmarks
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineRecentTitleBookmarks({ report, handleSelect, selectedTitle }) {
  const { titleBookmarks } = useSelector((state) => state.outline);

  if (!titleBookmarks || titleBookmarks.length === 0) {
    return null;
  }

  const list = [];
  const offset = titleBookmarks.length > 3 ? titleBookmarks.length - 3 : 0;
  for (let i = offset; i < titleBookmarks.length; i++) {
    const itemIndex = i - offset;
    list.push(
      <OutlineTitleBookmarkListItem
        key={`recent-title-${itemIndex}`}
        report={report}
        title={titleBookmarks[i]}
        i={itemIndex}
        handleSelect={handleSelect}
        selectedTitle={selectedTitle}
      />
    );
  }
  return (
    <div className="pb-2">
      <h6>Recently Bookmarked</h6>
      {list}
    </div>
  );
}

export default OutlineRecentTitleBookmarks;
