/* eslint-disable */

import React from "react";
import Loader from "./loader";

// props
// type - ['upgrade', 'switch'] - determines if this is used in the upgrade flow or the switch flow
//      - if used in the upgrade flow, it modifies the payment form
//      - if used in the switch flow, it modifies the switch form and indicates the current plan

class SubscriptionUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlanId: null,
      calculatedAmount: null,
      isOneTimeCoupon: false,
      couponCode: "",
      coupon: null,
      couponError: "",
      isLoadingCoupon: false,
      validationError: "",
      reactivateInProcess: false,
    };
    this.plans = (() => {
      let filtered_plans = _.filter(AP.plans, (plan) => {
        if (plan.name.indexOf("Trial") === -1) {
          if (AP.currentUser.plan && AP.currentUser.plan.id == plan.id) {
            plan.active = true;
            return plan;
          }
          if (
            plan.associated_role == "ptrial" &&
            AP.currentUser.activated_paid_trail == "true"
          ) {
          } else if (plan.active) {
            return plan;
          }
        }
      });

      if (AP.currentUser.plan && AP.currentUser.plan.associated_role == "pro") {
        filtered_plans = _.filter(
          filtered_plans,
          (plan) => plan.associated_role != "ptrial" && plan.active
        );
      }
      return filtered_plans;
    })();
    this.handleSelect = this.handleSelect.bind(this);
    this.calculateAmount = this.calculateAmount.bind(this);
    this.handleCouponApply = this.handleCouponApply.bind(this);
    this.centsToFormattedDollars = this.centsToFormattedDollars.bind(this);
    this.addOrUpdateFieldToPaymentForm =
      this.addOrUpdateFieldToPaymentForm.bind(this);
    this.reactivateScheduledSubscription =
      this.reactivateScheduledSubscription.bind(this);
    this.validate = this.validate.bind(this);
    if (this.props.type === "upgrade") {
      this.formClass = "#payment_form";
    } else if (this.props.type === "switch") {
      this.formClass = "#plan_switch_form";
    }
  }

  componentDidMount() {
    // bind to the submit button for extra validation
    const form = $(this.formClass);
    form.find("button").click(this.validate);
  }

  componentDidUpdate() {
    // if there is a credit card form on the page, keep the coupon in sync with it so it can be sent to stripe
    const form = $(this.formClass); // located at _card_form.haml
    if (!_.isObject(this.state.coupon)) {
      this.addOrUpdateFieldToPaymentForm(form, "coupon", null);
    } else {
      this.addOrUpdateFieldToPaymentForm(form, "coupon", this.state.coupon.id);
    }
    this.addOrUpdateFieldToPaymentForm(
      form,
      "plan_id",
      this.state.selectedPlanId
    );
  }

  addOrUpdateFieldToPaymentForm(form, name, value) {
    if ($(form).length > 0) {
      let hiddenInput = $(form).find(`input[name="${name}"]`);
      if (value === null && hiddenInput.length > 0) {
        // value is null so remove field if present
        hiddenInput.remove();
      } else if (hiddenInput.length === 0) {
        // create new input
        hiddenInput = $("<input>");
        hiddenInput.attr("type", "hidden");
        hiddenInput.attr("name", name);
        hiddenInput.attr("value", value);
        $(form).append(hiddenInput);
      } else {
        // update existing input
        hiddenInput.attr("type", "hidden");
        hiddenInput.attr("name", name);
        hiddenInput.attr("value", value);
      }
      return hiddenInput;
    }
  }

  centsToFormattedDollars(cents) {
    if (cents === 0) {
      return "Free";
    }
    const dollars = cents / 100;
    return `${dollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })}/mo`;
  }

  calculateAmount(planId, coupon) {
    let calculatedAmount = 0;
    if (!planId) {
      return;
    }
    calculatedAmount = _.find(this.plans, { id: planId }).amount;

    if (coupon) {
      // apply coupon to calculatedAmount
      if (_.isNumber(coupon.percent_off)) {
        // percentage
        calculatedAmount -= (calculatedAmount * coupon.percent_off) / 100;
      } else if (_.isNumber(coupon.amount_off)) {
        // amount off
        calculatedAmount -= coupon.amount_off;
        if (calculatedAmount < 0) {
          calculatedAmount = 0;
        }
      }
    }
    return calculatedAmount;
  }

  handleSelect(planId) {
    if (
      planId != AP.currentUser.scheduled_plan_id ||
      planId == AP.currentUser.planId
    ) {
      this.setState({
        selectedPlanId: planId,
        calculatedAmount: this.calculateAmount(planId, this.state.coupon),
        validationError: "",
      });
    } else {
      this.setState({
        selectedPlanId: null,
        calculatedAmount: null,
        validationError: "",
      });
    }
  }

  handleCouponApply() {
    const that = this;

    if (_.isNull(this.state.selectedPlanId)) {
      this.setState({
        validationError: "Please select a plan before applying a coupon.",
      });
      return;
    }

    this.setState({ isLoadingCoupon: true });

    // get coupon from stripe
    fetch(
      `/subscription/coupon?coupon=${encodeURIComponent(
        this.state.couponCode
      )}`,
      {
        credentials: "same-origin",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        that.setState({ isLoadingCoupon: false });
        if (response.error) {
          that.setState({ couponError: response.error });
        } else {
          that.setState({
            coupon: response,
            calculatedAmount: that.calculateAmount(
              that.state.selectedPlanId,
              response
            ),
            couponError: "",
            isOneTimeCoupon: response.duration === "once",
          });
        }
      });
  }

  validate(e) {
    if (!_.isInteger(this.state.selectedPlanId)) {
      e.stopPropagation();
      e.preventDefault();
      this.setState({
        validationError: "Please select a plan above ☝️",
      });
    } else if (
      this.state.couponCode !== "" &&
      (_.isNull(this.state.coupon) ||
        this.state.coupon.id !== this.state.couponCode)
    ) {
      e.stopPropagation();
      e.preventDefault();
      this.setState({
        validationError: "Please apply your coupon",
      });
    } else {
      this.setState({
        validationError: "",
      });
    }
  }

  reactivateScheduledSubscription() {
    if (
      window.confirm(
        `Would you like to reactivate your account, You will be set back to current plan on account ${AP.currentUser.email}`
      )
    ) {
      this.setState({ reactivateInProcess: true });
      window.location.href = "/subscription/reactivate";
    }
  }

  render() {
    let totalView;
    if (_.isObject(this.state.coupon)) {
      // crossout original price and show new price
      totalView = (
        <div>
          Total:{" "}
          <span style={{ textDecoration: "line-through" }}>
            {this.centsToFormattedDollars(
              _.find(this.plans, { id: this.state.selectedPlanId }).amount
            )}
          </span>{" "}
          <span className="text-bold text-success">
            {this.centsToFormattedDollars(this.state.calculatedAmount)}
          </span>{" "}
          / mo{" "}
          {this.state.isOneTimeCoupon && (
            <div className="text-s">* this coupon is a one-time coupon</div>
          )}
        </div>
      );
    } else if (this.state.calculatedAmount) {
      // no coupon applied
      totalView = (
        <div>
          Total:{" "}
          <span className="text-bold">
            {this.centsToFormattedDollars(this.state.calculatedAmount)}
          </span>{" "}
          / mo
        </div>
      );
    } else {
      totalView = <div>Select a plan to see price</div>;
    }

    return (
      <div className="row pb-4">
        {_.map(this.plans, (plan, index) => {
          if (AP.currentUser.planId != plan.id) {
            return null;
          }
          const isSelected = this.state.selectedPlanId === plan.id;
          let isCurrentPlan = false;
          if (this.props.type === "switch") {
            // don't allow interaction if this is the current user's plan and this module is "switch" type
            isCurrentPlan = AP.currentUser.planId === plan.id;
          }
          return (
            <div key={index} className="col-md-6">
              <div
                className={`element-box ${isCurrentPlan ? "" : "clickable"}`}
                style={{ minHeight: "230px" }}
                onClick={() => {
                  if (!isCurrentPlan) {
                    this.handleSelect(plan.id);
                  }
                }}
              >
                <h5>
                  {plan.name}, {this.centsToFormattedDollars(plan.amount)}
                </h5>
                <ul style={{ paddingLeft: "18px" }}>
                  {_.map(plan.features, (feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {!isCurrentPlan && (
                    <button
                      className={`btn ${
                        isSelected ? "btn-success" : "btn-outline-primary"
                      }`}
                      hidden={plan.id == AP.currentUser.scheduled_plan_id}
                      onClick={() => {
                        this.handleSelect(plan.id);
                      }}
                    >
                      {isSelected
                        ? "Plan Selected"
                        : `Select ${plan.name} Plan`}
                    </button>
                  )}
                  {isCurrentPlan && (
                    <div style={{ marginTop: 10 }}>
                      <i className="fa fa-check-circle text-success" />
                      Current Plan
                      {AP.currentUser.scheduled_plan_id ||
                      AP.currentUser.cancellation_in_progress ? (
                        <div>
                          (
                          {AP.currentUser.scheduled_plan_id
                            ? "Downgrading"
                            : "Cancelling"}{" "}
                          on{" "}
                          {new Date(
                            AP.currentUser.currentPeriod.replace(/-/g, "/")
                          ).toLocaleString("default", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                          )
                        </div>
                      ) : null}
                      {(AP.currentUser.scheduled_plan_id ||
                        AP.currentUser.cancellation_in_progress) &&
                      !this.state.reactivateInProcess ? (
                        <button
                          className="btn btn-primary btn-reactivate-account"
                          onClick={() => {
                            this.reactivateScheduledSubscription();
                          }}
                        >
                          <strong>Reactivate</strong>
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="col-md-12 d-flex flex-row">
        <div className="p-3 text-l flex-grow-1">{totalView}</div>
        <div style={{width: '240px', height: '38px'}} className="d-flex flex-column">
          <div className="input-group pt-3 pb-1">
            <input name="coupon" className="form-control" placeholder="Coupon" value={this.state.couponCode} onChange={(e) => { this.setState({couponCode: e.target.value}); }} type="text" required />
            <div className="input-group-append">
              <button className="btn btn-outline-primary" type="button" onClick={this.handleCouponApply}>Apply</button>
              {this.state.isLoadingCoupon && <Loader type="spinner" className="dark p-2" />}
            </div>
          </div>
          {this.state.couponError && <div className="text-right">{this.state.couponError}</div>}
        </div>
      </div> */}
        {this.state.validationError && (
          <div className="col-md-12 text-danger">
            {this.state.validationError}
          </div>
        )}
      </div>
    );
  }
}

export default SubscriptionUpdate;
