import {
  REQUEST_SEGMENTS,
  RECEIVE_SEGMENTS,
  SET_ACTIVE_SEGMENT,
  SET_IS_FETCHING,
  ADD_SEGMENT,
  UPDATE_SEGMENT_LOCALLY,
  REMOVE_SEGMENT,
  SET_SELECTED_SEGMENT,
} from "../actions";

const segmentLabels = [
  {
    id: 1,
    color: "#B2689D",
    backgroundColor: "#DCB1D0",
    icon: "fas fa-question",
  },
  {
    id: 2,
    color: "#B681CC",
    backgroundColor: "#DDCFE3",
    icon: "fas fa-flask",
  },
  {
    id: 3,
    color: "#7186D6",
    backgroundColor: "#CECAE1",
    icon: "fad fa-pie",
  },
  {
    id: 4,
    color: "#6880D7",
    backgroundColor: "#B8C3E9",
    icon: "fad fa-crow",
  },
  {
    id: 5,
    color: "#397FD3",
    backgroundColor: "#B4CFF0",
    icon: "fas fa-mouse-pointer",
  },
  {
    id: 6,
    color: "#40A0D0",
    backgroundColor: "#A7D8F0",
    icon: "fas fa-paper-plane",
  },
  {
    id: 7,
    color: "#81D6E9",
    backgroundColor: "#CEF6FF",
    icon: "fad fa-gem",
  },
  {
    id: 8,
    color: "#0DB39E",
    backgroundColor: "#98EADF",
    icon: "fas fa-check-double",
  },
  {
    id: 9,
    color: "#3EBD93",
    backgroundColor: "#C3F8E1",
    icon: "fas fa-check",
  },
  {
    id: 10,
    color: "#65CA76",
    backgroundColor: "#D2F2D7",
    icon: "fas fa-dollar-sign",
  },
  {
    id: 11,
    color: "#92BD47",
    backgroundColor: "#E1F6BC",
    icon: "fad fa-seedling",
  },
  {
    id: 12,
    color: "#D1A73E",
    backgroundColor: "#F8F6BF",
    icon: "fad fa-pencil",
  },
  {
    id: 13,
    color: "#C9A13C",
    backgroundColor: "#FFEDBE",
    icon: "fad fa-sparkles",
  },
  {
    id: 14,
    color: "#C6792F",
    backgroundColor: "#FFD0A2",
    icon: "fad fa-cheeseburger",
  },
  {
    id: 15,
    color: "#E12D39",
    backgroundColor: "#FF9B9B",
    icon: "fas fa-exclamation",
  },
];

const defaultSegment = {
  name: "Unsaved Segment",
  filters: [],
  saveState: "unsaved", // 'saved or unsaved'
  id: "newid",
};

export function segments(
  state = {
    isFetching: false,
    items: [],
    didInvalidate: true,
    defaultSegment,
    segmentLabels,
  },
  action
) {
  switch (action.type) {
    case REQUEST_SEGMENTS:
      return { ...state, isFetching: true };
    case RECEIVE_SEGMENTS:
      return {
        ...state,
        isFetching: false,
        items: action.segments,
        didInvalidate: false,
      };
    case ADD_SEGMENT:
      var stateCopy = { ...state };
      stateCopy.items.push(action.segment);
      return stateCopy;
    case SET_IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case UPDATE_SEGMENT_LOCALLY:
      // replace the active segment locally
      const index = _.findIndex(
        state.items,
        (item) => item.id === action.segment.id
      );
      var stateCopy = _.cloneDeep(state);
      if (index !== -1) {
        var newItem = _.merge({}, action.segment); // do a deep merge to retain any data not sent back by server
        stateCopy.items[index] = _.cloneDeep(newItem);
      } else {
        // updating a segment that does not exist in the db yet
        var newItem = _.merge({}, action.segment);
        stateCopy.items.push(_.cloneDeep(newItem));
      }
      stateCopy.isFetching = false;
      return stateCopy;
    case REMOVE_SEGMENT:
      var stateCopy = { ...state };
      stateCopy.items = _.filter(
        stateCopy.items,
        (item) => item.id !== action.segmentId
      );
      return stateCopy;
    default:
      return state;
  }
}

export function activeSegment(state = null, action) {
  if (action.type === SET_ACTIVE_SEGMENT) {
    return action.segmentId;
  }
  return state || "newid"; // default to 'newid' id active segment if no active segment is defined
}

export function selectedSegment(state = null, action) {
  if (action.type === SET_SELECTED_SEGMENT) {
    return _.cloneDeep(action.segmentId);
  }
  return state; // default to 'newid' id active segment if no active segment is defined
}
