import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import OutlineBookmarksTitle from "./title";
import OutlineBookmarksDescription from "./description";
import { BookmarkType } from "./bookmark_type";

/**
 * Represents the container that has the tab selection and the bookmark list
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineBookmarksContent({ report, handleClose }) {
  const { showBookmarks } = useSelector((state) => state.outline);
  const [bookmarkType, setBookmarkType] = React.useState(BookmarkType.TITLE);

  if (!showBookmarks) {
    return null;
  }
  return (
    <>
      <div className="builder-popover-rainbow" />

      <div className="animated fadeIn builder-popover-bookmarks-container p-3 pt-4">
        <div className="builder-popover-head mb-2">Bookmarks</div>
        <div className="btn-group w-100">
          <button
            type="button"
            className={`btn w-50 ${
              bookmarkType === BookmarkType.TITLE ? "btn-primary" : ""
            }`}
            onClick={() => {
              setBookmarkType(BookmarkType.TITLE);
            }}
          >
            Title
          </button>
          <button
            type="button"
            className={`btn w-50 ${
              bookmarkType === BookmarkType.DESCRIPTION ? "btn-primary" : ""
            }`}
            onClick={() => {
              setBookmarkType(BookmarkType.DESCRIPTION);
            }}
          >
            Description
          </button>
        </div>
        <div className="pt-2">
          {bookmarkType === BookmarkType.TITLE && (
            <OutlineBookmarksTitle report={report} handleClose={handleClose} />
          )}
          {bookmarkType === BookmarkType.DESCRIPTION && (
            <OutlineBookmarksDescription
              report={report}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default OutlineBookmarksContent;
