import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  dateToPrettyString,
  gradeToColor,
  isImportedAndNotConfirmed,
} from "./helpers";
import ShareToggle from "./share_toggle";
import { getReportById } from "./redux/selectors";

class NavTop extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let reportNameView;
    let shareView;
    let dashboardNavView;
    const { report } = this.props;
    const showReport = !_.isEmpty(report);
    const isLoggedIn = !_.isEmpty(AP.currentUser.email);
    const isOwner = AP.currentUser.owner;
    const isSubAccount = AP.currentUser.sub_account;
    const isPaidUser = AP.currentUser.is_paid_user;
    const isCancelledUser = AP.currentUser.cancelled;
    const userHasInventoriesAccess =
      AP &&
      AP.currentUser &&
      AP.currentUser.plan &&
      AP.currentUser.plan.meta &&
      AP.currentUser.plan.meta.inventories;
    const userIsCafemedia =
      (AP &&
        AP.currentBriefUser &&
        AP.currentBriefUser.plan &&
        AP.currentBriefUser.plan.meta &&
        AP.currentBriefUser.plan.meta.cafemedia) ||
      (AP &&
        AP.currentUser &&
        AP.currentUser.plan &&
        AP.currentUser.plan.meta &&
        AP.currentUser.plan.meta.cafemedia);
    const isInventory = /inventories|inventory/.test(
      this.props.location.pathname
    );
    const isBriefs = /briefs/.test(this.props.location.pathname);
    const isLearn = /learn/.test(this.props.location.pathname);
    const isEditor = /\/editor/.test(window.location.href);
    const isBrief = /\/overview/.test(window.location.href);

    if (isEditor || isBrief) {
      if (showReport) {
        // const locationString = _.isEmpty(report.location) ? 'United States (google.com)' : `${report.location.canonical_name} (${report.location.domain})`;
        const { grade } = report.content;
        const isImportedAndNotConfirmedState =
          isImportedAndNotConfirmed(report);
        const showGrade =
          !isImportedAndNotConfirmedState &&
          report.content &&
          _.isNumber(report.content.word_count) &&
          report.content.word_count > 20;
        reportNameView = (
          <div className="flex-grow-1 mt-1">
            <div className="row">
              <div className="text-left">
                <ul
                  className={`nav nav-tabs ${isBrief ? "mr-5" : ""}`}
                  style={{ borderBottom: "none", marginBottom: "-11px" }}
                >
                  {true && (
                    <li className="nav-item" style={{ zIndex: 2 }}>
                      <Link
                        to={{
                          pathname: `/briefs/${this.props.match.params.id}/overview`,
                          search: this.props.location.search,
                        }}
                        className={`${
                          isBrief
                            ? "active active-editor text-bold"
                            : "not-active-editor"
                        } clickable nav-side-tab nav-link d-flex`}
                      >
                        <div className="os-icon os-icon-tasks-checked d-inline pr-2 mt-1" />{" "}
                        <span data-test="main-menu-enhancements">
                          Content Research
                        </span>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item" style={{ zIndex: 2 }}>
                    <Link
                      to={{
                        pathname: `/briefs/${this.props.match.params.id}/editor`,
                        search: this.props.location.search,
                      }}
                      className={`${
                        isEditor
                          ? "active active-editor text-bold"
                          : "not-active-editor"
                      } clickable nav-side-tab nav-link d-flex`}
                    >
                      <div className="os-icon os-icon-text-input d-inline pr-2 mt-1" />{" "}
                      <span data-test="main-menu-enhancements">
                        Content Grader
                      </span>
                      {showGrade && (
                        <div
                          className={`badge bg-${gradeToColor(
                            grade
                          )}-2 text-${gradeToColor(grade)}-10 ml-2 pr-2 pl-2`}
                          style={{
                            lineHeight: "1.1",
                            opacity: isEditor ? "1" : "0.6",
                          }}
                        >
                          {grade}
                        </div>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col text-right mr-5 mt-1 nav-top-brief-label">
                <span>Brief For </span>
                <span className="text-bold">{report.name}</span>
                {isLoggedIn &&
                  !_.isEmpty(report.tags) &&
                  report.tags.map((tag, index) => (
                    <Link to={{ pathname: `/briefs/tag/${tag}` }} key={index}>
                      <div className="clickable badge text-s bg-bluegray-2 mr-1">
                        <i className="fa fa-tag" /> {tag}
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        );

        shareView = <ShareToggle />;
      }
    } else {
      dashboardNavView = (
        <div className="pl-5 d-flex">
          <div
            className={`nav-top-dashboard-button ml-3 ${
              isBriefs ? "active" : ""
            }`}
          >
            <Link
              className={`${isBriefs ? "text-bold" : ""}`}
              to={{ pathname: `/briefs` }}
            >
              Content Briefs
            </Link>
          </div>
          <div
            className={`nav-top-dashboard-button ml-3 ${
              isInventory ? "active" : ""
            } ${userHasInventoriesAccess ? "" : "d-none"}`}
          >
            <Link
              className={`${isInventory ? "text-bold" : ""}`}
              to={{ pathname: `/inventories` }}
            >
              Content Opportunities
            </Link>
          </div>
          <div
            className={`nav-top-dashboard-button ml-3 ${
              isLearn ? "active" : ""
            }`}
          >
            <Link
              className={`${isLearn ? "text-bold" : ""}`}
              to={{ pathname: `/learn` }}
            >
              <i className="fad fa-play-circle" /> Learn
            </Link>
          </div>
        </div>
      );
    }

    let logoView;
    let logoUrl = "/img/logo-rectangle.png";
    if (userIsCafemedia) {
      logoUrl = "/img/adthrive-topic-transparent.png";
    }

    if ((!isPaidUser || !isLoggedIn) && !isCancelledUser) {
      logoView = <img src={logoUrl} />;
    } else if (this.props.forceReload) {
      logoView = (
        <a className="logo" href="/briefs">
          <img src={logoUrl} />
        </a>
      );
    } else {
      logoView = (
        <Link
          className="logo"
          to={{ pathname: "/briefs", search: this.props.location.search }}
        >
          <img src={logoUrl} />
        </Link>
      );
    }

    return (
      <div className="d-flex flex-grow-0 nav-top">
        <div className="top-bar color-scheme-transparent w-100 d-flex">
          <div
            className={`logo-w menu-size ${showReport ? "" : "flex-grow-1"}`}
          >
            {logoView}
            {isPaidUser && dashboardNavView}
          </div>
          {reportNameView}
          {shareView}
          <div
            className="top-menu-controls"
            style={{ visibility: isLoggedIn ? "visible" : "hidden" }}
          >
            <div className="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
              <i className="os-icon os-icon-ui-46" />
              <div className="os-dropdown">
                <div className="icon-w">
                  <i className="os-icon os-icon-ui-46" />
                </div>
                {isPaidUser && (
                  <ul>
                    <li>
                      <a href="/profile/edit">Account</a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://usetopic.zendesk.com/hc"
                        rel="noreferrer"
                      >
                        Support
                      </a>
                    </li>
                    <li>
                      <a href="/account">Team Management</a>
                    </li>
                    {isOwner && !isSubAccount && (
                      <li>
                        <a href="/billing">Billing</a>
                      </li>
                    )}
                    <li>
                      <a
                        href="https://www.usetopic.com/affiliates"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Affiliate Program
                      </a>
                    </li>
                    <li>
                      <a href="/logout">Logout</a>
                    </li>
                  </ul>
                )}
                {!isPaidUser && (
                  <ul>
                    {isOwner && !isSubAccount && (
                      <li>
                        <a href="/billing">Billing</a>
                      </li>
                    )}
                    <li>
                      <a href="/logout">Logout</a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { reports } = state;
  return {
    report: getReportById(state, ownProps.match.params.id),
  };
}

export default withRouter(connect(mapStateToProps, {})(NavTop));
