import React from "react";
import { connect } from "react-redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { DebounceInput } from "react-debounce-input";
import { saveOutlineWordCount, receiveOutline } from "./redux/actions";
import { getReportById } from "./redux/selectors";

class OutlineBuilderWordCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localWordCount: null,
    };

    this.getSliderMaxWordCount = this.getSliderMaxWordCount.bind(this);
    this.initializeWordCount = this.initializeWordCount.bind(this);
    this.handleChangeWordCount = this.handleChangeWordCount.bind(this);

    // debounce the save so that we do not fire a save too many times
    this.debouncedSaveOutlineWordCount = _.debounce(
      this.props.saveOutlineWordCount,
      500
    );
  }

  componentDidMount() {
    this.initializeWordCount();
  }

  componentDidUpdate(prevProps, prevState) {
    this.initializeWordCount(prevProps, prevState);
  }

  initializeWordCount(prevProps, prevState) {
    const { localWordCount } = this.state;
    const { report, receiveOutline } = this.props;
    let wordCount;
    let recommendedWordCount;
    if (report && report.report && report.outline) {
      wordCount = report.outline.wordCount;
      recommendedWordCount = report.report.recommended_word_count;
    }
    if (localWordCount === null) {
      // initial load
      if (_.isNumber(wordCount) && wordCount > 0) {
        // pull word count from outline
        this.setState({
          localWordCount: wordCount,
        });
      } else if (_.isNumber(recommendedWordCount)) {
        // pull word count from recommended word count
        this.setState({
          localWordCount: recommendedWordCount,
        });
        if (report.id) {
          receiveOutline(report.id, { wordCount: recommendedWordCount });
        }
      }
    } else if (
      _.isNumber(wordCount) &&
      wordCount > 0 &&
      wordCount !== localWordCount &&
      prevProps &&
      prevProps.report &&
      prevProps.report.outline &&
      prevProps.report.outline.wordCount !== wordCount
    ) {
      // another component modified word count so update locally
      this.setState({
        localWordCount: wordCount,
      });
    }
  }

  handleChangeWordCount(newWordCount) {
    const { localWordCount } = this.state;
    const { report, receiveOutline } = this.props;
    let wordCount;
    let recommendedWordCount;
    if (report && report.report && report.outline) {
      wordCount = report.outline.wordCount;
      recommendedWordCount = report.report.recommended_word_count;
    }

    this.setState({
      localWordCount: newWordCount,
    });

    if (newWordCount !== null && wordCount !== newWordCount) {
      // word count has changed
      receiveOutline(report.id, { wordCount: newWordCount });
      this.debouncedSaveOutlineWordCount(report, newWordCount);
    }
  }

  getSliderMaxWordCount() {
    try {
      const { report } = this.props;
      const maxWordCount = _.max(
        _.map(report.report.competitors, "word_count")
      );
      return _.round(maxWordCount * 1.25, -2);
    } catch (e) {
      return 0;
    }
  }

  render() {
    const that = this;
    const { report } = this.props;
    const { localWordCount } = this.state;
    const isLoaded =
      report && report.report && !_.isEmpty(report.report.competitors);
    let sliderMaxWordCount;
    let sliderLabel;
    let sliderLabelClass;
    if (isLoaded) {
      sliderMaxWordCount = this.getSliderMaxWordCount();

      const numCompetitors = report.report.competitors.length;
      let numOver = 0;
      _.each(report.report.competitors, (competitor) => {
        if (competitor.word_count < localWordCount) {
          numOver += 1;
        }
      });
      const longerThanPercent = Math.round((numOver / numCompetitors) * 100);
      if (longerThanPercent > 50) {
        sliderLabel = `longer than ${longerThanPercent}% of competitors`;
      } else {
        sliderLabel = `shorter than ${100 - longerThanPercent}% of competitors`;
      }

      if (localWordCount < report.report.recommended_word_count) {
        sliderLabelClass = "text-orange-5";
      } else {
        sliderLabelClass = "text-success";
      }
    }

    return (
      <div>
        {isLoaded && (
          <div className="d-flex">
            <div style={{ minWidth: "93px" }}>
              <label
                htmlFor="outline-builder-word-count-input"
                className="text-bold"
              >
                Word Count
              </label>
            </div>
            <div style={{ width: "20%" }}>
              <DebounceInput
                id="outline-builder-word-count-input"
                className="topic-line-input"
                debounceTimeout={500}
                onChange={(e) => {
                  let num = Math.round(e.target.value);
                  if (num < 0) {
                    num = 0;
                  }
                  that.handleChangeWordCount(num);
                }}
                type="number"
                min={0}
                style={{ width: "100%" }}
                value={localWordCount}
              />
            </div>
            <div className="d-flex flex-column pl-3" style={{ width: "48%" }}>
              <div
                className={`text-center ${sliderLabelClass}`}
                style={{ position: "relative", fontSize: "8px" }}
              >
                {sliderLabel}
              </div>
              <div>
                <Slider
                  max={sliderMaxWordCount}
                  step={100}
                  onChange={(value) => {
                    that.handleChangeWordCount(value);
                  }}
                  trackStyle={{ backgroundColor: "#859FBD" }}
                  handleStyle={{ borderColor: "#859FBD" }}
                  value={localWordCount}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps, {
  saveOutlineWordCount,
  receiveOutline,
})(OutlineBuilderWordCount);
