import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import _ from "lodash";
import { getReportById } from "../../../redux/selectors";
import InitialBrainstormHeader from "./initial_brainstorm_header";
import InitialBrainstormLoadingSkeleton from "./initial_brainstorm_loading_skeleton";
import InitialBrainstormStartFromScratch from "./initial_brainstorm_start_from_scratch";
import { fetchInitialBrainstorm } from "../../../api/openai";
import { InitialBrainstormContext } from "./context";
import InitialBrainstormContent from "./initial_brainstorm_content";

function OutlineBuilderInitialBrainstorm() {
  const state = useSelector((s) => s);
  const { id } = useParams();
  const report = getReportById(state, id);
  const serps = _.get(report, "report.competitors", [])
    .map((c) => c.title)
    .join("\n");

  const { data, isFetching } = useQuery(
    ["fetchInitialBrainstorm", serps],
    () => fetchInitialBrainstorm(serps),
    {
      refetchOnWindowFocus: false,
    }
  );

  const initialValue = useMemo(() => ({ data, isFetching }), [isFetching]);
  return (
    <InitialBrainstormContext.Provider value={initialValue}>
      <div className="mt-3">
        <div>
          <InitialBrainstormHeader />
          <InitialBrainstormLoadingSkeleton />
          <InitialBrainstormContent />
        </div>
        <InitialBrainstormStartFromScratch />
      </div>
    </InitialBrainstormContext.Provider>
  );
}

export default OutlineBuilderInitialBrainstorm;
