import React from "react";
import { gradeToColor, isImportedAndNotConfirmed } from "./helpers";
import Loader from "./loader";

class EditorProgressbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let progressbarMessage;
    const { report, isGradeLoading, showEmptyState } = this.props;
    const { grade } = report.content;
    const recommendedGrade = report.report.recommended_grade;
    const recommendedCoverage = report.report.recommended_coverage;
    const coverage = parseInt(report.content.coverage, 10);
    const topicsForNextGrade = parseInt(
      report.content.topics_for_next_grade,
      10
    );
    const isImportedAndNotConfirmedState = isImportedAndNotConfirmed(report);

    if (coverage < 99) {
      progressbarMessage = `cover ${topicsForNextGrade} more topics to reach next grade`;
    } else if (grade === "A++") {
      progressbarMessage = "🎊 congrats on achieving an A++ 🎉";
    } else {
      progressbarMessage = "";
    }

    const progressWidth = coverage;
    let gradeView;

    const importConfirmationPrompt = (
      <div
        style={{
          position: "absolute",
          height: "100%",
          zIndex: 3,
          backgroundColor: "transparent",
        }}
      >
        <div
          className="ml-5 mr-5 p-3 shadow-med"
          style={{
            fontSize: "14px",
            borderRadius: "3px",
            backgroundColor: "white",
            position: "relative",
            overflow: "hidden",
            marginTop: "200px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "4px",
              backgroundColor: "#0a1f44",
            }}
          />
          <p>
            To see your content grade and recommendations, please confirm the
            accuracy of the imported content.
          </p>

          <p className="text-bold m-0 mr-2 mb-3">
            {" "}
            <i className="fa fa-arrow-left pr-2" /> Does the imported content
            look correct?
          </p>
          <div>
            <button
              className="btn btn-primary mr-2 ml-2"
              onClick={() => {
                this.props.confirmImportInformation("YES");
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-grey mr-2"
              onClick={() => {
                this.props.confirmImportInformation("NO");
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );

    if (isGradeLoading) {
      gradeView = (
        <>
          <div style={{ fontSize: "12px" }}>Current Grade</div>
          <div className="pt-4">
            <Loader type="spinner" className="dark" />
          </div>
        </>
      );
    } else {
      gradeView = (
        <>
          {" "}
          <div style={{ fontSize: "12px" }}>Current Grade</div>
          <div>
            <div
              className="text-bold"
              style={{ fontSize: "32px", height: "38px", lineHeight: "1.2" }}
            >
              {grade}
            </div>
            <div className="editor-progressbar-bar">
              <div style={{ position: "relative" }}>
                <div
                  className="text-bluegray-5 d-none"
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: `${recommendedCoverage}%`,
                    marginRight: "0",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "8px",
                      position: "absolute",
                      top: "-24px",
                      right: "-25px",
                    }}
                  >
                    Recommended Grade
                  </div>
                  {recommendedGrade}
                  <div
                    style={{
                      height: "16px",
                      width: "1px",
                      borderLeft: "1px dashed",
                      marginLeft: "0",
                    }}
                  />
                </div>
                <div
                  className="progress"
                  style={{ height: "14px", borderRadius: "40px" }}
                >
                  <div
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow="0"
                    className={`progress-bar-new text-bluegray-6 ${
                      this.props.saveState === "saving"
                        ? "progress-bar-animated progress-bar-striped"
                        : ""
                    } bg-${gradeToColor(grade)}-6`}
                    style={{ width: `${progressWidth}%` }}
                    role="progressbar"
                  />
                  <div
                    className={`text-${gradeToColor(
                      grade
                    )}-10 justify-content-center d-flex position-absolute w-100`}
                  >
                    Recommended: {recommendedGrade}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-s mt-1">{progressbarMessage}</div>
          </div>
        </>
      );
    }

    return (
      <>
        {isImportedAndNotConfirmedState && importConfirmationPrompt}
        <div
          className={`editor-progressbar ${
            isImportedAndNotConfirmedState ? "blur-low" : ""
          }`}
          style={{ backgroundColor: "#f2f4f8" }}
        >
          {!showEmptyState && (
            <div
              className="p-2 pr-4"
              style={{
                textAlign: "center",
                borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
              }}
            >
              <div>{gradeView}</div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default EditorProgressbar;
