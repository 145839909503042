import React from "react";
import ReactDOM from "react-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Loader from "./loader";
import { trackEvent } from './keen';

class EditorCopilotTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      completion: "",
      isLoading: false,
      diff: undefined, // rewrites use diff
      error: "",
    };

    this.handleRewrite = this.handleRewrite.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.fetchCompletion = this.fetchCompletion.bind(this);
    this.lastAction = null; // used for redo
    // the GPT3.5Turbo-16k model can handle up to 16k tokens (~64k char) but we limit to 4k char for now to
    // prevent any hallucinations
    this.maxRewriteInput = 4000;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { isLoading, completion, diff } = this.state;
    const { selectedText } = this.props;
    const newCompletionGenerated =
      !isLoading &&
      prevState.completion !== completion &&
      !_.isEmpty(completion);
    const newCompletionHasDiff = !_.isEmpty(diff);
    const target = $(".editor-copilot-tooltip-completion-text");
    if (newCompletionGenerated && !newCompletionHasDiff) {
      target.empty();
      new TypeIt(".editor-copilot-tooltip-completion-text", {
        strings: completion,
        speed: 10,
        startDelay: 0,
        waitUntilVisible: true,
        afterComplete: async (step, instance) => {
          instance.destroy();
        },
      }).go();
    } else if (newCompletionGenerated && newCompletionHasDiff) {
      target.empty();
      target.html(diff).fadeIn();
    }
    if (prevProps.selectedText !== selectedText) {
      this.setState({
        completion: "",
      });
    }
  }

  convertToHtmlParagraphs(text) {
    const newParagraphs = text.split("\n").filter((p) => !_.isEmpty(p));
    const html = newParagraphs.map((p) => `<p>${p}</p>`).join("");
    return html;
  }

  handleRewrite() {
    this.trackRewriteEvent("RequestRewrite");
    const { editor, selectedText, report } = this.props;
    this.lastAction = this.handleRewrite;
    editor.selection.restore();

    const siteId = this.getSiteId();

    const endpoint = `/api/openai/rewrite?content=${encodeURIComponent(
      selectedText
    )}&site_id=${encodeURIComponent(siteId)}`;
    this.fetchCompletion(endpoint);
  }

  handleReplace() {
    this.trackRewriteEvent("Replace");
    const htmlCompletions = this.convertToHtmlParagraphs(this.state.completion);
    this.props.editor.undo.saveStep();
    this.props.editor.html.insert(htmlCompletions);
    this.props.editor.undo.saveStep();
    this.setState({
      completion: "",
    });
    this.props.handleDisable();
  }

  handleRedo() {
    this.trackRewriteEvent("Redo");
    this.lastAction();
  }

  handleCancel() {
    this.trackRewriteEvent("Cancel");
    this.setState({
      completion: "",
    });
  }

  getSiteId() {
    const { report } = this.props;
    return report.tags.join();
  }

  trackRewriteEvent(event) {
    const siteId = this.getSiteId();
    const { selectedText, report } = this.props;
    const { completion } = this.state;
    const briefId = report?.id
    trackEvent('RewriteLikeMe', {
      event,
      siteId,
      briefId,
      selectedText,
      completion,
    });
  }

  fetchCompletion(endpoint) {
    const that = this;
    this.setState({
      isLoading: true,
      completion: "",
    });
    fetch(endpoint)
      .then((response) => response.json())
      .then((response) => {
        if (response.filter === "1" || response.filter === "2") {
          // toxic or controvertial
          that.props.handleToxicContent(
            response,
            () => {
              that.setState({
                completion: response.completion,
                isLoading: false,
                error: "",
              });
            },
            () => {
              that.setState({
                completion: "",
                isLoading: false,
                error: "",
              });
              that.props.handleDisable();
            }
          );
        } else if (_.isString(response.error) && !_.isEmpty(response.error)) {
          // error
          that.setState({
            completion: "",
            isLoading: false,
            error: response.error,
          });
        } else if (!_.isEmpty(response.completion)) {
          // successful completion
          that.setState({
            completion: response.completion,
            diff: response.diff,
            isLoading: false,
            error: "",
          });
        } else {
          // unexpected response, reset state
          that.setState({
            completion: "",
            isLoading: false,
            error: "",
          });
        }
      });
  }

  render() {
    const that = this;
    const { x, y, maxHeight, selectedText } = this.props;
    const { completion, isLoading, error } = this.state;

    const hasCompletion = !_.isEmpty(completion);

    const rewriteEnabled = selectedText.length < this.maxRewriteInput;

    let rewriteTooltip;
    if (rewriteEnabled) {
      rewriteTooltip = <Tooltip>Rewrite Like Me</Tooltip>;
    } else {
      rewriteTooltip = <Tooltip>Rephrase (select less text to enable)</Tooltip>;
    }

    return ReactDOM.createPortal(
      <div
        className="editor-copilot-tooltip d-flex"
        style={{ top: y + 7, left: x, maxHeight: maxHeight ? `${maxHeight}px`: undefined }}
      >
        {!hasCompletion && !isLoading && (
          <div className="d-flex">
            {error && (
              <div className="editor-copilot-tooltip-error text-red-7 text-m">
                {error}
              </div>
            )}
            <OverlayTrigger overlay={rewriteTooltip} placement="bottom">
              <div
                className={`btn hover-bg-primary-2 ${
                  rewriteEnabled ? "clickable" : ""
                }`}
                onClick={() => {
                  if (rewriteEnabled) {
                    that.handleRewrite();
                  }
                }}
              >
                <i
                  className={`fad fa-wand-magic ${
                    rewriteEnabled ? "text-green-7" : "text-primary-4"
                  }`}
                />
              </div>
            </OverlayTrigger>
          </div>
        )}
        {!hasCompletion && isLoading && (
          <Loader type="spinner" className="dark" />
        )}
        {hasCompletion && (
          <div className="editor-copilot-tooltip-completion" style={{maxHeight: maxHeight ? `${maxHeight - 32}px` : undefined}} >
            <div className="editor-copilot-tooltip-completion-text text-m" style={{maxHeight: maxHeight ? `${maxHeight - 32}px` : undefined}}/>
            <div className="editor-copilot-tooltip-completion-actions">
              <OverlayTrigger
                overlay={<Tooltip>Replace</Tooltip>}
                placement="bottom"
              >
                <button
                  onClick={this.handleReplace}
                  className="btn btn-sm bg-green-1 hover-bg-green-2 text-green-7"
                >
                  <i className="fas fa-check" />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip>Redo</Tooltip>}
                placement="bottom"
              >
                <button
                  onClick={this.handleRedo}
                  className="btn btn-sm bg-primary-1 hover-bg-primary-2 text-primary-7 ml-2"
                >
                  <i className="fas fa-redo" />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip>Cancel</Tooltip>}
                placement="bottom"
              >
                <button
                  onClick={this.handleCancel}
                  className="btn btn-sm bg-red-1 hover-bg-red-2 text-red-7 ml-2"
                >
                  <i className="fas fa-times" />
                </button>
              </OverlayTrigger>
            </div>
          </div>
        )}
      </div>,
      $(".topic")[0]
    );
  }
}

export default EditorCopilotTooltip;
