import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  saveOutlineDescription,
  saveOutlineTitle,
} from "../../../../redux/actions";
import { BookmarkType } from "./bookmark_type";

/**
 * Renders Choose Title or Choose Description button in the bookmarks list
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineBookmarkChooseButton({
  report,
  type,
  handleClose,
  bookmarkChosen,
}) {
  const dispatch = useDispatch();
  const onClick = () => {
    if (type === BookmarkType.TITLE) {
      dispatch(saveOutlineTitle(report, bookmarkChosen));
    } else if (type === BookmarkType.DESCRIPTION) {
      dispatch(saveOutlineDescription(report, bookmarkChosen));
    }
    handleClose();
  };
  return (
    <div className="text-right">
      <div
        className={`clickable text-right btn btn-primary mt-2 ${
          _.isEmpty(bookmarkChosen) ? "disabled" : ""
        }`}
        onClick={onClick}
      >
        Choose {type}
      </div>
    </div>
  );
}

export default OutlineBookmarkChooseButton;
