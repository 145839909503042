import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getActiveSegment } from "./redux/selectors";
import {
  setActiveSegment,
  updateSegmentLocally,
  addSegment,
  deleteSegment,
  setSelectedPages,
  setSelectedSegment,
} from "./redux/actions";
import InventorySegmentLabel from "./inventory_segment_label";
import InventorySegmentCreator from "./inventory_segment_creator";
import InventorySegmentLabelPopover from "./inventory_segment_label_popover";
import { getSegmentLabel } from "./helpers";

class InventorySegmentPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentIdBeingEdited: null,
    };
    this.handleSetActiveSegment = this.handleSetActiveSegment.bind(this);
    this.handleStopEditing = this.handleStopEditing.bind(this);
    this.handleCreateNewSegment = this.handleCreateNewSegment.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.segmentsWithFixedLabelMap = {
      all: {
        id: 1,
        color: "#829AB1",
        backgroundColor: "#D9E2EC",
        icon: "fas fa-globe-americas",
      },
      ignored: {
        id: 1,
        color: "#829AB1",
        backgroundColor: "#D9E2EC",
        icon: "fas fa-do-not-enter",
      },
    };
  }

  handleSetActiveSegment(segment) {
    const { setActiveSegment, setSelectedPages, setSelectedSegment } =
      this.props;
    if (segment.id) {
      this.setState({ segmentIdBeingEdited: null });
      setActiveSegment(segment.id);
      setSelectedPages(null);
      setSelectedSegment(null);
    }
  }

  handleStopEditing() {
    this.setState({
      segmentIdBeingEdited: null,
    });
  }

  handleCreateNewSegment() {
    const {
      defaultSegment,
      segments,
      inventory,
      addSegment,
      setActiveSegment,
    } = this.props;
    const that = this;
    // set the active segment to be unsaved if on "all"
    if (_.isEmpty(_.find(segments, { id: "newid" }))) {
      // create default segment if none exists
      defaultSegment.inventory_id = inventory.id;
      addSegment(defaultSegment);
    }
    setActiveSegment("newid");
    that.setState({ segmentIdBeingEdited: "newid" });
  }

  handleDelete(e, segment) {
    const { deleteSegment, setActiveSegment } = this.props;
    deleteSegment(segment.id);
    setActiveSegment("all");
    e.stopPropagation();
  }

  handleEdit(e, segment) {
    this.props.setActiveSegment(segment.id);
    this.setState({ segmentIdBeingEdited: segment.id });
    e.stopPropagation();
  }

  render() {
    const that = this;
    const { segments, activeSegment, segmentLabels, inventory } = this.props;
    const { segmentIdBeingEdited } = this.state;

    return (
      <div className="inventory-segment-panel mt-4">
        <div>
          <h6>Segments</h6>
        </div>
        {_.map(segments, (segment, i) => {
          const isActive = activeSegment.id === segment.id;
          const isEditing = segment.id === segmentIdBeingEdited;
          const deletePopover = (
            <Popover>
              <Popover.Content>
                <div className="text-bold mb-3">
                  Are you sure you want to delete this segment?
                </div>
                <button
                  className="btn btn-primary-outline"
                  onClick={() => document.body.click()}
                >
                  No
                </button>
                <button
                  className="btn btn-danger ml-2"
                  onClick={(e) => {
                    that.handleDelete(e, segment);
                  }}
                >
                  Yes
                </button>
              </Popover.Content>
            </Popover>
          );

          const labelPopover = (
            <InventorySegmentLabelPopover
              segment={segment}
              labels={segmentLabels}
            />
          );
          const label = getSegmentLabel(segmentLabels, segment);
          const labelDiv = <InventorySegmentLabel label={label} />;

          const fixedLabel = this.segmentsWithFixedLabelMap[segment.id];
          const isAllPagesSegment = segment.id === "all";

          const regularView = (
            <div
              className={`inventory-segment-panel-item mb-1 clickable p-2 d-flex ${
                isActive ? "active" : ""
              }`}
              key={i}
              onClick={() => {
                that.handleSetActiveSegment(segment);
              }}
            >
              <div className="flex-grow-1 d-flex">
                {!fixedLabel && (
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom"
                    overlay={labelPopover}
                  >
                    {labelDiv}
                  </OverlayTrigger>
                )}
                {fixedLabel && <InventorySegmentLabel label={fixedLabel} />}
                <div className="ml-2 pt-1">
                  {segment.name}{" "}
                  {_.isNumber(segment.page_count) && (
                    <span className="text-primary-3">
                      ({segment.page_count})
                    </span>
                  )}
                </div>
              </div>
              {!fixedLabel && (
                <div className="inventory-segment-panel-item-actions">
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom"
                    overlay={deletePopover}
                  >
                    <i className="fa fa-trash mr-3" />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Edit Segment</Tooltip>}>
                    <i
                      className="fa fa-pencil clickable"
                      onClick={(e) => {
                        this.handleEdit(e, segment);
                      }}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </div>
          );

          const editingView = (
            <div
              className="inventory-segment-panel-item clickable pt-3 pb-3 pr-3 d-flex editing bg-primary-1"
              style={{ paddingLeft: "8px" }}
              key={i}
            >
              <InventorySegmentCreator
                inventory={inventory}
                handleStopEditing={this.handleStopEditing}
              />
            </div>
          );

          if (isEditing) {
            return editingView;
          }
          return regularView;
        })}
        {segmentIdBeingEdited !== "newid" && (
          <div
            className="inventory-segment-panel-item text-center editing p-2 clickable mt-3 bg-primary-2 text-primary-10"
            onClick={this.handleCreateNewSegment}
          >
            Create New Segment
          </div>
        )}
        <div
          className="p-3 mt-5 d-none"
          style={{ backgroundColor: "white", borderRadius: "3px" }}
        >
          <h6 style={{ opacity: "0.9" }}>Bring in conversion data</h6>
          <p>Discover which content is generating leads for your business</p>
          <a
            className="btn btn-grey"
            href="https://h3uew4wv.paperform.co"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { segments } = state;
  const activeSegment = _.cloneDeep(getActiveSegment(state));
  return {
    segments: segments.items,
    segmentLabels: segments.segmentLabels,
    activeSegment,
    defaultSegment: segments.defaultSegment,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    updateSegmentLocally,
    setActiveSegment,
    addSegment,
    deleteSegment,
    setSelectedPages,
    setSelectedSegment,
  })(InventorySegmentPanel)
);
