import React from "react";
import { connect } from "react-redux";
import Creatable from "react-select/creatable";
import Loader from "./loader";
import TextLoader from "./loader_text";
import { saveOutlineTitleAndDescription } from "./redux/actions";
import OutlineComboBookmarkButton from "./components/outline/outline_combo_bookmark_button";

class OutlineBuilderWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.startStep || 1,
      isFetching: false,
      requiredKeywords: [],
      chosenRequiredKeywords: [],
      contentIdeas: [],
      idealQualities: [],
      error: null,
    };
    this.maxSelectedKeywords = 3; // the max number of "required keywords"
    this.defaultState = this.state;
    this.resetState = this.resetState.bind(this);
    this.validate = this.validate.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.fetchRequiredKeywords = this.fetchRequiredKeywords.bind(this);
    this.fetchContentIdeas = this.fetchContentIdeas.bind(this);
    this.stepOneView = this.stepOneView.bind(this);
    this.stepTwoView = this.stepTwoView.bind(this);
    this.stepThreeView = this.stepThreeView.bind(this);
    this.handleRequiredKeywordChange =
      this.handleRequiredKeywordChange.bind(this);
    this.toggleRequiredKeyword = this.toggleRequiredKeyword.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleChooseIdea = this.handleChooseIdea.bind(this);
    this.fetchIfNeeded = this.fetchIfNeeded.bind(this);
    this.ref = React.createRef();
  }

  resetState() {
    this.setState(this.defaultState);
  }

  componentDidMount() {
    this.fetchIfNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchIfNeeded();
    if (
      prevState.isFetching !== this.state.isFetching &&
      _.isFunction(this.props.updateIsFetching)
    ) {
      this.props.updateIsFetching(this.state.isFetching);
    }
  }

  fetchIfNeeded() {
    var { step, requiredKeywords, isFetching, contentIdeas, error } =
      this.state;
    if (
      step === 2 &&
      _.isEmpty(requiredKeywords) &&
      !isFetching &&
      _.isNull(error)
    ) {
      this.fetchRequiredKeywords();
    } else if (
      step === 3 &&
      _.isEmpty(contentIdeas) &&
      !isFetching &&
      _.isNull(error)
    ) {
      this.fetchContentIdeas();
    }
  }

  fetchRequiredKeywords() {
    var that = this;
    this.setState({
      isFetching: true,
    });
    fetch(
      `/api/openai/content_ideas_title_keywords_helper/${this.props.report.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.keywords) {
          var keywords = data.keywords;
          that.setState({
            requiredKeywords: keywords,
            isFetching: false,
          });
        } else {
          that.setState({
            isFetching: false,
            error: "something went wrong getting required keywords",
          });
          console.log("something went wrong getting required keywords");
        }
      });
  }

  fetchContentIdeas() {
    var that = this;
    var { chosenRequiredKeywords, contentIdeas } = this.state;
    this.setState({
      isFetching: true,
      contentIdeas: [],
      error: null,
    });
    fetch(`/api/openai/content_ideas/${this.props.report.id}`, {
      method: "POST",
      body: JSON.stringify({
        required_keywords: chosenRequiredKeywords,
      }),
      headers: {
        // ***
        "Content-Type": "application/json", // ***
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.ideas) {
          var error = null;
          if (data.filter === "1" || data.filter === "2") {
            error =
              "The content filter has detected that the generated text may potentially be sensitive, political, or controversial. Give it an extra check just in case something sounds wrong.";
          }

          if (_.isEmpty(data) || _.isEmpty(data.ideas)) {
            error =
              "System was unable to brainstorm content ideas. Please try again or contact support@usetopic.com";
          }

          that.setState({
            contentIdeas: data.ideas,
            idealQualities: data.ideal_qualities,
            isFetching: false,
            error,
          });
        } else {
          that.setState({
            isFetching: false,
            error: "Unable to come up with any ideas. Please try again.",
          });
          console.log("something went wrong getting content ideas");
        }
      });
  }

  handleRequiredKeywordChange(selected) {
    if (_.isNull(selected) || selected.length <= this.maxSelectedKeywords) {
      this.setState({
        chosenRequiredKeywords: _.map(selected, "value"),
      });
    }
  }

  toggleRequiredKeyword(keyword) {
    var { chosenRequiredKeywords } = this.state;
    if (_.includes(chosenRequiredKeywords, keyword)) {
      this.setState({
        chosenRequiredKeywords: _.without(chosenRequiredKeywords, keyword),
      });
    } else if (
      chosenRequiredKeywords &&
      chosenRequiredKeywords.length < this.maxSelectedKeywords
    ) {
      this.setState({
        chosenRequiredKeywords: _.concat(chosenRequiredKeywords, [keyword]),
      });
    }
  }

  validate(successFunction) {
    var { chosenRequiredKeywords } = this.state;
    if (chosenRequiredKeywords.length > 0) {
      successFunction();
    } else {
      this.setState({
        error: "Please choose at least one keyword",
      });
    }
  }

  nextStep() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  handleSkip() {
    var { handleClose, report } = this.props;
    if (_.isFunction(handleClose)) {
      handleClose();
    }
    this.props.saveOutlineTitleAndDescription(report, "", "");
    this.resetState();
  }

  handleChooseIdea(idea) {
    var { handleClose, report } = this.props;
    if (_.isFunction(handleClose)) {
      handleClose();
    }
    this.props.saveOutlineTitleAndDescription(
      report,
      idea.title,
      idea.description
    );
    this.resetState();
  }

  stepOneView() {
    return (
      <div>
        <h4>Let's get started</h4>
        <div>
          <div
            className="clickable text-left bg-primary-1 hover-bg-primary-2 p-3 rounded"
            onClick={this.nextStep}
          >
            <span className="text-bold">Brainstorm Ideas</span>
            <p className="mt-1 mb-0">
              Avoid writer's block by having our AI brainstorm content ideas for
              you.
            </p>
          </div>
          <div
            className="clickable text-primary-4 mt-2"
            onClick={this.handleSkip}
          >
            ... or start from scratch
          </div>
        </div>
      </div>
    );
  }

  stepTwoView() {
    var that = this;
    var { requiredKeywords, chosenRequiredKeywords, isFetching, error } =
      this.state;
    return (
      <div>
        <div>
          Choose the keywords that must be in the title.{" "}
          <span className="text-primary-4">
            (up to {this.maxSelectedKeywords})
          </span>
        </div>
        {isFetching && (
          <div>
            <Loader type="spinner" className="dark" />
          </div>
        )}
        {error && <div className="text-danger">{error}</div>}
        {!_.isEmpty(requiredKeywords) && (
          <div>
            <div className="form-group text-left">
              <Creatable
                isMulti
                id="required-keywords"
                options={_.map(requiredKeywords, (keyword) => ({
                  label: keyword,
                  value: keyword,
                }))}
                value={_.map(chosenRequiredKeywords, (keyword) => ({
                  label: keyword,
                  value: keyword,
                }))}
                isClearable
                name="required-keywords"
                className="outline-wizard-select"
                placeholder="Choose Keywords..."
                onChange={this.handleRequiredKeywordChange}
              />
            </div>
            <div>
              Suggested:{" "}
              {_.map(requiredKeywords, (keyword, index) => {
                var isActive = _.includes(chosenRequiredKeywords, keyword);
                return (
                  <div
                    key={index}
                    onClick={() => {
                      that.toggleRequiredKeyword(keyword);
                    }}
                    style={{ borderRadius: "3px" }}
                    className={`badge p-1 mr-2 mt-2 ${
                      isActive
                        ? "clickable bg-green-1 text-green-8 active"
                        : "clickable bg-primary-1"
                    }`}
                  >
                    {isActive && <i className="fas fa-check mr-1" />}
                    {!isActive && (
                      <i className="far fa-plus mr-1 text-primary-4" />
                    )}
                    {keyword}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="text-right mt-4">
          <button
            className="btn btn-primary ml-2"
            onClick={() => {
              that.validate(that.nextStep);
            }}
          >
            Brainstorm Ideas
          </button>
        </div>
      </div>
    );
  }

  stepThreeView() {
    var that = this;
    var { contentIdeas, idealQualities, isFetching, error } = this.state;
    return (
      <div>
        {isFetching && _.isEmpty(contentIdeas) && (
          <div className="mt-3">
            <TextLoader
              lines={[
                "Identifying target audience",
                "Defining persona",
                "Generating content ideas",
                "Removing irrelevant ideas",
                "Formatting titles",
                "Generating descriptions",
              ]}
            />
          </div>
        )}
        {error && (
          <div>
            <div className="text-danger">{error}</div>
            <button
              className="btn bg-primary-1 hover-bg-primary-2 mt-2"
              onClick={this.fetchContentIdeas}
            >
              Try Again <i className="fas fa-redo-alt" />
            </button>
          </div>
        )}
        {!_.isEmpty(contentIdeas) && (
          <div>
            {_.map(contentIdeas, (idea, i) => (
              <div
                key={i}
                className={`p-3 mt-3 bg-primary-1 rounded animated fadeUp delay-${i}`}
              >
                <div className="text-bold">{idea.title}</div>
                <div
                  className="text-primary-5 mt-2"
                  style={{ fontSize: "12px" }}
                >
                  {idea.description}
                </div>
                <OutlineComboBookmarkButton
                  report={this.props.report}
                  title={idea.title}
                  description={idea.description}
                />
                <button
                  className="btn bg-primary-3 hover-bg-primary-4 w-100 mt-2"
                  onClick={() => {
                    that.handleChooseIdea(idea);
                  }}
                >
                  Choose This Idea
                </button>
              </div>
            ))}
            {!_.isEmpty(idealQualities) && (
              <div
                className={`bg-blue-1 p-3 mt-3 rounded animated fadeUp delay-${contentIdeas.length}`}
              >
                <div className="text-bold">
                  Here are a few ideas on how to make your content stand out.
                </div>
                {_.map(idealQualities, (quality, i) => (
                  <div
                    key={i}
                    className="badge bg-blue-2 text-blue-9 mr-2 mt-2"
                  >
                    {quality}
                  </div>
                ))}
              </div>
            )}
            <div className="text-center mt-4">
              {!isFetching && (
                <button
                  className="btn bg-primary-1 hover-bg-primary-2 mb-2 w-100"
                  onClick={this.fetchContentIdeas}
                >
                  Get More Ideas <i className="fas fa-redo-alt" />
                </button>
              )}
              {isFetching && (
                <button className="btn bg-primary-1 hover-bg-primary-2 mb-2 w-100">
                  <Loader type="spinner" className="dark" />
                </button>
              )}
              <a
                href="https://usetopic.zendesk.com/hc/en-us/articles/360061836694"
                target="_blank"
                className="text-primary-4"
                rel="noreferrer"
              >
                where do these ideas come from?
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    var that = this;
    var stepView;
    var { step } = this.state;
    var { handleSetPopoverType } = this.props;
    if (step === 1) {
      stepView = this.stepOneView();
    } else if (step === 2) {
      stepView = this.stepTwoView();
    } else if (step === 3) {
      stepView = this.stepThreeView();
    }
    return (
      <div
        ref={this.ref}
        className="builder-wizard"
        style={{ overflowY: step === 2 ? "visible" : "scroll" }}
      >
        {stepView}
        {step === 2 && (
          <a
            href="#"
            className="text-primary-4 text-s float-right mt-2"
            onClick={() => {
              handleSetPopoverType("serpTitle");
            }}
          >
            ... or get title ideas from SERP
          </a>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  var props = {};
  return props;
}

export default connect(mapStateToProps, { saveOutlineTitleAndDescription })(
  OutlineBuilderWizard
);
