import {
  REQUEST_INVENTORIES,
  RECEIVE_INVENTORIES,
  REQUEST_INVENTORY,
  RECEIVE_INVENTORY,
  SET_ACTIVE_INVENTORY,
  INVALIDATE_INVENTORIES,
  SET_IS_FETCHING,
  UPDATE_SIGNALS,
} from "../actions";

export function inventories(
  state = {
    isFetching: false,
    items: [],
    didInvalidate: true,
    signals: {}, // maps ids to signals so they can be aborted when making lots of requests
  },
  action
) {
  switch (action.type) {
    case REQUEST_INVENTORIES:
      return { ...state, isFetching: true };
    case RECEIVE_INVENTORIES:
      return {
        ...state,
        isFetching: false,
        items: action.inventories,
        didInvalidate: false,
      };
    case REQUEST_INVENTORY:
      return { ...state, isFetching: true };
    case RECEIVE_INVENTORY:
      var newInventories;
      if (!_.isEmpty(state.items)) {
        if (_.isUndefined(_.find(state.items, { id: action.inventory.id }))) {
          // received new inventory
          newInventories = state.items.concat([action.inventory]);
        } else {
          // received existing inventory
          newInventories = _.map(state.items, (inventory) => {
            if (inventory.id === action.inventory.id) {
              return action.inventory;
            }
            return inventory;
          });
        }
      } else {
        newInventories = [action.inventory];
      }
      return {
        ...state,
        isFetching: false,
        items: newInventories,
        didInvalidate: false,
      };
    case INVALIDATE_INVENTORIES:
      return { ...state, didInvalidate: true };
    case SET_IS_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case UPDATE_SIGNALS:
      var newSignals = { ...state.signals, ...action.signalObj };
      return { ...state, signals: newSignals };
    default:
      return state;
  }
}

export function activeInventory(state = null, action) {
  if (action.type === SET_ACTIVE_INVENTORY) {
    return action.inventoryId;
  }
  return state;
}
