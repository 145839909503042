// Application wide JS

document.addEventListener("turbolinks:load", () =>
  $('[data-toggle="tooltip"]').tooltip()
);

$(document).ready(() => {
  // Dropdowns
  $(".os-dropdown-trigger").on("mouseenter", function () {
    $(this).addClass("over");
  });
  $(".os-dropdown-trigger").on("mouseleave", function () {
    $(this).removeClass("over");
  });
});
