import React from "react";
import { connect } from "react-redux";
import EditorCopilotModal from "./editor_copilot_modal";
import { saveOutlineTitle, saveOutlineDescription } from "./redux/actions";
import EditorCopilotTooltip from "./editor_copilot_tooltip";

class EditorCopilot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // chosenTonesOfVoice: [],
      title: "",
      description: "",
      modalContent: "",
      mode: "compose", // init, compose, charging
      chargeCharacterCount: 0, // how many characters user needs to type in order to unlock feature
      lastCompletionLength: 0,
      lastCopilotAction: null,
      lastCopilotContent: null,
      showInlineTooltip: false,
      inlineTooltipX: 0,
      inlineTooltipY: 0,
      selectedText: "",
    };
    this.handleChangeTones = this.handleChangeTones.bind(this);
    this.maxTones = 2;
    this.initializeCopilot = this.initializeCopilot.bind(this);
    this.bindEditorEvents = this.bindEditorEvents.bind(this);
    this.trackTextUntilCursor = this.trackTextUntilCursor.bind(this);
    this.fetchAutocomplete = this.fetchAutocomplete.bind(this);
    this.fetchCompletion = this.fetchCompletion.bind(this);
    this.handleToxicContent = this.handleToxicContent.bind(this);
    this.animateCompletion = this.animateCompletion.bind(this);
    this.getLastParagraphObject = this.getLastParagraphObject.bind(this);
    this.getLastNCharsBeforeCursor = this.getLastNCharsBeforeCursor.bind(this);
    this.trackKeysForCharging = this.trackKeysForCharging.bind(this);
    this.unwrapAutocomplete = this.unwrapAutocomplete.bind(this);
    this.saveTitleAndDescription = this.saveTitleAndDescription.bind(this);
    this.enableInlineTooltip = this.enableInlineTooltip.bind(this);
    this.disableInlineTooltip = this.disableInlineTooltip.bind(this);
    this.renderCopilotTooltip = this.renderCopilotTooltip.bind(this);

    // retry
    this.retryLastAction = this.retryLastAction.bind(this);
    this.clearLastCopilotAction = this.clearLastCopilotAction.bind(this);
    this.beforeCursorText = "";
    this.beforeCursorHTML = "";

    this.typingAnimationConfig = {
      speed: 10,
      startDelay: 0,
      waitUntilVisible: true,
    };

    this.minTooltipCharSelection = 100;

    // openai determined input limit - 150 tokens
    this.maxCompleterInput = 300; // reducing to 75 tokens to control cost

    // debug
    this.debugDisableCharging = false;
  }

  componentDidMount() {
    const { initialized } = this.props;
    if (initialized) {
      // on initial initialization, after parent is mounted
      this.editor = AP.editor; // using a global to communicate to make it easier to debug
      this.initializeCopilot();
    }
  }

  componentDidUpdate(prevProps) {
    const { initialized } = this.props;
    if (!prevProps.initialized && initialized) {
      // on initial initialization, after parent is mounted
      this.editor = AP.editor; // using a global to communicate to make it easier to debug
      this.initializeCopilot();
    }

    if (
      this.state.mode !== "init" &&
      (this.props.title !== this.state.title ||
        this.props.description !== this.state.description)
    ) {
      this.setState({
        title: this.props.title,
        description: this.props.description,
      });
    } else if (
      this.state.mode !== "init" &&
      (_.isEmpty(this.state.title) || _.isEmpty(this.state.description))
    ) {
      this.setState({
        mode: "init",
      });
    }

    if (!this.props.enabled && this.state.showInlineTooltip) {
      // disable inline tooltip if copilot is disabled and tooltip is enabled
      this.disableInlineTooltip();
    }
  }

  saveTitleAndDescription() {
    this.props.saveOutlineTitle(this.props.report, this.state.title);
    this.props.saveOutlineDescription(
      this.props.report,
      this.state.description,
    );
    this.setState({
      mode: "compose",
    });
  }

  initializeCopilot() {
    console.log("initializingCopilot");
    this.bindEditorEvents();
    this.trackTextUntilCursor();
  }

  bindEditorEvents() {
    const that = this;
    $(".fr-view").bind("keydown", this.trackKeysForCharging);
    $(".fr-view").bind("keydown", this.clearLastCopilotAction);
    $(".fr-wrapper").bind("keydown", this.renderCopilotTooltip);
    $(".fr-wrapper").bind("click", this.renderCopilotTooltip);
    $(".fr-view").bind("click", this.clearLastCopilotAction);
  }

  componentWillUnmount() {
    $(".fr-view").unbind("keydown", this.trackKeysForCharging);
    $(".fr-view").unbind("keydown", this.clearLastCopilotAction);
    $(".fr-wrapper").unbind("keydown", this.renderCopilotTooltip);
    $(".fr-wrapper").unbind("click", this.renderCopilotTooltip);
    $(".fr-view").unbind("click", this.clearLastCopilotAction);
  }

  clearLastCopilotAction() {
    this.setState({
      lastCopilotAction: null,
      lastCopilotContent: "",
    });
    this.unwrapAutocomplete();
  }

  // used to charge feature up in between completions
  trackKeysForCharging(e) {
    const { chargeCharacterCount, mode } = this.state;
    const { enabled } = this.props;
    if (!enabled) {
      return;
    }
    if (mode === "charging") {
      if (
        e &&
        (e.keyCode === 192 || e.keyCode === 32) &&
        chargeCharacterCount > 0
      ) {
        // the ` character is a hidden shortcut
        this.setState({
          chargeCharacterCount: chargeCharacterCount - 30,
        });
      } else if (
        e &&
        /[a-zA-Z0-9-_]/.test(String.fromCharCode(e.keyCode)) &&
        chargeCharacterCount > 0
      ) {
        this.setState({
          chargeCharacterCount: chargeCharacterCount - 4,
        });
      } else if (chargeCharacterCount <= 0) {
        this.setState({
          mode: "compose",
          chargeCharacterCount: 0,
        });
      }
    }
  }

  unwrapAutocomplete() {
    if ($(".fr-view .editor-copilot-completion").length > 0) {
      console.log("unwrapping autocomplete");
      $(".fr-view .editor-copilot-completion").after(
        '<span class="cursor-marker"></span>',
      );
      $(".fr-view .editor-copilot-completion").contents().unwrap();
      this.editor.selection.setAfter($(".cursor-marker")[0]);
      this.editor.selection.restore();
      $(".cursor-marker").remove();
    }
  }

  retryLastAction() {
    const completionDiv = $(".editor-copilot-completion");
    const { lastCopilotAction, lastCopilotContent } = this.state;
    const { isFetching } = this.props;
    if (!_.isFunction(lastCopilotAction)) {
      console.log(
        "tried to retry last copilot action, but function was undefined",
      );
      this.clearLastCopilotAction();
    } else if (isFetching) {
      alert("Please wait until current action finishes.");
    } else if (completionDiv.length > 0) {
      console.log("retrying last copilot action");
      if (lastCopilotAction === this.fetchAutocomplete) {
        this.setState({
          mode: "compose",
        });
      }
      completionDiv.remove(); // get rid of the last copilot output
      lastCopilotAction(lastCopilotContent);
    }
  }

  enableInlineTooltip() {
    const selection = document.getSelection();
    const range = selection.getRangeAt(0);
    const clientRects = range.getClientRects();

    let x = false;
    let y = 0;

    _.each(clientRects, (rect) => {
      // find the leftmost and bottommost rect
      if (x === false || rect.x < x) {
        x = rect.x;
      }
      if (rect.y + rect.height > y) {
        y = rect.y + rect.height;
      }
    });

    if (y > window.innerHeight * 0.6) {
      // handle tooltip falling off of bottom
      // find the topmost rect
      y = false;
      _.each(clientRects, (rect) => {
        if (y === false || rect.y < y) {
          y = rect.y - 100;
        }
      });
    }

      // set max height in case the tooltip is too tall
      // We determine this by how much space between the top of the tooltip and the bottom of the screen
      const maxHeight = window.innerHeight - y - 96;

    this.setState({
      showInlineTooltip: true,
      inlineTooltipX: x,
      inlineTooltipY: y,
      selectedText: this.editor.selection.text(),
      maxHeight,
    });
  }

  disableInlineTooltip() {
    this.setState({
      showInlineTooltip: false,
    });
  }

  trackTextUntilCursor(e) {
    console.log("trackTextUntilCursor");
    const that = this;
    try {
      // keep track of text before cursor for autocomplete
      const element = $(".fr-view")[0];
      let caretOffset = 0;
      let html = "";
      this.editor.events.focus();
      if (typeof window.getSelection !== "undefined") {
        const range = window.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
        html = $("<div>")
          .append(preCaretRange.cloneRange().cloneContents())
          .html();
      } else if (
        typeof document.selection !== "undefined" &&
        document.selection.type != "Control"
      ) {
        const textRange = document.selection.createRange();
        const preCaretTextRange = document.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
        html = $("<div>")
          .append(preCaretRange.cloneRange().cloneContents())
          .html();
      }
      // var spaceCounter = 0;
      // var text = $($('.fr-view').html()).each(function( index ) {
      //    $( this ).append(' ');
      //    spaceCounter++;
      // }).text();
      let text = $(".fr-view").text();
      text = text.substring(0, caretOffset);
      if (e && /[a-zA-Z0-9-_ ]/.test(String.fromCharCode(e.keyCode))) {
        text += e.key;
      }
      if (text.trim().length > 0) {
        console.log(html);
        that.beforeCursorText = text;
        that.beforeCursorHTML = html;
      }
    } catch (e) {
      console.log("there was an error tracking text until cursor");
      console.log(e);
    }
  }

  renderCopilotTooltip(e) {
    const that = this;
    const { enabled } = this.props;
    const { showInlineTooltip } = this.state;
    if (!enabled) {
      return;
    }
    window.setTimeout(() => {
      // add a timeout to handle case where clicking after highlighting results in selection text returning the previously highlighted text
      // "LinkUndoRedo" - this is a hack to fix a bug where click and dragging in the whitespace next to the content results in the tooltip appearing
      const textSelected =
        this.editor.selection.text().length > that.minTooltipCharSelection &&
        !_.includes(this.editor.selection.text(), "LinkUndoRedo");
      if (textSelected && !showInlineTooltip) {
        this.enableInlineTooltip();
        // this.editor.selection.save(); for some reason this is causing backspace to crash the editor
      } else if (!textSelected && showInlineTooltip) {
        this.disableInlineTooltip();
        $(".fr-marker").remove();
      }
    }, 1);
  }

  // content (optional) - passed in if we want to force using a specific content
  fetchAutocomplete(content) {
    const that = this;
    const maxContentLength = this.maxCompleterInput;
    const { title, description } =
      this.state;

    this.trackTextUntilCursor();

    if (!_.isString(content) || _.isEmpty(content)) {
      // var cursorIsInNewParagraph = this.beforeCursorHTML.trim().toLowerCase().slice(-7) === '<p></p>';

      content = this.getLastNCharsBeforeCursor(maxContentLength);

      // var paragraphs = this.getLastParagraphObject();
      // var {lastParagraph} = paragraphs;
      // if(cursorIsInNewParagraph || lastParagraph.length < 20 || true){
      //   // if the cursor is in a new paragraph, get last n characters
      //   content = this.getLastNCharsBeforeCursor(maxContentLength);
      //   console.log('getting last n chars');
      // } else{
      //   // if cursor is in the middle of a paragraph, just use the current paragraph
      //   content = lastParagraph;
      //   console.log('using current paragraph');
      // }
    }

    // add a space if the cursor is at the middle/end of a word or end of sentence
    if (
      content.length > 1 &&
      /[A-Za-z]|[0-9]|\./.test(content[content.length - 1])
    ) {
      this.editor.html.insert(" ");
    }

    // get rid of any numerals from beginning of content to prevent a new list from forming
    content = content.replace(/^\d+\./gi, "");

    this.setState({
      lastCopilotContent: content,
      lastCopilotAction: this.fetchAutocomplete,
    });

    console.log(`fetching autocomplete with text ${content}`);
    const endpoint = `/api/openai/autocomplete?content=${encodeURIComponent(
      content,
    )}&focus_keyword=${this.props.report.name}&title=${encodeURIComponent(
      title,
    )}&description=${encodeURIComponent(description)}`;
    this.fetchCompletion(endpoint);
  }

  fetchCompletion(endpoint) {
    const that = this;
    this.props.setIsFetching(true);
    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.filter === "1" || response.filter === "2") {
          // toxic or controvertial
          that.handleToxicContent(response);
        } else if (!_.isEmpty(response.completion)) {
          that.animateCompletion(response.completion);
          if (!that.debugDisableCharging) {
            that.setState({
              mode: "charging",
              chargeCharacterCount: Math.round(response.completion.length),
              lastCompletionLength: response.completion.length,
            });
          }
        }
        that.props.setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Too Many Requests") {
          alert("Too many requests. Wait a minute before trying again.");
        }
        that.props.setIsFetching(false);
      });
  }

  handleToxicContent(response, acceptFunction, rejectFunction) {
    if (_.isFunction(acceptFunction) && _.isFunction(rejectFunction)) {
      this.acceptToxicContentFunction = acceptFunction;
      this.rejectToxicContentFunction = rejectFunction;
    }
    this.setState({
      modalContent: response.completion,
    });
    $(".editor-copilot-modal").modal("show");
  }

  animateCompletion(completion) {
    const that = this;
    this.editor.html.insert('<span class="editor-copilot-completion">');

    const typeIt = new TypeIt(".fr-view .editor-copilot-completion", {
      ...this.typingAnimationConfig,
      afterComplete: async (step, instance) => {
        that.editor.edit.on();
        that.editor.events.focus();

        instance.destroy();

        // the order of the following commands matters
        $(".fr-view .editor-copilot-completion").after(
          '<span class="cursor-marker"></span>',
        );
        that.editor.selection.setAfter($(".cursor-marker")[0]);
        that.editor.selection.restore();
        $(".cursor-marker").remove();
        that.editor.save.save();
      },
    });
    const completionArray = completion.split("\n");
    _.each(completionArray, (line, i) => {
      typeIt.type(line);
      if (i !== completionArray.length - 1) {
        typeIt.break();
      }
    });

    typeIt.go();
  }

  getLastParagraphObject() {
    const paragraphs = _.compact(this.beforeCursorHTML.split("<p>"));
    const everyParagraphExceptLast = $("<div>")
      .append(_.dropRight(paragraphs).join("<p>"))
      .text();
    const lastParagraph = $("<div>").append(_.last(paragraphs)).text();
    return {
      lastParagraph,
      everyParagraphExceptLast,
    };
  }

  getLastNCharsBeforeCursor(n) {
    const textWithNewlines = $("<div>")
      .append(this.beforeCursorHTML.replace("<br>", "\n"))
      .text();
    let content = textWithNewlines.slice(-1 * n);

    // remove the trailing slash from opening up the command
    // but only do it if has a slash in the last 10 characters
    if (
      this.beforeCursorText.slice(-10).indexOf(this.triggerCharacter) !== -1
    ) {
      content = content.split(this.triggerCharacter);
      content.pop();
      content = content.join(this.triggerCharacter);
    }
    return content;
  }

  handleChangeTones(selected) {
    if (_.isNull(selected) || selected.length <= this.maxTones) {
      this.setState({
        chosenTonesOfVoice: _.map(selected, "value"),
      });
    }
  }

  render() {
    const that = this;
    const {
      modalContent,
      showInlineTooltip,
      inlineTooltipX,
      inlineTooltipY,
      selectedText,
      maxHeight,
    } = this.state;
    const { enabled, report } = this.props;

    return (
      <div>
        <EditorCopilotModal
          content={modalContent}
          onAccept={() => {
            if (_.isFunction(that.acceptToxicContentFunction)) {
              that.acceptToxicContentFunction();
              that.acceptToxicContentFunction = null;
            } else {
              that.animateCompletion(modalContent);
            }
          }}
          onReject={() => {
            // $('.fr-tribute').empty();
            // $('#editor-froala-wrapper .typing-loader').remove();
            if (_.isFunction(that.rejectToxicContentFunction)) {
              that.rejectToxicContentFunction();
              that.rejectToxicContentFunction = null;
            } else {
              that.editor.edit.on();
              that.editor.events.focus();
            }
          }}
        />
        {enabled && showInlineTooltip && (
          <EditorCopilotTooltip
            report={report}
            editor={this.editor}
            x={inlineTooltipX}
            y={inlineTooltipY}
            maxHeight={maxHeight}
            selectedText={selectedText}
            handleDisable={this.disableInlineTooltip}
            handleToxicContent={this.handleToxicContent}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    title: state.outline.title,
    description: state.outline.description,
  };
  return props;
}

export default connect(mapStateToProps, {
  saveOutlineTitle,
  saveOutlineDescription,
})(EditorCopilot);
