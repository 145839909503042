import React from "react";
import ReactDOM from "react-dom";

class EditorCopilotModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const that = this;
    $(this.modalRef.current).on("hidden.bs.modal", () => {
      // reset state
    });
  }

  render() {
    const { content, onAccept, onReject } = this.props;
    return ReactDOM.createPortal(
      <div
        ref={this.modalRef}
        aria-hidden="true"
        aria-labelledby="editorCopilotModal"
        className="modal fade editor-copilot-modal onboarding-modal"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div
              className="onboarding-content pt-3"
              style={{ textAlign: "left" }}
            >
              <h4 className="onboarding-title">Copilot Warning</h4>
              <div>
                The content filter has flagged the text that was generated, not
                because discussion of this topic is necessarily wrong, but
                because this system can discuss some topics using unsafe or
                insensitive language.
              </div>
              <div className="mt-3 text-s">Generated text:</div>
              <div className="card card-body bg-light p-2">{content}</div>
              <div className="mt-4">What do you want to do?</div>
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={onReject}
                >
                  Reject
                </button>
                <button
                  className="btn btn-white ml-2"
                  data-dismiss="modal"
                  onClick={onAccept}
                >
                  Keep
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      $("body")[0]
    );
  }
}

export default EditorCopilotModal;
