import _ from "lodash";
import React, { Fragment } from "react";
import { useReport } from "../../../hooks/useReport";
import OutlineBuilderCards from "../../../outline_builder_cards";
import OutlineBuilderCustomDragLayer from "../../../outline_builder_custom_drag_layer";
import OutlineBuilderInitialBrainstorm from "../initial_brainstorm";

function OutlineBuilderContent({ groupBySemanticSimilarity }) {
  const report = useReport();

  if (!report) {
    return null;
  }

  const bypassBrainstorm =
    report.outline.title ||
    report.outline.description ||
    !!_.get(report, "outline.cards.length") ||
    _.get(report, "outline.bypassBrainstorm");

  return (
    <>
      {!bypassBrainstorm && <OutlineBuilderInitialBrainstorm />}
      {bypassBrainstorm && (
        <div className="p-3">
          <OutlineBuilderCards
            report={report}
            groupBySemanticSimilarity={groupBySemanticSimilarity}
          />
          <OutlineBuilderCustomDragLayer />
        </div>
      )}
    </>
  );
}

export default OutlineBuilderContent;
