import React from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import _ from "lodash";

class ToastsView extends React.Component {
  componentDidUpdate(prevProps) {
    const { toast } = this.props;
    if (toast && !_.isEqual(toast, prevProps.toast) && _.isObject(toast)) {
      this.props.toastManager.add(toast.message, toast.options);
    }
  }

  render() {
    return <div />;
  }
}

function mapStateToProps(state) {
  const props = {
    toast: state.toast,
  };
  return props;
}

export default withToastManager(connect(mapStateToProps, {})(ToastsView));
