import React from "react";

class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIframe: false,
      iframeHeight: null, // to handle safari not handling height 100% correctly
    };
    this.handleShown = this.handleShown.bind(this);
    this.handleHidden = this.handleHidden.bind(this);
    this.node = React.createRef();
  }

  componentDidMount() {
    $(this.node.current).on("shown.bs.modal", this.handleShown);
    $(this.node.current).on("hidden.bs.modal", this.handleHidden);
  }

  componentWillUnmount() {
    $(this.node.current).unbind("shown.bs.modal", this.handleShown);
    $(this.node.current).unbind("hidden.bs.modal", this.handleHidden);
  }

  handleHidden() {
    // stop playback of iframe
    this.setState({ showIframe: false });
  }

  handleShown() {
    const that = this;
    // vertically center the modal
    const modalDialog = $(this.node.current).find(".modal-dialog");
    modalDialog.css(
      "margin-top",
      Math.max(0, ($(window).height() - modalDialog.height()) / 2)
    );
    // delay iframe to reduce animation jitter
    window.setTimeout(() => {
      this.setState({
        showIframe: true,
        iframeHeight: `${$(that.node.current).find(".modal-body").height()}px`,
      });
    }, 300);
  }

  render() {
    const { wistiaId, id, vimeoId } = this.props;
    const { showIframe, iframeHeight } = this.state;
    return (
      <div
        aria-hidden="true"
        ref={this.node}
        id={id}
        className="modal fade topic-video-modal"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div className="modal-body">
              {showIframe && wistiaId && (
                <iframe
                  src={`https://fast.wistia.net/embed/iframe/${wistiaId}?videoFoam=true`}
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameBorder="0"
                  scrolling="no"
                  className="wistia_embed"
                  name="wistia_embed"
                  allowFullScreen
                  width="100%"
                  height={iframeHeight}
                />
              )}
              {showIframe && vimeoId && (
                <iframe
                  src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&title=0&byline=0&portrait=0&texttrack=en`}
                  width="100%"
                  height={iframeHeight}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoModal;
