import React, { Component } from "react";
import TextLoader from "./loader_text";
import Loader from "./loader";

class ReportCommonlyCitedLinksTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedBy: "no_of_mentions",
      order: "desc",
    };

    this.handleSortClick = this.handleSortClick.bind(this);
  }

  handleSortClick(sortedBy) {
    if (sortedBy === this.state.sortedBy) {
      this.setState({ order: this.state.order === "asc" ? "desc" : "asc" });
    }
    this.setState({ sortedBy });
  }

  render() {
    const that = this;
    let { data } = this.props;
    const citationStatus = this.props.CitationsStatus;
    const { highlightedCitations } = this.props;
    const { refreshAsyncComponents } = this.props;
    data = _.sortBy(data, (o) => {
      const coefficient = that.state.order === "asc" ? 1 : -1;
      return coefficient * parseInt(o[that.state.sortedBy], 10);
    });

    const cols = [
      ["Title", "title"],
      ["Domain", "domain"],
      ["Domain Authority", "domain_score"],
      ["Cited By", "no_of_mentions"],
    ];

    return (
      <div>
        {citationStatus == "Pending" && (
          <div className="p-2">
            Fetching Commonly Cited Links. Please wait...{" "}
            <Loader
              type="spinner"
              className="dark"
              style={{ paddingTop: "0.5rem" }}
            />
            <div className="mt-3">
              <TextLoader
                lines={[
                  `Extracting links from competitors`,
                  "Verifying data",
                  "Looking for most relevant and used citations",
                  "Organizing data",
                ]}
              />
            </div>
          </div>
        )}
        {_.isEmpty(data) && citationStatus == "Success" && (
          <div className="p-2">No links found</div>
        )}
        {(citationStatus == "Error" || citationStatus == "Retrying") && (
          <div>
            Error Fetching Commonly Cited Links <br />
            {/* <button className="btn btn-primary right mt-2" type="button" onClick={()=>{refreshAsyncComponents('external_citations')}} disabled={(citationStatus == 'Retrying')}>{(citationStatus == 'Retrying')? <p> Retrying .. <Loader type="spinner" style={{paddingTop:'0.5rem'}} /></p> : 'Retry'}</button> */}
          </div>
        )}
        {!_.isEmpty(data) && citationStatus == "Success" && (
          <table
            className="report-commonly-cited-links-table table table-striped"
            ref={this.citationRef}
          >
            <thead>
              <tr>
                {cols.map((col, index) => {
                  const label = col[0];
                  const key = col[1];
                  let icon;
                  if (key === that.state.sortedBy) {
                    icon =
                      that.state.sortedBy === key &&
                      that.state.order === "asc" ? (
                        <i className="fa fa-angle-down" />
                      ) : (
                        <i className="fa fa-angle-up" />
                      );
                  }
                  if (label === "Domain" || label === "Title") {
                    return <th key={index}>{label}</th>;
                  }
                  const semrushLogo =
                    label === "Domain Authority"
                      ? "<br /> source: <img src='/img/semrush_source_logo.svg' height='23px' />"
                      : "";
                  return (
                    <th
                      key={index}
                      data-html="true"
                      data-original-title={`Click to sort by ${label}${semrushLogo}`}
                      data-toggle="tooltip"
                      className={`clickable ${
                        key === that.state.sortedBy ? "text-bold" : ""
                      }`}
                      onClick={() => {
                        that.handleSortClick(key);
                      }}
                    >
                      {label} {icon}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {_.map(_.filter(data, "title"), (link, index) => {
                let isHighlighted = false;
                highlightedCitations.map((citation) => {
                  if (
                    citation.text === link.title &&
                    citation.url === link.url
                  ) {
                    isHighlighted = true;
                  }
                });
                const url = new URL(link.url);
                return (
                  <tr className="white-row citation-row" key={index}>
                    <td>
                      <img
                        className="pr-2"
                        src={`https://www.google.com/s2/favicons?domain_url=${url.host}`}
                      />{" "}
                      <a
                        className={`${
                          isHighlighted ? "highlight" : ""
                        } text-bold`}
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link.title}
                      </a>{" "}
                      <i className="fa fa-external-link" />
                    </td>
                    <td>
                      <a href={url.origin} target="_blank" rel="noreferrer">
                        {url.host}
                      </a>
                    </td>
                    <td>
                      {_.isEmpty(link.domain_score) ? "-" : link.domain_score}
                    </td>
                    <td className="position-relative">
                      <div>{link.no_of_mentions}</div>
                      <div className="citation-actions">
                        <div className="citation-actions-inner">
                          <button
                            className="btn bg-primary-2 hover-bg-primary-3"
                            onClick={(e) => {
                              e.stopPropagation();
                              that.props.toggleHighlightedCitations(link);
                            }}
                          >
                            {!isHighlighted
                              ? "Add to Outline"
                              : "Remove from Outline"}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default ReportCommonlyCitedLinksTable;
