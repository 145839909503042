import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { BookmarkType } from "../bookmark_type";
import OutlineBookmarkChooseButton from "../choose_button";
import OutlineBookmarksNotFound from "../no_bookmarks_found";
import OutlineTitleBookmarkListItem from "./list_item";

/**
 * Renders the bookmarked titles or returns an empty message
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineBookmarksTitle({ report, handleClose }) {
  const { titleBookmarks } = useSelector((state) => state.outline);
  const [selectedTitle, setSelectedTitle] = React.useState(null);
  const handleSelect = (title) => {
    if (selectedTitle === title) {
      setSelectedTitle(null);
    } else {
      setSelectedTitle(title);
    }
  };
  if (!titleBookmarks || titleBookmarks.length === 0) {
    return <OutlineBookmarksNotFound />;
  }

  const list = titleBookmarks.map((b, i) => (
    <OutlineTitleBookmarkListItem
      key={`title-bookmark-${i}`}
      report={report}
      title={b}
      i={i}
      selectedTitle={selectedTitle}
      handleSelect={handleSelect}
    />
  ));

  return (
    <>
      <div className="pt-2 builder-popover-bookmarks-list">{list}</div>
      <OutlineBookmarkChooseButton
        report={report}
        handleClose={handleClose}
        type={BookmarkType.TITLE}
        bookmarkChosen={selectedTitle}
      />
    </>
  );
}

export default OutlineBookmarksTitle;
