import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getReportById } from "../../../redux/selectors";
import RefreshButton from "../../common/refresh_button";
import { InitialBrainstormContext } from "./context";

function InitialBrainstormHeader() {
  const state = useSelector((s) => s);
  const { id } = useParams();
  const report = getReportById(state, id);
  const { data, isFetching } = useContext(InitialBrainstormContext);
  const queryClient = useQueryClient();

  if (!report) {
    return null;
  }

  const refresh = () => {
    queryClient.invalidateQueries("fetchInitialBrainstorm");
  };
  return (
    <div className="pt-3 px-3 pb-1 d-flex justify-between">
      <div>
        {isFetching && "Brainstorming post ideas for "}
        {!isFetching && data && "Here are a few ideas for "}
        <span className="text-bold">"{report.name}"</span>
      </div>
      {!isFetching && <RefreshButton onClick={refresh} />}
    </div>
  );
}

export default InitialBrainstormHeader;
