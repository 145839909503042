import React from "react";
import "mark.js/dist/jquery.mark.min.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { updateActiveReportLocally } from "./redux/actions";
import { getReportById } from "./redux/selectors";
import { htmlTextIsDifferent } from "./helpers";

class FroalaEditorWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {};
    this.unmark = this.unmark.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.initializeAutosave = this.initializeAutosave.bind(this);
    this.autosaveURL = `/api/keyword_reports/${props.report.id}`;
    this.autosaveMethod = "PUT";
    this.autosaveParam = "editor_content";
    this.autosaveIntervalLength = 3000;
  }

  unmark(html) {
    return sanitizeHtml(html, {
      allowedTags: [
        "address",
        "article",
        "aside",
        "footer",
        "header",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "hgroup",
        "main",
        "nav",
        "section",
        "blockquote",
        "dd",
        "div",
        "dl",
        "dt",
        "figcaption",
        "figure",
        "hr",
        "li",
        "main",
        "ol",
        "p",
        "pre",
        "ul",
        "a",
        "abbr",
        "b",
        "bdi",
        "bdo",
        "br",
        "cite",
        "code",
        "data",
        "dfn",
        "em",
        "i",
        "kbd",
        "q",
        "rb",
        "rp",
        "rt",
        "rtc",
        "ruby",
        "s",
        "samp",
        "small",
        "strong",
        "sub",
        "sup",
        "time",
        "u",
        "var",
        "wbr",
        "caption",
        "col",
        "colgroup",
        "table",
        "tbody",
        "td",
        "tfoot",
        "th",
        "thead",
        "tr",
        "img",
      ],
    });
  }

  // used for hotkeys (workaround due to Froala not exposing mac command key combo events)
  handleKeydown(e) {
    const isSaveHotkey = e.key === "s" && (e.metaKey || e.ctrlKey);
    if (isSaveHotkey) {
      e.preventDefault();
      this.editor.save.save();
      this.props.handleAddContentRevision();
    }
  }

  componentDidMount() {
    const that = this;
    this.editor = new FroalaEditor(
      "#editor-froala-wrapper",
      {
        key: "WE1B5dF3F4J4B7B7B6cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5B4F3E3D3F2B6B3==",
        listAdvancedTypes: false,
        toolbarButtons: [
          ["paragraphFormat", "bold", "italic", "underline"],
          ["formatOL", "formatUL", "insertLink"],
          ["undo", "redo"],
        ],
        shortcutsEnabled: [
          "show",
          "bold",
          "italic",
          "underline",
          "indent",
          "outdent",
          "undo",
          "redo",
          "insertImage",
          "createLink",
        ],
        quickInsertTags: [""],
        linkStyles: false,
        placeholderText: "",
        imageUploadParam: "image",
        imageUploadURL: "/api/keyword_reports/upload_image",
        imageUploadMethod: "POST",
        imageUploadRemoteUrls: false,
        attribution: false,
        imageMaxSize: 5 * 1024 * 1024, // 5MB
        events: {
          "image.beforePasteUpload": function (file) {
            // this is a workaround to avoid Froala storing pasted images
            // as base64 blobs which cause the browser to slow down

            // keeping this code in case I need to debug edge cases later
            // but seemingly the imageUploadRemoteUrls parameter is working
            // if the image is external, keep it external
            // var dataOriginal = $(file).attr('data-original');
            // if(dataOriginal && dataOriginal.indexOf(';base64') === -1){
            //   $(file).attr('src', $(file).attr('data-original'));
            //   return false;
            // }

            // if(file.currentSrc && file.src.indexOf(';base64') !== -1){
            //   file.src = file.currentSrc;
            //   return false;
            // }

            console.log("before paste upload", file);
          },
          "image.inserted": function ($img, response) {
            console.log("image.inserted", $img);
          },
          "paste.after": function () {
            const that = this;
            // remove all styles from content being pasted in
            this.selection.save();
            const html = this.html.get(true);
            const sanitizedHtml = sanitizeHtml(html, {
              allowedClasses: {
                span: ["fr-marker"],
              },
              allowedAttributes: {
                span: ["data-*"],
                a: ["href", "name", "target"],
                img: ["src"],
              },
              allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
            });
            console.log(html);
            console.log(sanitizedHtml);
            this.html.set(sanitizedHtml, true);
          },
        },
      },
      () => {
        // when the report is loaded in, add the content into Froala
        // but from then on, Froala is the source of truth

        // using this global to communicate between components to make to easy to debug the editor
        AP.editor = that.editor;
        const parentStateUpdate = {};
        if (
          that.props.report &&
          that.props.report.content &&
          that.props.report.content.html &&
          !that.state.initialized
        ) {
          that.editor.html.set(that.props.report.content.html);
          parentStateUpdate.copyMode = false;
        } else {
          console.log("froala initialized, but not setting HTML");
        }
        that.props.updateParentState({
          ...parentStateUpdate,
          initialized: true,
        });

        that.editor.$el.on("keydown", that.handleKeydown);
        this.initializeAutosave();
      }
    );
  }

  initializeAutosave() {
    const that = this;

    function beforeSave() {
      const html = that.editor.html.get();
      if (that.props.revisionPreview) {
        console.log("Just a Revision Preview, Do not save.");
        return false;
      }
      if (that.props.copilotIsFetching) {
        console.log("copilot is fetching, so dont save");
        return false;
      }
      that.saveBeforeTimestamp = new Date().getTime();
      const unmarkedHTMLIsDifferent =
        that.unmark(html) !== that.unmark(that.props.report.content.html);
      if (unmarkedHTMLIsDifferent) {
        that.props.updateParentState({
          saveState: "saving",
        });
        const htmlToSave = that.unmark(html);
        console.log(`saving ${htmlToSave}`);
        return htmlToSave;
      }
      return false;
    }

    function afterSave(data) {
      if (that.saveBeforeTimestamp) {
        const saveAfterTimestamp = new Date().getTime();
        console.log(
          `editor save took ${
            (saveAfterTimestamp - that.saveBeforeTimestamp) / 1000
          }s`
        );
      }
      if (that.props.copilotIsFetching) {
        console.log("copilot is fetching, so dont save.after");
        return false;
      }
      // backend responds with report every time an update is sent
      // do not update the html for the report, otherwise we lose the place of the cursor
      // delete data.report.content.html;
      that.props.updateActiveReportLocally(data.report);
      that.props.updateParentState({
        saveState: "saved",
      });
    }

    function afterSaveError(e) {
      console.log("afterSaveError", e);
      that.props.updateParentState({
        saveState: "error",
        saveError: "There was an error saving the content.",
      });
    }

    function autosave() {
      const htmlToSave = beforeSave();
      if (htmlToSave) {
        const payload = {};
        payload[that.autosaveParam] = htmlToSave;
        fetch(that.autosaveURL, {
          method: that.autosaveMethod,
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            afterSave(response);
          })
          .catch((e) => {
            afterSaveError(e);
          });
      }
    }

    if (this.autosaveInterval) {
      window.clearInterval(this.autosaveInterval);
    }
    this.autosaveInterval = window.setInterval(
      autosave,
      this.autosaveIntervalLength
    );
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.save.save();
      this.editor.$el.off("keydown");
      if (this.autosaveInterval) {
        window.clearInterval(this.autosaveInterval);
      }
    }
  }

  shouldComponentUpdate() {
    if (this.props.initialized) {
      return false;
    }
    return true;
  }

  render() {
    console.log("froala wrapper render");
    return <div id="editor-froala-wrapper" />;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    report: getReportById(state, ownProps.match.params.id),
  };
}

export default withRouter(
  connect(mapStateToProps, { updateActiveReportLocally })(FroalaEditorWrapper)
);
