import React from "react";
import { withRouter } from "react-router-dom";
import Loader from "./loader";

class InventoryPageModalRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serp: null,
      isLoading: false,
      isActive: false,
    };
    this.handleCreateBrief = this.handleCreateBrief.bind(this);
    this.handleSeeCompetitors = this.handleSeeCompetitors.bind(this);
    this.searchKeywordSERP = this.searchKeywordSERP.bind(this);
    this.getPositionChangeView = this.getPositionChangeView.bind(this);
    this.getClickChangeView = this.getClickChangeView.bind(this);
    this.getImpressionsChangeView = this.getImpressionsChangeView.bind(this);
    this.getSerpTableView = this.getSerpTableView.bind(this);
    this.node = React.createRef();
  }

  componentDidMount() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  searchKeywordSERP() {
    const that = this;
    const { serp } = this.state;
    const { keyword, location } = this.props;
    if (!serp) {
      this.setState({ isLoading: true });
      tanalytics.track("Keyword SERP Searched", { keyword });
      fetch(
        `/api/keyword_research_serp/${encodeURIComponent(keyword)}?location=us`,
        {
          credentials: "same-origin",
        }
      )
        .then((response) => response.json())
        .then((response) => {
          that.setState({ serp: response, isLoading: false });
        });
    }
  }

  shortenURL(urlString) {
    const maxLength = 25;
    const url = new URL(urlString).host;
    return url.replace(/^www./, "").slice(0, maxLength);
  }

  handleSeeCompetitors() {
    const { isLoading, isActive } = this.state;
    if (!isLoading && !isActive) {
      this.searchKeywordSERP();
      this.setState({
        isLoading: true,
        isActive: true,
      });
    } else if (!isLoading && isActive) {
      this.setState({
        isActive: false,
        isLoading: false,
      });
    }
  }

  handleCreateBrief() {
    const that = this;
    const { page, keyword, handleCreateBrief } = this.props;
    handleCreateBrief(
      [
        {
          name: keyword,
          import_url: page.url,
        },
      ],
      (res) => {
        that.props.history.push(`/briefs`);
      }
    );
  }

  getPositionChangeView(previousPosition, position) {
    if (position === previousPosition) {
      return (
        <div className="badge badge-pill bg-primary-2 text-primary-10">
          {position}
        </div>
      );
    }
    let color;
    const delta = previousPosition - position;
    if (delta > 4) {
      color = "green";
    } else if (delta > 0) {
      color = "yellow-green";
    } else if (delta > -2) {
      color = "yellow";
    } else if (delta > -4) {
      color = "orange-yellow";
    } else if (delta > -6) {
      color = "orange";
    } else {
      color = "red";
    }
    return (
      <div className="d-flex">
        <div className="badge badge-pill bg-primary-2 text-primary-10">
          {previousPosition}
        </div>
        <div className="pr-2">
          <i className="fal fa-arrow-right" />
        </div>
        <div className={`badge badge-pill bg-${color}-2 text-${color}-10`}>
          {position}
        </div>
      </div>
    );
  }

  getClickChangeView(
    previousClicks,
    clicks,
    globalMinClicksDelta,
    globalMaxClicksDelta
  ) {
    const delta = clicks - previousClicks;
    const percentage = Math.round((delta / previousClicks) * 100);
    let color;

    const minThreshhold =
      globalMinClicksDelta < 0 ? globalMinClicksDelta : -100;
    const maxThreshhold = globalMaxClicksDelta > 0 ? globalMaxClicksDelta : 100;

    if (delta < minThreshhold * 0.75) {
      color = "red";
    } else if (delta < minThreshhold * 0.5) {
      color = "orange";
    } else if (delta < minThreshhold * 0.25) {
      color = "orange-yellow";
    } else if (delta < 0) {
      color = "yellow";
    } else if (delta < maxThreshhold * 0.3) {
      color = "primary";
    } else if (delta < maxThreshhold * 0.6) {
      color = "yellow-green";
    } else {
      color = "green";
    }

    return (
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className={`badge badge-pill bg-${color}-2 text-${color}-10`}>
            {delta > 0 ? "+" : ""}
            {delta}
          </div>
        </div>
        <div className="text-primary-4 text-s mt-2 d-flex">
          <div>
            {previousClicks} <i className="fal fa-arrow-right" /> {clicks}
          </div>
        </div>
      </div>
    );
  }

  getImpressionsChangeView(
    previousImpressions,
    impressions,
    globalMinImpressionsDelta,
    globalMaxImpressionsDelta
  ) {
    const delta = impressions - previousImpressions;
    let color;

    const minThreshhold =
      globalMinImpressionsDelta < 0 ? globalMinImpressionsDelta : -100;
    const maxThreshhold =
      globalMaxImpressionsDelta > 0 ? globalMaxImpressionsDelta : 100;

    if (delta < minThreshhold * 0.75) {
      color = "red";
    } else if (delta < minThreshhold * 0.5) {
      color = "orange";
    } else if (delta < minThreshhold * 0.25) {
      color = "orange-yellow";
    } else if (delta < 0) {
      color = "yellow";
    } else if (delta < maxThreshhold * 0.3) {
      color = "primary";
    } else if (delta < maxThreshhold * 0.6) {
      color = "yellow-green";
    } else {
      color = "green";
    }

    return (
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className={`badge badge-pill bg-${color}-2 text-${color}-10`}>
            {delta > 0 ? "+" : ""}
            {delta}
          </div>
        </div>
        <div className="text-primary-4 text-s mt-2 d-flex">
          <div>
            {previousImpressions} <i className="fal fa-arrow-right" />{" "}
            {impressions}
          </div>
        </div>
      </div>
    );
  }

  getSerpTableView(serp) {
    return (
      <table className="keyword-research-serp-table">
        <thead>
          <tr>
            <th>Position</th>
            <th>Content</th>
            <th
              data-toggle="tooltip"
              data-original-title="Domain Authority (higher is more authoritative)"
            >
              DA
            </th>
            <th
              data-toggle="tooltip"
              data-original-title="Page Authority (higher is more authoritative)"
            >
              PA
            </th>
            <th
              data-toggle="tooltip"
              data-original-title="Number of unique domains linking to this page"
            >
              Backlinks
            </th>
            <th
              data-toggle="tooltip"
              data-original-title="Word count of the page"
            >
              WC
            </th>
          </tr>
        </thead>
        <tbody>
          {_.map(serp.results, (item, index) => (
            <tr key={index}>
              <td>
                <div
                  className="p-1 bg-bluegray-2 text-center"
                  style={{
                    borderRadius: "60px",
                    height: "30px",
                    width: "30px",
                  }}
                >
                  {item.position}
                </div>
              </td>
              <td>
                <div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      </div>
                      <div className="text-s">
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {this.shortenURL(item.link)}
                        </a>{" "}
                        {item.position === 0 && (
                          <div className="text-s badge bg-bluegray-2 ml-2 mr-0">
                            <i className="fa fa-book" /> featured snippet
                          </div>
                        )}
                      </div>
                    </div>
                    {_.isString(item.thumbnail) &&
                      !_.includes(item.thumbnail, "loading") && (
                        <div>
                          <div
                            style={{
                              width: "100px",
                              height: "60px",
                              backgroundImage: `url(${item.thumbnail})`,
                              backgroundSize: "cover",
                              borderRadius: "3px",
                            }}
                          />
                        </div>
                      )}
                  </div>
                  {item.snippet && _.isString(item.snippet) && (
                    <div className="text-s mt-1">{item.snippet}</div>
                  )}
                </div>
              </td>
              <td>
                {_.isEmpty(item.domain_score) ? (
                  "-"
                ) : (
                  <div>{item.domain_score}</div>
                )}
              </td>
              <td>
                {_.isEmpty(item.page_score) ? (
                  "-"
                ) : (
                  <div>{item.page_score}</div>
                )}
              </td>
              <td>
                {_.isEmpty(item.page_linking_domains) ? (
                  "-"
                ) : (
                  <div>{item.page_linking_domains}</div>
                )}
              </td>
              <td>{!item.word_count ? "-" : <div>{item.word_count}</div>}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const that = this;

    const {
      keyword,
      clicks,
      impressions,
      position,
      i,
      previousClicks,
      previousPosition,
      globalMinClicksDelta,
      globalMaxClicksDelta,
      previousImpressions,
      globalMinImpressionsDelta,
      globalMaxImpressionsDelta,
    } = this.props;
    const { serp, isLoading, isActive } = this.state;

    const isOpportunityRow = !_.isNumber(previousClicks);
    const cols = 5;

    let row;
    if (isOpportunityRow) {
      row = (
        <tr
          key={`row-${i}`}
          className={`inventory-page-modal-row ${
            isActive ? "active bg-bluegray-1" : ""
          }`}
          style={{ transform: "none" }}
        >
          <td className="text-bold">{keyword}</td>
          <td>{clicks}</td>
          <td>{impressions}</td>
          <td>{Math.round(position)}</td>
          <td>
            <div className="d-flex">
              <button
                className="btn bg-primary-1 hover-bg-primary-2 mr-1"
                onClick={this.handleSeeCompetitors}
              >
                {isActive ? "Minimize SERP" : "Expand SERP"}
              </button>{" "}
              <button
                className="btn bg-green-2 hover-bg-green-5"
                onClick={this.handleCreateBrief}
              >
                Create Brief
              </button>
            </div>
          </td>
        </tr>
      );
    } else {
      // decline row
      row = (
        <tr
          key={`row-${i}`}
          className={`inventory-page-modal-row ${
            isActive ? "active bg-bluegray-1" : ""
          }`}
          style={{ transform: "none" }}
        >
          <td className="text-bold">{keyword}</td>
          <td>
            {this.getImpressionsChangeView(
              previousImpressions,
              impressions,
              globalMinImpressionsDelta,
              globalMaxImpressionsDelta
            )}
          </td>
          <td>{this.getPositionChangeView(previousPosition, position)}</td>
          <td>
            {this.getClickChangeView(
              previousClicks,
              clicks,
              globalMinClicksDelta,
              globalMaxClicksDelta
            )}
          </td>
          <td>
            <div className="d-flex">
              <button
                className="btn bg-primary-1 hover-bg-primary-2 mr-1"
                onClick={this.handleSeeCompetitors}
              >
                {isActive ? "Minimize SERP" : "Expand SERP"}
              </button>{" "}
              <button
                className="btn bg-green-2 hover-bg-green-5"
                onClick={this.handleCreateBrief}
              >
                Create Brief
              </button>
            </div>
          </td>
        </tr>
      );
    }

    if (isActive || isLoading) {
      const detailRow = (
        <tr key={`detail-row-${i}`} className="active" ref={this.node}>
          <td colSpan={cols}>
            {!isLoading && serp && <div>{this.getSerpTableView(serp)}</div>}
            {isLoading && (
              <div className="w-100 text-center p-3">
                <Loader type="spinner" className="dark p-2" />
              </div>
            )}
          </td>
        </tr>
      );
      return (
        <>
          {row}
          {detailRow}
        </>
      );
    }
    return row;
  }
}

export default withRouter(InventoryPageModalRow);
