import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import OutlineBuilderContent from "./components/outline/builder/outline_builder_content";
import OutlineBuilderWizard from "./outline_builder_wizard";
import { saveOutlineTitle, saveOutlineDescription } from "./redux/actions";

class OutlineBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        position: "fixed",
        top: "79px",
        zIndex: "10",
      },
    };
    this.resizeHack = this.resizeHack.bind(this);
    this.ref = React.createRef();
  }

  resizeHack() {
    if ($(".builder-width-reference").length > 0) {
      // hack to enable fixed layout for this component
      // that is still responsive

      window.setTimeout(() => {
        if ($(".builder-width-reference").length > 0) {
          const style = {
            ...this.state.style,
            left: `${$(".builder-width-reference").offset().left}px`,
            width: `${$(".builder-width-reference").outerWidth()}px`,
          };
          if (!_.isEqual(style, this.state.style)) {
            this.setState({ style });
            if ($(window).width() < this.props.minScreenWidth) {
              // minimize if window gets resized too small
              this.props.onMinimize();
            }
          }
        }
      }, 351); // wait until width animation of brief is finished
    }
  }

  componentDidMount() {
    this.resizeHack();
    window.addEventListener("resize", this.resizeHack);
    $(this.ref.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    this.resizeHack();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHack);
  }

  render() {
    const { groupBySemanticSimilarity } = this.props;
    return (
      <DndProvider backend={HTML5Backend}>
        <div style={this.state.style} ref={this.ref}>
          <div className="builder element-box fadeRight d-flex flex-grow-1 flex-column">
            <i
              data-original-title="Minimize"
              data-toggle="tooltip"
              title=""
              className="builder-minimize clickable fas fa-minus text-bluegray-4"
              onClick={this.props.onMinimize}
            />
            <OutlineBuilderContent
              groupBySemanticSimilarity={groupBySemanticSimilarity}
            />
          </div>
        </div>
      </DndProvider>
    );
  }
}

function mapStateToProps(state) {
  const props = {};
  return props;
}

export default connect(mapStateToProps, {})(OutlineBuilder);
