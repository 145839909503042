import React from "react";
import { getShuffledClusterColors } from "./helpers";

class ReportOverviewQuestionsByClusterItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.node = React.createRef();
  }

  markCommonWords(source, maximum = 5) {
    let _source;
    let matches;
    let x;
    let y;
    _source = source.slice();
    matches = [];
    for (x = _source.length - 1; x >= 0; x--) {
      const output = _source.splice(x, 1);
      for (y = _source.length - 1; y >= 0; y--) {
        if (levenshtein.get(output[0], _source[y]) <= maximum) {
          output.push(_source[y]);
          _source.splice(y, 1);
          x--;
        }
      }
      matches.push(output);
    }

    matches = _.sortBy(matches, (group) => group.length);

    if (!_.isEmpty(matches) && !_.isEmpty(matches[0])) {
      $(this.node).unmark().mark(matches[0]);
    }
  }

  render() {
    let { questions, cards, handleQuestionClick, report, index } = this.props;

    const colorSeed = report.name.length; // this ensures colors stay consistent within a brief
    const clusterColors = getShuffledClusterColors(colorSeed);

    questions = _.sortBy(
      questions,
      (q) => -1 * (_.isNumber(q.vol) ? q.vol : 0)
    );

    return (
      <div
        className="report-overview-questions-box m-0 mt-2 mb-2 d-flex"
        style={{ fontSize: "12px" }}
        ref={this.node}
      >
        <div
          className={`bg-${clusterColors[index % clusterColors.length]}-5`}
          style={{ width: "3px" }}
        />
        <table
          className="table-minimal table-striped m-0 flex-grow-1"
          style={{ border: "2px solid #f1f4f8", borderLeft: "none" }}
        >
          <tbody>
            {_.map(questions, (q, i) => {
              const cardIdentifier = {
                type: "question",
                originalText: q.keyword,
              };
              const isHighlighted = _.isObject(_.find(cards, cardIdentifier));

              return (
                <tr
                  key={i}
                  className={`clickable ${
                    isHighlighted
                      ? "highlight-child-span"
                      : "highlight-child-span-on-hover"
                  } ${i === 0 ? "text-bold" : ""}`}
                  onClick={() => {
                    handleQuestionClick(q.keyword);
                  }}
                >
                  <td className="report-overview-questions-item pl-2">
                    <span>{q.keyword}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ReportOverviewQuestionsByClusterItem;
