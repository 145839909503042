import React from "react";

class Loader extends React.Component {
  constructor(props) {
    super(props);
  }

  ellipsisLoader() {}

  render() {
    const { type, className, style, height, width } = this.props;
    const apparatusLoader = (
      <div className={`logo_spinner_logo ${className || ""}`}>
        <div className="logo_spinner_outline" />
        <div className="logo_spinner_bg" />
      </div>
    );

    const spinnerLoader = (
      <div style={{ ...style }} className={`lds-spinner ${className || ""}`}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );

    let lineStyle = {};
    if (height) {
      lineStyle.height = `${height}px`;
    }
    if (width) {
      lineStyle.width = `${width}px`;
    }
    if (style) {
      lineStyle = Object.assign(lineStyle, style);
    }
    const lineLoader = (
      <div className={`line-loader ${className || ""}`} style={lineStyle} />
    );

    if (type === "spinner") {
      return spinnerLoader;
    }
    if (type === "line") {
      return lineLoader;
    }
    if (type === "ellipsis") {
      return <EllipsisLoader className={className} style={style} />;
    }
    if(type === "dot-flashing") {
      return <div className="loader-dot-flashing"></div>
    }
    return apparatusLoader;
  }
}

class EllipsisLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incrementor: 0,
    };
  }

  componentDidMount() {
    const that = this;
    this.animationInterval = window.setInterval(() => {
      that.setState({
        incrementor: that.state.incrementor + 1,
      });
    }, 600);
  }

  componentWillUnmount() {
    window.clearInterval(this.animationInterval);
  }

  render() {
    const { incrementor } = this.state;
    const { className, style } = this.props;
    return (
      <span style={{ ...style }} className={`${className || ""}`}>
        {".".repeat(incrementor % 4)}
      </span>
    );
  }
}

export default Loader;
