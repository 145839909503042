import React from "react";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import Loader from "./loader";
import { getClusteredQuestions } from "./redux/selectors";
import { ignoreTopic, restoreTopic, fetchReport } from "./redux/actions";
import {
  gradeToColor,
  wordCountToColor,
  headersToHTML,
  hasUsedTopic,
  isTopicOverused,
  timesTopicIsUsed,
  getShuffledClusterColors,
  triggerTopicClusters,
  isImportedAndNotConfirmed,
} from "./helpers";
import AddCustomTopicModal from "./add_custom_topic_modal";
import EditAlternateSpellingModal from "./edit_alternate_spelling_modal";
import EditorSidebarQuestions from "./editor_sidebar_questions";
import EditorSidebarBrainstorm from "./editor_sidebar_brainstorm";

import Coachmark from "./components/common/coachmark";

class EditorSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCompetitorIndex: 0,
      showOnlyUnused: false,
      groupBySemanticSimilarity: false,
      showScrollHelper: true,
      ignoredTopicName: null,
      restoredTopicName: null,
      groupBySemanticSimilarityLoading: false,
      showAddTopicDialog: false,
      showAddAlternateDialog: false,
      topicAlternatives: [],
    };
    this.ignoreTopic = this.ignoreTopic.bind(this);
    this.restoreTopic = this.restoreTopic.bind(this);
    this.enableGroupBySemanticSimilarity =
      this.enableGroupBySemanticSimilarity.bind(this);
    this.node = React.createRef();
  }

  componentDidUpdate() {
    const { activeTab } = this.props;
    if (activeTab === "topicDetail") {
      $(this.node.current).unmark();
      const activeTopic = this.getActiveTopic();
      if (activeTopic) {
        let markedTopics = [activeTopic.name];
        if (_.isArray(activeTopic.similar_keywords)) {
          markedTopics = _.concat(markedTopics, activeTopic.similar_keywords);
        }
        $(this.node.current)
          .find(".mark-topic")
          .mark(markedTopics, {
            separateWordSearch: false,
            accuracy: {
              value: "exactly",
              limiters: [
                ",",
                ".",
                ")",
                "(",
                "]",
                "[",
                "-",
                "{",
                "}",
                "~",
                "/",
                "?",
                "<",
                ">",
                ";",
                ":",
                "'",
                '"',
                "\\",
                "|",
                "+",
                "=",
                "_",
                "*",
                "&",
                "^",
                "%",
                "$",
                "#",
                "@",
                "!",
              ],
            },
          });

        // eliminate nested <mark> tags
        $(this.node.current)
          .find("mark")
          .each((index, mark) => {
            $(mark).html($(mark).text());
          });
      }
    } else {
      $(this.node.current).unmark();
    }

    if (
      this.state.ignoredTopicName !== null &&
      !this.props.isFetching &&
      activeTab === "topicDetail"
    ) {
      this.setState({ ignoredTopicName: null });
      this.props.onTabChange("topics");
    }
  }

  ignoreTopic(topic) {
    const that = this;
    this.setState({ ignoredTopicName: topic, activeTab: "topics" });
    this.props.ignoreTopic(this.props.report, topic);
  }

  restoreTopic(topic) {
    this.setState({ restoredTopicName: topic });
    this.props.restoreTopic(this.props.report, topic);
  }

  getActiveTopic() {
    let activeTopic;
    // this is required to handle the case when ignoring the last topic
    const lastActiveTopic =
      this.activeTopicList[this.activeTopicList.length - 1];
    if (
      lastActiveTopic &&
      this.props.activeTopicName ===
        this.activeTopicList[this.activeTopicList.length - 1].name
    ) {
      activeTopic = this.activeTopicList[this.activeTopicList.length - 1];
    } else {
      activeTopic = _.find(
        this.activeTopicList,
        (topic) =>
          topic.name.toLowerCase() === this.props.activeTopicName.toLowerCase()
      );
    }
    return activeTopic;
  }

  enableGroupBySemanticSimilarity() {
    // calls the API to get the topic relationships for this content brief

    if (this.props.isAnon) {
      $(".upgradeModal").modal("show");
      return;
    }

    const that = this;
    const { groupBySemanticSimilarityLoading } = this.state;

    if (!groupBySemanticSimilarityLoading) {
      that.setState({ groupBySemanticSimilarityLoading: true });
      triggerTopicClusters(
        this.props.report.id,
        () => {
          that.props.fetchReport(that.props.report.id);
          that.setState({
            groupBySemanticSimilarity: true,
            groupBySemanticSimilarityLoading: false,
          });
        },
        () => {
          that.props.showToast({
            message: `Something went wrong clustering the topics.`,
            options: { appearance: "error", autoDismiss: true },
          });
          that.setState({ groupBySemanticSimilarityLoading: false });
        }
      );
    }
  }

  render() {
    const that = this;
    const { report, isAnon, activeTab } = this.props;
    const {
      groupBySemanticSimilarity,
      groupBySemanticSimilarityLoading,
      showOnlyUnused,
      showAddTopicDialog,
    } = this.state;
    const { ignored_topics } = report;
    const { recommended_topics, competitors, topic_relationships } =
      report.report;
    var clusterColors;
    const showSortingToggle =
      !_.isEmpty(recommended_topics) &&
      _.isNumber(recommended_topics[0].cluster);
    const isImportedAndNotConfirmedState = isImportedAndNotConfirmed(report);

    // choose the which list of topics to show
    this.topics = null;
    if (groupBySemanticSimilarity && showSortingToggle) {
      this.topics = _.sortBy(recommended_topics, (topic) => topic.cluster);
      const colorSeed = report.name.length; // this ensures colors stay consistent within a brief
      var clusterColors = getShuffledClusterColors(colorSeed);
    } else {
      this.topics = _.sortBy(recommended_topics, (topic) => -1 * topic.score);
    }

    // ignore ignored topics
    if (!_.isEmpty(ignored_topics)) {
      this.topics = _.filter(
        this.topics,
        (topic) => !_.includes(ignored_topics, topic.name)
      );
    }

    if (showOnlyUnused && activeTab === "topics") {
      this.topics = _.filter(
        this.topics,
        (topic) => !hasUsedTopic(report, topic)
      );
    }

    this.activeTopicList = this.topics;
    const hasActiveTopics = this.activeTopicList.length > 0;

    // Topic List View
    const topicsView = !hasActiveTopics ? (
      <div className="text-center text-s pt-3">
        🎉 You've used all the topics! 🎊
      </div>
    ) : (
      this.activeTopicList.map((topic, index) => {
        const hasEnoughKeywords = hasUsedTopic(report, topic);
        const min = _.min(topic.count);
        const max = _.max(topic.count);

        let headerCount = 0;
        if (_.isArray(topic.header_usage)) {
          _.each(topic.header_usage, (header_use) => {
            headerCount += header_use.sentences.length;
          });
        }
        const hasSummary = !!topic.summary;
        const topicIsOverused = isTopicOverused(report, topic);

        // cluster colors
        const showClusterColor =
          _.isNumber(topic.cluster) && _.isArray(clusterColors);
        let clusterColor;
        if (showClusterColor) {
          clusterColor = clusterColors[topic.cluster % clusterColors.length];
        }

        return (
          <div
            key={index}
            className={`topic-view ${hasSummary ? "clickable" : ""} ${
              hasEnoughKeywords ? "active" : ""
            }`}
            onClick={() => {
              if (topic.summary) {
                that.props.onActiveTopicNameChange(topic.name);
                that.props.onTabChange("topicDetail");
              }
            }}
          >
            <div className="px-2 pt-2 pb-1 d-flex align-items-center font-sm">
              <div
                className={`editor-sidebar-cluster-color bg-${clusterColor}-5`}
              />
              <div className="flex-grow-1">
                <div
                  className={`text-medium ${
                    hasEnoughKeywords ? "text-green-10" : ""
                  }`}
                >
                  <span>{topic.name}</span>
                </div>
                <div
                  style={{ fontSize: "12px" }}
                  className={`text-muted text-light font-xs ${
                    hasSummary ? "topic-view-sources" : ""
                  } ${hasEnoughKeywords ? "text-green-10" : ""}`}
                >
                  {!topic.manually_added && (
                    <>
                      used by{" "}
                      <span className="font-italic">{topic.count.length}</span>{" "}
                      {topic.count.length > 1 ? "sources" : "source"}{" "}
                    </>
                  )}
                  {topic.manually_added && <>custom topic</>}
                </div>
                {hasSummary && (
                  <div
                    style={{ fontSize: "12px" }}
                    className="text-muted text-light font-xs topic-view-clicktosee"
                  >
                    click to see examples
                  </div>
                )}
              </div>
              <div>
                {!!topicIsOverused && (
                  <div className="topic-view-header">
                    <div className="topic-view-header-tooltip">
                      potential overuse of keyword <br />- reduce by{" "}
                      {topicIsOverused}
                      <div className="topic-view-header-triangle" />
                    </div>
                    <i className="fa fa-exclamation-triangle text-warning" />
                  </div>
                )}
              </div>
              <div>
                {headerCount > 0 && (
                  <div className="topic-view-header">
                    <div className="topic-view-header-tooltip">
                      used in {headerCount}{" "}
                      {headerCount > 1 ? "headings" : "heading"}
                      <div className="topic-view-header-triangle" />
                    </div>
                    <i
                      className={`fa fa-header ${
                        hasEnoughKeywords ? "text-green-10" : ""
                      }`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })
    );

    topicsView.push(
      <div
        key="editor-sidebar-add-custom-topic"
        className="topic-view clickable"
        onClick={() => {
          this.setState({ showAddTopicDialog: true });
        }}
      >
        <div className="px-2 pt-2 pb-3 d-flex align-items-center font-sm">
          <div className="editor-sidebar-cluster-color" />
          <div className="flex-grow-1">
            <div className="text-medium text-primary-7 font-italic">
              <span>+ add custom topic</span>
            </div>
          </div>
        </div>
      </div>
    );

    // Ignored Topic List View
    const hasIgnoredTopics =
      _.isArray(report.ignored_topics) && report.ignored_topics.length > 0;
    const ignoredTopicsView = !hasIgnoredTopics ? (
      <div />
    ) : (
      <div className="pb-3">
        <div
          className="px-2 pt-2 pb-1 d-flex align-items-center text-bold text-l"
          style={{ borderTop: "1px solid #efefef" }}
        >
          Ignored Topics
        </div>
        {report.ignored_topics.map((topic, index) => {
          const showRestoreLoader =
            that.props.isFetching && topic === that.state.restoredTopicName;
          return (
            <div
              key={index}
              className="px-2 pt-2 pb-1 d-flex align-items-center font-sm topic-view clickable"
              onClick={() => {
                that.restoreTopic(topic);
              }}
            >
              <div className="flex-grow-1">
                <div>{topic}</div>
                {showRestoreLoader ? (
                  <div className="text-muted font-weight-light text-s">
                    restoring...
                  </div>
                ) : (
                  <div className="text-muted font-weight-light text-s topic-view-clicktosee">
                    <span>click to restore</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );

    // Topic Detail View
    const activeTopic = this.getActiveTopic();
    let activeHeaderCount = 0;
    if (activeTopic && _.isArray(activeTopic.header_usage)) {
      _.each(activeTopic.header_usage, (header_use) => {
        activeHeaderCount += header_use.sentences.length;
      });
    }
    let uses;
    let mean;
    let useString;
    let currentUseString;
    let topicDetailView = <div />;
    if (activeTopic) {
      uses = timesTopicIsUsed(report, activeTopic);
      mean = Math.round(_.mean(activeTopic.count));
      useString = mean > 1 ? `${mean} uses` : `${mean} use`;
      currentUseString = uses > 1 ? `${uses} uses` : `${uses} use`;
      const showIgnoreLoader =
        that.props.isFetching &&
        activeTopic.name === that.state.ignoredTopicName;
      const topicIsOverused = isTopicOverused(report, activeTopic);
      let usageIcon = "";
      if (topicIsOverused) {
        usageIcon = (
          <a
            data-original-title="potential overuse of keyword"
            data-toggle="tooltip"
            href="#"
            title=""
          >
            <i className="fa fa-exclamation-triangle text-orange-5" />
          </a>
        );
      } else if (uses >= mean) {
        usageIcon = <i className="fa fa-check text-success" />;
      }
      topicDetailView = (
        <div
          className="mt-2 pr-4 pl-4 pb-2 editor-sidebar-detail"
          style={{ overflow: "auto" }}
        >
          <div className="editor-sidebar-detail-nav pt-2 pb-3">
            <a
              style={{ position: "absolute", left: 0, top: "11px" }}
              href="#"
              onClick={() => {
                this.props.onTabChange("topics");
              }}
            >
              <i className="fa fa-arrow-right" /> Back
            </a>
            <div style={{ position: "absolute", right: 0, top: "11px" }}>
              {showIgnoreLoader ? (
                <Loader type="spinner" className="dark" />
              ) : (
                <a
                  href="#"
                  onClick={() => {
                    this.ignoreTopic(activeTopic.name);
                  }}
                >
                  Ignore
                </a>
              )}
            </div>
            <div className="text-bold text-l pr-5 pl-5">
              Examples of <i>{activeTopic.name}</i>
            </div>
            <div className="btn text-right">
              <div
                className="text-s w-100 text-bluegray-4"
                onClick={() => {
                  const similarTopicsObject = activeTopic.similar_keywords.map(
                    (v) => ({
                      label: v,
                      value: v,
                    })
                  );
                  this.setState({
                    showAddAlternateDialog: true,
                    topicAlternatives: similarTopicsObject,
                  });
                }}
              >
                <i className="fa fa-pencil" />
                Add Alternate Spelling
              </div>
            </div>
            {_.isArray(activeTopic.similar_keywords) &&
              !_.isEmpty(activeTopic.similar_keywords) && (
                <div
                  className="text-s w-100 text-bluegray-4"
                  style={{
                    position: "absolute",
                    marginTop: "-3px",
                    height: "13px",
                    overflow: "hidden",
                  }}
                >
                  similar: {activeTopic.similar_keywords.join(", ")}
                </div>
              )}
          </div>
          <div className="pt-2">
            <div
              className="d-flex text-center pb-2"
              style={{ borderBottom: "1px solid rgb(229, 236, 244)" }}
            >
              <div className="flex-grow-1">
                <div className="text-s">Relevance</div>

                <div>{Math.round(activeTopic.score)}%</div>
              </div>
              <div className="flex-grow-1">
                <div className="text-s">Typical Usage</div>
                <div>{useString}</div>
              </div>
              <div className="flex-grow-1">
                <div className="text-s">Current Usage</div>
                <div>
                  {uses === 0 ? "none" : currentUseString} {usageIcon}
                </div>
              </div>
            </div>
            {!_.isArray(activeTopic.header_usage) &&
              !_.isArray(activeTopic.summary) && (
                <div>
                  Although this keyword was mentioned in{" "}
                  {activeTopic.count.length}{" "}
                  {activeTopic.count.length > 1 ? "sources" : "source"}, we
                  weren't able to pull up the exact examples. Our apologies!
                </div>
              )}
            {_.isArray(activeTopic.header_usage) &&
              !_.isEmpty(activeTopic.header_usage) && (
                <div className="mb-5">
                  <div
                    className="pt-2 pb-2 text-l"
                    style={{
                      position: "sticky",
                      top: "43px",
                      backgroundColor: "white",
                      borderBottom: "1px solid #e5ecf4",
                      zIndex: 2,
                    }}
                  >
                    <i className="fa fa-header" />{" "}
                    <span className="text-medium">
                      Used in {activeHeaderCount}{" "}
                      {activeHeaderCount > 1 ? "Headings" : "Heading"}{" "}
                    </span>
                  </div>
                  {activeTopic.header_usage.map((header_use, index) => (
                    <div key={index} className="pt-1 pb-1">
                      {!header_use.opted_out ? (
                        header_use.no_access ? null : (
                          // <div className="topic-opt-out-section">
                          //   <div className="topic-opt-out-dummy-info">
                          //     <div className="topic-sources-overview-section">
                          //       <div className="pb-2">
                          //         <div className="text-medium pt-2">
                          //           Lorem Ipsum is simply dummy text of the printing
                          //         </div>
                          //         <div className="text-muted font-sm pt-1">
                          //           <img className="pr-2" src={`https://www.google.com/s2/favicons?domain_url=${new URL(header_use.url).host}`} /><div target="_blank" style={{display:'inline'}}>{new URL(header_use.url).host} <i className="fa fa-external-link" /></div>
                          //         </div>
                          //       </div>
                          //       <div className="pt-2">
                          //         <ul className="pl-4 mark-topic">
                          //             <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</li>
                          //         </ul>
                          //       </div>
                          //     </div>
                          //   </div>
                          //   <div className="card shadow p-3 mt-2 mb-3 topic-view-opt-out-card">
                          //     <div className="card-body">
                          //       <div className="pb-1">
                          //         <p className="font-weight-bold">Opt-In to See Examples from AdThrive Publishers</p>
                          //         <p className="text">Use examples of how this topic is used to get the full context and write more compelling content.</p>
                          //       </div>
                          //       <div className="text-muted pt-0 pb-0">
                          //         <div className="btn btn-grey mr-2 p-2"><i className="fa fa-unlock mr-1" style={{color:'#44859B'}}/> Opt-In to Unlock</div>
                          //       </div>
                          //     </div>
                          //   </div>
                          // </div>
                          <div className="topic-heading-overview-section">
                            <div
                              className="pb-2"
                              style={{
                                position: "sticky",
                                top: "87px",
                                backgroundColor: "white",
                              }}
                            >
                              <div className="text-medium pt-2">
                                {header_use.title}
                              </div>
                              <div className="text-muted font-sm pt-1">
                                <img
                                  className="pr-2"
                                  src={`https://www.google.com/s2/favicons?domain_url=${
                                    new URL(header_use.url).host
                                  }`}
                                />{" "}
                                <a
                                  href={header_use.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {new URL(header_use.url).host}{" "}
                                  <i className="fa fa-external-link" />
                                </a>
                              </div>
                            </div>
                            <div className="pt-2">
                              <ul className="pl-4 mark-topic">
                                {header_use.sentences.map((sentence, index) => (
                                  <li key={index}>{sentence}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            {_.isArray(activeTopic.summary) && !_.isEmpty(activeTopic.summary) && (
              <div>
                <div
                  className="pt-2 pb-2 text-l"
                  style={{
                    position: "sticky",
                    top: "43px",
                    backgroundColor: "white",
                    borderBottom: "1px solid #e5ecf4",
                    zIndex: 2,
                  }}
                >
                  <i className="fa fa-paragraph" />{" "}
                  <span className="text-medium">
                    Used in {activeTopic.summary.length}{" "}
                    {activeTopic.summary.length > 1 ? "Sources" : "Source"}{" "}
                  </span>
                </div>
                {activeTopic.summary.map((summary, index) => (
                  <div key={index} className="pt-1 pb-1">
                    {!summary.opted_out ? (
                      summary.no_access ? null : (
                        // <div className="topic-opt-out-section">
                        //   <div className="topic-opt-out-dummy-info">
                        //     <div className="topic-sources-overview-section">
                        //       <div className="pb-2">
                        //         <div className="text-medium pt-2">
                        //           Lorem Ipsum is simply dummy text of the printing
                        //         </div>
                        //         <div className="text-muted font-sm pt-1">
                        //           <img className="pr-2" src={`https://www.google.com/s2/favicons?domain_url=${new URL(summary.url).host}`} /><div target="_blank" style={{display:'inline'}}>{new URL(summary.url).host} <i className="fa fa-external-link" /></div>
                        //         </div>
                        //       </div>
                        //       <div className="pt-2">
                        //         <ul className="pl-4 mark-topic">
                        //             <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</li>
                        //         </ul>
                        //       </div>
                        //     </div>
                        //   </div>
                        //   <div className="card shadow p-3 mt-2 mb-3 topic-view-opt-out-card">
                        //     <div className="card-body">
                        //       <div className="pb-1">
                        //         <p className="font-weight-bold">Opt-In to See Examples from AdThrive Publishers</p>
                        //         <p className="text">Use examples of how this topic is used to get the full context and write more compelling content.</p>
                        //       </div>
                        //       <div className="text-muted pt-0 pb-0">
                        //         <div className="btn btn-grey mr-2 p-2"><i className="fa fa-unlock mr-1" style={{color:'#44859B'}}/> Opt-In to Unlock</div>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                        <div className="topic-sources-overview-section">
                          <div
                            className="pb-2"
                            style={{
                              position: "sticky",
                              top: "87px",
                              backgroundColor: "white",
                            }}
                          >
                            <div className="text-medium pt-2">
                              {summary.title}
                            </div>
                            <div className="text-muted font-sm pt-1">
                              <img
                                className="pr-2"
                                src={`https://www.google.com/s2/favicons?domain_url=${
                                  new URL(summary.url).host
                                }`}
                              />
                              <a
                                href={summary.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {new URL(summary.url).host}{" "}
                                <i className="fa fa-external-link" />
                              </a>
                            </div>
                          </div>
                          <div className="pt-2">
                            <ul className="pl-4 mark-topic">
                              {summary.sentences.map((sentence, index) => (
                                <li key={index}>{sentence}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                ))}
              </div>
            )}
          </div>
          <EditAlternateSpellingModal
            show={this.state.showAddAlternateDialog}
            report={this.props.report}
            onHide={() => {
              that.setState({ showAddAlternateDialog: false });
            }}
            activeTopic={activeTopic}
            topicAlternatives={this.state.topicAlternatives}
            setTopicAlternative={(topicAlternatives) => {
              this.setState({ topicAlternatives });
            }}
          />
        </div>
      );
    }

    const {
      related_questions,
      related_questions_quora,
      related_questions_autocomplete,
    } = this.props.report.report;
    let { clusteredQuestions } = this.props;
    clusteredQuestions = _.filter(clusteredQuestions, (cq) => cq.length > 1);
    const questionsView = (
      <EditorSidebarQuestions
        report={this.props.report}
        questions={related_questions}
        quora_questions={related_questions_quora}
        autocomplete_questions={related_questions_autocomplete}
        clusteredQuestions={clusteredQuestions}
        fetchReport={this.props.fetchReport}
        showToast={this.props.showToast}
      />
    );

    const brainstormView = (
      <EditorSidebarBrainstorm
        report={this.props.report}
        showToast={this.props.showToast}
        sessionId={this.props.brainstormSessionId}
      />
    );

    const competitorsSortedByGrade = _.sortBy(
      competitors,
      (competitor) => -1 * competitor.coverage
    );
    const researchView = (
      <div className="w-100">
        {competitorsSortedByGrade.map((competitor, index) => {
          let url = "";
          try {
            url = new URL(competitor.url || competitor.link);
          } catch (e) {
            console.log("invalid competitor url: ", competitor);
          }
          const badgeClass = `bg-${gradeToColor(
            competitor.grade
          )}-2 text-${gradeToColor(competitor.grade)}-10`;
          return (
            <div key={index} className="d-flex flex-column">
              <div className="d-flex">
                <div className="p-3">
                  <img
                    className="pr-2"
                    src={`https://www.google.com/s2/favicons?domain_url=${url.hostname}`}
                  />
                </div>
                <div className="flex-grow-1 p-3">
                  <div className="font-weight-medium">{competitor.title}</div>
                  <div className="text-muted">
                    <a href={url} target="_blank" rel="noreferrer">
                      {url.hostname}
                    </a>
                  </div>
                  <div>
                    {!_.isEmpty(competitor.description) && (
                      <div>
                        <div className="text-medium text-bluegray-4 mt-2 text-uppercase">
                          Description
                        </div>
                        <div>{competitor.description}</div>
                      </div>
                    )}
                    {!_.isEmpty(headersToHTML(competitor.html)) && (
                      <div style={{ lineHeight: "1.8" }}>
                        <div className="text-medium text-bluegray-4 mt-2 text-uppercase">
                          Outline
                        </div>
                        {headersToHTML(competitor.html)}
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        this.setState({ activeCompetitorIndex: index });
                        this.props.onTabChange("researchDetail");
                      }}
                    >
                      See Full Text
                    </button>
                  </div>
                </div>
                <div className="p-3">
                  <div className={`p-2 text-medium ${badgeClass}`}>
                    {competitor.grade}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

    const activeCompetitor =
      competitorsSortedByGrade[this.state.activeCompetitorIndex];
    const researchDetailView = (
      <div>
        <div className="d-flex editor-sidebar-research-detail-nav mb-3 pb-3">
          <div className="p-2">
            <img
              className="pr-2"
              src={`https://www.google.com/s2/favicons?domain_url=${
                new URL(activeCompetitor.url).hostname
              }`}
            />
          </div>
          <div className="flex-grow-1">
            <div className="text-medium">{activeCompetitor.title}</div>
            <div className="text-muted">
              <a href={activeCompetitor.url} target="_blank" rel="noreferrer">
                {new URL(activeCompetitor.url).hostname}
              </a>
            </div>
          </div>
          <div className="pr-3">
            <a
              href="#"
              onClick={() => {
                this.props.onTabChange("research");
              }}
            >
              <i className="fa fa-times" />
            </a>
          </div>
        </div>
        <div
          className="editor-sidebar-research-detail pr-4 pl-4 pt-0"
          dangerouslySetInnerHTML={{ __html: activeCompetitor.html }}
        />
      </div>
    );

    return (
      <div className="editor-sidebar d-flex flex-column" ref={this.node}>
        {this.props.gradingProgressBar}
        <div className="editor-sidebar-nav d-flex flex-row">
          <a
            className={`d-flex flex-grow-1 ${
              /topic/.test(activeTab) ? "active" : ""
            }`}
            href="#"
            onClick={() => {
              this.props.onTabChange("topics");
            }}
          >
            Topics
          </a>
          <a
            className={`d-flex flex-grow-1 ${
              /questions/.test(activeTab) ? "active" : ""
            }`}
            href="#"
            onClick={() => {
              this.props.onTabChange("questions");
            }}
          >
            Questions
          </a>

          <a
            className={`d-flex flex-grow-1 ${
              /brainstorm/.test(activeTab) ? "active" : ""
            } position-relative`}
            href="#"
            onClick={() => {
              this.props.onTabChange("brainstorm");
            }}
            style={{ padding: 0 }}
          >
            <Coachmark>
              <div style={{ position: "relative" }}>
                <div style={{ padding: "9px 8px" }}>Brainstorm</div>
                <div
                  className="badge badge-pill bg-green-2 text-green-8 text-s"
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "6px",
                    borderRadius: "3px",
                  }}
                >
                  beta
                </div>
              </div>
            </Coachmark>
          </a>
        </div>
        {(activeTab === "topics" || activeTab === "topicDetail") && (
          <div
            className="flex-column editor-sidebar-scroll-holder"
            style={{ display: activeTab === "topics" ? "flex" : "none" }}
            onScroll={(e) => {
              const newShowScrollHelper = !(e.target.scrollTop > 0);
              if (newShowScrollHelper !== this.state.showScrollHelper) {
                this.setState({ showScrollHelper: newShowScrollHelper });
              }
            }}
          >
            <div
              className={`${
                isAnon ? "overflow-hidden" : "overflow-auto"
              } flex-grow-1 editor-sidebar-scroll ${
                isImportedAndNotConfirmedState ? "blur-low" : ""
              }`}
            >
              <div className="text-bold text-l p-3">Topics to Cover</div>
              <div className="d-flex flex-row pt-1 pl-3 pr-3 pb-2 toggle-small">
                <Toggle
                  id="show-only-unused"
                  checked={showOnlyUnused}
                  icons={false}
                  onChange={() => {
                    if (this.props.isAnon) {
                      $(".upgradeModal").modal("show");
                    } else {
                      that.setState({ showOnlyUnused: !showOnlyUnused });
                    }
                  }}
                />
                <label
                  style={{ marginLeft: "5px" }}
                  className="text-s clickable"
                  htmlFor="show-only-unused"
                >
                  Show Only Unused
                </label>
              </div>
              {showSortingToggle && (
                <div className="d-flex flex-row pt-1 pl-3 pr-3 pb-2 toggle-small">
                  <Toggle
                    id="group-by-semantic"
                    checked={this.state.groupBySemanticSimilarity}
                    icons={false}
                    onChange={() => {
                      that.setState({
                        groupBySemanticSimilarity:
                          !this.state.groupBySemanticSimilarity,
                      });
                    }}
                  />
                  <label
                    style={{ marginLeft: "5px" }}
                    className="text-s clickable"
                    htmlFor="group-by-semantic"
                  >
                    Group By Theme
                  </label>
                </div>
              )}
              {!showSortingToggle && (
                <div className="d-flex flex-row pt-1 pl-3 pr-3 pb-2 toggle-small">
                  <Toggle
                    id="group-by-semantic"
                    checked={this.state.groupBySemanticSimilarity}
                    icons={false}
                    onChange={() => {
                      that.enableGroupBySemanticSimilarity();
                    }}
                  />
                  <label
                    style={{ marginLeft: "5px" }}
                    className="text-s clickable"
                    htmlFor="group-by-semantic"
                  >
                    Group By Theme
                  </label>
                  {groupBySemanticSimilarityLoading && (
                    <div>
                      <Loader
                        type="spinner"
                        className="dark"
                        style={{ marginTop: "-6px", marginLeft: "6px" }}
                      />
                    </div>
                  )}
                </div>
              )}
              {topicsView}
              {ignoredTopicsView}
            </div>
            {!isAnon && hasActiveTopics && (
              <div
                className={`text-center bg-primary-1 animate-all w-100 clickable editor-sidebar-scroll-helper ${
                  this.state.showScrollHelper ? "" : "hidden"
                }`}
                onClick={() => {
                  $(".editor-sidebar-scroll").scrollTop(500);
                }}
              >
                Scroll to see more <i className="fa fa-arrow-down" />
              </div>
            )}
            {isAnon && (
              <div
                className="text-center bg-bluegray-3 animate-all w-100 clickable editor-sidebar-scroll-helper"
                onClick={() => {
                  $(".upgradeModal").modal("show");
                }}
              >
                Upgrade to Unlock <i className="far fa-lock-alt" />
              </div>
            )}
          </div>
        )}
        {activeTab === "research" && (
          <div className="d-flex overflow-auto">{researchView}</div>
        )}
        {activeTab === "researchDetail" && (
          <div className="d-flex overflow-auto">{researchDetailView}</div>
        )}
        {activeTab === "topicDetail" && (
          <div className="d-flex overflow-auto">{topicDetailView}</div>
        )}
        {activeTab === "questions" && (
          <div className="d-flex overflow-auto">{questionsView}</div>
        )}
        <div
          className={`${
            activeTab === "brainstorm" ? "d-flex" : "d-none"
          } overflow-auto`}
        >
          {brainstormView}
        </div>
        <AddCustomTopicModal
          show={showAddTopicDialog}
          report={this.props.report}
          onHide={() => {
            that.setState({ showAddTopicDialog: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.reports.isFetching,
    clusteredQuestions: getClusteredQuestions(state, ownProps.match.params.id),
  };
}
export default withRouter(
  connect(mapStateToProps, { ignoreTopic, restoreTopic, fetchReport })(
    EditorSidebar
  )
);
