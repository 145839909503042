import { SHOW_TOAST, CLEAR_TOAST } from "../actions";

const initialState = null;

export function toast(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST: {
      return action.toast;
    }
    case CLEAR_TOAST: {
      return null;
    }
    default: {
      return state;
    }
  }
}

export default toast;
