import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOutlineTitleBookmark,
  removeOutlineTitleBookmark,
  addOutlineDescriptionBookmark,
  removeOutlineDescriptionBookmark,
} from "../../redux/actions";

/**
 * Renders the bookmark icon for the title + description combo generated ideas
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineComboBookmarkButton({ report, title, description }) {
  const dispatch = useDispatch();
  const { titleBookmarks, descriptionBookmarks } = useSelector(
    (state) => state.outline
  );

  const titleAlreadyBookmarked = titleBookmarks.includes(title);
  const descriptionAlreadyBookmarked =
    descriptionBookmarks.includes(description);
  const comboAlreadyBookmarked =
    titleAlreadyBookmarked && descriptionAlreadyBookmarked;
  const onClick = (e) => {
    e.stopPropagation();
    if (comboAlreadyBookmarked) {
      dispatch(removeOutlineTitleBookmark(report, title));
      dispatch(removeOutlineDescriptionBookmark(report, description));
    } else {
      dispatch(addOutlineTitleBookmark(report, title));
      dispatch(addOutlineDescriptionBookmark(report, description));
    }
  };

  return (
    <i
      className={`builder-popover-card-bookmark fa-bookmark ${
        comboAlreadyBookmarked ? "fas" : "far"
      }`}
      onClick={onClick}
    />
  );
}

export default OutlineComboBookmarkButton;
