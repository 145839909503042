import { applyMiddleware, createStore, compose } from "redux";
import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";
import * as Sentry from "@sentry/react";
import rootReducer from "./reducers";

const composeEnhancer =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export default createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware, logger), sentryReduxEnhancer)
);
