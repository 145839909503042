import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { BookmarkType } from "../bookmark_type";
import OutlineBookmarkChooseButton from "../choose_button";
import OutlineBookmarksNotFound from "../no_bookmarks_found";
import OutlineDescriptionBookmarkListItem from "./list_item";

/**
 * Renders the bookmarked descriptions or returns an empty message
 * @param {object} props
 * @returns {JSX.Element}
 */
function OutlineBookmarksDescription({ report, handleClose }) {
  const { descriptionBookmarks } = useSelector((state) => state.outline);
  const [selectedDescription, setSelectedDescription] = React.useState(null);
  const handleSelect = (description) => {
    if (selectedDescription === description) {
      setSelectedDescription(null);
    } else {
      setSelectedDescription(description);
    }
  };
  if (!descriptionBookmarks || descriptionBookmarks.length === 0) {
    return <OutlineBookmarksNotFound />;
  }

  const list = descriptionBookmarks.map((b, i) => (
    <OutlineDescriptionBookmarkListItem
      key={`description-bookmark-${i}`}
      report={report}
      description={b}
      i={i}
      selectedDescription={selectedDescription}
      handleSelect={handleSelect}
    />
  ));

  return (
    <>
      <div className="pt-2 builder-popover-bookmarks-list">{list}</div>
      <OutlineBookmarkChooseButton
        report={report}
        handleClose={handleClose}
        type={BookmarkType.DESCRIPTION}
        bookmarkChosen={selectedDescription}
      />
    </>
  );
}

export default OutlineBookmarksDescription;
