import React from "react";
import { Link, withRouter } from "react-router-dom";

class NavSide extends React.Component {
  constructor(props) {
    super(props);
    this.showVideoPopup = this.showVideoPopup.bind(this);
  }

  componentDidMount() {
    this.menu_timer = null;
    const that = this;
    $(".menu-activated-on-hover").on(
      "mouseenter",
      "ul.main-menu > li.has-sub-menu",
      function () {
        const $elem = $(this);
        clearTimeout(that.menu_timer);
        $elem
          .closest("ul")
          .addClass("has-active")
          .find("> li.active")
          .removeClass("active")
          .addClass("was-active");
        $elem.addClass("active");
      }
    );

    $(".menu-activated-on-hover").on(
      "mouseleave",
      "ul.main-menu > li.has-sub-menu",
      function () {
        const $elem = $(this);
        that.menu_timer = setTimeout(() => {
          $elem.removeClass("active").closest("ul").removeClass("has-active");
          $elem
            .closest("ul")
            .find(".was-active")
            .addClass("active")
            .removeClass("was-active");
        }, 30);
      }
    );
  }

  showVideoPopup() {
    $("#outline-builder-video").modal("show");
  }

  render() {
    const isEditor = /\/editor/.test(window.location.href);
    const isBrief = /\/overview/.test(window.location.href);
    const isLoggedIn = !_.isEmpty(AP.currentUser.email);
    const showLinkToResearch =
      isLoggedIn || (!isLoggedIn && isBrief) || AP.hasLoadedResearch;

    if (isBrief) {
      // If an anonymous brief was shared using the research link, allow access to both sections of brief.
      // If shared using grader link, only allow access to grader.
      AP.hasLoadedResearch = true;
    }

    return (
      <div className={`nav-side ${isEditor ? "col-9 col-xl-9-5" : ""}`}>
        <div className="nav-side-inner d-flex">
          <div className="nav-side-tabs">
            <div className="main-menu d-flex flex-row">
              {/* <ul className={`nav nav-tabs ml-4 ${isBrief ? 'mr-5' : ''}`} style={{borderBottom: 'none'}}>
                      {showLinkToResearch && <li className="nav-item">
                        <Link to={{pathname: `/briefs/${this.props.match.params.id}/overview`, search: this.props.location.search}} className={`${isBrief ? "active text-bold" : ""} clickable nav-side-tab nav-link d-flex`}>
                            <div className="os-icon os-icon-tasks-checked d-inline pr-2"></div> <span data-test="main-menu-enhancements">Content Research</span>
                        </Link>
                      </li>}
                      <li className="nav-item">
                        <Link to={{pathname: `/briefs/${this.props.match.params.id}/editor`, search: this.props.location.search}} className={`${isEditor ? "active text-bold" : ""} clickable nav-side-tab nav-link d-flex`}>
                          <div className="os-icon os-icon-text-input d-inline pr-2"></div> <span data-test="main-menu-enhancements">Content Grader</span>
                      </Link>
                      </li>
                    </ul> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NavSide);
