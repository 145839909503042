import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ListSkeletonLoader() {
  return (
    <div className="clickable p-3 mt-2 mb-2 builder-popover-card animated fadeUp">
      <Skeleton />
    </div>
  );
}

export default ListSkeletonLoader;
