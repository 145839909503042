import React from "react";
import { DebounceInput } from "react-debounce-input";
import { connect } from "react-redux";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { withRouter } from "react-router-dom";
import { getActiveSegment } from "./redux/selectors";
import {
  updatePageExtraFilters,
  fetchPages,
  fetchPagesForExport,
  ignorePages,
  restorePages,
  setSelectedPages,
  setSelectedSegment,
  createReportBulk,
} from "./redux/actions";

class InventoryFilterbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      openDropdown: "", // 'strikingDistance', 'compareDates'
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.initializeDateRangePickers =
      this.initializeDateRangePickers.bind(this);
    this.reinitializeSecondDateRangePicker =
      this.reinitializeSecondDateRangePicker.bind(this);
    this.handleCompareDates = this.handleCompareDates.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDropdownButtonClick = this.handleDropdownButtonClick.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleCreateBrief = this.handleCreateBrief.bind(this);
    this.handleIgnore = this.handleIgnore.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.fetchPages = this.fetchPages.bind(this);
    this.getFilterButtonViewWithPopover =
      this.getFilterButtonViewWithPopover.bind(this);
    this.ref = React.createRef();

    this.dateFormat = "YYYY-MM-DD";
    this.filtersWithDropdown = ["declining_traffic", "compare_dates"];
    this.defaultExtraFilters = {
      strikingDistance: {
        type: "striking_distance",
      },
      titleOpportunities: {
        type: "title_opportunities",
      },
      decliningTraffic: {
        type: "declining_traffic",
        dateStart: moment().subtract(30, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        datePreviousStart: moment().subtract(60, "day").format(this.dateFormat),
        datePreviousEnd: moment().subtract(31, "day").format(this.dateFormat),
      },
      compareDates: {
        type: "compare_dates",
        dateStart: moment().subtract(30, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        datePreviousStart: moment().subtract(60, "day").format(this.dateFormat),
        datePreviousEnd: moment().subtract(31, "day").format(this.dateFormat),
      },
      dateRange: {
        type: "date_range",
        dateStart: moment().subtract(30, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
      },
    };

    this.decliningTrafficOptions = [
      {
        label: "Last 30 days vs Previous Period",
        shortLabel: "Last 30 days",
        dateStart: moment().subtract(30, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        datePreviousStart: moment().subtract(60, "day").format(this.dateFormat),
        datePreviousEnd: moment().subtract(31, "day").format(this.dateFormat),
      },
      {
        label: "Last 90 days vs Previous Period",
        shortLabel: "Last 90 days",
        dateStart: moment().subtract(90, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        datePreviousStart: moment()
          .subtract(180, "day")
          .format(this.dateFormat),
        datePreviousEnd: moment().subtract(91, "day").format(this.dateFormat),
      },
      {
        label: "Last 6 months vs Previous Period",
        shortLabel: "Last 6 mos",
        dateStart: moment().subtract(180, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        datePreviousStart: moment()
          .subtract(360, "day")
          .format(this.dateFormat),
        datePreviousEnd: moment().subtract(181, "day").format(this.dateFormat),
      },
      {
        label: "Last 90 days, Year over Year",
        shortLabel: "Last 3 days, YoY",
        dateStart: moment().subtract(90, "day").format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        datePreviousStart: moment()
          .subtract(365 + 90, "day")
          .format(this.dateFormat),
        datePreviousEnd: moment().subtract(365, "day").format(this.dateFormat),
      },
    ];
  }

  handleSearch(e) {
    const search = e.target.value;
    this.setState({ search });
  }

  handleExport() {
    const exportPageLimit = 10000;
    const {
      fetchPagesForExport,
      inventory,
      activeSegment,
      sort,
      order,
      extraFilters,
    } = this.props;
    fetchPagesForExport(
      inventory.id,
      activeSegment.id,
      {
        sort,
        order,
        extraFilters,
        limit: exportPageLimit,
      },
      (res) => {
        if (res && _.isArray(res.pages)) {
          const data = [
            [
              "url",
              "top_keyword",
              "position",
              "clicks",
              "impressions",
              "title",
            ],
          ];
          _.each(res.pages, (page) => {
            data.push([
              page.url,
              page.top_keyword,
              page.top_keyword_position,
              page.clicks,
              page.impressions,
              _.replace(page.metadata.title, ",", ""),
            ]);
          });
          const csvContent = `data:text/csv;charset=utf-8,${data
            .map((e) => e.join(","))
            .join("\n")}`;

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `pages.csv`);
          document.body.appendChild(link); // Required for FF

          link.click();
        }
      }
    );
  }

  componentDidUpdate() {
    this.initializeDateRangePickers();
  }

  initializeDateRangePickers() {
    const that = this;
    if ($(this.ref.current).find(".dr-input").length === 0) {
      // load data into jquery widget
      const compareDates = _.find(this.props.extraFilters, {
        type: "compare_dates",
      });
      if (compareDates) {
        // from redux
        var { dateStart, dateEnd, datePreviousStart, datePreviousEnd } =
          compareDates;
      } else {
        // default
        var { dateStart, dateEnd, datePreviousStart, datePreviousEnd } =
          this.defaultExtraFilters.compareDates;
      }
      const rangeData = {
        dateStart,
        dateEnd,
        datePreviousStart,
        datePreviousEnd,
      };

      const firstUpdated = this.props.inventory.first_updated;
      const lastUpdated = this.props.inventory.last_updated;

      this.compareDate1 = new Calendar({
        element: $(".inventory-filterbar-compare-date1"),
        latest_date: moment(lastUpdated),
        earliest_date: moment(firstUpdated),
        start_date: rangeData.dateStart,
        end_date: rangeData.dateEnd,
        presets: [
          {
            label: "This Month",
            start: moment().startOf("month"),
            end: moment(),
          },
          {
            label: "Last 30 days",
            start: moment().subtract(29, "days"),
            end: moment(),
          },
          {
            label: "Last month",
            start: moment().subtract(1, "month").startOf("month"),
            end: moment().subtract(1, "month").endOf("month"),
          },
          {
            label: "Last 3 months",
            start: moment().subtract(3, "month").startOf("month"),
            end: moment().subtract(1, "month").endOf("month"),
          },
        ],
        callback() {
          const start = moment(this.start_date).format(that.dateFormat);
          const end = moment(this.end_date).format(that.dateFormat);

          that.reinitializeSecondDateRangePicker(
            this.start_date,
            this.end_date
          );
        },
      });

      this.compareDate2 = new Calendar({
        element: $(".inventory-filterbar-compare-date2"),
        latest_date: moment(lastUpdated),
        earliest_date: moment(firstUpdated),
        start_date: rangeData.datePreviousStart,
        end_date: rangeData.datePreviousEnd,
      });

      // date range date picker
      const dateRange = _.find(this.props.extraFilters, { type: "date_range" });
      if (dateRange) {
        // from redux
        var { dateStart, dateEnd } = dateRange;
      } else {
        // default
        var { dateStart, dateEnd } = this.defaultExtraFilters.dateRange;
      }
      this.compareDate3 = new Calendar({
        element: $(".inventory-filterbar-range-date1"),
        latest_date: moment(lastUpdated),
        earliest_date: moment(firstUpdated),
        start_date: dateStart,
        end_date: dateEnd,
      });
    }
  }

  reinitializeSecondDateRangePicker(firstStartDate, firstEndDate) {
    // calculate the previous period and set as both the value and a preset
    const holder = $(".inventory-filterbar-compare-date2");
    firstStartDate = moment(firstStartDate);
    firstEndDate = moment(firstEndDate);
    const daysInPeriod = firstEndDate.clone().diff(firstStartDate, "days");

    const previousStartDate = firstStartDate
      .clone()
      .subtract(daysInPeriod + 1, "day");
    const previousEndDate = firstStartDate.clone().subtract(1, "day");

    const firstUpdated = this.props.inventory.first_updated;
    const lastUpdated = this.props.inventory.last_updated;

    holder.empty();
    this.compareDate2 = new Calendar({
      element: holder,
      latest_date: moment(lastUpdated),
      earliest_date: moment(firstUpdated),
      start_date: previousStartDate,
      end_date: previousEndDate,
      presets: [
        {
          label: "Previous Period",
          start: previousStartDate,
          end: previousEndDate,
        },
      ],
    });
    holder.fadeIn();
  }

  handleCompareDates(e) {
    // get dates from jquery datepickers and save into redux
    this.handleActivateFilter(e, "compareDates", {
      dateStart: moment(this.compareDate1.start_date).format(this.dateFormat),
      dateEnd: moment(this.compareDate1.end_date).format(this.dateFormat),
      datePreviousStart: moment(this.compareDate2.start_date).format(
        this.dateFormat
      ),
      datePreviousEnd: moment(this.compareDate2.end_date).format(
        this.dateFormat
      ),
    });
    this.closeDropdown();
  }

  handleDateRange(e) {
    // get dates from jquery datepickers and save into redux
    this.handleActivateFilter(e, "dateRange", {
      dateStart: moment(this.compareDate3.start_date).format(this.dateFormat),
      dateEnd: moment(this.compareDate3.end_date).format(this.dateFormat),
    });
    this.closeDropdown();
  }

  handleDropdownButtonClick(e, filterObj, type) {
    const inactive = !_.isObject(filterObj);
    const activeButDropdownClosed =
      _.isObject(filterObj) && this.state.openDropdown !== type;
    if (inactive || activeButDropdownClosed) {
      // inactive or active, dropdown closed - open dropdown
      this.openDropdown(type);
      e.stopPropagation();
    } else {
      // active, dropdown already open - reset
      this.handleReset();
    }
  }

  openDropdown(type) {
    const that = this;
    this.setState({
      openDropdown: type,
    });
    $(window).one("click", (e) => {
      const clickIsFromPicker = e.target.className.includes("dr-date");
      if (!clickIsFromPicker) {
        that.closeDropdown();
      }
    });
  }

  closeDropdown() {
    this.setState({
      openDropdown: "",
    });
    $(window).unbind("click", this.closeDropdown);
  }

  handleActivateFilter(e, type, details) {
    const that = this;
    const { updatePageExtraFilters, extraFilters } = this.props;
    const defaultExtraFilter = this.defaultExtraFilters[type];
    let extraFilter;

    if (_.isObject(details)) {
      extraFilter = { ...defaultExtraFilter, ...details };
    } else {
      extraFilter = defaultExtraFilter;
    }

    updatePageExtraFilters({ extraFilters: [extraFilter] });
    e.stopPropagation();
  }

  handleReset() {
    this.props.updatePageExtraFilters({ extraFilters: [] });
    this.closeDropdown();
  }

  handleCreateBrief() {
    const that = this;
    const {
      createReportBulk,
      selectedPages,
      pages,
      setSelectedPages,
      setSelectedSegment,
    } = this.props;
    let data = _.map(selectedPages, (pageId) => {
      const page = _.find(pages, { id: pageId });
      if (page) {
        return {
          name: page.top_keyword,
          import_url: page.url,
        };
      }
      return null;
    });
    data = _.compact(data);
    createReportBulk(data, (res) => {
      setSelectedPages(null);
      setSelectedSegment(null);
      that.props.history.push(`/briefs`);
    });
  }

  handleIgnore() {
    const that = this;
    const {
      selectedPages,
      selectedSegment,
      ignorePages,
      inventory,
      setSelectedPages,
      setSelectedSegment,
    } = this.props;
    if (!_.isEmpty(selectedPages) || selectedSegment) {
      ignorePages(
        inventory.id,
        selectedPages,
        selectedSegment,
        that.fetchPages
      );
      setSelectedPages(null);
      setSelectedSegment(null);
    }
  }

  handleRestore() {
    const that = this;
    const {
      selectedPages,
      selectedSegment,
      restorePages,
      inventory,
      setSelectedPages,
      setSelectedSegment,
    } = this.props;
    if (!_.isEmpty(selectedPages)) {
      restorePages(
        inventory.id,
        selectedPages,
        selectedSegment,
        that.fetchPages
      );
      setSelectedPages(null);
      setSelectedSegment(null);
    }
  }

  fetchPages() {
    const {
      limit,
      inventory,
      activeSegment,
      sort,
      order,
      fetchPages,
      extraFilters,
      offset,
    } = this.props;
    fetchPages(inventory.id, activeSegment.id, {
      sort,
      order,
      offset,
      limit,
      extraFilters,
    });
  }

  getFilterButtonViewWithPopover(filterButtonView, popoverView, isActive) {
    if (!isActive) {
      return (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={popoverView}
          delay={350}
        >
          {filterButtonView}
        </OverlayTrigger>
      );
    }
    return (
      <OverlayTrigger
        show={false}
        trigger={[]}
        placement="bottom"
        overlay={popoverView}
        delay={350}
      >
        {filterButtonView}
      </OverlayTrigger>
    );
  }

  render() {
    const that = this;
    const { search, openDropdown } = this.state;
    const { extraFilters, selectedSegment, selectedPages, activeSegment } =
      this.props;

    const strikingDistance = _.find(extraFilters, {
      type: "striking_distance",
    });
    const titleOpportunities = _.find(extraFilters, {
      type: "title_opportunities",
    });
    const decliningTraffic = _.find(extraFilters, {
      type: "declining_traffic",
    });
    const compareDates = _.find(extraFilters, { type: "compare_dates" });
    const dateRange = _.find(extraFilters, { type: "date_range" });
    const showReset =
      strikingDistance ||
      decliningTraffic ||
      compareDates ||
      dateRange ||
      titleOpportunities;
    const noFilters = !showReset;

    const hasSelectedSegment = _.isNumber(selectedSegment);
    const hasSelectedPages = !_.isEmpty(selectedPages);
    const showActions = hasSelectedPages || hasSelectedSegment;
    const showingIgnoredPages = activeSegment && activeSegment.id === "ignored";
    let selectedPagesCount = 0;
    if (hasSelectedSegment) {
      selectedPagesCount = selectedSegment.page_count;
    } else if (hasSelectedPages) {
      selectedPagesCount = selectedPages.length;
    }

    const strikingDistancePopover = (
      <Popover>
        <Popover.Content>
          <div className="inventory-filterbar-button-popover">
            These are pages with lots of impressions but few clicks.
          </div>
        </Popover.Content>
      </Popover>
    );
    const titleOpportunitiesPopover = (
      <Popover>
        <Popover.Content>
          <div className="inventory-filterbar-button-popover">
            These are pages with a low click-through-rate. We recommend editing
            the title to make it more clickworthy.
          </div>
        </Popover.Content>
      </Popover>
    );
    const decliningTrafficPopover = (
      <Popover>
        <Popover.Content>
          <div className="inventory-filterbar-button-popover">
            These are pages that have seen a decline in traffic due to a decline
            in position.
          </div>
        </Popover.Content>
      </Popover>
    );
    const strikingDistanceButton = (
      <div
        className={`inventory-filterbar-button btn btn-grey-shadow clickable ${
          strikingDistance ? "active" : ""
        }`}
        onClick={(e) => {
          strikingDistance
            ? that.handleReset()
            : that.handleActivateFilter(e, "strikingDistance");
        }}
      >
        <span>
          <i className="fas fa-stars mr-2" />
          Opportunity Pages
        </span>
      </div>
    );
    const titleOpportunitiesButton = (
      <div
        className={`d-none inventory-filterbar-button btn btn-grey-shadow clickable ${
          titleOpportunities ? "active" : ""
        }`}
        onClick={(e) => {
          titleOpportunities
            ? that.handleReset()
            : that.handleActivateFilter(e, "titleOpportunities");
        }}
      >
        <span>Title Opportunities</span>
      </div>
    );
    const decliningTrafficButton = (
      <div
        className={`inventory-filterbar-button btn btn-grey-shadow clickable ${
          decliningTraffic ? "active" : ""
        }`}
        onClick={(e) => {
          this.handleDropdownButtonClick(
            e,
            decliningTraffic,
            "decliningTraffic"
          );
        }}
      >
        <span>
          <i className="fa fa-chart-line-down mr-2" />
          Decaying Pages
          {decliningTraffic && decliningTraffic.shortLabel
            ? `: ${decliningTraffic.shortLabel}`
            : ""}
        </span>
        {openDropdown === "decliningTraffic" && (
          <div className="inventory-filterbar-dropdown">
            <ul className="inventory-filterbar-dropdown-unordered-list pb-2 pt-2">
              {_.map(that.decliningTrafficOptions, (option, i) => {
                const isActive = _.find(extraFilters, {
                  type: "declining_traffic",
                  label: option.label,
                });
                return (
                  <li
                    key={i}
                    className={`${
                      isActive ? "active" : ""
                    } clickable inventory-filterbar-dropdown-list-item`}
                    onClick={(e) => {
                      that.handleActivateFilter(e, "decliningTraffic", option);
                      that.closeDropdown();
                    }}
                  >
                    {option.label}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );

    const filtersView = (
      <div className="d-flex">
        {this.getFilterButtonViewWithPopover(
          strikingDistanceButton,
          strikingDistancePopover,
          !!strikingDistance
        )}
        {this.getFilterButtonViewWithPopover(
          titleOpportunitiesButton,
          titleOpportunitiesPopover,
          !!titleOpportunities
        )}
        {this.getFilterButtonViewWithPopover(
          decliningTrafficButton,
          decliningTrafficPopover,
          !!decliningTraffic || openDropdown === "decliningTraffic"
        )}

        <div
          className={`d-none inventory-filterbar-button btn btn-grey-shadow clickable ${
            compareDates ? "active" : ""
          }`}
          onClick={(e) => {
            this.handleDropdownButtonClick(e, compareDates, "compareDates");
          }}
        >
          <span>Compare Dates</span>
          {openDropdown === "compareDates" && (
            <div
              className="inventory-filterbar-dropdown"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="d-flex flex-column p-3">
                <div className="inventory-filterbar-compare-date1 daterange" />
                <div className="text-center p-3">vs</div>
                <div className="inventory-filterbar-compare-date2 daterange" />
                <div className="mt-3 d-flex">
                  <div className="p-2 flex-grow-1" />
                  <button className="btn btn-white" onClick={this.handleReset}>
                    Reset
                  </button>
                  <div className="p-2" />
                  <button
                    className="btn btn-primary"
                    onClick={this.handleCompareDates}
                  >
                    Compare
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`d-none inventory-filterbar-button btn btn-grey-shadow clickable ${
            dateRange || noFilters ? "active" : ""
          }`}
          onClick={(e) => {
            this.handleDropdownButtonClick(e, dateRange, "dateRange");
          }}
        >
          <span>
            Date Range
            {dateRange && dateRange.dateStart && dateRange.dateEnd
              ? `: ${dateRange.dateStart} - ${dateRange.dateEnd}`
              : ""}
            {noFilters ? ": Last 30 days" : ""}
          </span>
          {openDropdown === "dateRange" && (
            <div
              className="inventory-filterbar-dropdown"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="d-flex flex-column p-3">
                <div className="inventory-filterbar-range-date1 daterange" />
                <div className="mt-3 d-flex">
                  <div className="p-2 flex-grow-1" />
                  <button className="btn btn-white" onClick={this.handleReset}>
                    Reset
                  </button>
                  <div className="p-2" />
                  <button
                    className="btn btn-primary"
                    onClick={this.handleDateRange}
                  >
                    Show
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {showReset && (
          <div className="p-1">
            <a href="#" className="text-danger" onClick={this.handleReset}>
              Reset
            </a>
          </div>
        )}
      </div>
    );

    let createBriefPopover = (
      <Popover>
        <Popover.Content>
          <div className="mb-3">
            This will use{" "}
            <span className="text-bold">{selectedPagesCount}</span> content
            brief credits out of your remaining{" "}
            <span className="text-bold">{AP.currentUser.briefs_remaining}</span>
            . Do you want to proceed?
          </div>
          <button
            className="btn btn-primary-outline"
            onClick={() => document.body.click()}
          >
            No
          </button>
          <button
            className="btn btn-primary ml-2"
            onClick={(e) => {
              that.handleCreateBrief(e);
            }}
          >
            Create {selectedPagesCount} Briefs
          </button>
        </Popover.Content>
      </Popover>
    );
    if (selectedPagesCount > AP.currentUser.briefs_remaining) {
      createBriefPopover = (
        <Popover>
          <Popover.Content>
            <div className="mb-3">
              You do not have enough content brief credits to create briefs for
              the selected pages.
            </div>
            <a className="btn btn-primary ml-2" href="/billing">
              Upgrade
            </a>
          </Popover.Content>
        </Popover>
      );
    }
    const actionsView = (
      <div className="d-flex">
        {!hasSelectedSegment && (
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom"
            overlay={createBriefPopover}
          >
            <div className="inventory-filterbar-button btn btn-grey-shadow clickable">
              <i className="fas fa-file-alt mr-1" /> Create Briefs
            </div>
          </OverlayTrigger>
        )}
        {!showingIgnoredPages && (
          <div
            className="inventory-filterbar-button btn btn-grey-shadow clickable"
            onClick={this.handleIgnore}
          >
            <i className="fas fa-do-not-enter mr-1" /> Ignore Pages
          </div>
        )}
        {showingIgnoredPages && (
          <div
            className="inventory-filterbar-button btn btn-grey-shadow clickable"
            onClick={this.handleRestore}
          >
            <i className="fas fa-trash-restore mr-1" /> Restore Pages
          </div>
        )}
      </div>
    );

    return (
      <div
        ref={this.ref}
        className="inventory-filterbar d-flex"
        style={{ height: "30px", minHeight: "30px" }}
      >
        {!showActions && filtersView}
        {showActions && actionsView}
        <div className="flex-grow-1" />
        <div className="element-wrapper" style={{ paddingBottom: "0" }}>
          <div
            className="clickable btn btn-primary"
            onClick={this.handleExport}
          >
            <i className="far fa-download" /> Export
          </div>
          <div className="element-search float-right small d-none">
            <DebounceInput
              minLength={3}
              debounceTimeout={300}
              onChange={this.handleSearch}
              style={{ backgroundColor: "white", border: "1px solid #ccd9e8" }}
              placeholder="search pages"
              type="text"
              value={search}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { pages, selectedSegment, selectedPages } = state;
  const activeSegment = getActiveSegment(state);
  return {
    activeSegment,
    sort: pages.sort,
    order: pages.order,
    limit: pages.limit,
    extraFilters: pages.extraFilters,
    pages: pages.items,
    selectedSegment,
    selectedPages,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updatePageExtraFilters,
    fetchPages,
    fetchPagesForExport,
    ignorePages,
    restorePages,
    setSelectedPages,
    setSelectedSegment,
    createReportBulk,
  })(InventoryFilterbar)
);
