import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { timeAgo } from "./helpers";
import {
  fetchInventories,
  invalidateInventories,
  setActiveInventory,
  saveInventory,
  deleteInventory,
} from "./redux/actions";
import Loader from "./loader";
import InventoryModal from "./inventory_modal";

class InventoriesDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleDelete = this.handleDelete.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.pollIfNecessary = this.pollIfNecessary.bind(this);
    this.handleInventoryAdded = this.handleInventoryAdded.bind(this);
    this.node = React.createRef();
  }

  componentDidMount() {
    this.props.fetchInventories();
  }

  componentDidUpdate() {
    this.pollIfNecessary();
  }

  componentWillUnmount() {
    if (this.pollInterval) {
      window.clearInterval(this.pollInterval);
    }
  }

  handleRename(id) {
    console.log(`renaming ${id}`);
  }

  handleDelete(id) {
    this.props.deleteInventory(id);
  }

  pollIfNecessary() {
    const intervalTime = 5000;
    const that = this;
    const { inventories } = this.props;
    if (this.pollInterval) {
      window.clearInterval(this.pollInterval);
    }
    if (
      _.isArray(inventories) &&
      _.filter(inventories, { status: "pending" }).length > 0
    ) {
      this.pollInterval = window.setInterval(() => {
        that.props.fetchInventories();
      }, intervalTime);
    }
  }

  handleInventoryAdded() {
    $(".inventoryModal").modal("hide");
    this.props.fetchInventories();
  }

  render() {
    const that = this;

    const { inventories, isFetching } = this.props;
    const pagesUsed = 100;
    const pagesAvailable = 1000;

    const emptyState = (
      <div className="row">
        <div className="col-md-4 offset-4 text-center">
          <img
            className="mt-5 mb-3"
            src="/img/logo_large_icon.png"
            style={{ width: "100px" }}
          />
          <h1>
            Content Opportunities{" "}
            <span
              className="text-s text-primary-5"
              style={{ fontSize: "19px", fontFamily: "Graphik Regular" }}
            >
              beta
            </span>
          </h1>
          <p className="text-l mb-5 mt-2">
            Content Opportunities help you find the biggest opportunities for
            optimization for your site. Click the button below to add your first
            inventory.
          </p>
          <button
            className="btn btn-primary btn-lg"
            onClick={() => {
              $(".inventoryModal").modal("show");
            }}
          >
            Add Site
          </button>
        </div>
      </div>
    );

    const noAccessState = (
      <div className="row">
        <div className="col-md-4 offset-4 text-center">
          <img
            className="mt-5 mb-3"
            src="/img/logo_large_icon.png"
            style={{ width: "100px" }}
          />
          <h1>
            Content Opportunities{" "}
            <span
              className="text-s text-primary-5"
              style={{ fontSize: "19px", fontFamily: "Graphik Regular" }}
            >
              beta
            </span>
          </h1>
          <p className="text-l mb-5 mt-2">
            Connect your Google Search Console account to easily organize your
            sites' pages and find optimization opportunities.
          </p>
          <a
            className="btn btn-primary btn-lg text-white"
            target="_blank"
            href="https://content-opportunities.paperform.co"
            rel="noreferrer"
          >
            Request Early Access
          </a>
        </div>
      </div>
    );

    const showEmptyState = !_.isArray(inventories) || inventories.length === 0;
    const userHasAccess =
      AP &&
      AP.currentUser &&
      AP.currentUser.plan &&
      AP.currentUser.plan.meta &&
      AP.currentUser.plan.meta.inventories;

    const editTooltip = <Tooltip>Rename Inventory</Tooltip>;
    const deleteTooltip = <Tooltip>Delete Inventory</Tooltip>;

    return (
      <div className="w-100 inventories-dashboard" ref={this.node}>
        <div className="element-wrapper">
          {this.props.isFetching && showEmptyState && (
            <div className="text-center p-5">
              <Loader type="spinner" className="dark" />
            </div>
          )}
          {!this.props.isFetching && !userHasAccess && noAccessState}
          {!this.props.isFetching &&
            userHasAccess &&
            showEmptyState &&
            emptyState}
          {!showEmptyState && userHasAccess && (
            <div>
              <h4>Content Opportunities</h4>
              <div
                style={{
                  position: "relative",
                  marginTop: "-20px",
                  height: "65px",
                }}
              >
                <button
                  className="mr-2 mb-2 btn btn-primary"
                  style={{ position: "absolute", right: "0", top: "0" }}
                  onClick={() => {
                    $(".inventoryModal").modal("show");
                  }}
                >
                  Add Inventory +
                </button>
                <div
                  className="text-bluegray-4 text-s d-none"
                  style={{ position: "absolute", right: "9px", top: "-21px" }}
                >
                  <span>
                    {pagesUsed} pages used out of {pagesAvailable} on your{" "}
                    {_.find(AP.plans, { id: AP.currentUser.planId }).name} plan
                  </span>
                </div>
              </div>
              <div
                className="element-box-tp projects-list"
                style={{ opacity: this.props.isFetching ? "0.5" : "1" }}
              >
                {_.map(inventories, (inventory, i) => {
                  const isPending = inventory.status === "pending";
                  return (
                    <div
                      key={i}
                      className={`inventory-box project-box mr-2 mb-2 mt-0 ${
                        isPending ? "pending" : "clickable"
                      }`}
                      style={{ opacity: isPending ? "0.7" : "1" }}
                      onClick={() => {
                        if (!isPending) {
                          that.props.history.push(
                            `/inventories/${inventory.id}`
                          );
                        }
                      }}
                    >
                      <div
                        className="project-head"
                        style={{ wordBreak: "break-word" }}
                      >
                        <div className="project-title">
                          <h5>
                            {inventory.name}{" "}
                            {!isPending && (
                              <OverlayTrigger overlay={editTooltip}>
                                <i
                                  className="fas fa-pencil text-primary-2 inventory-box-show-on-hover d-none"
                                  style={{ fontSize: "14px" }}
                                />
                              </OverlayTrigger>
                            )}
                          </h5>
                        </div>
                        <div>
                          {isPending ? "in progress" : ""}
                          {!isPending && (
                            <div>
                              <Link
                                className="btn btn-primary text-white"
                                to={{
                                  pathname: `/inventories/${inventory.id}`,
                                }}
                              >
                                Open
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="project-info">
                        <div className="d-flex">
                          {_.isNumber(inventory.pages) && (
                            <div className="flex-grow-1">
                              <div className="el-tablo">
                                <div className="label">Pages Indexed</div>
                                <div className="value">{inventory.pages}</div>
                              </div>
                            </div>
                          )}
                          {isPending && inventory.created_at && (
                            <div className="flex-grow-1">
                              <div className="el-tablo">
                                <div className="label">Time Elapsed</div>
                                <div className="value">
                                  {timeAgo(
                                    new Date(inventory.created_at)
                                  ).replace("ago", "")}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {!isPending && (
                          <OverlayTrigger overlay={deleteTooltip}>
                            <i
                              className="fas fa-trash text-primary-2 inventory-box-show-on-hover d-none"
                              style={{
                                position: "absolute",
                                right: "25px",
                                bottom: "25px",
                              }}
                            />
                          </OverlayTrigger>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <InventoryModal onAdd={this.handleInventoryAdded} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { inventories, tags } = state;
  return {
    inventories: inventories.items,
    isFetching: inventories.isFetching,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchInventories,
    invalidateInventories,
    setActiveInventory,
    saveInventory,
    deleteInventory,
  })(InventoriesDashboard)
);
