import React from "react";
import { getUrlParam } from "./helpers";

// This is a slider that slides from the bottom that shows the user how to use Topic
// it is toggled by a URL parameter "guide=true"
class IntroSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "minimized", // minimized, maximized
    };
    this.loadCalendly = this.loadCalendly.bind(this);
  }

  componentDidMount() {
    this.loadCalendly();
  }

  componentDidUpdate() {
    this.loadCalendly();
  }

  loadCalendly() {
    const calendlyScript = document.createElement("script");
    calendlyScript.async = true;
    calendlyScript.src =
      "https://assets.calendly.com/assets/external/widget.js";
    document.head.appendChild(calendlyScript);
  }

  render() {
    const guide = getUrlParam("guide"); // true or false

    return (
      guide === "true" && (
        <div className={`intro-slider intro-slider-${this.state.status}`}>
          <div className="intro-slider-inner d-inline-block bg-bluegray-6 text-white">
            <div className="d-flex">
              {this.state.status === "maximized" && (
                <div className="d-flex" style={{ position: "relative" }}>
                  <div
                    className="clickable"
                    style={{ position: "absolute", top: "10px", right: "15px" }}
                    onClick={() => {
                      this.setState({ status: "minimized" });
                    }}
                  >
                    <i className="fa fa-minus" />
                  </div>
                  <div className="m-4">
                    <div
                      className="calendly-inline-widget"
                      data-url="https://calendly.com/ryo-topic/45?hide_event_type_details=1"
                      style={{ minWidth: "320px", height: "470px" }}
                    />
                  </div>
                  <div className="m-4 text-left">
                    <h3 className="text-white">Let Us Show You How</h3>
                    <p>
                      Let us show you how you can get more out of your content
                      with Topic.
                    </p>
                    <p>👈 Use the calendar to book a time with us.</p>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            <i className="fa fa-check text-success" />
                          </td>
                          <td className="pl-3 pb-3">
                            Learn how using Topic increases organic search
                            traffic
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            <i className="fa fa-check text-success" />
                          </td>
                          <td className="pl-3 pb-3">
                            Find out how others have gotten more business from
                            their SEO content
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            <i className="fa fa-check text-success" />
                          </td>
                          <td className="pl-3 pb-3">
                            Write better content that resonates with your
                            audience
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {this.state.status === "minimized" && (
                <div
                  className="clickable text-center w-100 p-3"
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    this.setState({ status: "maximized" });
                  }}
                >
                  <span className="">
                    Want to get more out of your SEO content?
                  </span>{" "}
                  <button className="btn btn-success ml-3 text-bold">
                    Show me how
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default IntroSlider;
