import React from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import { saveOutlineTitle } from "./redux/actions";
import { cleanTitle } from "./helpers";
import OutlineTitleBookmarkButton from "./components/outline/bookmarks/content/title/bookmark_button";
import OutlineRecentTitleBookmarks from "./components/outline/bookmarks/recent/title_bookmarks";

class OutlineBuilderPopoverSerpTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: "existingTitles", // 'existingTitles', 'newTitles'
      isLoading: false,
      error: "",
      existingTitlesChosen: [], // array of existing titles chosen
      titles: [], // array of title ideas
      titleChosen: "", // the title idea that was chosen
    };
    this.ref = React.createRef();
    this.generateTitleIdeas = this.generateTitleIdeas.bind(this);
    this.handleSelectExistingTitle = this.handleSelectExistingTitle.bind(this);
    this.handleSelectTitle = this.handleSelectTitle.bind(this);
    this.saveTitleToOutline = this.saveTitleToOutline.bind(this);
  }

  generateTitleIdeas() {
    const that = this;
    this.setState({ isLoading: "true", error: "" });

    const competitorTitles = _.uniq(
      _.compact(
        _.map(this.props.report.report.competitors, (competitor) => {
          if (_.includes(that.state.existingTitlesChosen, competitor.title)) {
            return null;
          }
          return competitor.title;
        })
      )
    );

    $.getJSON(`/api/openai/serp_title_variations`, {
      titles: this.state.existingTitlesChosen,
      avoid_titles: competitorTitles,
    })
      .done((res) => {
        if (!_.isEmpty(res)) {
          if (res.filter === "1" || res.filter === "2") {
            that.setState({
              isLoading: false,
              titles: res.titles,
              currentState: "newTitles",
              error:
                "The content filter has detected that the generated text may potentially be sensitive, political, or controversial. Give it an extra check just in case something sounds wrong.",
            });
          } else if (res.error) {
            that.setState({
              isLoading: false,
              error: "Unable to come up with any ideas. Please try again.",
            });
          } else if (!_.isEmpty(res.titles)) {
            that.setState({
              titles: res.titles,
              currentState: "newTitles",
              isLoading: false,
            });
          }
        } else {
          that.setState({
            isLoading: false,
            error: "Unable to come up with any ideas. Please try again.",
          });
        }
      })
      .fail((res) => {
        that.setState({
          isLoading: false,
          error:
            "Something went wrong getting the ideas. Please email support@usetopic.com",
        });
      });
  }

  handleSelectExistingTitle(title) {
    const { existingTitlesChosen } = this.state;
    if (existingTitlesChosen.includes(title)) {
      this.setState({
        existingTitlesChosen: _.without(existingTitlesChosen, title),
      });
    } else {
      this.setState({
        existingTitlesChosen: existingTitlesChosen.concat(title),
      });
    }
  }

  handleSelectTitle(title) {
    const { titleChosen } = this.state;
    if (titleChosen === title) {
      this.setState({
        titleChosen: "",
      });
    } else {
      this.setState({
        titleChosen: title,
      });
    }
  }

  // title has been chosen for outline
  saveTitleToOutline() {
    this.props.saveOutlineTitle(this.props.report, this.state.titleChosen);
    this.props.handleClose();
  }

  render() {
    const that = this;
    let {
      currentState,
      error,
      isLoading,
      existingTitlesChosen,
      titles,
      titleChosen,
    } = this.state;
    const { type, handleClose, report } = this.props;

    const notEnoughTitlesSelected = existingTitlesChosen.length < 3;

    var competitorTitles = _.uniq(
      _.compact(
        _.map(report.report.competitors, (competitor) => competitor.title)
      )
    );

    titles = _.uniq(_.compact(_.map(titles, (title) => cleanTitle(title))));

    const builderBodyClass =
      this.props.outline.titleBookmarks.length > 0
        ? "builder-popover-body-compact"
        : "builder-popover-body";
    return (
      <div>
        <div
          className={`builder-popover-rainbow ${isLoading ? "loading" : ""}`}
        />
        <div className={isLoading ? "builder-popover-isloading" : ""}>
          <div className="builder-popover-head mb-2">Generate Title Ideas</div>
          {currentState === "existingTitles" && (
            <div>
              <div className="mb-3">
                First, choose 3 or more competitor titles that match your
                targetted search intent.
              </div>
              <div className="builder-popover-body">
                {competitorTitles.map((competitorTitle, i) => (
                  <div
                    key={i}
                    className={`clickable p-2 mt-2 mb-2 ml-1 mr-1 builder-popover-card ${
                      existingTitlesChosen.includes(competitorTitle)
                        ? "text-bold active"
                        : ""
                    }`}
                    onClick={that.handleSelectExistingTitle.bind(
                      that,
                      competitorTitle
                    )}
                  >
                    {competitorTitle}{" "}
                    {existingTitlesChosen.includes(competitorTitle) && (
                      <i className="fas fa-check text-success ml-2" />
                    )}
                  </div>
                ))}
              </div>
              {error && <div className="text-danger">{error}</div>}
              <div className="text-right">
                <div
                  onClick={() => {
                    if (notEnoughTitlesSelected) {
                      this.setState({
                        error: "Please select at least 3 titles",
                      });
                    } else {
                      this.generateTitleIdeas();
                    }
                  }}
                  className={`clickable text-right btn btn-primary mt-2 ${
                    notEnoughTitlesSelected ? "disabled" : ""
                  }`}
                >
                  Next <i className="fas fa-arrow-right" />
                </div>
              </div>
            </div>
          )}
          {currentState === "newTitles" && (
            <div>
              <OutlineRecentTitleBookmarks
                report={this.props.report}
                handleSelect={this.handleSelectTitle}
                selectedTitle={titleChosen}
              />
              <div className="mb-2">
                <h6 className="d-inline">New title ideas </h6>
                <button
                  className="btn btn-white text-bluegray-5 builder-popover-refresh"
                  onClick={this.generateTitleIdeas}
                >
                  <i className="fas fa-redo-alt" /> Refresh
                </button>
              </div>
              <div className={builderBodyClass}>
                {titles
                  .filter((t) => !this.props.outline.titleBookmarks.includes(t))
                  .map((title, i) => {
                    var titleIsChosen = titleChosen === title;
                    return (
                      <div
                        key={i}
                        className={`clickable p-2 pr-4 mt-2 mb-2 builder-popover-card animated fadeUp delay-${i} ${
                          titleIsChosen ? "text-bold active" : ""
                        }`}
                        onClick={that.handleSelectTitle.bind(that, title)}
                      >
                        {title}{" "}
                        {titleIsChosen && (
                          <i className="fas fa-check text-success ml-2" />
                        )}
                        <OutlineTitleBookmarkButton
                          report={this.props.report}
                          title={title}
                        />
                      </div>
                    );
                  })}
              </div>
              {error && <div className="text-orange">{error}</div>}
              <div className="text-right">
                <div
                  className={`clickable text-right btn btn-primary mt-2 ${
                    _.isEmpty(titleChosen) ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (!_.isEmpty(titleChosen)) {
                      this.saveTitleToOutline();
                    }
                  }}
                >
                  Choose Title
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    outline: state.outline,
  };
}

export default connect(mapStateToProps, { saveOutlineTitle })(
  OutlineBuilderPopoverSerpTitle
);
