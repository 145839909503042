import React from "react";
import OutlineBuilderCard from "./outline_builder_card";

const styles = {
  display: "block",
  transform: "rotate(-3deg)",
  WebkitTransform: "rotate(-3deg)",
  boxShadow: "0 2px 5px rgba(69,101,173,.1)",
  position: "absolute",
};

class OutlineBuilderCardDragPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
  }

  render() {
    const that = this;

    const {
      i,
      id,
      text,
      type,
      indent,
      moveCard,
      selectCard,
      editCard,
      originalText,
      selected,
      editing,
    } = this.props.item.props;

    return (
      <div style={styles} ref={this.ref} className="builder-card-drag-preview">
        <OutlineBuilderCard
          index={i}
          key={i}
          text={text}
          type={type}
          indent={indent}
          selected={selected}
          draggable
          editing={editing}
          moveCard={moveCard}
          selectCard={selectCard}
          editCard={editCard}
        />
      </div>
    );
  }
}

export default OutlineBuilderCardDragPreview;
