import React, { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchTitles } from "./api/openai";
import RefreshButton from "./components/common/refresh_button";
import OutlineTitleBookmarkButton from "./components/outline/bookmarks/content/title/bookmark_button";
import OutlineRecentTitleBookmarks from "./components/outline/bookmarks/recent/title_bookmarks";
import { saveOutlineTitle } from "./redux/actions";
import { getReportById } from "./redux/selectors";
import ListSkeletonLoader from "./components/common/list_skeleton_loader";

function OutlineBuilderPopoverContentIdeas({ handleClose }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const { outline } = state;
  const { id } = useParams();
  const report = getReportById(state, id);
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleTitleSelect = (title) => {
    if (title === selectedTitle) {
      setSelectedTitle("");
    } else {
      setSelectedTitle(title);
    }
  };

  const chooseTitle = () => {
    dispatch(saveOutlineTitle(report, selectedTitle));
    handleClose();
  };

  const serps = report.report.competitors.map((c) => c.title).join("\n");

  const titlesQueryKey = "fetchTitles";
  const { data, isFetching } = useQuery(
    titlesQueryKey,
    () => fetchTitles(serps),
    { refetchOnWindowFocus: false }
  );

  const builderBodyClass =
    outline.titleBookmarks.length > 0
      ? "builder-popover-body-compact"
      : "builder-popover-body";
  return (
    <div>
      <div
        className={`builder-popover-rainbow ${isFetching ? "loading" : ""}`}
      />
      <div>
        <div className="builder-popover-head mb-2">Title Ideas</div>
        <OutlineRecentTitleBookmarks
          report={report}
          handleSelect={handleTitleSelect}
          selectedTitle={selectedTitle}
        />
        <div>
          {isFetching && (
            <>
              <h6 className="d-inline">Brainstorming Ideas</h6>
              <ListSkeletonLoader />
              <ListSkeletonLoader />
              <ListSkeletonLoader />
            </>
          )}
          {!isFetching && data && (
            <div>
              <div className="mb-2">
                <h6 className="d-inline">New Title Ideas </h6>
                <RefreshButton
                  onClick={() => queryClient.refetchQueries(titlesQueryKey)}
                />
              </div>
              <div className={builderBodyClass}>
                {(data.results || [])
                  .filter((t) => !outline.titleBookmarks.includes(t))
                  .map((title, i) => {
                    const titleIsChosen = selectedTitle === title;
                    return (
                      <div
                        key={i}
                        className={`clickable p-2 pr-4 mt-2 mb-2 builder-popover-card animated fadeUp delay-${i} ${
                          titleIsChosen ? "text-bold active" : ""
                        }`}
                        onClick={() => handleTitleSelect(title)}
                      >
                        {title}{" "}
                        {titleIsChosen && (
                          <i className="fas fa-check text-success ml-2" />
                        )}
                        <OutlineTitleBookmarkButton
                          report={report}
                          title={title}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="text-right">
                <div
                  className={`clickable text-right btn btn-primary mt-2 ${
                    _.isEmpty(selectedTitle) ? "disabled" : ""
                  }`}
                  onClick={() => {
                    if (!_.isEmpty(selectedTitle)) {
                      chooseTitle();
                    }
                  }}
                >
                  Choose Title
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default OutlineBuilderPopoverContentIdeas;
