import React from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import Loader from "./loader";
import { addNewTopicToReport, showToast } from "./redux/actions";

class AddCustomTopicModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newTopic: "",
      newTopicAlternate: [],
      topicValidationMessage: "",
      isLoading: false,
    };

    this.validateAndCreateNewTopic = this.validateAndCreateNewTopic.bind(this);
  }

  componentDidMount() {
    const that = this;
  }

  validateAndCreateNewTopic() {
    const { newTopic, newTopicAlternate } = this.state;
    const { onHide, showToast, addNewTopicToReport } = this.props;
    if (newTopic.length > 0) {
      const { report } = this.props;
      console.log(report);
      const allTopicNames = _.map(report.report.recommended_topics, (topic) => [
        topic.name,
        topic.similar_keywords,
      ]).flat(2);
      if (!_.includes(allTopicNames, newTopic)) {
        const newTopicObject = {
          name: newTopic,
          similar_keywords: newTopicAlternate,
          count: [],
          header_usage: [],
          relevance: "",
          score: "",
          manually_added: true,
        };
        this.setState({
          topicValidationMessage: "",
          isLoading: true,
        });
        addNewTopicToReport(report, newTopicObject, (response) => {
          this.setState({
            newTopicAlternate: [],
            newTopic: "",
            isLoading: false,
          });
          onHide();

          // If custom topic was added Sucessfully then show the toast message.
          if (response) {
            showToast({
              message: `Your Topic "${newTopicObject.name}" has been added successfully.`,
              options: { appearance: "success", autoDismiss: true },
            });
          } else {
            showToast({
              message: `Error adding your Topic "${newTopicObject.name}".`,
              options: { appearance: "error", autoDismiss: true },
            });
          }
        });
      } else {
        this.setState({
          topicValidationMessage:
            "The topic already exists. It might be a similar spelling for one of the existing topics.",
        });
      }
    } else {
      this.setState({
        topicValidationMessage: "Please specify a custom topic.",
      });
    }
  }

  render() {
    const { newTopic, topicValidationMessage, isLoading } = this.state;
    const { show, onHide } = this.props;
    return (
      <div className="add-new-topic-modal">
        <Modal
          size="sm"
          show={show}
          animation={false}
          backdrop={isLoading ? "static" : true}
          onHide={() => {
            this.setState({
              newTopic: "",
              newTopicAlternate: [],
              topicValidationMessage: "",
              isLoading: false,
            });
            onHide();
          }}
        >
          <Modal.Header closeButton={!isLoading} />
          <Modal.Body className="pt-0">
            <div className="text-center">
              <h4>Add Custom Topic</h4>
              <div className="text-primary-6">
                Useful if there is a specific topic that you want mentioned in
                the grader.
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="flex-grow-1 pt-3 pl-3 pr-3">
                <div className="p-1">
                  <label className="text-bold">Topic</label>
                  <input
                    className="w-100 form-control"
                    placeholder="fiddle leaf fig"
                    value={newTopic}
                    onChange={(e) => {
                      this.setState({ newTopic: e.target.value.toLowerCase() });
                    }}
                    disabled={isLoading}
                  />
                </div>
                <div className="p-1 mt-2">
                  <label className="text-bold">
                    Variations / Alternate Spellings
                  </label>
                  <CreatableSelect
                    isMulti
                    formatCreateLabel={(word) => `Add spelling: ${word}`}
                    onChange={(value) => {
                      const alternateSpelling = [];
                      if (!_.isEmpty(value)) {
                        value.map((v) => {
                          alternateSpelling.push(v.value);
                        });
                      }
                      this.setState({ newTopicAlternate: alternateSpelling });
                    }}
                    isDisabled={isLoading}
                  />
                </div>
                <div className="p-1 text-danger">
                  {topicValidationMessage && <p>{topicValidationMessage}</p>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="btn btn-primary"
              disabled={isLoading}
              role="button"
              onClick={() => {
                if (!isLoading) {
                  this.validateAndCreateNewTopic();
                }
              }}
            >
              {isLoading ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  Creating Topic ... &nbsp; <Loader type="spinner" />
                </span>
              ) : (
                "Add Topic"
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps, { addNewTopicToReport, showToast })(
  AddCustomTopicModal
);
