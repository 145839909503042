import React, { Fragment } from "react";
import OutlineBookmarksContent from "./content";
import OutlineBookmarksToggle from "./toggle";

/**
 * OutlineBookmarks
 * This is the encompassing view for the entire bookmarks feature
 * @returns {JSX.Element}
 */
function OutlineBookmarks({ report, handleClose }) {
  return (
    <>
      <OutlineBookmarksToggle />
      <OutlineBookmarksContent report={report} handleClose={handleClose} />
    </>
  );
}

export default OutlineBookmarks;
