import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useReport } from "../../../hooks/useReport";
import {
  saveOutlineDescription,
  saveOutlineTitle,
} from "../../../redux/actions";
import { InitialBrainstormContext } from "./context";

function InitialBrainstormContent() {
  const { data, isFetching } = useContext(InitialBrainstormContext);
  const dispatch = useDispatch();
  const report = useReport();

  if (!data || isFetching || !report) {
    return null;
  }

  const onSelect = async (title, description) => {
    await dispatch(saveOutlineTitle(report, title));
    await dispatch(saveOutlineDescription(report, description));
  };

  const list = Object.entries(data.results).map(
    ([title, description], index) => (
      <div
        key={`initial-brainstorm-${index}`}
        className={`p-3 mt-3 bg-white shadow rounded-md animated fadeUp delay-${index}`}
      >
        <div className="text-bold">{title}</div>
        <div className="text-primary-5 mt-2" style={{ fontSize: "12px" }}>
          {description}
        </div>
        <button
          onClick={() => onSelect(title, description)}
          className="btn bg-primary-2 hover-bg-primary-3 w-100 mt-2"
        >
          Start With This
        </button>
      </div>
    )
  );

  return <div className="px-3 initial-brainstorm-content">{list}</div>;
}

export default InitialBrainstormContent;
