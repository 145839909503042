import React from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import { saveOutlineIdea } from "./redux/actions";
import { cleanTitle } from "./helpers";
import OutlineBuilderPopoverTitle from "./outline_builder_popover_title";
import OutlineBuilderPopoverSerpTitle from "./outline_builder_popover_serp_title";
import OutlineBuilderPopoverDescription from "./outline_builder_popover_description";
import OutlineBuilderPopoverHeading from "./outline_builder_popover_heading";
import OutlineBuilderPopoverContentIdeas from "./outline_builder_popover_content_ideas";
import OutlineBookmarks from "./components/outline/bookmarks";

class OutlineBuilderPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnon: false,
    };
    this.ref = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.report && !_.isEmpty(nextProps.report.anon_email)) {
      const derivedState = {
        isAnon: true,
      };
      return derivedState;
    }
    return null;
  }

  render() {
    const that = this;
    const { type, handleClose, report, handleSetPopoverType } = this.props;
    const { isAnon } = this.state;
    let content;
    if (isAnon) {
      content = (
        <div>
          <div className="builder-popover-rainbow loading" />
          <p className="text-bold">
            Upgrade to see AI-generated titles, descriptions, and headings
          </p>
          <p>
            Beat back writer's block and develop compelling content faster. Use
            AI-generated content to find new angles to cover any topic.
          </p>
          <a
            className="btn btn-primary text-white"
            href="https://www.usetopic.com/pricing"
            target="_blank"
            rel="noreferrer"
          >
            Upgrade
          </a>
        </div>
      );
    } else if (type === "title") {
      content = (
        <OutlineBuilderPopoverTitle
          report={this.props.report}
          handleClose={handleClose}
        />
      );
    } else if (type === "serpTitle") {
      content = (
        <OutlineBuilderPopoverSerpTitle
          report={this.props.report}
          handleClose={handleClose}
        />
      );
    } else if (type === "description") {
      content = (
        <OutlineBuilderPopoverDescription
          report={this.props.report}
          handleClose={handleClose}
        />
      );
    } else if (type === "heading") {
      content = (
        <OutlineBuilderPopoverHeading
          report={this.props.report}
          handleClose={handleClose}
        />
      );
    } else if (type === "contentIdeas") {
      content = (
        <OutlineBuilderPopoverContentIdeas
          handleClose={handleClose}
          handleSetPopoverType={handleSetPopoverType}
        />
      );
    }

    return (
      <div
        className="builder-popover animated fadeIn"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a
          href="#"
          onClick={handleClose}
          className="builder-popover-close text-bluegray-4"
        >
          <i className="fas fa-times" />
        </a>
        <OutlineBookmarks report={report} handleClose={handleClose} />
        <div
          className={`p-3 pt-4 ${
            this.props.outline.showBookmarks ? "d-none" : ""
          }`}
        >
          {content}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    outline: state.outline,
  };
}

export default connect(mapStateToProps, { saveOutlineIdea })(
  OutlineBuilderPopover
);
