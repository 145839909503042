import React from "react";
import {
  gradeToColor,
  dateToPrettyString,
  timeAgo,
  findInterestingContent,
} from "./helpers";

class ReportComparisonTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedBy: "position", // can be position, da, created, grade, wordcount
      order: "asc", // asc or desc
    };
    this.metricToColor = this.metricToColor.bind(this);
    this.getAverageMetric = this.getAverageMetric.bind(this);
    this.getNormalizedMetric = this.getNormalizedMetric.bind(this);
    this.handleSortClick = this.handleSortClick.bind(this);
  }

  getAverageMetric(metric) {
    return this.getNormalizedMetric(
      _.floor(_.mean(_.map(this.props.data, metric))),
      metric
    );
  }

  getNormalizedMetric(metricValue, metric) {
    let maxMetric = _.max(_.map(this.props.data, metric));
    if (maxMetric < 100) {
      maxMetric = 100;
    }
    return _.floor((metricValue / maxMetric) * 100);
  }

  metricToColor(metricValue, metric) {
    const comparisonFactor = metricValue / this.getAverageMetric(metric);

    if (comparisonFactor > 1.5) {
      return "green-4";
    }
    if (comparisonFactor > 1.3) {
      return "green-3";
    }
    if (comparisonFactor > 1.2) {
      return "green-2";
    }
    if (comparisonFactor > 1) {
      return "green-1";
    }
    if (comparisonFactor > 0.8) {
      return "yellow";
    }
    if (comparisonFactor > 0.5) {
      return "orange";
    }
    if (comparisonFactor > 0.3) {
      return "redorange";
    }
    return "red";
  }

  shortenURL(urlString) {
    const maxLength = 25;
    const url = new URL(urlString).host;
    return url.replace(/^www./, "").slice(0, maxLength);
  }

  handleSortClick(sortedBy) {
    if (sortedBy === this.state.sortedBy) {
      this.setState({ order: this.state.order === "asc" ? "desc" : "asc" });
    }
    this.setState({ sortedBy });
  }

  render() {
    const that = this;
    const averageMetric = this.getAverageMetric();
    let { data, isExpanded, isAnon, report } = this.props;
    const { sortedBy, order } = this.state;

    // to handle issue where occasionally backend saves a duplicate of a competitor
    data = _.uniqBy(data, "position");

    // get interesting and comprehensive content
    const interestingContentObj = findInterestingContent(data);
    const { mostComprehensiveFirstPageUrl } = interestingContentObj;
    const { mostComprehensiveOverallUrl } = interestingContentObj;
    const { interestingContentUrls } = interestingContentObj;

    if (isAnon) {
      data = data.slice(0, 5);
    } else if (!isExpanded) {
      data = data.slice(0, 10);
    }

    // for now, don't show editor content in the competitive analysis section
    // if(!_.isEmpty(this.props.editorContent) && this.props.editorContent.html.trim().length > 0){
    //   data = _.concat(data, this.props.editorContent);
    // }

    // sort data
    data = _.sortBy(data, (o) => {
      const coefficient = order === "asc" ? 1 : -1;
      if (sortedBy === "date") {
        if (_.isEmpty(o.date)) {
          return 0;
        }
        return coefficient * new Date(o.date);
      }
      if (sortedBy === "grade") {
        return coefficient * o.coverage;
      }
      return coefficient * o[sortedBy];
    });

    const cols = [
      ["Position", "position"],
      ["Content", "content"],
      ["Last Updated", "date"],
      ["Domain Authority", "domain_score"],
      ["Word Count", "word_count"],
      ["Content Grade", "grade"],
    ];

    // if sorted by position, add placeholders for ignored sites
    if (sortedBy === "position" && order === "asc") {
      const serpHasFeaturedSnippet = data[0].position === 0;
      const lastPosition = _.last(data).position;
      const totalPositionRange = _.range(0, lastPosition);

      if (serpHasFeaturedSnippet) {
        var featuredSnippet = data.shift();
      }

      // iterate through a the full position range
      _.each(totalPositionRange, (index) => {
        const competitor = data[index];
        if (competitor.position - 1 !== index) {
          // missing a competitor, so insert
          data.splice(index, 0, {
            placeholder: true,
            position: index + 1,
          });
        }
      });

      if (serpHasFeaturedSnippet) {
        data.unshift(featuredSnippet);
      }
    }

    return (
      <div className="report-comparison-table w-100 table table-striped">
        <table className="w-100">
          <colgroup>
            <col />
            <col style={{ width: "50%" }} />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              {cols.map((col, index) => {
                const label = col[0];
                const key = col[1];
                let icon;
                let average;
                if (key === sortedBy) {
                  icon =
                    sortedBy === key && order === "asc" ? (
                      <i className="fa fa-angle-down" />
                    ) : (
                      <i className="fa fa-angle-up" />
                    );
                }

                // calculate averages
                if (label === "Last Updated") {
                  average = timeAgo(
                    _.mean(
                      _.map(_.compact(_.map(data, "date")), (dateString) =>
                        new Date(dateString).getTime()
                      )
                    )
                  );
                } else if (
                  label === "Domain Authority" ||
                  label === "Word Count"
                ) {
                  average = Math.round(
                    _.mean(
                      _.map(_.compact(_.map(data, key)), (ds) =>
                        parseInt(ds, 10)
                      )
                    )
                  );
                }

                if (label === "Content") {
                  return <th key={index}>Content</th>;
                }
                const semrushLogo =
                  label === "Domain Authority"
                    ? "<br /> source: <img src='/img/semrush_source_logo.svg' height='23px' />"
                    : "";
                return (
                  <th
                    key={index}
                    data-html="true"
                    data-original-title={`Click to sort by ${label}${semrushLogo}`}
                    data-toggle="tooltip"
                    className={`clickable ${
                      key === sortedBy ? "text-bold" : ""
                    }`}
                    onClick={() => {
                      that.handleSortClick(key);
                    }}
                  >
                    <div>
                      {label} {icon}
                    </div>
                    {!!average && <div>Avg: {average}</div>}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const isEditorContentItem = !_.isString(item.title);
              const isPlaceholder = item.placeholder;
              const notEnoughData = item.word_count === 0;
              const isOptedOut = item.opted_out;
              const hasNoAccess = item.no_access;
              const hideMetrics = isOptedOut || hasNoAccess;
              const isImportedUrl =
                _.isString(report.import_url) &&
                _.isString(item.url) &&
                report.import_url.toLowerCase() === item.url.toLowerCase();
              if (isPlaceholder) {
                return (
                  <tr key={index} style={{ opacity: "0.5" }}>
                    <td>
                      <div
                        className="p-1 bg-bluegray-2 text-center"
                        style={{
                          borderRadius: "60px",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        {item.position}
                      </div>
                    </td>
                    <td colSpan="5">
                      <div>
                        Site was ignored.{" "}
                        <a
                          href="https://help.adthrive.com/hc/en-us/articles/4417708658715"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click here
                        </a>{" "}
                        to learn more.
                      </div>
                    </td>
                  </tr>
                );
              }
              if (!isEditorContentItem) {
                const slugEnd =
                  _.last(_.compact(new URL(item.url).pathname.split("/"))) ||
                  "";
                const slug = `/${slugEnd}`;
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="p-1 bg-bluegray-2 text-center"
                        style={{
                          borderRadius: "60px",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        {item.position}
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>
                          <a href={item.url} target="_blank" rel="noreferrer">
                            {item.title}
                          </a>
                        </div>
                        <div className="text-s">
                          <div>
                            <a href={item.url} target="_blank" rel="noreferrer">
                              {this.shortenURL(item.url)}
                              {slug.length > 2 && <span>{slug}</span>}
                            </a>
                          </div>
                          {item.type === "article" && (
                            <div className="badge bg-bluegray-2 text-s ml-0 mr-2">
                              <i className="fa fa-book" /> article
                            </div>
                          )}
                          {item.position === 0 && (
                            <div className="badge bg-bluegray-2 text-s ml-0 mr-2">
                              <i className="fa fa-book" /> featured snippet
                            </div>
                          )}
                          {interestingContentUrls.indexOf(item.url) !== -1 && (
                            <div
                              className="badge bg-cyan-3 text-cyan-10 text-s ml-0 mr-2"
                              data-original-title="An article that is outperforming its domain authority"
                              data-toggle="tooltip"
                            >
                              <i className="fa fa-book" /> interesting content
                            </div>
                          )}
                          {item.url === mostComprehensiveFirstPageUrl && (
                            <div
                              className="badge bg-green-3 text-green-10 text-s ml-0 mr-2"
                              data-original-title="The article that covers the topic most comprehensively on the first page"
                              data-toggle="tooltip"
                            >
                              <i className="fa fa-book" /> most comprehensive
                              (first page)
                            </div>
                          )}
                          {item.url === mostComprehensiveOverallUrl && (
                            <div
                              className="badge bg-green-3 text-green-10 text-s ml-0 mr-2"
                              data-original-title="The article that covers the topic most comprehensively overall"
                              data-toggle="tooltip"
                            >
                              <i className="fa fa-book" /> most comprehensive
                              (overall)
                            </div>
                          )}
                        </div>
                        {item.description && _.isString(item.description) && (
                          <div className="text-s mt-1">
                            {item.description.slice(0, 158)}
                            {item.description.length > 158 ? "..." : ""}
                          </div>
                        )}
                        {!hideMetrics && notEnoughData && (
                          <div
                            className="text-s text-bluegray-4 mt-2 d-inline"
                            data-original-title="Occasionally we are unable to read the content of the article due to its structure or accessibility. When this happens, we don't factor the page into our analysis"
                            data-toggle="tooltip"
                          >
                            <i className="fas fa-exclamation-triangle" /> Did
                            not find enough content for analysis
                          </div>
                        )}
                        {hideMetrics && (
                          <div className="text-s text-bluegray-4 mt-2 d-inline">
                            <i className="fas fa-exclamation-triangle" />{" "}
                            Content is not available for analysis
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      {!hideMetrics && !_.isEmpty(item.date) && (
                        <span title={dateToPrettyString(new Date(item.date))}>
                          {timeAgo(new Date(item.date)) || ""}
                        </span>
                      )}
                      {(_.isEmpty(item.date) || hideMetrics) && "-"}
                    </td>
                    <td>
                      {_.isEmpty(item.domain_score) || hideMetrics
                        ? "-"
                        : item.domain_score}
                    </td>
                    <td>
                      {notEnoughData || hideMetrics ? "-" : item.word_count}
                    </td>
                    <td className="text-bold">
                      {notEnoughData || hideMetrics ? "-" : item.grade}{" "}
                      {isImportedUrl && (
                        <a
                          data-original-title="Grade different than what you expected? Click to learn more."
                          data-toggle="tooltip"
                          href="https://help.adthrive.com/hc/en-us/articles/4408262496155"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fad fa-question-circle" />
                        </a>
                      )}
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={index} className="bg-bluegray-1">
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ReportComparisonTable;
