import React from "react";
import {
  gradeToColor,
  headersToHTML,
  timeAgo,
  findInterestingContent,
  triggerExpandOutline,
} from "./helpers";
import Loader from "./loader";

class ReportOverviewOutline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandingOutline: false,
      showTakeaways: true,
    };
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.handleTakeawayClick = this.handleTakeawayClick.bind(this);
    this.handleExpandOutline = this.handleExpandOutline.bind(this);
    this.handleContractOutline = this.handleContractOutline.bind(this);
    this.node = React.createRef();
  }

  handleHeaderClick(heading) {
    // either add or remove card from the outline cards in redux

    // remove any starting number from heading
    const cleanedHeading = heading.replace(/^\d{1,3}\. /, "");

    this.props.toggleCard({
      text: cleanedHeading,
      indent: 0,
      originalText: heading,
      type: "heading",
      selected: false,
      editing: false,
    });
  }

  handleTakeawayClick(takeaway) {
    this.props.toggleCard({
      text: takeaway,
      indent: 0,
      originalText: takeaway,
      type: "point",
      selected: false,
      editing: false,
    });
  }

  handleExpandOutline(position) {
    let hasTakeaways = false;
    const { competitor, resize } = this.props;
    _.each(competitor.headings, (heading) => {
      if (_.isArray(heading.takeaways) && heading.takeaways.length > 0) {
        hasTakeaways = true;
      }
    });

    if (hasTakeaways) {
      // already expanded the outline before
      this.setState({
        showTakeaways: true,
      });
      resize();
    } else {
      // first time expanding the outline
      const reportId = this.props.report.id;
      const that = this;
      if (!AP.isExpandingOutline) {
        // HACK - using a global variable to limit number of outline expansions at a time
        AP.isExpandingOutline = true;
        this.setState({ isExpandingOutline: true });
        triggerExpandOutline(
          reportId,
          position,
          () => {
            // async job finished
            // refresh brief
            that.props.fetchReport(reportId, () => {
              AP.isExpandingOutline = false;
              that.props.resize();
            });
            this.setState({ isExpandingOutline: false });
          },
          () => {
            that.props.showToast({
              message: `We were unable to expand this article`,
              options: { appearance: "error", autoDismiss: true },
            });
            that.props.fetchReport(reportId, () => {
              AP.isExpandingOutline = false;
              that.props.resize();
            });
            this.setState({ isExpandingOutline: false });
          }
        );
      } else {
        this.props.showToast({
          message: `You can currently only expand 1 outline at a time`,
          options: { appearance: "info", autoDismiss: true },
        });
      }
    }
  }

  handleContractOutline() {
    this.setState({
      showTakeaways: false,
    });
    this.props.resize();
  }

  competitorHasHeaders(competitor) {
    const oldHeaderCheck = headersToHTML(competitor.html).length > 3;
    const newHeaderCheck =
      _.isArray(competitor.headings) && competitor.headings.length >= 3;
    return newHeaderCheck || oldHeaderCheck;
  }

  render() {
    const that = this;
    const { noAccess, competitor, report, index } = this.props;
    const { isExpandingOutline, showTakeaways } = this.state;
    const url = new URL(competitor.url);
    const badgeClass = `bg-${gradeToColor(
      competitor.grade
    )}-2 text-${gradeToColor(competitor.grade)}-10`;
    const hasHeaders = that.competitorHasHeaders(competitor);

    const interestingContentObj = findInterestingContent(
      report.report.competitors
    );
    const { mostComprehensiveFirstPageUrl } = interestingContentObj;
    const { mostComprehensiveOverallUrl } = interestingContentObj;
    const { interestingContentUrls } = interestingContentObj;

    let outlineView = <div />;
    if (
      hasHeaders &&
      _.isArray(competitor.headings) &&
      competitor.headings.length >= 3
    ) {
      // new outline view

      // shift headings if necessary so that if all headings are more than H2, they are shifted so that minimum heading is H2
      let competitorHeadingsWithIntegers = _.map(
        competitor.headings,
        (heading) => {
          heading.tagInt = parseInt(
            heading.tag.toLowerCase().replace("h", ""),
            10
          );
          return heading;
        }
      );
      const minHeading = _.min(_.map(competitorHeadingsWithIntegers, "tagInt"));
      if (minHeading && minHeading > 2) {
        const shiftAmount = minHeading - 2;
        competitorHeadingsWithIntegers = _.map(
          competitorHeadingsWithIntegers,
          (headerObj) => {
            headerObj.tagInt -= shiftAmount;
            headerObj.tag = `h${headerObj.tagInt}`;
            return headerObj;
          }
        );
      }

      var isReallyExpandingOutline = isExpandingOutline;
      var isExpanded = competitor.expansion_status === "success";
      var showExpandButton =
        !isExpanded &&
        AP &&
        AP.currentUser &&
        AP.currentUser.plan &&
        AP.currentUser.plan.meta &&
        AP.currentUser.plan.meta.takeaways;

      var hasTakeaways = false;
      _.each(competitor.headings, (heading) => {
        if (_.isArray(heading.takeaways) && heading.takeaways.length > 0) {
          hasTakeaways = true;
        }
      });

      outlineView = (
        <div
          className="topic-masonry-card-outline"
          style={{ lineHeight: "1.8" }}
        >
          <div className="text-bold text-bluegray-4 mt-2 text-uppercase">
            Outline
          </div>
          {competitorHeadingsWithIntegers.map((heading, index) => {
            const cardIdentifier = { originalText: heading.text };
            const isHighlighted = _.isObject(
              _.find(that.props.cards, cardIdentifier)
            );
            return (
              <div
                key={index}
                className={`competitor-h competitor-${heading.tag.toLowerCase()}`}
              >
                <span
                  className={`clickable ${
                    isHighlighted ? "highlight" : "highlight-on-hover"
                  }`}
                  onClick={() => {
                    that.handleHeaderClick(heading.text);
                  }}
                >
                  {heading.text}
                </span>
                {!_.isEmpty(heading.takeaways) && showTakeaways && (
                  <ul>
                    {_.map(heading.takeaways, (takeaway, index) => {
                      const cardIdentifier = { originalText: takeaway };
                      const isHighlighted = _.isObject(
                        _.find(that.props.cards, cardIdentifier)
                      );
                      return (
                        <li
                          key={index}
                          className={`clickable ${
                            isHighlighted ? "highlight" : "highlight-on-hover"
                          }`}
                          onClick={() => {
                            that.handleTakeawayClick(takeaway);
                          }}
                        >
                          {takeaway}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      );
    } else if (hasHeaders && !_.isEmpty(headersToHTML(competitor.html))) {
      // old outline view
      outlineView = (
        <div
          className="topic-masonry-card-outline"
          style={{ lineHeight: "1.8" }}
        >
          <div className="text-bold text-bluegray-4 mt-2 text-uppercase">
            Outline
          </div>
          {headersToHTML(competitor.html, (index, className, contents) => {
            // check to see if this heading is already highlighted
            const cardIdentifier = { type: "heading", originalText: contents };
            const isHighlighted = _.isObject(
              _.find(that.props.cards, cardIdentifier)
            );
            return (
              <div key={index} className={`competitor-h ${className}`}>
                <span
                  className={`clickable ${
                    isHighlighted ? "highlight" : "highlight-on-hover"
                  }`}
                  onClick={() => {
                    this.handleHeaderClick(contents);
                  }}
                >
                  {contents}
                </span>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div
        key={index}
        className="topic-masonry-card"
        data-position={competitor.position}
        data-coverage={competitor.coverage}
        ref={this.node}
      >
        {!noAccess && (
          <div>
            <div className="topic-masonry-card-label d-flex bg-bluegray-1">
              <div className="topic-masonry-card-metric p-2">
                <div className="text-s">Position</div>
                {competitor.position}
              </div>
              <div className="topic-masonry-card-metric p-2">
                <div className="text-s">Content Grade</div>
                {competitor.grade}
              </div>
              {!_.isEmpty(competitor.date) && (
                <div className="topic-masonry-card-metric p-2">
                  <div className="text-s">Published</div>
                  {timeAgo(new Date(competitor.date))}
                </div>
              )}
            </div>
            {interestingContentUrls.indexOf(competitor.url) !== -1 && (
              <div
                className="badge bg-green-3 text-s ml-2 mr-0"
                data-original-title="An article that is outperforming its domain authority"
                data-toggle="tooltip"
              >
                <i className="fa fa-book" /> interesting content
              </div>
            )}
            {competitor.url === mostComprehensiveFirstPageUrl && (
              <div
                className="badge bg-green-4 text-s ml-2 mr-0"
                data-original-title="The article that covers the topic most comprehensively on the first page"
                data-toggle="tooltip"
              >
                <i className="fa fa-book" /> most comprehensive (first page)
              </div>
            )}
            {competitor.url === mostComprehensiveOverallUrl && (
              <div
                className="badge bg-green-4 text-s ml-2 mr-0"
                data-original-title="The article that covers the topic most comprehensively overall"
                data-toggle="tooltip"
              >
                <i className="fa fa-book" /> most comprehensive (overall)
              </div>
            )}
            <div className="d-flex">
              <div className="flex-grow-1">
                <div className="font-weight-bold pb-1">
                  <img
                    className="pr-2"
                    src={`https://www.google.com/s2/favicons?domain_url=${url.hostname}`}
                  />{" "}
                  <a href={url} target="_blank" rel="noreferrer">
                    {competitor.title}
                  </a>
                </div>
                <div className="text-muted pt-0 pb-0">
                  <a href={url} target="_blank" rel="noreferrer">
                    {url.hostname}
                  </a>
                </div>
              </div>
              <div className="pl-2">
                <div
                  className={`p-2 text-bold text-center ${badgeClass}`}
                  style={{ minWidth: "43px", display: "none" }}
                >
                  {competitor.grade}
                </div>
              </div>
            </div>
            <div className="pt-0">
              {!_.isEmpty(competitor.description) && (
                <div className="pb-2">
                  <div className="text-bold text-bluegray-4 mt-2 text-uppercase">
                    Description
                  </div>
                  <div
                    className="topic-masonry-card-description"
                    style={{ overflow: "hidden", fontSize: "11px" }}
                  >
                    {competitor.description}
                  </div>
                </div>
              )}

              {hasHeaders && outlineView}
              {!hasHeaders && (
                <div className="mt-3">
                  Unable to pull outline from article.{" "}
                  <a
                    href="https://usetopic.zendesk.com/hc/en-us/articles/360051872774"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{" "}
                  to learn more.
                </div>
              )}

              {showExpandButton && (
                <div>
                  {!isReallyExpandingOutline && (
                    <button
                      className="btn btn-gray w-100 text-center mt-3"
                      style={{ opacity: 0.7 }}
                      onClick={() => {
                        that.handleExpandOutline(competitor.position);
                      }}
                    >
                      Expand{" "}
                      <i
                        className="fas fa-chevron-double-down"
                        style={{ fontSize: "11px", opacity: "0.7" }}
                      />
                    </button>
                  )}
                  {isReallyExpandingOutline && (
                    <button
                      className="btn btn-gray w-100 text-center mt-3"
                      style={{ opacity: 0.4 }}
                    >
                      Expanding...{" "}
                      <Loader
                        style={{ height: 0, top: "-16px", left: 0 }}
                        type="spinner"
                        className="dark"
                      />
                    </button>
                  )}
                </div>
              )}
              {isExpanded && !hasTakeaways && (
                <div className="text-s text-bluegray-4 mt-3">
                  We were not able to expand this article{" "}
                  <a
                    href="https://usetopic.zendesk.com/hc/en-us/articles/360051872774"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              )}

              {!showExpandButton && showTakeaways && hasTakeaways && (
                <div>
                  <button
                    className="btn btn-gray w-100 text-center mt-3"
                    style={{ opacity: 0.7 }}
                    onClick={that.handleContractOutline}
                  >
                    Only Show Headings{" "}
                    <i
                      className="fas fa-chevron-double-up"
                      style={{ fontSize: "11px", opacity: "0.7" }}
                    />
                  </button>
                </div>
              )}

              {!showExpandButton && !showTakeaways && hasTakeaways && (
                <div>
                  <button
                    className="btn btn-gray w-100 text-center mt-3"
                    style={{ opacity: 0.7 }}
                    onClick={that.handleExpandOutline}
                  >
                    Expand{" "}
                    <i
                      className="fas fa-chevron-double-down"
                      style={{ fontSize: "11px", opacity: "0.7" }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {
          // <div>
          //   <div className="topic-masonry-card-label d-flex bg-bluegray-1">
          //     <div className="topic-masonry-card-metric p-2">
          //       <div className="text-s">Position</div>
          //       {competitor.position}
          //     </div>
          //     <div className="topic-masonry-card-metric p-2">
          //       <div className="text-s">Content Grade</div>
          //       -
          //     </div>
          //   </div>
          //   <div style={{filter:'blur(5px)',marginBottom:'4rem'}}>
          //     <div className="d-flex disabled">
          //       <div className="flex-grow-1">
          //         <div className="font-weight-bold pb-1">
          //           <img className="pr-2" src={`https://www.google.com/s2/favicons?domain_url=${url.hostname}`} /> <a href={url} target="_blank">Lorem Ipsum is simply dummy text of the printing</a>
          //         </div>
          //         <div className="text-muted pt-0 pb-0">
          //           <a href={url} target="_blank">{url.hostname}</a>
          //         </div>
          //       </div>
          //       <div className="pl-2">
          //         <div className={`p-2 text-bold text-center ${badgeClass}`} style={{minWidth:'43px', display: 'none'}}>-</div>
          //       </div>
          //     </div>
          //     <div className="pt-0">
          //       {!_.isEmpty(competitor.description) && <div className="pb-2">
          //         <div className="text-bold text-bluegray-4 mt-2 text-uppercase">Description</div>
          //         <div className="topic-masonry-card-description" style={{overflow: 'hidden', fontSize: '11px'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</div>
          //       </div>}
          //     </div>
          //     <div className="topic-masonry-card-outline" style={{lineHeight: '1.8'}}>
          //         <div className="text-bold text-bluegray-4 mt-2 text-uppercase">Outline</div>
          //         <ul>
          //           <li>
          //           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
          //           </li>
          //         </ul>
          //       </div>
          //   </div>
          //   <div className="d-flex shadow p-3 bg-white" style={{position:'absolute',width:'80%',top:'4rem'}}>
          //     <div className="flex-grow-1">
          //       <div className="pb-1">
          //         <p className="font-weight-bold">Opt-In to See AdThrive Publisher Outline</p>
          //         <p className="text">Quickly research themes in other top-ranking articles to understand what your audience is interested in learning about.</p>
          //       </div>
          //       <div className="text-muted pt-0 pb-0">
          //         <div className="btn btn-grey mr-2 p-2"><i className="fa fa-unlock mr-1" style={{color:'#44859B'}}/> Opt-In to Unlock</div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        }
      </div>
    );
  }
}

export default ReportOverviewOutline;
