import React from "react";
import { DropTarget } from "react-dnd";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import Mousetrap from "mousetrap";
import { withRouter } from "react-router-dom";
import { DNDTypes } from "./dndTypes";
import {
  moveCard,
  moveSelectedCards,
  selectCard,
  editCard,
  editCards,
  shiftCards,
  shiftSelections,
  removeCards,
  addCard,
  unselectAllCards,
  discardEditsAllCards,
  clearRecentlyToggled,
  saveOutline,
  receiveOutline,
  hideOutlineBookmarks,
} from "./redux/actions";
import OutlineBuilderCard from "./outline_builder_card";
import OutlineBuilderTitleAndDescription from "./outline_builder_title_and_description";
import OutlineBuilderWordCount from "./outline_builder_word_count";
import OutlineBuilderPopover from "./outline_builder_popover";
import Loader from "./loader";
import {
  copyFormatted,
  setJSONToLocalStorage,
  getJSONFromLocalStorage,
  getShuffledClusterColors,
} from "./helpers";

class OutlineBuilderCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddCard: false,
      addCardType: "heading", // heading or point
      addCardIndent: 0, // 0-2
      showPopover: false,
      popoverType: "title", // 'title', 'description', 'heading'
      showCopyPopover: false,
      showOptionsDropdown: false,
      showPasteTemplateOption: false,
    };
    this.ref = React.createRef();
    this.handleCardSave = this.handleCardSave.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
    this.validateHotkey = this.validateHotkey.bind(this);
    this.handleDropCard = this.handleDropCard.bind(this);
    this.handleAddLine = this.handleAddLine.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.showPopover = this.showPopover.bind(this);
    this.toggleOptionsDropdown = this.toggleOptionsDropdown.bind(this);
    this.handleCopyTemplate = this.handleCopyTemplate.bind(this);
    this.handlePasteTemplate = this.handlePasteTemplate.bind(this);
    this.editLastSelectedCard = this.editLastSelectedCard.bind(this);

    // used to handle copying and pasting outline from one brief to another
    this.templateLocalStorageKey = "topic_outlinetemplate";
  }

  componentDidMount() {
    const that = this;
    this.bindHotkeys();
  }

  componentDidUpdate(prevProps) {
    const that = this;

    if (!_.isEmpty(this.props.recentlyToggled)) {
      this.props.clearRecentlyToggled();
    }

    $(this.ref.current).find('[data-toggle="tooltip"]').tooltip();

    const template = getJSONFromLocalStorage(this.templateLocalStorageKey);
    if (template && !this.state.showPasteTemplateOption) {
      this.setState({ showPasteTemplateOption: true });
    }
  }

  componentWillUnmount() {
    this.unbindHotkeys();
  }

  validateHotkey(e, successFunction) {
    if (this.props.cardIsSelected) {
      successFunction(e);
    }
  }

  bindHotkeys() {
    const that = this;
    Mousetrap.bind("up", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftCards(that.props.report, "up");
      });
    });
    Mousetrap.bind("down", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftCards(that.props.report, "down");
      });
    });
    Mousetrap.bind("left", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftCards(that.props.report, "left");
      });
    });
    Mousetrap.bind("right", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftCards(that.props.report, "right");
      });
    });
    Mousetrap.bind("shift+up", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftSelections(that.props.report, "up");
      });
    });
    Mousetrap.bind("shift+down", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftSelections(that.props.report, "down");
      });
    });
    Mousetrap.bind("tab", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftCards(that.props.report, "right");
      });
    });
    Mousetrap.bind("shift+tab", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.shiftCards(that.props.report, "left");
      });
    });
    Mousetrap.bind(["del", "backspace"], (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.props.removeCards(that.props.report);
      });
    });
    Mousetrap.bind("shift+enter", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.editLastSelectedCard(e);
      });
    });
    Mousetrap.bind("enter", (e) => {
      that.validateHotkey(e, (e) => {
        e.preventDefault();
        that.handleAddLine(e);
      });
    });
    Mousetrap.bind("esc", (e) => {
      that.handleEscape(e);
    });
  }

  unbindHotkeys() {
    Mousetrap.unbind("up");
    Mousetrap.unbind("down");
    Mousetrap.unbind("left");
    Mousetrap.unbind("right");
    Mousetrap.unbind("shift+up");
    Mousetrap.unbind("shift+down");
    Mousetrap.unbind("tab");
    Mousetrap.unbind("shift+tab");
    Mousetrap.unbind(["del", "backspace"]);
    Mousetrap.unbind("shift+enter");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("esc");
  }

  editLastSelectedCard(e) {
    e.stopPropagation();
    const { cards, report } = this.props;
    let lastSelectedIndex = 0;
    _.each(cards, (card, index) => {
      if (card.selected) {
        lastSelectedIndex = index;
      }
    });
    this.props.unselectAllCards();
    this.props.editCard(report, lastSelectedIndex, {
      selected: false,
      editing: true,
    });
  }

  handleDropCard() {
    this.props.saveOutline(this.props.report, this.props.outline);
  }

  handleCardSave(index, edits) {
    if (!_.isEmpty(edits.text)) {
      this.props.addCard(this.props.report, index, {
        indent: this.state.addCardIndent,
        originalText: edits.text,
        type: this.state.addCardType,
        selected: true,
        editing: false,
        ...edits,
      });
    }
    this.setState({ showAddCard: false });
    $(this.ref.current).find("input").attr("value", "");
  }

  copyToClipboard(onlySelected) {
    const {
      cards,
      highlightedTopics,
      title,
      description,
      wordCount,
      groupBySemanticSimilarity,
      clusteredHighlightedTopics,
    } = this.props;
    const hasSelectedAnyCards = _.filter(cards, { selected: true }).length > 0;
    const copyAlert = $(this.ref.current).find(".builder-copy-alert");
    copyAlert.addClass("show");
    if (this.copyAlertTimeout) {
      window.clearTimeout(this.copyAlertTimeout);
    }
    this.copyAlertTimeout = window.setTimeout(() => {
      copyAlert.removeClass("show");
    }, 700);

    let outline = _.map(cards, (card) => {
      if (onlySelected && !card.selected) {
        return false;
      }

      if (hasSelectedAnyCards && !card.selected) {
        return false;
      }

      if (card.type === "point") {
        if (card.indent === 0) {
          return `* ${card.text}`;
        }
        var spaces = _.times(card.indent, () => "  ").join("");
        return `${spaces}* ${card.text}`;
      }
      if (card.type === "link") {
        if (card.indent === 0) {
          return `Link - ${card.text} (${card.url})`;
        }
        var spaces = _.times(card.indent, () => "  ").join("");
        return `${spaces}Link - ${card.text} (${card.url})`;
      }
      if (card.indent === 0) {
        return `H2 - ${card.text}`;
      }
      var spaces = _.times(card.indent, () => "  ").join("");
      return `${spaces}H${card.indent + 2} - ${card.text}`;
    });
    if (!_.isEmpty(highlightedTopics) && !hasSelectedAnyCards) {
      if (groupBySemanticSimilarity) {
        outline.push("\nTopics to Cover:");
        const clusterNumbers = _.uniq(
          _.map(clusteredHighlightedTopics, "cluster")
        );
        _.each(clusterNumbers, (cluster) => {
          outline.push(
            `* ${_.map(
              _.filter(clusteredHighlightedTopics, { cluster }),
              "name"
            ).join(", ")}`
          );
        });
      } else {
        outline.push(`\nTopics to Cover: ${highlightedTopics.join(", ")}`);
      }
    }

    outline = _.compact(outline).join("\n");
    if (!hasSelectedAnyCards) {
      outline = `Title: "${title}"\nDescription: "${description}"\n${
        _.isNumber(wordCount) ? `Word Count: ${wordCount}\n` : "\n"
      }\nOutline\n${outline}`;
    }
    navigator.clipboard.writeText(outline);
  }

  copyToClipboardFormatted() {
    const {
      cards,
      highlightedTopics,
      title,
      description,
      wordCount,
      groupBySemanticSimilarity,
      clusteredHighlightedTopics,
    } = this.props;
    const hasSelectedAnyCards = _.filter(cards, { selected: true }).length > 0;
    const copyAlert = $(this.ref.current).find(".builder-copy-alert");
    copyAlert.addClass("show");
    if (this.copyAlertTimeout) {
      window.clearTimeout(this.copyAlertTimeout);
    }
    this.copyAlertTimeout = window.setTimeout(() => {
      copyAlert.removeClass("show");
    }, 700);

    let html = "";
    if (!hasSelectedAnyCards) {
      html = `<p>Title: "${title}"</p><p>Description: "${description}"</p><p>Word Count: ${wordCount}</p>`;
    }

    _.each(cards, (card, index) => {
      if (hasSelectedAnyCards && !card.selected) {
        return;
      }
      if (card.type === "point") {
        if (index > 0 && cards[index - 1].type !== "point") {
          html += `<ul><li>${card.text}</li>`;
        } else {
          html += `<li>${card.text}</li>`;
        }

        if (cards[index + 1] && cards[index + 1].type !== "point") {
          html += "</ul>";
        }
      } else if (card.type === "link") {
        if (index > 0 && cards[index - 1].type !== "point") {
          html += `<ul><li><a href='${card.url}' target='_blank'>${card.text}</a></li>`;
        } else {
          html += `<li><a href='${card.url}' target='_blank'>${card.text}</a></li>`;
        }

        if (cards[index + 1] && cards[index + 1].type !== "point") {
          html += "</ul>";
        }
      } else {
        html += `<h${card.indent + 2}>${card.text}</h${card.indent + 2}>`;
      }
    });

    if (
      !_.isEmpty(highlightedTopics) &&
      _.isArray(highlightedTopics) &&
      !hasSelectedAnyCards
    ) {
      if (groupBySemanticSimilarity) {
        html += `<p>Topics to Cover: </p>`;
        html += `<ul>`;
        const clusterNumbers = _.uniq(
          _.map(clusteredHighlightedTopics, "cluster")
        );
        _.each(clusterNumbers, (cluster) => {
          html += `<li>${_.map(
            _.filter(clusteredHighlightedTopics, { cluster }),
            "name"
          ).join(", ")}</li>`;
        });
        html += `</ul>`;
      } else {
        html += `<p>Topics to Cover: ${highlightedTopics.join(", ")}</p>`;
      }
    }

    copyFormatted(html);
  }

  handleVideo() {
    $("#outline-builder-video").modal("show");
  }

  handleAddLine(e) {
    e.stopPropagation(); // required to prevent global click event from resetting the showAddCard
    const { cardIsSelected, cards } = this.props;

    // if cards are selected, check the last selected card so that we choose the right type and indentation for addCard
    if (cardIsSelected) {
      let lastSelectedIndex = 0;
      _.each(cards, (card, index) => {
        if (card.selected) {
          lastSelectedIndex = index;
        }
      });
      const lastCard = cards[lastSelectedIndex];
      this.setState({
        showAddCard: true,
        addCardType: lastCard.type != "link" ? lastCard.type : "heading",
        addCardIndent: lastCard.indent,
      });
    } else {
      this.setState({ showAddCard: true, addCardType: "heading" });
    }
  }

  handleEscape(e) {
    const {
      cardIsSelected,
      cardIsEditing,
      unselectAllCards,
      discardEditsAllCards,
    } = this.props;
    if (cardIsEditing) {
      // discard edits
      e.stopPropagation();
      e.preventDefault();
      this.setState({
        showAddCard: false,
      });
      discardEditsAllCards();
    } else if (cardIsSelected) {
      // unselect cards
      e.stopPropagation();
      e.preventDefault();
      unselectAllCards();
    } else {
      // allow event to bubble up
    }
  }

  handleAddOrEditLine(e, type, indent) {
    e.stopPropagation();
    if (!_.isNumber(indent)) {
      indent = 0;
    }
    if (this.props.cardIsSelected && type != "link") {
      const selectedCardsOfType = _.filter(this.props.cards, {
        selected: true,
        type,
      });
      const allSelectedCards = _.filter(this.props.cards, { selected: true });
      if (selectedCardsOfType.length === allSelectedCards.length) {
        // if all cards selected are of the same type, show the add card for this type
        this.setState({
          showAddCard: true,
          addCardType: type,
          addCardIndent: indent,
        });
      } else {
        // convert selected cards to this type
        this.props.editCards(this.props.report, { type });
      }
    } else {
      this.setState({
        showAddCard: true,
        addCardType: type,
        addCardIndent: indent,
      });
    }
  }

  showPopover(e, popoverType) {
    e.stopPropagation();
    const that = this;
    if (!this.state.showPopover) {
      // this handler hides tooltip if anything is clicked on
      $(window).one("click", () => {
        that.setState({ showPopover: false });
      });
    }
    this.setState({
      popoverType,
      showPopover: true,
    });
  }

  toggleOptionsDropdown(e) {
    e.stopPropagation();
    const that = this;
    this.setState({ showOptionsDropdown: !this.state.showOptionsDropdown });
    if (!this.state.showOptionsDropdown) {
      $(window).one("click", () => {
        that.setState({ showOptionsDropdown: false });
      });
    }
  }

  handleCopyTemplate() {
    const outlineCopy = { ...this.props.outline };
    delete outlineCopy.highlightedTopics; // these should not transfer between briefs
    setJSONToLocalStorage(this.templateLocalStorageKey, outlineCopy);
    console.log(getJSONFromLocalStorage(this.templateLocalStorageKey));
    this.setState({ showOptionsDropdown: false });
  }

  handlePasteTemplate() {
    try {
      const { saveOutline, report, receiveOutline } = this.props;

      if (
        report &&
        report.outline &&
        report.outline.cards &&
        !_.isEmpty(report.outline.cards) &&
        !confirm(
          "This will replace the existing outline with the one in your clipboard. Are you sure you want to continue?"
        )
      ) {
        return;
      }

      var outline = getJSONFromLocalStorage(this.templateLocalStorageKey);
      outline = { ...outline, reportId: report.id };
      receiveOutline(report.id, outline);
      saveOutline(report, outline);
      this.setState({ showOptionsDropdown: false });
    } catch (e) {
      console.log("Something went wrong pasting the template", outline);
      console.log(e);
    }
  }

  render() {
    const that = this;
    const {
      cards,
      highlightedTopics,
      isFetching,
      title,
      description,
      report,
      groupBySemanticSimilarity,
      clusteredHighlightedTopics,
    } = this.props;
    const {
      expanded,
      showAddCard,
      showPopover,
      popoverType,
      addCardType,
      addCardIndent,
      showCopyPopover,
      showOptionsDropdown,
      showPasteTemplateOption,
    } = this.state;

    // create a data structure which represents the indexes of the cards to access
    const cardIndexes = _.range(cards.length);

    // insert the add-card under the last selected card, or at the very bottom
    if (showAddCard) {
      let insertIndex = cards.length - 1;
      _.each(cards, (card, index) => {
        if (card.selected) {
          insertIndex = index;
        }
      });
      cardIndexes.splice(insertIndex + 1, 0, "addCard");
    }

    const hasNoCards = _.isEmpty(cardIndexes);
    const selectedCardCount = _.filter(cards, { selected: true }).length;

    // clustering of topics to cover
    const clusteringAvailable =
      _.isArray(clusteredHighlightedTopics) &&
      !_.isEmpty(clusteredHighlightedTopics);
    if (groupBySemanticSimilarity && clusteringAvailable) {
      const colorSeed = report.name.length; // this ensures colors stay consistent within a brief
      var clusterColors = getShuffledClusterColors(colorSeed);
    }

    let ideasButton;
    if (!_.isEmpty(title) && !_.isEmpty(description)) {
      ideasButton = (
        <button
          className="btn btn-sm btn-white text-bluegray-5 builder-idea-btn"
          onClick={(e) => {
            this.showPopover(e, "heading");
          }}
        >
          <i className="fas fa-lightbulb" /> Get Outline Ideas
        </button>
      );
    } else {
      ideasButton = (
        <div
          className="d-inline"
          data-original-title="Add a title and description first"
          data-toggle="tooltip"
          title=""
        >
          <button
            className="btn btn-sm btn-white text-bluegray-5 builder-idea-btn"
            style={{ opacity: 0.3 }}
          >
            <i className="fas fa-lightbulb" /> Get Outline Ideas
          </button>
        </div>
      );
    }

    return (
      <div
        ref={this.ref}
        className="d-flex"
        style={{
          flexDirection: "column",
          height: "100%",
          opacity: isFetching ? ".5" : "1",
        }}
      >
        <i
          className={`text-bluegray-4 fa fa-gear builder-button-options clickable ${
            showOptionsDropdown ? "active" : ""
          }`}
          onClick={this.toggleOptionsDropdown}
        />
        {showOptionsDropdown && (
          <div className="builder-options-dropdown">
            <div className="clickable" onClick={this.handleCopyTemplate}>
              Copy Template
            </div>
            {showPasteTemplateOption && (
              <div className="clickable" onClick={this.handlePasteTemplate}>
                Paste Template
              </div>
            )}
          </div>
        )}
        <div className="builder-panel builder-expanded flex-grow-1">
          <div>
            <OutlineBuilderTitleAndDescription
              report={this.props.report}
              onShowPopover={this.showPopover}
              unselectAllCards={this.props.unselectAllCards}
            />
            <OutlineBuilderWordCount report={this.props.report} />
            <div className="mt-3">
              <label className="text-bold">Outline</label> {ideasButton}
            </div>
            <div className="builder-buttons-container">
              <div
                className={`builder-buttons ${
                  this.props.cardIsSelected ? "card-selected" : ""
                } mt-1`}
              >
                <i
                  data-original-title="Move up<br /><span style='font-size:8px'>Shortcut: Up Arrow</span>"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-angle-up pl-0"
                  onClick={() => {
                    this.props.shiftCards(this.props.report, "up");
                  }}
                />
                <i
                  data-original-title="Move down<br /><span style='font-size:8px'>Shortcut: Down Arrow</span>"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-angle-down"
                  onClick={() => {
                    this.props.shiftCards(this.props.report, "down");
                  }}
                />
                <i
                  data-original-title="Unindent<br /><span style='font-size:8px'>Shortcut: Tab</span>"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-angle-left"
                  onClick={() => {
                    this.props.shiftCards(this.props.report, "left");
                  }}
                />
                <i
                  data-original-title="Indent<br /><span style='font-size:8px'>Shortcut: Shift+Tab</span>"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-angle-right"
                  onClick={() => {
                    this.props.shiftCards(this.props.report, "right");
                  }}
                />
                <i
                  data-original-title="Remove line<br /><span style='font-size:8px'>Shortcut: Backspace</span>"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-trash"
                  onClick={() => {
                    this.props.removeCards(this.props.report);
                  }}
                />
                <i
                  data-original-title="Heading"
                  data-toggle="tooltip"
                  className="builder-button fas fa-heading active"
                  onClick={(e) => {
                    this.handleAddOrEditLine(e, "heading");
                  }}
                />
                <i
                  data-original-title="Bullet Point"
                  data-toggle="tooltip"
                  className="builder-button fas fa-list-ul active"
                  onClick={(e) => {
                    this.handleAddOrEditLine(e, "point", 0);
                  }}
                />
                <i
                  data-original-title="Add line<br /><span style='font-size:8px'>Shortcut: Enter</span>"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-plus active"
                  onClick={this.handleAddLine}
                />
                <i
                  data-original-title="Add link"
                  data-html="true"
                  data-toggle="tooltip"
                  title=""
                  className="builder-button fas fa-link active"
                  onClick={(e) => {
                    this.handleAddOrEditLine(e, "link");
                  }}
                />
              </div>
            </div>
            <div>
              {hasNoCards && (
                <div
                  className="text-bluegray-4 clickable mt-3"
                  style={{ borderBottom: "1px solid #91a9c3" }}
                  onClick={this.handleAddLine}
                >
                  click to add Heading
                </div>
              )}

              {cardIndexes.map((cardIndex, i) => {
                if (cardIndex === "addCard") {
                  // "add line" card
                  return (
                    <OutlineBuilderCard
                      key={`${i}custom`}
                      type={addCardType}
                      indent={addCardIndent}
                      selected={false}
                      editing
                      draggable={false}
                      selectable={false}
                      editCard={this.handleCardSave}
                      id={_.random(10000000)}
                      text=""
                      url=""
                      index={i}
                    />
                  );
                }
                // regular card
                const card = cards[cardIndex];
                return (
                  <OutlineBuilderCard
                    index={i}
                    key={i}
                    id={card.id}
                    text={card.text}
                    url={card.url}
                    type={card.type}
                    indent={card.indent}
                    selected={card.selected}
                    editing={card.editing}
                    draggable={this.props.draggable}
                    selectable={this.props.selectable}
                    moveCard={this.props.moveCard}
                    moveSelectedCards={this.props.moveSelectedCards}
                    selectCard={this.props.selectCard}
                    editCard={(index, edits) => {
                      that.props.editCard(that.props.report, index, edits);
                    }}
                    dropCard={this.handleDropCard}
                    unselectAllCards={this.props.unselectAllCards}
                    removeCards={this.props.removeCards}
                    report={this.props.report}
                  />
                );
              })}
              {!_.isEmpty(highlightedTopics) && (
                <div
                  className="pt-3 mt-2 pb-2"
                  style={{ borderTop: "1px solid rgba(158, 187, 218, 0.25)" }}
                >
                  <span className="text-bold">Topics to Cover</span>:
                  {clusteringAvailable &&
                    groupBySemanticSimilarity &&
                    clusteredHighlightedTopics.map((topic, i) => {
                      const clusterColor =
                        clusterColors[topic.cluster % clusterColors.length];
                      return (
                        <div
                          key={i}
                          className={`badge badge-light bg-${clusterColor}-1 text-${clusterColor}-9`}
                          style={{ marginRight: "3px" }}
                        >
                          {topic.name}
                        </div>
                      );
                    })}
                  {(!clusteringAvailable || !groupBySemanticSimilarity) &&
                    highlightedTopics.map((topic, i) => (
                      <div
                        key={i}
                        className="badge badge-light"
                        style={{ marginRight: "3px" }}
                      >
                        {topic}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {!isFetching && (
          <div className="flex-grow-0 text-center position-relative pt-3">
            <div className="builder-copy-alert text-success">Copied!</div>
            {!showCopyPopover && (
              <button
                className="btn btn-outline-secondary w-100 p-2"
                onClick={() => {
                  this.copyToClipboard(false);
                  this.setState({ showCopyPopover: !showCopyPopover });
                }}
              >
                <i className="fas fa-clipboard active" />{" "}
                <span>
                  Copy{" "}
                  {selectedCardCount === 0
                    ? "All"
                    : `${selectedCardCount} Selected`}{" "}
                  to Clipboard
                </span>
              </button>
            )}
            {showCopyPopover && (
              <div className="d-flex">
                <button
                  className="btn btn-outline-secondary p-2 flex-grow-1 builder-copy-button-2"
                  onClick={() => {
                    this.copyToClipboardFormatted();
                    this.setState({ showCopyPopover: false });
                  }}
                >
                  <i className="fas fa-clipboard active" /> Copy Formatted Text
                </button>
                <div className="p-2" />
                <button
                  className="btn btn-outline-secondary p-2 flex-grow-1 builder-copy-button-2"
                  onClick={() => {
                    this.copyToClipboard(false);
                    this.setState({ showCopyPopover: false });
                  }}
                >
                  <i className="fas fa-clipboard active" /> Copy Plain Text
                </button>
              </div>
            )}
          </div>
        )}
        {showPopover && (
          <OutlineBuilderPopover
            type={popoverType}
            report={this.props.report}
            handleClose={() => {
              this.setState({ showPopover: false });
              this.props.hideOutlineBookmarks();
            }}
            handleSetPopoverType={(popoverType) => {
              that.setState({ popoverType });
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let recommended_topics;
  if (!_.isEmpty(ownProps.report) && !_.isEmpty(ownProps.report.report)) {
    recommended_topics = ownProps.report.report.recommended_topics;
  }

  const hasClusters =
    !_.isEmpty(recommended_topics) && _.isNumber(recommended_topics[0].cluster);
  let clusteredHighlightedTopics;
  if (hasClusters) {
    clusteredHighlightedTopics = _.map(
      state.outline.highlightedTopics,
      (highlightedTopic) =>
        _.find(
          recommended_topics,
          (topicWithCluster) => topicWithCluster.name === highlightedTopic
        )
    );
    clusteredHighlightedTopics = _.sortBy(
      _.compact(clusteredHighlightedTopics),
      (topic) => topic.cluster
    );
  }
  const props = {
    cards: state.outline.cards,
    outline: state.outline,
    title: state.outline.title,
    description: state.outline.description,
    wordCount: state.outline.wordCount,
    recentlyToggled: state.outline.recentlyToggled,
    highlightedTopics: state.outline.highlightedTopics,
    cardIsSelected: _.isObject(_.find(state.outline.cards, { selected: true })),
    cardIsEditing: _.isObject(_.find(state.outline.cards, { editing: true })),
    draggable: !_.isObject(_.find(state.outline.cards, { editing: true })), // disable dnd when cards being edited
    selectable: !_.isObject(_.find(state.outline.cards, { editing: true })), // disable selecting when cards being edited
    isFetching: state.reports.isFetching,
    clusteredHighlightedTopics,
  };
  return props;
}

export default withRouter(
  connect(mapStateToProps, {
    moveCard,
    moveSelectedCards,
    selectCard,
    editCard,
    editCards,
    shiftCards,
    shiftSelections,
    removeCards,
    addCard,
    unselectAllCards,
    discardEditsAllCards,
    clearRecentlyToggled,
    saveOutline,
    receiveOutline,
    hideOutlineBookmarks,
  })(OutlineBuilderCards)
);
