import React from "react";

class EditorDisconnectModal extends React.Component {
  render() {
    return (
      <div
        aria-labelledby="EditorDisconnectModal"
        className="modal fade EditorDisconnectModal onboarding-modal show"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-disconnected" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true"> &times;</span>
              </button>
            </div>
            <div className="onboarding-content with-gradient pt-2 text-center">
              <div style={{ fontSize: "62px", opacity: 0.6 }}>
                <i className="fas fa-wifi-slash" />
              </div>
              <p className="text-bold mb-1">Uh oh, you’ve been disconnected</p>
              <p>
                Please re-connect to the Internet to continue using the Content
                Grader.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorDisconnectModal;
