import React from "react";
import { connect } from "react-redux";
import Loader from "./loader";
import { addCard } from "./redux/actions";
import { cleanTitle } from "./helpers";

class OutlineBuilderPopoverHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      headings: [],
      headingsChosen: [],
      error: "",
      currentState: "headings", // 'headings', 'tryAgain', 'noTitle'
    };
    this.ref = React.createRef();
    this.handleSelectHeading = this.handleSelectHeading.bind(this);
    this.generateHeadings = this.generateHeadings.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.title) || _.isEmpty(this.props.description)) {
      this.setState({ currentState: "noTitle" });
    } else {
      this.generateHeadings();
    }
  }

  generateHeadings() {
    const that = this;
    this.setState({ isLoading: true, error: "" });
    $.getJSON(
      `/api/openai/outlines?title=${encodeURIComponent(
        this.props.title
      )}&description=${encodeURIComponent(this.props.description)}`
    )
      .done((res) => {
        if (!_.isEmpty(res)) {
          if (res.filter === "1" || res.filter === "2") {
            that.setState({
              error:
                "The content filter has detected that the generated text may potentially be sensitive, political, or controversial. Give it an extra check just in case something sounds wrong.",
              headings: res.outlines[0],
              currentState: "headings",
            });
          } else if (!_.isEmpty(res.outlines) && !_.isEmpty(res.outlines[0])) {
            that.setState({
              headings: res.outlines[0],
              currentState: "headings",
            });
          }
        } else {
          that.setState({
            currentState: "tryAgain",
            error: "Unable to come up with any ideas. Please try again.",
          });
        }
      })
      .fail((res) => {
        that.setState({
          currentState: "tryAgain",
          error:
            "Something went wrong getting the ideas. Please email support@usetopic.com",
        });
      })
      .always(() => {
        that.setState({
          isLoading: false,
        });
      });
  }

  handleSelectHeading(heading) {
    const { headingsChosen } = this.state;
    if (headingsChosen.includes(heading)) {
      this.setState({
        headingsChosen: _.without(headingsChosen, heading),
      });
    } else {
      this.setState({
        headingsChosen: headingsChosen.concat(heading),
      });
    }
  }

  saveHeadingsToOutline() {
    const that = this;
    const { headingsChosen, headings } = this.state;
    let headingsToAdd;

    if (_.isEmpty(headingsChosen)) {
      headingsToAdd = headings;
    } else {
      headingsToAdd = headingsChosen;
    }
    headingsToAdd.forEach((heading) => {
      that.props.addCard(that.props.report, 999999, {
        text: heading,
        indent: 0,
        originalText: heading,
        type: "heading",
      });
    });
    this.props.handleClose();
  }

  render() {
    const that = this;
    const { isLoading, headings, headingsChosen, error, currentState } =
      this.state;
    const { type, handleClose } = this.props;

    let buttonText = 0;
    if (_.isEmpty(headingsChosen)) {
      buttonText = "Add All Headings";
    } else {
      buttonText = `Add ${headingsChosen.length} Heading${
        headingsChosen.length > 1 ? "s" : ""
      }`;
    }
    return (
      <div>
        <div
          className={`builder-popover-rainbow ${isLoading ? "loading" : ""}`}
        />
        <div className={isLoading ? "builder-popover-isloading" : ""}>
          <div className="builder-popover-head mb-2">Get Outline Ideas</div>
          {currentState === "noTitle" && (
            <div>
              Please add a title and description before generating an outline.
            </div>
          )}
          {currentState === "tryAgain" && (
            <div>
              {error && <div className="text-danger">{error}</div>}
              <button
                className="btn btn-primary"
                onClick={this.generateHeadings}
              >
                Try Again
              </button>
            </div>
          )}
          {currentState === "headings" && (
            <div>
              {!isLoading && (
                <div className="mb-3">
                  Here is an idea for an outline.{" "}
                  <button
                    className="btn btn-white text-bluegray-5 builder-popover-refresh"
                    onClick={this.generateHeadings}
                  >
                    <i className="fas fa-redo-alt" /> Refresh
                  </button>
                </div>
              )}
              {isLoading && <div>generating headings...</div>}
              {!isLoading && (
                <div className="builder-popover-body">
                  {headings.map((heading, i) => {
                    const headingIsChosen = headingsChosen.includes(heading);
                    return (
                      <div
                        key={i}
                        className={`clickable ml-1 mr-1 p-2 mt-2 mb-2 builder-popover-card animated fadeUp delay-${i} ${
                          headingIsChosen ? "text-bold active" : ""
                        }`}
                        onClick={that.handleSelectHeading.bind(that, heading)}
                      >
                        {heading}{" "}
                        {headingIsChosen && (
                          <i className="fas fa-check text-success ml-2" />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {error && <div className="text-danger">{error}</div>}
              {!isLoading && (
                <div className="text-right">
                  <div
                    onClick={() => {
                      this.saveHeadingsToOutline();
                    }}
                    className="clickable text-right btn btn-primary mt-2 builder-popover-heading-submit"
                  >
                    {buttonText}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    title: state.outline.title,
    description: state.outline.description,
  };
  return props;
}

export default connect(mapStateToProps, { addCard })(
  OutlineBuilderPopoverHeading
);
