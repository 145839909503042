import React from "react";
import { DragLayer } from "react-dnd";
import { DNDTypes } from "./dndTypes";
import OutlineBuilderCardDragPreview from "./outline_builder_card_drag_preview";

class OutlineBuilderCustomDragLayer extends React.Component {
  constructor(props) {
    super(props);
    this.getItemStyles = this.getItemStyles.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  getItemStyles() {
    const { initialOffset, currentOffset } = this.props;
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  renderItem() {
    const { item, itemType } = this.props;
    switch (itemType) {
      case DNDTypes.CARD:
        return <OutlineBuilderCardDragPreview item={item} />;
      default:
        return null;
    }
  }

  render() {
    const layerStyles = {
      position: "fixed",
      pointerEvents: "none",
      zIndex: 100,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
    };

    return (
      <div style={layerStyles}>
        <div style={this.getItemStyles()}>{this.renderItem()}</div>
      </div>
    );
  }
}

export default DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))(OutlineBuilderCustomDragLayer);
