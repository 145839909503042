import React from "react";
import Select from "react-select";
import _ from "lodash";
import { headersToHTML, downloadCSV } from "./helpers";
import ReportOverviewOutline from "./report_overview_outline";

class ReportOverviewOutlines extends React.Component {
  constructor(props) {
    super(props);

    this.sortedByOptions = [
      {
        value: "position",
        label: "Position",
      },
      {
        value: "grade",
        label: "Content Grade",
      },
    ];

    this.state = {
      columns: 2,
      showAll: false,
      sortedBy: this.sortedByOptions[0],
      competitorCards: [], // populated by the reorder function
    };

    this.node = React.createRef();
    this.reorderIfNeeded = this.reorderIfNeeded.bind(this);
    this.resize = this.resize.bind(this);
    this.downloadOutlines = this.downloadOutlines.bind(this);
  }

  componentDidMount() {
    this.reorderIfNeeded();
    this.resize();
    window.addEventListener("resize", this.resize);
    this.resizeObserver = new ResizeObserver(this.resize);
    this.resizeObserver.observe(this.node.current);
  }

  componentDidUpdate(prevProps, prevState) {
    // we have to cache the scrollTop to prevent scroll position from changing during resize
    const competitorExpanded = !_.isEqual(
      _.map(prevProps.report.report.competitors, "expansion_status"),
      _.map(this.props.report.report.competitors, "expansion_status")
    );
    if (
      prevState.sortedBy !== this.state.sortedBy ||
      prevState.showAll !== this.state.showAll ||
      competitorExpanded
    ) {
      this.reorderIfNeeded();
      this.resize();
    }
    $(".tooltip").remove();
    $(this.node.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    if (this.resizeObserver && this.node) {
      this.resizeObserver.unobserve(this.node.current);
    }
    $(".topic-masonry-layout").isotope("destroy");
  }

  resize() {
    const that = this;

    const windowWidth = $(window).width();
    if (windowWidth < 1500) {
      this.setState({
        columns: 2,
      });
    } else {
      this.setState({
        columns: 3,
      });
    }

    const columnWidth = Math.floor(
      $(that.node.current).width() / that.state.columns
    );
    $(".topic-masonry-card").css("width", `${columnWidth}px`);
    if ($(".topic-masonry-layout").data("isotope")) {
      window.setTimeout(() => {
        $(".topic-masonry-layout").isotope("reloadItems").isotope({
          masonry: {
            columnWidth,
          },
        });
      }, 1);
    } else {
      $(".topic-masonry-layout").isotope({
        itemSelector: ".topic-masonry-card",
        horizontalOrder: true,
        masonry: {
          columnWidth,
        },
        sortAscending: {
          position: true,
          grade: false,
        },
        getSortData: {
          position(itemElem) {
            return parseInt($(itemElem).attr("data-position"), 10);
          },
          grade(itemElem) {
            return parseInt($(itemElem).attr("data-coverage"), 10);
          },
        },
        sortBy: that.state.sortedBy.value,
      });
    }
  }

  reorderIfNeeded() {
    const that = this;
    let competitors = _.cloneDeep(this.props.report.report.competitors);

    // remove any with no outline
    competitors = _.filter(competitors, (competitor) => {
      if (competitor.opted_out || competitor.no_access) {
        return false;
      }
      const oldHeaderCheck = headersToHTML(competitor.html).length > 3;
      const newHeaderCheck =
        _.isArray(competitor.headings) && competitor.headings.length >= 3;
      return newHeaderCheck || oldHeaderCheck;
    });

    // top 3
    if (!this.state.showAll) {
      competitors = competitors.splice(0, 3);
    }

    if (!_.isEqual(this.state.competitorCards, competitors)) {
      this.setState({
        competitorCards: competitors,
      });
    }
  }

  // download CSV
  downloadOutlines() {
    const competitors = _.cloneDeep(this.props.report.report.competitors);
    if (_.isArray(competitors)) {
      const data = [["heading", "tag", "url", "title", "position"]];
      competitors.forEach((competitor) => {
        const { headings } = competitor;
        headings.forEach((heading) => {
          const { takeaways } = heading;
          data.push([
            `"${heading.text.replace(/"|#/g, "")}"`,
            heading.tag,
            `"${competitor.url.replace(/,|#/g, "")}"`,
            `"${competitor.title.replace(/,|#/g, "")}"`,
            competitor.position,
          ]);
          if (!_.isEmpty(takeaways)) {
            takeaways.forEach((takeaway) => {
              data.push([
                `"${takeaway.replace(/"|#/g, "")}"`,
                "takeaway",
                `"${competitor.url.replace(/,|#/g, "")}"`,
                `"${competitor.title.replace(/,|#/g, "")}"`,
                competitor.position,
              ]);
            });
          }
        });
      });

      downloadCSV(
        `Competitor Content for ${this.props.report.report.focus_keyword}.csv`,
        data
      );
    }
  }

  render() {
    const that = this;
    AP.headersToHTML = headersToHTML;
    const { report, toggleCard, fetchReport, cards } = this.props;

    const competitorCardsView = this.state.competitorCards.map(
      (competitor, index) => (
        <ReportOverviewOutline
          key={index}
          report={report}
          toggleCard={toggleCard}
          competitor={competitor}
          index={index}
          fetchReport={fetchReport}
          cards={cards}
          noAccess={competitor.no_access ? competitor.no_access : false}
          showToast={this.props.showToast}
          resize={this.resize}
        />
      )
    );

    return (
      <div className="position-relative">
        <button
          className="btn btn-white"
          style={{
            position: "absolute",
            top: "-10px",
            right: "-10px",
            opacity: "0.5",
          }}
          onClick={this.downloadOutlines}
        >
          <i className="fa fa-download" /> Export CSV
        </button>
        <h2>Competitor Outlines</h2>
        <p>
          Easily see how others are covering this topic so that you can quickly
          create your own outline.{" "}
          <a
            href="https://help.adthrive.com/hc/en-us/articles/4407864405531-Why-was-Topic-unable-to-pull-an-outline-from-a-competitor-"
            target="_blank"
            className="text-bluegray-4"
            rel="noreferrer"
          >
            Why are some outlines missing?
          </a>
        </p>
        <div className="row">
          <div className="col-md-12">
            <div className="w-100" ref={this.node}>
              <div>
                Sort by:{" "}
                <div style={{ display: "inline-block", width: "150px" }}>
                  <Select
                    options={this.sortedByOptions}
                    onChange={(selected) => {
                      this.setState({ sortedBy: selected });
                    }}
                    value={this.state.sortedBy}
                  />
                </div>
              </div>
              <div
                className="report-overview-outlines topic-masonry-layout"
                style={{ marginTop: "4rem" }}
              >
                {_.isEmpty(this.state.competitorCards) && (
                  <div>No outlines discovered</div>
                )}
                {!_.isEmpty(this.state.competitorCards) && competitorCardsView}
              </div>
              {!this.props.isAnon && (
                <div
                  className="clickable bg-bluegray-1 p-3 text-center"
                  onClick={() => {
                    this.setState({ showAll: !this.state.showAll });
                    this.resize();
                  }}
                >
                  {!this.state.showAll
                    ? "Show all outlines"
                    : "Minimize outlines"}
                </div>
              )}
              {this.props.isAnon && (
                <div
                  className="clickable bg-bluegray-1 p-3 text-center"
                  onClick={() => {
                    $(".upgradeModal").modal("show");
                  }}
                >
                  Unlock all outlines <i className="far fa-lock-alt" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportOverviewOutlines;
